import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Rollup } from 'shared/types/protoc-gen/raas-rollup';
import { Hex, hexToNumber } from 'viem';

export const useCurrentBlockheight = (rollup: Rollup | undefined) =>
  useQuery({
    queryKey: ['GET_BLOCK_HEIGHT', { rollupId: rollup?.id }],
    queryFn: async () => {
      const types = ['unsafe', 'safe', 'finalized'];

      const resps = await Promise.all(
        types.map(type =>
          axios.post<{
            jsonrpc: '2.0';
            result: Hex;
            id: 1;
          }>(rollup?.rpcEndpoint ?? '', {
            jsonrpc: '2.0',
            method: 'eth_blockNumber',
            id: 1,
            params: [type],
          }),
        ),
      );

      const result = resps.map<{ type: string; blockHeight: number }>((resp, idx) => ({
        type: types[idx],
        blockHeight: hexToNumber(resp.data.result),
      }));

      return result;
    },
    enabled: !!rollup,
  });
