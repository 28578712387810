import { Box, CircularProgress, Stack } from '@mui/material';
import { Footer } from 'react-day-picker';

import Header from './Header';
import { BaseLayout } from '.';

export default function ContentLoading() {
  return (
    <BaseLayout>
      <Box
        sx={{
          background: "url('/imgs/bgApp.png')",
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          backgroundRepeat: 'repeat',
          zIndex: -1,
          bgcolor: '#fafafa',
          backgroundPositionY: '5rem',
        }}
      />
      <Header />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flex: 1,
          position: 'relative',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <CircularProgress size={50} />
      </Stack>
      <Footer />
    </BaseLayout>
  );
}
