import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useRestartApp } from 'pages/aaas/hooks/useRestartApp';
import { DialogHeader } from 'shared/components/Dialog';
import { AAAApp } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

import { useChatStore } from '../../useChatStore';

export interface IRestartAppDialog extends DialogProps {
  app?: AAAApp;
  onCloseMenu: () => void;
}

export const RestartAppDialog: React.FC<IRestartAppDialog> = ({
  app,
  onClose,
  onCloseMenu,
  ...props
}) => {
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const { reset } = useChatStore();

  const { isPending: restartAppPending, mutateAsync: restartApp } = useRestartApp({
    onSuccess: () => {
      addAlert({ severity: ALERT_SEVERITY.SUCCESS, title: `Successfully restarted ${app?.name}` });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.AAAS_GET_AAA_APP_LOGS, { id: app?.id }],
      });

      reset(app?.id);

      handleClose();
    },
  });

  const handleRestartApp = () => {
    if (!app?.id) return;

    restartApp({ id: app?.id });
  };

  const handleClose = () => {
    onClose?.({}, 'backdropClick');
    onCloseMenu();
  };

  return (
    <Dialog onClose={onClose} {...props}>
      <Stack spacing="40px" sx={{ p: '40px', maxWidth: '504px' }}>
        <DialogHeader onClose={handleClose} title="Are you sure you want to restart this app?" />

        <Typography variant="caption">
          Restarting the app will take few minutes before the agent will be active again.
        </Typography>
        <LoadingButton
          disableElevation
          fullWidth
          loading={restartAppPending}
          onClick={handleRestartApp}
          variant="text"
        >
          <Typography variant="bodySmall">Yes</Typography>
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};
