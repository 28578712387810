import { RollupStatusChip } from 'pages/raas/components';
import { SectionContentKeyValue } from 'pages/raas/DeploymentDetails/components/SectionContentKeyValue';
import { isBscSettlementLayer } from 'pages/raas/DeploymentDetails/utils';
import {
  DataAvailabilityLayer,
  renderDaTypeIcon,
  renderRollupSettlementLayerIcon,
  renderRollupTypeIcon,
  RollupType,
  SettlementLayer,
} from 'pages/raas/helpers/labels';
import { TokenSymbolIcon } from 'shared/components/icons/TokenSymbolIcon';
import { Section } from 'shared/components/Section';

import { IRollupInfoSection } from './types';

export const GeneralSection = ({ loading, rollup }: IRollupInfoSection) => {
  return (
    <Section
      renderGridContent={
        <>
          <SectionContentKeyValue
            label="Chain ID"
            loading={loading}
            value={String(rollup?.chainId ?? '-')}
            xs={4}
          />
          <SectionContentKeyValue
            label="Rollup Stack"
            loading={loading}
            renderIcon={renderRollupTypeIcon(rollup?.type ?? RollupType.UNRECOGNIZED)}
            value={rollup?.type}
            xs={4}
          />
          <SectionContentKeyValue
            label="Settlement Layer"
            loading={loading}
            renderIcon={renderRollupSettlementLayerIcon(
              rollup?.settlementLayer ?? SettlementLayer.UNRECOGNIZED,
            )}
            value={rollup?.settlementLayer}
            xs={4}
          />
          <SectionContentKeyValue
            label="Symbol"
            loading={loading}
            renderIcon={<TokenSymbolIcon symbol={rollup?.tokenMetadata?.symbol} />}
            value={rollup?.tokenMetadata?.symbol}
            xs={4}
          />
          <SectionContentKeyValue
            label="Data Availability"
            loading={loading}
            renderIcon={renderDaTypeIcon(
              isBscSettlementLayer(rollup) &&
                rollup?.dataAvailabilityLayer === DataAvailabilityLayer.BLOBS
                ? DataAvailabilityLayer.BLOBS_BNB
                : rollup?.dataAvailabilityLayer ?? DataAvailabilityLayer.UNRECOGNIZED,
            )}
            value={rollup?.dataAvailabilityLayer}
            xs={4}
          />
        </>
      }
      renderSubHeaderEndContent={<RollupStatusChip loading={loading} rollup={rollup} />}
      title="General"
    />
  );
};
