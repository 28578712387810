import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface ISubHeader {
  children: ReactNode | string;
  isLoading?: boolean;
  endAdornment?: ReactNode;
}

export const SubHeader = ({ children, endAdornment, isLoading }: ISubHeader) => {
  return (
    <Grid
      alignItems="center"
      container
      sx={{
        background: '#fafafa',
        px: 5,
        py: 4,
        border: theme => `1px solid ${theme.colors.functional.subject.border}`,
        zIndex: 10,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ width: '100%', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
      >
        <Stack direction="row" spacing={3}>
          {typeof children === 'string' ? (
            <Typography variant="h5">{children}</Typography>
          ) : (
            children
          )}

          <Stack alignItems="center" justifyContent="center">
            {isLoading && <CircularProgress size={20} />}
          </Stack>
        </Stack>

        {endAdornment}
      </Stack>
    </Grid>
  );
};
