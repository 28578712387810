import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { FC, useCallback, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { bff } from 'shared/api';
import { CustomAutocomplete } from 'shared/components/CustomAutocomplete';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import { FormTextField, Label } from 'shared/components/form';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import {
  CreateGenerateCharacterJobReply,
  CreateGenerateCharacterJobRequest,
} from 'shared/types/protoc-gen/bffaaa';
import { MUTATION_KEYS } from 'shared/types/react-query';
import * as yup from 'yup';

const LOCAL_STORAGE_KEY = 'wizard-fe.user.twitter-profiles';
const FAMOUS_TWITTER_PROFILES = [
  'elonmusk',
  'realDonaldTrump',
  'BarackObama',
  'BillGates',
  'Cristiano',
  'justinbieber',
  'rihanna',
  'katyperry',
  'taylorswift13',
];

const twitterAccountRule = yup
  .string()
  .required('Twitter profile is required')
  .matches(
    /^[A-Za-z0-9_]{1,15}$/,
    'Twitter profile must be a valid username (1-15 characters, only letters, numbers, and underscores)',
  );

const formSchema = yup.object().shape({
  bio: yup.string().required(),
  lore: yup.string().required(),
  twitterAccount: twitterAccountRule,
});

interface FormValues extends CreateGenerateCharacterJobRequest {
  bio: string;
  lore: string;
}

interface IGeneratePersonaDialog extends DialogProps {
  onGenerated: (reply: CreateGenerateCharacterJobReply & FormValues) => void;
}

export const GeneratePersonaDialog: FC<IGeneratePersonaDialog> = ({
  onClose,
  onGenerated,
  ...props
}) => {
  const { addAlert } = useAlerts();
  const { sm } = useBreakpoints();

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const [twitterProfiles, setTwitterProfiles] = useState<string[]>([
    ...FAMOUS_TWITTER_PROFILES,
    ...(JSON.parse(localStorage?.getItem(LOCAL_STORAGE_KEY) || '[]') || []),
  ]);

  const form = useForm<FormValues>({
    defaultValues: {
      twitterAccount: '',
      bio: showExperimentalFeatures ? '' : 'bio',
      lore: showExperimentalFeatures ? '' : 'lore',
    },
    resolver: yupResolver(formSchema),
  });
  const formValues = form.watch();

  const { isPending: isGeneratePersonaPending, mutate: generatePersona } = useMutation({
    mutationKey: [MUTATION_KEYS.AAAS_GENERATE_PERSONA],
    mutationFn: async (payload: FormValues) => {
      const res = await bff.post<CreateGenerateCharacterJobReply>('bff/aaa/characters', payload);

      return { ...payload, ...res.data };
    },
    onSuccess: result => {
      onGenerated(result);
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Persona generation initiated',
        desc: 'This usually takes approximately 30 seconds, please wait.',
      });
      handleClose();
    },
  });

  const onValid: SubmitHandler<FormValues> = useCallback(
    formValues => generatePersona(formValues),
    [generatePersona],
  );

  return (
    <Dialog fullScreen={sm} id="generate-persona-dialog" maxWidth="md" {...props}>
      <FormProvider {...form}>
        <form
          id="generate-persona-dialog-form"
          name="generate-persona-dialog-form"
          onSubmit={form.handleSubmit(onValid)}
        >
          <DialogContainer sx={{ minWidth: '768px' }}>
            <DialogHeader
              alignItems="start"
              onClose={handleClose}
              startAdornment={
                <Stack>
                  <Typography variant="h5">Generate Persona</Typography>
                  <Typography variant="caption">
                    Generate a persona using a public Twitter profile
                  </Typography>
                </Stack>
              }
            />

            <Stack spacing={2}>
              <Controller
                name="twitterAccount"
                render={({ fieldState }) => {
                  return (
                    <FormControl>
                      <Stack spacing={1}>
                        <Label label="Twitter Profile" />
                        <CustomAutocomplete
                          afterChange={selected => {
                            try {
                              if (selected?.value) {
                                const value = String(selected.value).replace('@', '');

                                form.setValue('twitterAccount', value, {
                                  shouldTouch: true,
                                  shouldValidate: true,
                                });

                                const saved =
                                  localStorage?.getItem(LOCAL_STORAGE_KEY)?.split(',') ?? [];

                                if (
                                  !FAMOUS_TWITTER_PROFILES.includes(value) &&
                                  twitterAccountRule.validateSync(value)
                                )
                                  localStorage.setItem(
                                    LOCAL_STORAGE_KEY,
                                    JSON.stringify([...saved, value]),
                                  );

                                setTwitterProfiles(prev => [...prev, ...(selected ? [value] : [])]);
                              }
                            } catch (err) {
                              console.error('err:', err);
                            }
                          }}
                          creatable
                          getCreateOptionText={input => `Use Twitter profile: "${input}"`}
                          noOptionsText="Start typing to add a Twitter profile"
                          options={twitterProfiles.map(twitterProfile => ({
                            label: `@${twitterProfile}`,
                            value: twitterProfile,
                          }))}
                          placeholder="Select or input a Twitter handle"
                          sx={{
                            '& input': { height: 40, px: 5 },
                            '& .MuiInputBase-root': { px: 3 },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme =>
                                fieldState?.error ? theme.colors.schema.error : undefined,
                            },
                          }}
                          value={
                            formValues.twitterAccount
                              ? {
                                  value: formValues.twitterAccount,
                                  label: `@${formValues.twitterAccount}`,
                                }
                              : null
                          }
                        />
                        {fieldState?.error && (
                          <FormHelperText sx={{ ml: 2, color: theme => theme.colors.schema.error }}>
                            {fieldState.error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    </FormControl>
                  );
                }}
              />

              {showExperimentalFeatures && (
                <>
                  <Stack spacing={1}>
                    <Label label="Bio" />
                    <FormTextField
                      fieldConfig={{
                        name: 'bio',
                        placeholder: `Provide a detailed biography of the character, including their background, life experiences, and key personality traits. Write one complete sentence per line.`,
                      }}
                      helperTextShrink
                      hideLabel
                      maxRows={6}
                      minRows={4}
                      multiline
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Label label="Lore" />
                    <FormTextField
                      fieldConfig={{
                        name: 'lore',
                        placeholder: `Detail the character's universe, background, and significant events that influenced them. Write one complete sentence per line.`,
                      }}
                      helperTextShrink
                      hideLabel
                      maxRows={6}
                      minRows={4}
                      multiline
                    />
                  </Stack>
                </>
              )}
            </Stack>

            <LoadingButton
              disabled={!form.formState.isValid}
              id="generate-persona-dialog-submit-button"
              loading={form.formState.isSubmitting || isGeneratePersonaPending}
              type="submit"
              variant="contained"
            >
              Generate
            </LoadingButton>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};
