import { LoadingButton } from '@mui/lab';
import { CircularProgress, Skeleton, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FULFILLMENT_CHANNEL_ID } from 'pages/aaas/AgentCredits/constants';
import { AAAS_PATHS } from 'pages/aaas/routes';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { bff } from 'shared/api';
import IconCheckedCircleOutlined from 'shared/components/icons/IconCheckedCircleOutlined';
import {
  HandleFulfillmentReply,
  HandleFulfillmentRequest,
} from 'shared/types/protoc-gen/bffsubscription';
import { MUTATION_KEYS } from 'shared/types/react-query';

export const PurchaseSuccessfulView = () => {
  const channel = useMemo(() => new BroadcastChannel(FULFILLMENT_CHANNEL_ID), []);
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get('session_id');

  const alertPaymentFailed = useCallback(
    (desc?: string) =>
      addAlert({
        severity: ALERT_SEVERITY.INFO,
        title: 'Payment fulfillment failed',
        desc: desc ?? 'The session ID is not found in URL. Redirecting...',
      }),
    [addAlert],
  );

  const { isPending: isFulfillmentPending, mutate: handleFulfillment } = useMutation({
    mutationKey: [MUTATION_KEYS.BFF_PAYMENT_FULFILLMENT],
    mutationFn: async (params: HandleFulfillmentRequest) => {
      const res = await bff.get<HandleFulfillmentReply>('bff/subscription/fulfillments', {
        params,
      });

      return res.data;
    },
    onSuccess: () => {
      channel.postMessage('fulfilled');
    },
    onError: () => {
      channel.postMessage('failed');
      alertPaymentFailed('The session ID may be invalid. Redirecting...');
      setTimeout(() => {
        navigate(AAAS_PATHS.AAAS_DEPLOYMENTS, { replace: true });
      }, 1_500);
    },
  });

  useEffect(() => {
    if (!sessionId) {
      channel.postMessage('failed');
      setTimeout(() => {
        navigate(AAAS_PATHS.AAAS_DEPLOYMENTS, { replace: true });
        alertPaymentFailed();
      }, 750);

      return;
    }

    handleFulfillment({ sessionId });
  }, [alertPaymentFailed, channel, handleFulfillment, navigate, sessionId]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      py={5 * 2}
      spacing={5}
      sx={{ background: '#fff' }}
    >
      {sessionId ? <IconCheckedCircleOutlined /> : <CircularProgress size={80} />}

      {sessionId ? (
        <Typography variant="h3">Subscription Success</Typography>
      ) : (
        <Skeleton height="80px" variant="text" width="30%" />
      )}

      <LoadingButton
        disabled={!sessionId}
        loading={isFulfillmentPending}
        onClick={() => navigate(AAAS_PATHS.AAAS_NEW_DEPLOYMENT, { replace: true })}
        sx={theme => ({
          border: `1px solid ${theme.colors.functional.subject.border}`,
          '&, &:hover': {
            background: theme.colors.gradients.metal,
            color: theme.colors.functional.text.primary,
          },
        })}
        variant="contained"
      >
        Launch Agent
      </LoadingButton>
    </Stack>
  );
};
