import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useBlockscoutMetrics } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';
import { gweiFormatter } from 'shared/utils/recharts';

export const GasUsedGrowthMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const {
    end: to,
    start: from,
    timeframe,
  } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.FEE_TRACKER_GAS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'gasUsedGrowth',
    l2: true,
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Cumulative gas used for the period'}
      id={'gas-used-growth'}
      loading={isPending}
      title={'Gas used growth'}
      tooltipValueFormatter={gweiFormatter}
      yAxisProps={{
        tickFormatter: gweiFormatter,
      }}
    />
  );
};
