import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useState } from 'react';
import { bff } from 'shared/api';
import {
  GetCharacterGenerateJobReply,
  GetCharacterGenerateJobRequest,
} from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

interface IUsePollGeneratedCharacter {
  params: GetCharacterGenerateJobRequest;
}

export const usePollGeneratedCharacter = (opts?: IUsePollGeneratedCharacter) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(15_000);

  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_GENERATED_CHARACTER, { jobId: opts?.params.jobId }],
    queryFn: async () => {
      const res = await bff.get<GetCharacterGenerateJobReply>(
        `bff/aaa/characters/${opts?.params.jobId}`,
      );

      const reply = res.data;

      if (reply.isFailed) {
        addAlert({
          severity: ALERT_SEVERITY.ERROR,
          title: 'Failed to generate character',
          desc: reply.failedReason ?? 'Unknown error',
        });
        queryClient.removeQueries({
          queryKey: [QUERY_KEYS.AAAS_GET_GENERATED_CHARACTER, { jobId: opts?.params.jobId }],
        });
      }

      if (reply.isFinished) {
        addAlert({
          severity: ALERT_SEVERITY.SUCCESS,
          title: 'Persona successfully generated',
          desc: 'You can now use this persona for your agent deployment.',
        });
        setRefetchInterval(false);
      }

      return reply;
    },
    enabled: !!opts?.params.jobId,
    refetchInterval,
    refetchIntervalInBackground: !!refetchInterval,
  });
};
