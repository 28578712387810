import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { PERMISSIONS, useAuthContext, USER_ROLE } from 'contexts/AuthContext';
import { useCallback, useMemo, useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { GrUserAdmin } from 'react-icons/gr';
import { IoIosMore } from 'react-icons/io';
import { IoArrowBack } from 'react-icons/io5';
import { axiosAuth } from 'shared/api';
import useTeamMembers from 'shared/hooks/useTeamMembers';
import { ServerErrorResponse } from 'shared/types/axios';
import { MUTATION_KEYS } from 'shared/types/react-query';
import { UserRecord } from 'shared/types/user';
import { px } from 'theme/margins';

import { InviteTeamMember } from './InviteTeamMember';

const isAdminTypeRole = (role?: USER_ROLE) => Boolean(USER_ROLE?.[role as USER_ROLE]);

interface TeamSettingsMutationArgs {
  email: string;
}

export function TeamSettings() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showInviteScreen, setShowInviteScreen] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number | null>(null);
  const { user: currentAuthUser } = useAuthContext();
  const { addAlert } = useAlerts();
  const theme = useTheme();

  const handleClickMenuItem = useCallback((event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedMenuIndex(index);
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClosePopover = useCallback(() => setAnchorEl(null), []);

  const { invalidateTeamMembersQuery, isFetchTeamMembersPending, members } = useTeamMembers();

  const { isPending: promoteTeamMemberPending, mutateAsync: promoteTeamMember } = useMutation({
    mutationKey: [MUTATION_KEYS.PROMOTE_TEAM_MEMBER],
    mutationFn: async ({ email }: TeamSettingsMutationArgs): Promise<UserRecord> => {
      if (email === currentAuthUser?.email) throw new Error('Cannot promote yourself');
      const res = await axiosAuth.patch<UserRecord>(
        `/org/${currentAuthUser?.org_id}/users/${email}/promote`,
      );

      return res.data;
    },
    onMutate: () => handleClosePopover(),
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'User Promoted',
        desc: 'User has been promoted to organization admin',
      });
      invalidateTeamMembersQuery();
    },
    onError: (err: ServerErrorResponse) => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: err.response?.data?.message || 'Unexpected Error',
        desc: 'An error occurred when attempting to promote the user',
      });
    },
    onSettled: () => setSelectedMenuIndex(null),
  });

  const { isPending: kickTeamMemberPending, mutateAsync: kickTeamMember } = useMutation({
    mutationKey: [MUTATION_KEYS.KICK_TEAM_MEMBER],
    mutationFn: async ({ email }: TeamSettingsMutationArgs): Promise<UserRecord> => {
      if (email === currentAuthUser?.email) throw new Error('Cannot kick yourself');
      const res = await axiosAuth.delete<UserRecord>(
        `/org/${currentAuthUser?.org_id}/users/${email}/kick`,
      );

      return res.data;
    },
    onMutate: () => handleClosePopover(),
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'User Kicked',
        desc: 'User has been kicked from the organization',
      });
      invalidateTeamMembersQuery();
    },
    onError: (err: ServerErrorResponse) => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: err.response?.data?.message || 'Unexpected Error',
        desc: 'An error occurred when attempting to kick the user',
      });
    },
    onSettled: () => setSelectedMenuIndex(null),
  });

  const teamMemberActionPending = useMemo(
    () => promoteTeamMemberPending || kickTeamMemberPending,
    [kickTeamMemberPending, promoteTeamMemberPending],
  );

  const actions: {
    label: string;
    actionFn: (cur: UserRecord) => Promise<UserRecord>;
  }[] = [
    {
      label: 'Promote',
      actionFn: (cur: UserRecord) =>
        promoteTeamMember({
          email: cur.email,
        }),
    },
    {
      label: 'Kick',
      actionFn: (cur: UserRecord) =>
        kickTeamMember({
          email: cur.email,
        }),
    },
  ];

  return (
    <>
      {/* <PageBanner background={theme.colors.gradients.sheet} title="Team Settings" /> */}
      <Stack sx={{ background: theme.colors.gradients.sheet, p: px }}>
        <Typography variant="h3">Team Settings</Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ borderBottom: '1px solid rgba(32, 39, 35, 0.08)', minHeight: '40vh' }}
      >
        <Stack
          px={5}
          sx={{ flex: 1, background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)' }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography mt={5} variant="h3">
              Manage team
            </Typography>
            <Typography mt={3} variant="bodySmall">
              Invite a team member to your organization
            </Typography>
          </Box>
          {showInviteScreen ? (
            <Box sx={{ flex: 1 }}>
              <Typography
                mt={4}
                onClick={() => {
                  setShowInviteScreen(false);
                }}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
                variant="bodySmallC"
              >
                <IoArrowBack size={20} />
                BACK
              </Typography>
            </Box>
          ) : (
            <Box sx={{ flex: 1 }}>
              <Typography
                mt={4}
                onClick={() => {
                  setShowInviteScreen(true);
                }}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
                variant="bodySmallC"
              >
                <FaCirclePlus size={20} />
                INVITE TO TEAM
              </Typography>
            </Box>
          )}
        </Stack>
        {showInviteScreen ? (
          <InviteTeamMember />
        ) : (
          <Stack
            sx={{
              ...(isFetchTeamMembersPending && { justifyContent: 'center', alignItems: 'center' }),
              flex: 2,
              background: '#fff',
            }}
          >
            {isFetchTeamMembersPending ? (
              <CircularProgress />
            ) : (
              members?.map((member, index) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={member.email}
                  px={5}
                  py={3}
                  sx={{ borderTop: index === 0 ? 'none' : '1px solid rgba(32, 39, 35, 0.08)' }}
                >
                  <Box sx={{ display: 'flex', flex: 1, gap: 1.5 }}>
                    <Typography variant="bodySmall">{member.email}</Typography>
                    {isAdminTypeRole(member.role) && <GrUserAdmin size={20} />}
                  </Box>

                  {currentAuthUser?.permissions?.includes(PERMISSIONS.WRITE_ORG_USERS) &&
                    currentAuthUser?.email !== member.email &&
                    ![USER_ROLE.ORG_ADMIN, USER_ROLE.ROOT].includes(member.role as USER_ROLE) && (
                      <>
                        <IconButton
                          disabled={teamMemberActionPending}
                          onClick={e => handleClickMenuItem(e, index)}
                        >
                          {selectedMenuIndex === index && teamMemberActionPending ? (
                            <CircularProgress size={16} />
                          ) : (
                            <IoIosMore size={20} />
                          )}
                        </IconButton>
                        <Popover
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          key={member.email}
                          onClose={handleClosePopover}
                          open={Boolean(anchorEl)}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {actions.map(action => {
                            return (
                              <MenuItem
                                key={action.label}
                                onClick={() => action.actionFn(member)}
                                sx={{
                                  width: '164px',
                                  height: '52px',
                                  borderTop:
                                    index === 0 ? 'none' : '1px solid rgba(32, 39, 35, 0.08)',
                                }}
                              >
                                <Typography variant="body1">{action.label}</Typography>
                              </MenuItem>
                            );
                          })}
                        </Popover>
                      </>
                    )}
                </Stack>
              ))
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
}
