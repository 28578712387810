// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: rollup.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "orbit.v1";

/** Deployment type. */
export enum DeploymentType {
  TYPE_TESTNET = 0,
  TYPE_MAINNET = 1,
  UNRECOGNIZED = -1,
}

export function deploymentTypeFromJSON(object: any): DeploymentType {
  switch (object) {
    case 0:
    case "TYPE_TESTNET":
      return DeploymentType.TYPE_TESTNET;
    case 1:
    case "TYPE_MAINNET":
      return DeploymentType.TYPE_MAINNET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DeploymentType.UNRECOGNIZED;
  }
}

export function deploymentTypeToJSON(object: DeploymentType): string {
  switch (object) {
    case DeploymentType.TYPE_TESTNET:
      return "TYPE_TESTNET";
    case DeploymentType.TYPE_MAINNET:
      return "TYPE_MAINNET";
    case DeploymentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Rollup type. */
export enum RollupType {
  TYPE_OPSTACK = 0,
  TYPE_ORBIT = 1,
  TYPE_ZKSYNC = 2,
  TYPE_CDK = 3,
  UNRECOGNIZED = -1,
}

export function rollupTypeFromJSON(object: any): RollupType {
  switch (object) {
    case 0:
    case "TYPE_OPSTACK":
      return RollupType.TYPE_OPSTACK;
    case 1:
    case "TYPE_ORBIT":
      return RollupType.TYPE_ORBIT;
    case 2:
    case "TYPE_ZKSYNC":
      return RollupType.TYPE_ZKSYNC;
    case 3:
    case "TYPE_CDK":
      return RollupType.TYPE_CDK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RollupType.UNRECOGNIZED;
  }
}

export function rollupTypeToJSON(object: RollupType): string {
  switch (object) {
    case RollupType.TYPE_OPSTACK:
      return "TYPE_OPSTACK";
    case RollupType.TYPE_ORBIT:
      return "TYPE_ORBIT";
    case RollupType.TYPE_ZKSYNC:
      return "TYPE_ZKSYNC";
    case RollupType.TYPE_CDK:
      return "TYPE_CDK";
    case RollupType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** DA type. */
export enum DAType {
  TYPE_EIGENDA = 0,
  TYPE_ANYTRUST = 1,
  TYPE_CELESTIA = 2,
  TYPE_AVAIL = 3,
  UNRECOGNIZED = -1,
}

export function dATypeFromJSON(object: any): DAType {
  switch (object) {
    case 0:
    case "TYPE_EIGENDA":
      return DAType.TYPE_EIGENDA;
    case 1:
    case "TYPE_ANYTRUST":
      return DAType.TYPE_ANYTRUST;
    case 2:
    case "TYPE_CELESTIA":
      return DAType.TYPE_CELESTIA;
    case 3:
    case "TYPE_AVAIL":
      return DAType.TYPE_AVAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DAType.UNRECOGNIZED;
  }
}

export function dATypeToJSON(object: DAType): string {
  switch (object) {
    case DAType.TYPE_EIGENDA:
      return "TYPE_EIGENDA";
    case DAType.TYPE_ANYTRUST:
      return "TYPE_ANYTRUST";
    case DAType.TYPE_CELESTIA:
      return "TYPE_CELESTIA";
    case DAType.TYPE_AVAIL:
      return "TYPE_AVAIL";
    case DAType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Deployment status. */
export enum RollupDeploymentStatus {
  STATUS_WAITING = 0,
  STATUS_ONGOING = 1,
  STATUS_FAILED = 2,
  STATUS_SUCCEED = 3,
  UNRECOGNIZED = -1,
}

export function rollupDeploymentStatusFromJSON(object: any): RollupDeploymentStatus {
  switch (object) {
    case 0:
    case "STATUS_WAITING":
      return RollupDeploymentStatus.STATUS_WAITING;
    case 1:
    case "STATUS_ONGOING":
      return RollupDeploymentStatus.STATUS_ONGOING;
    case 2:
    case "STATUS_FAILED":
      return RollupDeploymentStatus.STATUS_FAILED;
    case 3:
    case "STATUS_SUCCEED":
      return RollupDeploymentStatus.STATUS_SUCCEED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RollupDeploymentStatus.UNRECOGNIZED;
  }
}

export function rollupDeploymentStatusToJSON(object: RollupDeploymentStatus): string {
  switch (object) {
    case RollupDeploymentStatus.STATUS_WAITING:
      return "STATUS_WAITING";
    case RollupDeploymentStatus.STATUS_ONGOING:
      return "STATUS_ONGOING";
    case RollupDeploymentStatus.STATUS_FAILED:
      return "STATUS_FAILED";
    case RollupDeploymentStatus.STATUS_SUCCEED:
      return "STATUS_SUCCEED";
    case RollupDeploymentStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Create rollup request.
 * The rollupType field must be specified, while only corresponding init param need to be set.
 * Others should be left as empty.
 */
export interface CreateRollupRequest {
  /** Support types: Optimism OP Stack, Arbitrum Orbit, Polygon CDK, ZkSync ZK Stack */
  rollupType: RollupType;
  /** Either testnet or mainnet */
  deploymentType: DeploymentType;
  /** Support types: EigneDA, Arbitrum Anytrust, Celestia, Avail */
  daType: DAType;
  /** If set to true, a ERC20 address should be provided. There should be some pre-validation on the gas token */
  customGasToken:
    | CustomGasToken
    | undefined;
  /** Specific init params to OP Stack */
  opstackInitparam?:
    | OPStackInitParam
    | undefined;
  /** Specific init params to orbit */
  orbitInitParam?:
    | OrbitInitParam
    | undefined;
  /** Specific init params to zksync */
  zksyncInitParam?:
    | ZKSyncInitParam
    | undefined;
  /** Specific init params to cdk */
  cdkInitParam?: CDKInitParam | undefined;
}

/**
 * Create rollup response.
 * deployment id is a UUID type, which can be tracked during the entire lifecycle for a rollup.
 */
export interface CreateRollupResponse {
  /** deployment id should be the unique receipt for checking deployment info */
  deploymentId: string;
}

/**
 * Request for listing all rollup deployments.
 * Leave it as empty for now.
 */
export interface ListRollupRequest {
}

/** Response for listing all rollup deployments. */
export interface ListRollupResponse {
  deployment: Deployment[];
}

/** Query rollup by id. */
export interface QueryRollupByIdRequest {
  deploymentId: string;
}

/** Result for quering by deployment id. */
export interface QueryRollupByIdResponse {
  deployment: Deployment | undefined;
}

/** A deployment could be completed, pending, or still on-going. Check RollupDeploymentStatus. */
export interface Deployment {
  rollupType: RollupType;
  deploymentType: DeploymentType;
  daType: DAType;
  deploymentId: string;
  customGasToken: CustomGasToken | undefined;
  deploymentStatus: RollupDeploymentStatus;
  initParam: string;
  networkInfo: NetworkInfo | undefined;
  failedReason: string;
}

/** Suspend rollup by id. */
export interface SuspendRollupByIdRequest {
  deploymentId: string;
}

/** Response for a suspending request. */
export interface SuspendRollupByIdResponse {
}

/** Remove rollup by id. */
export interface RemoveRollupByIdRequest {
  deploymentId: string;
}

/** Response for a removing request. */
export interface RemoveRollupByIdResponse {
}

/** Use custom gas token or not. If enable is true, address cannot be zero. */
export interface CustomGasToken {
  enable: boolean;
  address: string;
}

/** Minimal parameters for deploying an orbit stack. */
export interface OrbitInitParam {
  /** Required. */
  chainName: string;
  /** Required. */
  chainId: number;
  /** Required. */
  parentChainId: number;
  /** Required. */
  challengePeriod: number;
}

/** Minimal parameters for deploying an op stack. */
export interface OPStackInitParam {
}

/** Minimal parameters for deploying a zksycn stack. */
export interface ZKSyncInitParam {
}

/** Minimal parameters for deploying a cdk stack. */
export interface CDKInitParam {
}

/** The final output after deploying a rollup stack */
export interface NetworkInfo {
  id: number;
  name: string;
  network: string;
  faucetLink: string;
  nativeCurrency: NativeCurrecncy | undefined;
  rpcUrls: RPCInfo | undefined;
  blockExplorers: BlockExplorer | undefined;
  iconUrl: string;
  statusUrl: string;
  testnet: boolean;
  settlementChain: ChainInfo | undefined;
  rollupStackType: string;
  bridge: BridgeInfo | undefined;
  customContracts: ContractInfo[];
  additionalInfo: ContractInfo[];
}

/** Native currency info. */
export interface NativeCurrecncy {
  name: string;
  symbol: string;
  decimals: number;
}

/** RPC info. */
export interface RPCInfo {
  default: RPCElement | undefined;
  public: RPCElement | undefined;
}

export interface RPCElement {
  http: string[];
  webSocket: string[];
}

/** Block Explorer info. */
export interface BlockExplorer {
  default: BlockExplorerElement | undefined;
}

export interface BlockExplorerElement {
  name: string;
  url: string;
}

/** Bridge info. */
export interface BridgeInfo {
  bridge: string;
}

/** Contract info. */
export interface ContractInfo {
  group: string;
  items: { [key: string]: string };
}

export interface ContractInfo_ItemsEntry {
  key: string;
  value: string;
}

/** Chain info. */
export interface ChainInfo {
  id: number;
  name: string;
}

function createBaseCreateRollupRequest(): CreateRollupRequest {
  return {
    rollupType: 0,
    deploymentType: 0,
    daType: 0,
    customGasToken: undefined,
    opstackInitparam: undefined,
    orbitInitParam: undefined,
    zksyncInitParam: undefined,
    cdkInitParam: undefined,
  };
}

export const CreateRollupRequest = {
  encode(message: CreateRollupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollupType !== 0) {
      writer.uint32(8).int32(message.rollupType);
    }
    if (message.deploymentType !== 0) {
      writer.uint32(16).int32(message.deploymentType);
    }
    if (message.daType !== 0) {
      writer.uint32(24).int32(message.daType);
    }
    if (message.customGasToken !== undefined) {
      CustomGasToken.encode(message.customGasToken, writer.uint32(34).fork()).ldelim();
    }
    if (message.opstackInitparam !== undefined) {
      OPStackInitParam.encode(message.opstackInitparam, writer.uint32(42).fork()).ldelim();
    }
    if (message.orbitInitParam !== undefined) {
      OrbitInitParam.encode(message.orbitInitParam, writer.uint32(50).fork()).ldelim();
    }
    if (message.zksyncInitParam !== undefined) {
      ZKSyncInitParam.encode(message.zksyncInitParam, writer.uint32(58).fork()).ldelim();
    }
    if (message.cdkInitParam !== undefined) {
      CDKInitParam.encode(message.cdkInitParam, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateRollupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRollupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.rollupType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.daType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.customGasToken = CustomGasToken.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.opstackInitparam = OPStackInitParam.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orbitInitParam = OrbitInitParam.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.zksyncInitParam = ZKSyncInitParam.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.cdkInitParam = CDKInitParam.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRollupRequest {
    return {
      rollupType: isSet(object.rollupType) ? rollupTypeFromJSON(object.rollupType) : 0,
      deploymentType: isSet(object.deploymentType) ? deploymentTypeFromJSON(object.deploymentType) : 0,
      daType: isSet(object.daType) ? dATypeFromJSON(object.daType) : 0,
      customGasToken: isSet(object.customGasToken) ? CustomGasToken.fromJSON(object.customGasToken) : undefined,
      opstackInitparam: isSet(object.opstackInitparam) ? OPStackInitParam.fromJSON(object.opstackInitparam) : undefined,
      orbitInitParam: isSet(object.orbitInitParam) ? OrbitInitParam.fromJSON(object.orbitInitParam) : undefined,
      zksyncInitParam: isSet(object.zksyncInitParam) ? ZKSyncInitParam.fromJSON(object.zksyncInitParam) : undefined,
      cdkInitParam: isSet(object.cdkInitParam) ? CDKInitParam.fromJSON(object.cdkInitParam) : undefined,
    };
  },

  toJSON(message: CreateRollupRequest): unknown {
    const obj: any = {};
    if (message.rollupType !== 0) {
      obj.rollupType = rollupTypeToJSON(message.rollupType);
    }
    if (message.deploymentType !== 0) {
      obj.deploymentType = deploymentTypeToJSON(message.deploymentType);
    }
    if (message.daType !== 0) {
      obj.daType = dATypeToJSON(message.daType);
    }
    if (message.customGasToken !== undefined) {
      obj.customGasToken = CustomGasToken.toJSON(message.customGasToken);
    }
    if (message.opstackInitparam !== undefined) {
      obj.opstackInitparam = OPStackInitParam.toJSON(message.opstackInitparam);
    }
    if (message.orbitInitParam !== undefined) {
      obj.orbitInitParam = OrbitInitParam.toJSON(message.orbitInitParam);
    }
    if (message.zksyncInitParam !== undefined) {
      obj.zksyncInitParam = ZKSyncInitParam.toJSON(message.zksyncInitParam);
    }
    if (message.cdkInitParam !== undefined) {
      obj.cdkInitParam = CDKInitParam.toJSON(message.cdkInitParam);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateRollupRequest>, I>>(base?: I): CreateRollupRequest {
    return CreateRollupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateRollupRequest>, I>>(object: I): CreateRollupRequest {
    const message = createBaseCreateRollupRequest();
    message.rollupType = object.rollupType ?? 0;
    message.deploymentType = object.deploymentType ?? 0;
    message.daType = object.daType ?? 0;
    message.customGasToken = (object.customGasToken !== undefined && object.customGasToken !== null)
      ? CustomGasToken.fromPartial(object.customGasToken)
      : undefined;
    message.opstackInitparam = (object.opstackInitparam !== undefined && object.opstackInitparam !== null)
      ? OPStackInitParam.fromPartial(object.opstackInitparam)
      : undefined;
    message.orbitInitParam = (object.orbitInitParam !== undefined && object.orbitInitParam !== null)
      ? OrbitInitParam.fromPartial(object.orbitInitParam)
      : undefined;
    message.zksyncInitParam = (object.zksyncInitParam !== undefined && object.zksyncInitParam !== null)
      ? ZKSyncInitParam.fromPartial(object.zksyncInitParam)
      : undefined;
    message.cdkInitParam = (object.cdkInitParam !== undefined && object.cdkInitParam !== null)
      ? CDKInitParam.fromPartial(object.cdkInitParam)
      : undefined;
    return message;
  },
};

function createBaseCreateRollupResponse(): CreateRollupResponse {
  return { deploymentId: "" };
}

export const CreateRollupResponse = {
  encode(message: CreateRollupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateRollupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRollupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRollupResponse {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: CreateRollupResponse): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateRollupResponse>, I>>(base?: I): CreateRollupResponse {
    return CreateRollupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateRollupResponse>, I>>(object: I): CreateRollupResponse {
    const message = createBaseCreateRollupResponse();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseListRollupRequest(): ListRollupRequest {
  return {};
}

export const ListRollupRequest = {
  encode(_: ListRollupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRollupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRollupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListRollupRequest {
    return {};
  },

  toJSON(_: ListRollupRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRollupRequest>, I>>(base?: I): ListRollupRequest {
    return ListRollupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRollupRequest>, I>>(_: I): ListRollupRequest {
    const message = createBaseListRollupRequest();
    return message;
  },
};

function createBaseListRollupResponse(): ListRollupResponse {
  return { deployment: [] };
}

export const ListRollupResponse = {
  encode(message: ListRollupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.deployment) {
      Deployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRollupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRollupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deployment.push(Deployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRollupResponse {
    return {
      deployment: globalThis.Array.isArray(object?.deployment)
        ? object.deployment.map((e: any) => Deployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListRollupResponse): unknown {
    const obj: any = {};
    if (message.deployment?.length) {
      obj.deployment = message.deployment.map((e) => Deployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRollupResponse>, I>>(base?: I): ListRollupResponse {
    return ListRollupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRollupResponse>, I>>(object: I): ListRollupResponse {
    const message = createBaseListRollupResponse();
    message.deployment = object.deployment?.map((e) => Deployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryRollupByIdRequest(): QueryRollupByIdRequest {
  return { deploymentId: "" };
}

export const QueryRollupByIdRequest = {
  encode(message: QueryRollupByIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryRollupByIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRollupByIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryRollupByIdRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: QueryRollupByIdRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryRollupByIdRequest>, I>>(base?: I): QueryRollupByIdRequest {
    return QueryRollupByIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryRollupByIdRequest>, I>>(object: I): QueryRollupByIdRequest {
    const message = createBaseQueryRollupByIdRequest();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseQueryRollupByIdResponse(): QueryRollupByIdResponse {
  return { deployment: undefined };
}

export const QueryRollupByIdResponse = {
  encode(message: QueryRollupByIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deployment !== undefined) {
      Deployment.encode(message.deployment, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryRollupByIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRollupByIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deployment = Deployment.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryRollupByIdResponse {
    return { deployment: isSet(object.deployment) ? Deployment.fromJSON(object.deployment) : undefined };
  },

  toJSON(message: QueryRollupByIdResponse): unknown {
    const obj: any = {};
    if (message.deployment !== undefined) {
      obj.deployment = Deployment.toJSON(message.deployment);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryRollupByIdResponse>, I>>(base?: I): QueryRollupByIdResponse {
    return QueryRollupByIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryRollupByIdResponse>, I>>(object: I): QueryRollupByIdResponse {
    const message = createBaseQueryRollupByIdResponse();
    message.deployment = (object.deployment !== undefined && object.deployment !== null)
      ? Deployment.fromPartial(object.deployment)
      : undefined;
    return message;
  },
};

function createBaseDeployment(): Deployment {
  return {
    rollupType: 0,
    deploymentType: 0,
    daType: 0,
    deploymentId: "",
    customGasToken: undefined,
    deploymentStatus: 0,
    initParam: "",
    networkInfo: undefined,
    failedReason: "",
  };
}

export const Deployment = {
  encode(message: Deployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollupType !== 0) {
      writer.uint32(8).int32(message.rollupType);
    }
    if (message.deploymentType !== 0) {
      writer.uint32(16).int32(message.deploymentType);
    }
    if (message.daType !== 0) {
      writer.uint32(24).int32(message.daType);
    }
    if (message.deploymentId !== "") {
      writer.uint32(34).string(message.deploymentId);
    }
    if (message.customGasToken !== undefined) {
      CustomGasToken.encode(message.customGasToken, writer.uint32(42).fork()).ldelim();
    }
    if (message.deploymentStatus !== 0) {
      writer.uint32(48).int32(message.deploymentStatus);
    }
    if (message.initParam !== "") {
      writer.uint32(58).string(message.initParam);
    }
    if (message.networkInfo !== undefined) {
      NetworkInfo.encode(message.networkInfo, writer.uint32(66).fork()).ldelim();
    }
    if (message.failedReason !== "") {
      writer.uint32(74).string(message.failedReason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Deployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.rollupType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deploymentType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.daType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.customGasToken = CustomGasToken.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.deploymentStatus = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.initParam = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.networkInfo = NetworkInfo.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.failedReason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Deployment {
    return {
      rollupType: isSet(object.rollupType) ? rollupTypeFromJSON(object.rollupType) : 0,
      deploymentType: isSet(object.deploymentType) ? deploymentTypeFromJSON(object.deploymentType) : 0,
      daType: isSet(object.daType) ? dATypeFromJSON(object.daType) : 0,
      deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "",
      customGasToken: isSet(object.customGasToken) ? CustomGasToken.fromJSON(object.customGasToken) : undefined,
      deploymentStatus: isSet(object.deploymentStatus) ? rollupDeploymentStatusFromJSON(object.deploymentStatus) : 0,
      initParam: isSet(object.initParam) ? globalThis.String(object.initParam) : "",
      networkInfo: isSet(object.networkInfo) ? NetworkInfo.fromJSON(object.networkInfo) : undefined,
      failedReason: isSet(object.failedReason) ? globalThis.String(object.failedReason) : "",
    };
  },

  toJSON(message: Deployment): unknown {
    const obj: any = {};
    if (message.rollupType !== 0) {
      obj.rollupType = rollupTypeToJSON(message.rollupType);
    }
    if (message.deploymentType !== 0) {
      obj.deploymentType = deploymentTypeToJSON(message.deploymentType);
    }
    if (message.daType !== 0) {
      obj.daType = dATypeToJSON(message.daType);
    }
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    if (message.customGasToken !== undefined) {
      obj.customGasToken = CustomGasToken.toJSON(message.customGasToken);
    }
    if (message.deploymentStatus !== 0) {
      obj.deploymentStatus = rollupDeploymentStatusToJSON(message.deploymentStatus);
    }
    if (message.initParam !== "") {
      obj.initParam = message.initParam;
    }
    if (message.networkInfo !== undefined) {
      obj.networkInfo = NetworkInfo.toJSON(message.networkInfo);
    }
    if (message.failedReason !== "") {
      obj.failedReason = message.failedReason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Deployment>, I>>(base?: I): Deployment {
    return Deployment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Deployment>, I>>(object: I): Deployment {
    const message = createBaseDeployment();
    message.rollupType = object.rollupType ?? 0;
    message.deploymentType = object.deploymentType ?? 0;
    message.daType = object.daType ?? 0;
    message.deploymentId = object.deploymentId ?? "";
    message.customGasToken = (object.customGasToken !== undefined && object.customGasToken !== null)
      ? CustomGasToken.fromPartial(object.customGasToken)
      : undefined;
    message.deploymentStatus = object.deploymentStatus ?? 0;
    message.initParam = object.initParam ?? "";
    message.networkInfo = (object.networkInfo !== undefined && object.networkInfo !== null)
      ? NetworkInfo.fromPartial(object.networkInfo)
      : undefined;
    message.failedReason = object.failedReason ?? "";
    return message;
  },
};

function createBaseSuspendRollupByIdRequest(): SuspendRollupByIdRequest {
  return { deploymentId: "" };
}

export const SuspendRollupByIdRequest = {
  encode(message: SuspendRollupByIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SuspendRollupByIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSuspendRollupByIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SuspendRollupByIdRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: SuspendRollupByIdRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SuspendRollupByIdRequest>, I>>(base?: I): SuspendRollupByIdRequest {
    return SuspendRollupByIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SuspendRollupByIdRequest>, I>>(object: I): SuspendRollupByIdRequest {
    const message = createBaseSuspendRollupByIdRequest();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseSuspendRollupByIdResponse(): SuspendRollupByIdResponse {
  return {};
}

export const SuspendRollupByIdResponse = {
  encode(_: SuspendRollupByIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SuspendRollupByIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSuspendRollupByIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SuspendRollupByIdResponse {
    return {};
  },

  toJSON(_: SuspendRollupByIdResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SuspendRollupByIdResponse>, I>>(base?: I): SuspendRollupByIdResponse {
    return SuspendRollupByIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SuspendRollupByIdResponse>, I>>(_: I): SuspendRollupByIdResponse {
    const message = createBaseSuspendRollupByIdResponse();
    return message;
  },
};

function createBaseRemoveRollupByIdRequest(): RemoveRollupByIdRequest {
  return { deploymentId: "" };
}

export const RemoveRollupByIdRequest = {
  encode(message: RemoveRollupByIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveRollupByIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveRollupByIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveRollupByIdRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: RemoveRollupByIdRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveRollupByIdRequest>, I>>(base?: I): RemoveRollupByIdRequest {
    return RemoveRollupByIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveRollupByIdRequest>, I>>(object: I): RemoveRollupByIdRequest {
    const message = createBaseRemoveRollupByIdRequest();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseRemoveRollupByIdResponse(): RemoveRollupByIdResponse {
  return {};
}

export const RemoveRollupByIdResponse = {
  encode(_: RemoveRollupByIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveRollupByIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveRollupByIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RemoveRollupByIdResponse {
    return {};
  },

  toJSON(_: RemoveRollupByIdResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveRollupByIdResponse>, I>>(base?: I): RemoveRollupByIdResponse {
    return RemoveRollupByIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveRollupByIdResponse>, I>>(_: I): RemoveRollupByIdResponse {
    const message = createBaseRemoveRollupByIdResponse();
    return message;
  },
};

function createBaseCustomGasToken(): CustomGasToken {
  return { enable: false, address: "" };
}

export const CustomGasToken = {
  encode(message: CustomGasToken, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enable !== false) {
      writer.uint32(8).bool(message.enable);
    }
    if (message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomGasToken {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomGasToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enable = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomGasToken {
    return {
      enable: isSet(object.enable) ? globalThis.Boolean(object.enable) : false,
      address: isSet(object.address) ? globalThis.String(object.address) : "",
    };
  },

  toJSON(message: CustomGasToken): unknown {
    const obj: any = {};
    if (message.enable !== false) {
      obj.enable = message.enable;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomGasToken>, I>>(base?: I): CustomGasToken {
    return CustomGasToken.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomGasToken>, I>>(object: I): CustomGasToken {
    const message = createBaseCustomGasToken();
    message.enable = object.enable ?? false;
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseOrbitInitParam(): OrbitInitParam {
  return { chainName: "", chainId: 0, parentChainId: 0, challengePeriod: 0 };
}

export const OrbitInitParam = {
  encode(message: OrbitInitParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainName !== "") {
      writer.uint32(10).string(message.chainName);
    }
    if (message.chainId !== 0) {
      writer.uint32(16).uint32(message.chainId);
    }
    if (message.parentChainId !== 0) {
      writer.uint32(24).uint32(message.parentChainId);
    }
    if (message.challengePeriod !== 0) {
      writer.uint32(32).uint32(message.challengePeriod);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrbitInitParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrbitInitParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.chainId = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.parentChainId = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.challengePeriod = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrbitInitParam {
    return {
      chainName: isSet(object.chainName) ? globalThis.String(object.chainName) : "",
      chainId: isSet(object.chainId) ? globalThis.Number(object.chainId) : 0,
      parentChainId: isSet(object.parentChainId) ? globalThis.Number(object.parentChainId) : 0,
      challengePeriod: isSet(object.challengePeriod) ? globalThis.Number(object.challengePeriod) : 0,
    };
  },

  toJSON(message: OrbitInitParam): unknown {
    const obj: any = {};
    if (message.chainName !== "") {
      obj.chainName = message.chainName;
    }
    if (message.chainId !== 0) {
      obj.chainId = Math.round(message.chainId);
    }
    if (message.parentChainId !== 0) {
      obj.parentChainId = Math.round(message.parentChainId);
    }
    if (message.challengePeriod !== 0) {
      obj.challengePeriod = Math.round(message.challengePeriod);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrbitInitParam>, I>>(base?: I): OrbitInitParam {
    return OrbitInitParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrbitInitParam>, I>>(object: I): OrbitInitParam {
    const message = createBaseOrbitInitParam();
    message.chainName = object.chainName ?? "";
    message.chainId = object.chainId ?? 0;
    message.parentChainId = object.parentChainId ?? 0;
    message.challengePeriod = object.challengePeriod ?? 0;
    return message;
  },
};

function createBaseOPStackInitParam(): OPStackInitParam {
  return {};
}

export const OPStackInitParam = {
  encode(_: OPStackInitParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OPStackInitParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOPStackInitParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): OPStackInitParam {
    return {};
  },

  toJSON(_: OPStackInitParam): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<OPStackInitParam>, I>>(base?: I): OPStackInitParam {
    return OPStackInitParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OPStackInitParam>, I>>(_: I): OPStackInitParam {
    const message = createBaseOPStackInitParam();
    return message;
  },
};

function createBaseZKSyncInitParam(): ZKSyncInitParam {
  return {};
}

export const ZKSyncInitParam = {
  encode(_: ZKSyncInitParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZKSyncInitParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZKSyncInitParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ZKSyncInitParam {
    return {};
  },

  toJSON(_: ZKSyncInitParam): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ZKSyncInitParam>, I>>(base?: I): ZKSyncInitParam {
    return ZKSyncInitParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ZKSyncInitParam>, I>>(_: I): ZKSyncInitParam {
    const message = createBaseZKSyncInitParam();
    return message;
  },
};

function createBaseCDKInitParam(): CDKInitParam {
  return {};
}

export const CDKInitParam = {
  encode(_: CDKInitParam, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CDKInitParam {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCDKInitParam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CDKInitParam {
    return {};
  },

  toJSON(_: CDKInitParam): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CDKInitParam>, I>>(base?: I): CDKInitParam {
    return CDKInitParam.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CDKInitParam>, I>>(_: I): CDKInitParam {
    const message = createBaseCDKInitParam();
    return message;
  },
};

function createBaseNetworkInfo(): NetworkInfo {
  return {
    id: 0,
    name: "",
    network: "",
    faucetLink: "",
    nativeCurrency: undefined,
    rpcUrls: undefined,
    blockExplorers: undefined,
    iconUrl: "",
    statusUrl: "",
    testnet: false,
    settlementChain: undefined,
    rollupStackType: "",
    bridge: undefined,
    customContracts: [],
    additionalInfo: [],
  };
}

export const NetworkInfo = {
  encode(message: NetworkInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.network !== "") {
      writer.uint32(26).string(message.network);
    }
    if (message.faucetLink !== "") {
      writer.uint32(34).string(message.faucetLink);
    }
    if (message.nativeCurrency !== undefined) {
      NativeCurrecncy.encode(message.nativeCurrency, writer.uint32(42).fork()).ldelim();
    }
    if (message.rpcUrls !== undefined) {
      RPCInfo.encode(message.rpcUrls, writer.uint32(50).fork()).ldelim();
    }
    if (message.blockExplorers !== undefined) {
      BlockExplorer.encode(message.blockExplorers, writer.uint32(58).fork()).ldelim();
    }
    if (message.iconUrl !== "") {
      writer.uint32(66).string(message.iconUrl);
    }
    if (message.statusUrl !== "") {
      writer.uint32(74).string(message.statusUrl);
    }
    if (message.testnet !== false) {
      writer.uint32(80).bool(message.testnet);
    }
    if (message.settlementChain !== undefined) {
      ChainInfo.encode(message.settlementChain, writer.uint32(90).fork()).ldelim();
    }
    if (message.rollupStackType !== "") {
      writer.uint32(98).string(message.rollupStackType);
    }
    if (message.bridge !== undefined) {
      BridgeInfo.encode(message.bridge, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.customContracts) {
      ContractInfo.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.additionalInfo) {
      ContractInfo.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NetworkInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.network = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.faucetLink = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nativeCurrency = NativeCurrecncy.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.rpcUrls = RPCInfo.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.blockExplorers = BlockExplorer.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.iconUrl = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.statusUrl = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.testnet = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.settlementChain = ChainInfo.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.rollupStackType = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.bridge = BridgeInfo.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.customContracts.push(ContractInfo.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.additionalInfo.push(ContractInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkInfo {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      network: isSet(object.network) ? globalThis.String(object.network) : "",
      faucetLink: isSet(object.faucetLink) ? globalThis.String(object.faucetLink) : "",
      nativeCurrency: isSet(object.nativeCurrency) ? NativeCurrecncy.fromJSON(object.nativeCurrency) : undefined,
      rpcUrls: isSet(object.rpcUrls) ? RPCInfo.fromJSON(object.rpcUrls) : undefined,
      blockExplorers: isSet(object.blockExplorers) ? BlockExplorer.fromJSON(object.blockExplorers) : undefined,
      iconUrl: isSet(object.iconUrl) ? globalThis.String(object.iconUrl) : "",
      statusUrl: isSet(object.statusUrl) ? globalThis.String(object.statusUrl) : "",
      testnet: isSet(object.testnet) ? globalThis.Boolean(object.testnet) : false,
      settlementChain: isSet(object.settlementChain) ? ChainInfo.fromJSON(object.settlementChain) : undefined,
      rollupStackType: isSet(object.rollupStackType) ? globalThis.String(object.rollupStackType) : "",
      bridge: isSet(object.bridge) ? BridgeInfo.fromJSON(object.bridge) : undefined,
      customContracts: globalThis.Array.isArray(object?.customContracts)
        ? object.customContracts.map((e: any) => ContractInfo.fromJSON(e))
        : [],
      additionalInfo: globalThis.Array.isArray(object?.additionalInfo)
        ? object.additionalInfo.map((e: any) => ContractInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NetworkInfo): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.network !== "") {
      obj.network = message.network;
    }
    if (message.faucetLink !== "") {
      obj.faucetLink = message.faucetLink;
    }
    if (message.nativeCurrency !== undefined) {
      obj.nativeCurrency = NativeCurrecncy.toJSON(message.nativeCurrency);
    }
    if (message.rpcUrls !== undefined) {
      obj.rpcUrls = RPCInfo.toJSON(message.rpcUrls);
    }
    if (message.blockExplorers !== undefined) {
      obj.blockExplorers = BlockExplorer.toJSON(message.blockExplorers);
    }
    if (message.iconUrl !== "") {
      obj.iconUrl = message.iconUrl;
    }
    if (message.statusUrl !== "") {
      obj.statusUrl = message.statusUrl;
    }
    if (message.testnet !== false) {
      obj.testnet = message.testnet;
    }
    if (message.settlementChain !== undefined) {
      obj.settlementChain = ChainInfo.toJSON(message.settlementChain);
    }
    if (message.rollupStackType !== "") {
      obj.rollupStackType = message.rollupStackType;
    }
    if (message.bridge !== undefined) {
      obj.bridge = BridgeInfo.toJSON(message.bridge);
    }
    if (message.customContracts?.length) {
      obj.customContracts = message.customContracts.map((e) => ContractInfo.toJSON(e));
    }
    if (message.additionalInfo?.length) {
      obj.additionalInfo = message.additionalInfo.map((e) => ContractInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NetworkInfo>, I>>(base?: I): NetworkInfo {
    return NetworkInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NetworkInfo>, I>>(object: I): NetworkInfo {
    const message = createBaseNetworkInfo();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.network = object.network ?? "";
    message.faucetLink = object.faucetLink ?? "";
    message.nativeCurrency = (object.nativeCurrency !== undefined && object.nativeCurrency !== null)
      ? NativeCurrecncy.fromPartial(object.nativeCurrency)
      : undefined;
    message.rpcUrls = (object.rpcUrls !== undefined && object.rpcUrls !== null)
      ? RPCInfo.fromPartial(object.rpcUrls)
      : undefined;
    message.blockExplorers = (object.blockExplorers !== undefined && object.blockExplorers !== null)
      ? BlockExplorer.fromPartial(object.blockExplorers)
      : undefined;
    message.iconUrl = object.iconUrl ?? "";
    message.statusUrl = object.statusUrl ?? "";
    message.testnet = object.testnet ?? false;
    message.settlementChain = (object.settlementChain !== undefined && object.settlementChain !== null)
      ? ChainInfo.fromPartial(object.settlementChain)
      : undefined;
    message.rollupStackType = object.rollupStackType ?? "";
    message.bridge = (object.bridge !== undefined && object.bridge !== null)
      ? BridgeInfo.fromPartial(object.bridge)
      : undefined;
    message.customContracts = object.customContracts?.map((e) => ContractInfo.fromPartial(e)) || [];
    message.additionalInfo = object.additionalInfo?.map((e) => ContractInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNativeCurrecncy(): NativeCurrecncy {
  return { name: "", symbol: "", decimals: 0 };
}

export const NativeCurrecncy = {
  encode(message: NativeCurrecncy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.symbol !== "") {
      writer.uint32(18).string(message.symbol);
    }
    if (message.decimals !== 0) {
      writer.uint32(24).uint32(message.decimals);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NativeCurrecncy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNativeCurrecncy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.decimals = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NativeCurrecncy {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      symbol: isSet(object.symbol) ? globalThis.String(object.symbol) : "",
      decimals: isSet(object.decimals) ? globalThis.Number(object.decimals) : 0,
    };
  },

  toJSON(message: NativeCurrecncy): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.symbol !== "") {
      obj.symbol = message.symbol;
    }
    if (message.decimals !== 0) {
      obj.decimals = Math.round(message.decimals);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NativeCurrecncy>, I>>(base?: I): NativeCurrecncy {
    return NativeCurrecncy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NativeCurrecncy>, I>>(object: I): NativeCurrecncy {
    const message = createBaseNativeCurrecncy();
    message.name = object.name ?? "";
    message.symbol = object.symbol ?? "";
    message.decimals = object.decimals ?? 0;
    return message;
  },
};

function createBaseRPCInfo(): RPCInfo {
  return { default: undefined, public: undefined };
}

export const RPCInfo = {
  encode(message: RPCInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.default !== undefined) {
      RPCElement.encode(message.default, writer.uint32(10).fork()).ldelim();
    }
    if (message.public !== undefined) {
      RPCElement.encode(message.public, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RPCInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRPCInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.default = RPCElement.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.public = RPCElement.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RPCInfo {
    return {
      default: isSet(object.default) ? RPCElement.fromJSON(object.default) : undefined,
      public: isSet(object.public) ? RPCElement.fromJSON(object.public) : undefined,
    };
  },

  toJSON(message: RPCInfo): unknown {
    const obj: any = {};
    if (message.default !== undefined) {
      obj.default = RPCElement.toJSON(message.default);
    }
    if (message.public !== undefined) {
      obj.public = RPCElement.toJSON(message.public);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RPCInfo>, I>>(base?: I): RPCInfo {
    return RPCInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RPCInfo>, I>>(object: I): RPCInfo {
    const message = createBaseRPCInfo();
    message.default = (object.default !== undefined && object.default !== null)
      ? RPCElement.fromPartial(object.default)
      : undefined;
    message.public = (object.public !== undefined && object.public !== null)
      ? RPCElement.fromPartial(object.public)
      : undefined;
    return message;
  },
};

function createBaseRPCElement(): RPCElement {
  return { http: [], webSocket: [] };
}

export const RPCElement = {
  encode(message: RPCElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.http) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.webSocket) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RPCElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRPCElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.http.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.webSocket.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RPCElement {
    return {
      http: globalThis.Array.isArray(object?.http) ? object.http.map((e: any) => globalThis.String(e)) : [],
      webSocket: globalThis.Array.isArray(object?.webSocket)
        ? object.webSocket.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: RPCElement): unknown {
    const obj: any = {};
    if (message.http?.length) {
      obj.http = message.http;
    }
    if (message.webSocket?.length) {
      obj.webSocket = message.webSocket;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RPCElement>, I>>(base?: I): RPCElement {
    return RPCElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RPCElement>, I>>(object: I): RPCElement {
    const message = createBaseRPCElement();
    message.http = object.http?.map((e) => e) || [];
    message.webSocket = object.webSocket?.map((e) => e) || [];
    return message;
  },
};

function createBaseBlockExplorer(): BlockExplorer {
  return { default: undefined };
}

export const BlockExplorer = {
  encode(message: BlockExplorer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.default !== undefined) {
      BlockExplorerElement.encode(message.default, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BlockExplorer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlockExplorer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.default = BlockExplorerElement.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlockExplorer {
    return { default: isSet(object.default) ? BlockExplorerElement.fromJSON(object.default) : undefined };
  },

  toJSON(message: BlockExplorer): unknown {
    const obj: any = {};
    if (message.default !== undefined) {
      obj.default = BlockExplorerElement.toJSON(message.default);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlockExplorer>, I>>(base?: I): BlockExplorer {
    return BlockExplorer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlockExplorer>, I>>(object: I): BlockExplorer {
    const message = createBaseBlockExplorer();
    message.default = (object.default !== undefined && object.default !== null)
      ? BlockExplorerElement.fromPartial(object.default)
      : undefined;
    return message;
  },
};

function createBaseBlockExplorerElement(): BlockExplorerElement {
  return { name: "", url: "" };
}

export const BlockExplorerElement = {
  encode(message: BlockExplorerElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BlockExplorerElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlockExplorerElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BlockExplorerElement {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: BlockExplorerElement): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BlockExplorerElement>, I>>(base?: I): BlockExplorerElement {
    return BlockExplorerElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BlockExplorerElement>, I>>(object: I): BlockExplorerElement {
    const message = createBaseBlockExplorerElement();
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseBridgeInfo(): BridgeInfo {
  return { bridge: "" };
}

export const BridgeInfo = {
  encode(message: BridgeInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bridge !== "") {
      writer.uint32(10).string(message.bridge);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BridgeInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBridgeInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bridge = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BridgeInfo {
    return { bridge: isSet(object.bridge) ? globalThis.String(object.bridge) : "" };
  },

  toJSON(message: BridgeInfo): unknown {
    const obj: any = {};
    if (message.bridge !== "") {
      obj.bridge = message.bridge;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BridgeInfo>, I>>(base?: I): BridgeInfo {
    return BridgeInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BridgeInfo>, I>>(object: I): BridgeInfo {
    const message = createBaseBridgeInfo();
    message.bridge = object.bridge ?? "";
    return message;
  },
};

function createBaseContractInfo(): ContractInfo {
  return { group: "", items: {} };
}

export const ContractInfo = {
  encode(message: ContractInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== "") {
      writer.uint32(10).string(message.group);
    }
    Object.entries(message.items).forEach(([key, value]) => {
      ContractInfo_ItemsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContractInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContractInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = ContractInfo_ItemsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.items[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContractInfo {
    return {
      group: isSet(object.group) ? globalThis.String(object.group) : "",
      items: isObject(object.items)
        ? Object.entries(object.items).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ContractInfo): unknown {
    const obj: any = {};
    if (message.group !== "") {
      obj.group = message.group;
    }
    if (message.items) {
      const entries = Object.entries(message.items);
      if (entries.length > 0) {
        obj.items = {};
        entries.forEach(([k, v]) => {
          obj.items[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContractInfo>, I>>(base?: I): ContractInfo {
    return ContractInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContractInfo>, I>>(object: I): ContractInfo {
    const message = createBaseContractInfo();
    message.group = object.group ?? "";
    message.items = Object.entries(object.items ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseContractInfo_ItemsEntry(): ContractInfo_ItemsEntry {
  return { key: "", value: "" };
}

export const ContractInfo_ItemsEntry = {
  encode(message: ContractInfo_ItemsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContractInfo_ItemsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContractInfo_ItemsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContractInfo_ItemsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ContractInfo_ItemsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContractInfo_ItemsEntry>, I>>(base?: I): ContractInfo_ItemsEntry {
    return ContractInfo_ItemsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContractInfo_ItemsEntry>, I>>(object: I): ContractInfo_ItemsEntry {
    const message = createBaseContractInfo_ItemsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseChainInfo(): ChainInfo {
  return { id: 0, name: "" };
}

export const ChainInfo = {
  encode(message: ChainInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChainInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChainInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChainInfo {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: ChainInfo): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChainInfo>, I>>(base?: I): ChainInfo {
    return ChainInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChainInfo>, I>>(object: I): ChainInfo {
    const message = createBaseChainInfo();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

export type RollupDeploymentServiceDefinition = typeof RollupDeploymentServiceDefinition;
export const RollupDeploymentServiceDefinition = {
  name: "RollupDeploymentService",
  fullName: "orbit.v1.RollupDeploymentService",
  methods: {
    /** Create a rollup. */
    createRollup: {
      name: "CreateRollup",
      requestType: CreateRollupRequest,
      requestStream: false,
      responseType: CreateRollupResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              20,
              34,
              15,
              47,
              118,
              49,
              47,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * Update a rollup config, only works if the CreateRollupRequest is invalid, and pending on deployment.
     * If CreateRollupRequest is valid and the deployment procedure has started, the function only return current status of it.
     */
    updateRollup: {
      name: "UpdateRollup",
      requestType: CreateRollupRequest,
      requestStream: false,
      responseType: CreateRollupResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              20,
              26,
              15,
              47,
              118,
              49,
              47,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Query rollup by deployment id, only owned rollup can be return. */
    queryRollupById: {
      name: "QueryRollupById",
      requestType: QueryRollupByIdRequest,
      requestStream: false,
      responseType: QueryRollupByIdResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              32,
              18,
              30,
              47,
              118,
              49,
              47,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              115,
              47,
              123,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              73,
              100,
              125,
            ]),
          ],
        },
      },
    },
    /** List all rollup deployment belong to this owner. */
    listRollups: {
      name: "ListRollups",
      requestType: ListRollupRequest,
      requestStream: false,
      responseType: ListRollupResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([17, 18, 15, 47, 118, 49, 47, 100, 101, 112, 108, 111, 121, 109, 101, 110, 116, 115]),
          ],
        },
      },
    },
    /**
     * Suspend a rollup by deployment id, only owned rollup can be terminated.
     * todo: double check the http method
     */
    suspendRollupById: {
      name: "SuspendRollupById",
      requestType: SuspendRollupByIdRequest,
      requestStream: false,
      responseType: SuspendRollupByIdResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              35,
              26,
              30,
              47,
              118,
              49,
              47,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              115,
              47,
              123,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              73,
              100,
              125,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Remove a rollup by deployment, only WAITING can be removed for now. */
    removeRollupById: {
      name: "RemoveRollupById",
      requestType: RemoveRollupByIdRequest,
      requestStream: false,
      responseType: RemoveRollupByIdResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              32,
              42,
              30,
              47,
              118,
              49,
              47,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              115,
              47,
              123,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              73,
              100,
              125,
            ]),
          ],
        },
      },
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
