import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconCheckedNoBorder({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="18"
      sx={{ height: '18px', width: '18px', ...sx }}
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.5 9.06982L7.55374 12.1236L13.6236 6.05368" fill="none" stroke="#202723" />
    </SvgIcon>
  );
}
