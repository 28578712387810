import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';

import { BatcherBalanceSection } from './BatcherBalanceSection';
import { BatcherTxListSection } from './BatcherTxListSection';

export const BatcherView = () => (
  <>
    <BatcherBalanceSection />
    {
      // TODO: remove showExperimentalFeatures when finalized endpoint
      showExperimentalFeatures && <BatcherTxListSection />
    }
  </>
);
