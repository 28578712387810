import { LoadingButton } from '@mui/lab';
import { BoxProps, Button, Checkbox, Collapse, Grid, Stack, Typography } from '@mui/material';
import GenericTemplate from 'pages/avs/contracts/templates/GenericTemplate';
import { avsStrategyLabels } from 'pages/avs/helpers/labels';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckboxCard from 'shared/components/Card/CheckboxCard';
import { Option } from 'shared/components/Option';
import { AVS_QUORUMS, AVS_TYPES } from 'shared/types/avs';
import { AVSDeployment } from 'shared/types/protoc-gen/avs';
import { getQuorumsByStrategies } from 'shared/utils/avs';
import { Address } from 'viem';
import { useReadContract } from 'wagmi';

import EditStrategiesWizard from './EditStrategiesWizard';
import { AVSDetailsFormValues } from '.';

const strategyOptions = [
  {
    label: (
      <Typography>
        {avsStrategyLabels[AVS_QUORUMS.ETH_LST]}{' '}
        <Typography component="span" sx={{ opacity: 0.4 }}>
          (DEFAULT)
        </Typography>
      </Typography>
    ),
    value: AVS_QUORUMS.ETH_LST,
  },
  { label: avsStrategyLabels[AVS_QUORUMS.EIGEN], value: AVS_QUORUMS.EIGEN },
  { label: avsStrategyLabels[AVS_QUORUMS.REALT], value: AVS_QUORUMS.REALT },
];

export default function EditStrategies({
  avs,
  isAvsOwner,
  ...props
}: BoxProps & { avs: AVSDeployment; isAvsOwner: boolean }) {
  const { register, setValue } = useFormContext();
  const selectedStrategies = useWatch<AVSDetailsFormValues, 'strategies'>({ name: 'strategies' });

  const [showEditStrategiesWizard, setShowEditStrategiesWizard] = useState(false);

  const {
    data: strategies,
    isLoading,
    refetch: refetchStrategies,
  } = useReadContract({
    abi: GenericTemplate.abi,
    address: avs?.serviceManagerProxy as Address,
    functionName: 'getRestakeableStrategies',
    query: {
      enabled: Boolean(avs?.serviceManagerProxy),
    },
  });
  const quorums = useMemo(() => getQuorumsByStrategies(strategies || []), [strategies]);

  useEffect(() => {
    if (quorums) {
      setValue('strategies', quorums);
    }
  }, [quorums, setValue]);

  const handleSelectStrat = (value: AVS_QUORUMS) => () => {
    if (selectedStrategies.includes(value)) {
      setValue(
        'strategies',
        selectedStrategies.filter(cur => cur !== value),
        { shouldDirty: true, shouldTouch: true },
      );
    } else {
      setValue('strategies', [...selectedStrategies, value], {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const quorumChangeset = useMemo(() => {
    const addedQuorums = [] as AVS_QUORUMS[];
    const removedQuorums = [] as AVS_QUORUMS[];

    selectedStrategies?.forEach(cur => {
      if (!quorums?.includes(cur)) {
        addedQuorums.push(cur);
      }
    });
    quorums?.forEach(cur => {
      if (!selectedStrategies?.includes(cur)) {
        removedQuorums.push(cur);
      }
    });
    const changeset = {
      addedQuorums,
      removedQuorums,
    };

    console.debug('changeset: ', changeset);

    return changeset;
  }, [quorums, selectedStrategies]);

  const hasChanges = Boolean(
    quorums?.length &&
      selectedStrategies?.length &&
      JSON.stringify(selectedStrategies?.sort()) !== JSON.stringify(quorums?.sort()),
  );

  return (
    <>
      <EditStrategiesWizard
        avs={avs}
        onClose={() => {
          refetchStrategies();
          setShowEditStrategiesWizard(false);
        }}
        open={showEditStrategiesWizard}
        quorumChangeset={quorumChangeset}
      />
      <Option isLoading={isLoading || !strategies} optionTitle="Supported Strategies" {...props}>
        <Grid container {...register('strategies')} ref={null}>
          {strategyOptions?.map((strat, index) => {
            const isNextOptionSelected = selectedStrategies?.includes(
              strategyOptions?.[index + 1]?.value,
            );

            return (
              <Grid item key={strat?.value} xs={12}>
                <CheckboxCard
                  content={<Typography px={1}>{strat.label}</Typography>}
                  disabled={
                    quorums?.includes(strat.value) ||
                    [AVS_TYPES.ECDSA_HELLO_WORLD, AVS_TYPES.CUSTOM_ECDSA]?.includes(avs?.avsType) ||
                    !isAvsOwner
                  }
                  isSelected={selectedStrategies?.includes(strat.value)}
                  leftButtonGroup={
                    <Checkbox
                      checked={selectedStrategies?.includes(strat.value)}
                      onChange={handleSelectStrat(strat.value)}
                      size="small"
                      sx={{ ml: 1.5 }}
                    />
                  }
                  onClick={handleSelectStrat(strat.value)}
                  sx={{ py: 1, svg: {}, ...(isNextOptionSelected && { borderBottom: 'none' }) }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Option>
      <Collapse in={hasChanges}>
        <Stack alignItems="center" direction="row" justifyContent="flex-end" width="100%">
          <Button
            onClick={() => {
              if (quorums) {
                setValue('strategies', quorums);
              }
            }}
            sx={{ color: theme => theme.colors.functional.text.primary }}
            variant="text"
          >
            <Typography variant="bodySmallC">Cancel</Typography>
          </Button>
          <LoadingButton
            loading={false}
            onClick={() => setShowEditStrategiesWizard(true)}
            variant="contained"
          >
            <Typography variant="bodySmallC">Save Changes</Typography>
          </LoadingButton>
        </Stack>
      </Collapse>
    </>
  );
}
