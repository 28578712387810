import { useQuery } from '@tanstack/react-query';
import { bff } from 'shared/api';
import {
  AAATemplate,
  AAATemplateStatus,
  aAATemplateStatusToJSON,
  ListAAATemplateReply,
} from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

export interface TemplateTypes {
  builtin: AAATemplate[];
  appStore: AAATemplate[];
  selfOwned: AAATemplate[];
}

export const useListAppTypes = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_AAA_TEMPLATES],
    queryFn: async () => {
      const res = await bff.get<ListAAATemplateReply>('/bff/aaa/templates');

      const withoutBaseAgent = (template: AAATemplate) => template.id !== 'Base Agent';

      return {
        builtin: res?.data?.builtin.filter(withoutBaseAgent),
        appStore: res?.data?.appStore
          ?.filter(
            template =>
              String(template.status) ===
              aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_PUBLISHED),
          )
          .filter(withoutBaseAgent),
        selfOwned: res?.data?.selfOwned
          ?.filter(template =>
            [
              aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_SUBMITTED),
              aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_REJECTED),
              aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_PENDING),
            ]?.includes(String(template.status)),
          )
          .filter(withoutBaseAgent),
      } satisfies TemplateTypes;
    },
  });
};
