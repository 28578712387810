import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, Stack, SxProps, Typography } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { Character } from 'pages/aaas/components/ConfigSimpleMode/types';
import { useEnvVarsStore } from 'pages/aaas/NewAgentDeployment/store/useEnvVarsStore';
import { ExternalLink } from 'pages/raas/components';
import { ReactNode, useCallback, useEffect } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import {
  FormNumericTextField,
  FormPasswordTextField,
  FormTextField,
  IFormTextField,
} from 'shared/components/form';
import IconInfo from 'shared/components/icons/IconInfo';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { AAATemplate } from 'shared/types/protoc-gen/bffaaa';
import * as yup from 'yup';

import {
  ENV_VARS_FORM_MULTILINE_FIELDS,
  ENV_VARS_FORM_NON_SENSITIVE_FIELDS,
  ENV_VARS_FORM_NUMERIC_FIELDS,
  ENV_VARS_HELP_TEXT,
} from '../constants';
import { envVarLabelMap } from '../labels';
import { EnvVarsFormValues } from '../types';
import { getEnvVarValidationSchema } from '../validation';

const bodyProps: SxProps = { maxHeight: '60vh', overflow: 'auto' };

type ITextFieldComponent = (props: IFormTextField) => ReactNode;

interface IEnvVarsDialog extends DialogProps {
  template?: AAATemplate;
  envList?: string[];
  currentCharacterMetadata?: Partial<Character>;
  onConfirmed: (envVars: EnvVarsFormValues) => void;
}

export const EnvVarsDialog: React.FC<IEnvVarsDialog> = ({
  currentCharacterMetadata,
  envList,
  onClose,
  onConfirmed,
  open,
  template,
  ...props
}) => {
  const { addAlert } = useAlerts();
  const { envVars, setEnvVars } = useEnvVarsStore();
  const { sm } = useBreakpoints();

  const form = useForm<EnvVarsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      getEnvVarValidationSchema(envList ?? []) as yup.ObjectSchema<EnvVarsFormValues>,
    ),
  });

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const handleConfirmed = useCallback(
    (formValues: EnvVarsFormValues) => {
      onConfirmed(formValues);
      form.reset();
      addAlert({
        severity: ALERT_SEVERITY.INFO,
        title: 'Env variables set',
        desc: 'Environment variables are set. Ready for deployment!',
      });
      handleClose();
    },
    [addAlert, form, handleClose, onConfirmed],
  );

  const onSubmit: SubmitHandler<EnvVarsFormValues> = useCallback(
    formValues => {
      handleConfirmed(formValues);
      setEnvVars(formValues);
    },
    [handleConfirmed, setEnvVars],
  );

  const onSubmitError: SubmitErrorHandler<EnvVarsFormValues> = useCallback(
    err => {
      console.error(err);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to set env variables',
        desc: 'All fields are required. Please fill them out.',
      });
    },
    [addAlert],
  );

  const renderTextField = useCallback(
    (envVar: string, index: number) => {
      let TextFieldComponent: ITextFieldComponent;

      const isNonSensitive = ENV_VARS_FORM_NON_SENSITIVE_FIELDS.includes(envVar);
      const isMultiline = ENV_VARS_FORM_MULTILINE_FIELDS.includes(envVar);
      const isNumeric = ENV_VARS_FORM_NUMERIC_FIELDS.includes(envVar);
      const isError = !!form.formState.errors?.[envVar];

      if (isNumeric) {
        TextFieldComponent = FormNumericTextField;
      } else if (isNonSensitive) {
        TextFieldComponent = FormTextField;
      } else {
        TextFieldComponent = FormPasswordTextField;
      }

      return (
        <Stack spacing={2}>
          {envVarLabelMap[envVar] ? (
            typeof envVarLabelMap[envVar] === 'string' ? (
              <Typography variant="captionC">{envVarLabelMap[envVar]}</Typography>
            ) : (
              envVarLabelMap[envVar]
            )
          ) : (
            <Typography variant="captionC">{envVar}</Typography>
          )}

          <TextFieldComponent
            {...(form.formState.errors?.[envVar]?.message?.includes('top up') && {
              FormHelperTextProps: { sx: { '&&': { color: '#FFA756' } } },
            })}
            error={isError}
            fieldConfig={{
              name: envVar,
              placeholder: envVar.replaceAll('_', ' ').replace('TWITTER', 'X'),
            }}
            fullWidth
            helperText={
              isError
                ? form.formState.errors?.[envVar]?.message
                : ENV_VARS_HELP_TEXT?.[envVar] ?? undefined
            }
            hideLabel
            key={[envVar, index].join('-')}
            minRows={isMultiline ? 4 : undefined}
            multiline={isMultiline}
          />
        </Stack>
      );
    },
    [form],
  );

  useEffect(() => {
    form.reset();

    for (const [key, value] of Object.entries(envVars)) {
      form.setValue(key, value);
    }
  }, [template, form, envVars]);

  return (
    <Dialog fullScreen={sm} maxWidth={'md'} onClose={onClose} open={open} {...props}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onSubmitError)}>
          <DialogContainer sx={{ minWidth: { md: '750px', sm: undefined } }}>
            <DialogHeader onClose={handleClose} title="Setup environmental variables" />
            <Stack spacing={3} sx={bodyProps}>
              {String(template?.id) === 'Eliza' &&
                currentCharacterMetadata?.clients?.includes('twitter') && (
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      background: theme => theme.colors.gradients.cream,
                      border: theme => `1px solid ${theme.colors.functional.subject.border}`,
                      p: 3,
                    }}
                  >
                    <IconInfo />

                    <Stack>
                      <Typography>
                        {`This agent requires login access to an X/Twitter account with two-factor authentication (2FA) disabled. For security purposes, it's recommended to create a new X/Twitter account specifically to use this agent.`}
                      </Typography>
                      <ExternalLink
                        href="https://docs.altlayer.io/altlayer-documentation/autonome/troubleshooting-guide"
                        sx={{ fontSize: 12, mt: 1 }}
                      >
                        Having trouble with your agent logging into your X/Twitter account? Check
                        out our troubleshooting guide here
                      </ExternalLink>
                    </Stack>
                  </Stack>
                )}

              {envList?.map((envVar, index) => renderTextField(envVar, index))}
            </Stack>
            <Stack direction="row" spacing="24px">
              <LoadingButton
                fullWidth
                loading={form.formState.isSubmitting}
                type="submit"
                variant="contained"
              >
                <Typography>Confirm</Typography>
              </LoadingButton>
            </Stack>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};
