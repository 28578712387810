import { Stack, SvgIcon, Typography } from '@mui/material';
import { Bar, BarChart as RBarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { FONT_FAMILY } from 'shared/constants/font';
import { largeNumberYAxisTickFormatter, monthDateTickFormatter } from 'shared/utils/recharts';

import { chartDefaults } from './constants';
import { DataKey, Formatter } from './types';

export interface IBarChart extends Omit<CategoricalChartProps, 'data'> {
  dataKeys?: DataKey[];
  data?: any[] | undefined;
  legendDirection?: 'row' | 'column';
  legendSpacing?: string;
  linearGradientPrefix?: string;
  mock?: boolean;
  tooltipValueFormatter?: Formatter;
  xAxisProps?: any;
  yAxisProps?: any;
  cartesianGridProps?: any;
  tooltipProps?: any;
  hideLegend?: boolean;
  isAnimationActive?: boolean;
}

export const BarChart = ({
  cartesianGridProps,
  data,
  dataKeys,
  hideLegend,
  isAnimationActive,
  legendDirection = 'row',
  legendSpacing = '16px',
  linearGradientPrefix,
  mock,
  tooltipProps,
  tooltipValueFormatter,
  xAxisProps,
  yAxisProps,
  ...props
}: IBarChart) => {
  enum DataKeyVal {
    INITIATED_TXNS = 'initiated txns',
    FINALIZED = 'finalized',
  }

  const defaultDataKeys = [
    {
      name: DataKeyVal.INITIATED_TXNS,
      color: '#5C8CE1',
      fill: 'water',
    },
  ];

  const defaultData = [
    { date: '12/02', [DataKeyVal.INITIATED_TXNS]: 120000000 },
    { date: '12/04', [DataKeyVal.INITIATED_TXNS]: 100000000 },
    { date: '12/06', [DataKeyVal.INITIATED_TXNS]: 80000000 },
    { date: '12/08', [DataKeyVal.INITIATED_TXNS]: 60000000 },
    { date: '12/10', [DataKeyVal.INITIATED_TXNS]: 40000000 },
    { date: '12/12', [DataKeyVal.INITIATED_TXNS]: 75000000 },
    { date: '12/14', [DataKeyVal.INITIATED_TXNS]: 95000000 },
    { date: '12/16', [DataKeyVal.INITIATED_TXNS]: 105000000 },
    { date: '12/18', [DataKeyVal.INITIATED_TXNS]: 85000000 },
    { date: '12/20', [DataKeyVal.INITIATED_TXNS]: 65000000 },
  ];

  return (
    <RBarChart
      data={!data || mock ? defaultData : data}
      margin={{ ...chartDefaults.margin, left: 60 }}
      {...props}
    >
      <CartesianGrid
        strokeDasharray="0 0"
        strokeOpacity={0.25}
        strokeWidth={'1px'}
        vertical={false}
        {...cartesianGridProps}
      />

      <XAxis
        fontFamily={FONT_FAMILY}
        fontSize={'13px'}
        fontWeight={400}
        stroke={'rgba(32, 39, 35, 0.25)'}
        strokeWidth={'1px'}
        style={{ lineHeight: '18px' }}
        type="category"
        {...xAxisProps}
        dataKey={xAxisProps?.dataKey ?? 'date'}
        tickFormatter={xAxisProps?.tickFormatter ?? monthDateTickFormatter}
      />

      <YAxis
        axisLine={false}
        fontFamily={FONT_FAMILY}
        fontSize={'13px'}
        fontWeight={400}
        stroke={'rgba(32, 39, 35, 0.25)'}
        strokeWidth={'1px'}
        style={{ lineHeight: '18px' }}
        tickLine={false}
        {...yAxisProps}
        tickFormatter={yAxisProps?.tickFormatter ?? largeNumberYAxisTickFormatter}
      />
      <Tooltip<ValueType, NameType> formatter={tooltipValueFormatter} {...tooltipProps} />
      {!hideLegend && (
        <Legend
          content={({ payload }) => (
            <Stack
              direction={legendDirection}
              flexWrap="wrap"
              spacing={legendSpacing}
              sx={{ mt: '16px' }}
            >
              {payload?.map((entry, index) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={String(entry.dataKey) ?? index}
                  spacing="8px"
                >
                  <SvgIcon sx={{ height: '8px', width: '8px' }}>
                    <rect fill={entry.color} height="8" width="8" />
                  </SvgIcon>
                  <Typography variant="caption">{entry.value}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
        />
      )}
      {(dataKeys || defaultDataKeys).map(({ fill, name }) => (
        <Bar
          dataKey={name}
          fill={`url(#${[linearGradientPrefix, fill].join('-')})`}
          isAnimationActive={isAnimationActive}
          key={name}
        />
      ))}
    </RBarChart>
  );
};
