import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getBasicAuthHeaders } from 'pages/aaas/AaasDetails/utils';
import { Character } from 'pages/aaas/components/ConfigSimpleMode/types';
import { AAAApp } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';
import { constructEndpointSafe } from 'shared/utils/strings';

export const useAgentMetadata = (app?: AAAApp, agentId?: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_ELIZA_CONFIG, { appId: app?.id }],
    queryFn: async () => {
      if (!app?.endpoints?.apiUrl || !agentId) return;

      const res = await axios.get<{ character: Character; id: string }>(
        constructEndpointSafe(app?.endpoints?.apiUrl, 'agents', agentId),
        {
          headers: getBasicAuthHeaders(app),
        },
      );

      return res.data;
    },
    enabled: !!app?.endpoints?.apiUrl && !!app?.id && !!agentId,
  });
};
