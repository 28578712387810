import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconLeftArrow({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      sx={{ height: '14px', width: '15px', ...sx }}
      viewBox="0 0 15 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.5 7L1.5 7M1.5 7L8 13.5M1.5 7L8 0.5" stroke="#202723" strokeWidth="1.4" />
    </SvgIcon>
  );
}
