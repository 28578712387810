import RegistryCoordinatorAbi from 'pages/avs/contracts/RegistryCoordinatorAbi';
import BridgeTemplate from 'pages/avs/contracts/templates/BridgeTemplate';
import MachTemplate from 'pages/avs/contracts/templates/MachTemplate';
import { useCallback } from 'react';
import { AVS_TYPES } from 'shared/types/avs';
import { AVSDeployment } from 'shared/types/protoc-gen/avs';
import { Address } from 'viem';
import { useReadContracts } from 'wagmi';

export function useAvsRoles(avs: AVSDeployment) {
  const { data: svcMngrData, refetch: refetchSvcMgrRoles } = useReadContracts({
    contracts: [
      {
        abi: BridgeTemplate.abi,
        functionName: 'owner',
        address: avs?.serviceManagerProxy as Address,
      },
      {
        abi: BridgeTemplate.abi,
        functionName: 'allowlistManager',
        address: avs?.serviceManagerProxy as Address,
      },
      {
        abi: BridgeTemplate.abi,
        functionName: 'rewardsInitiator',
        address: avs?.serviceManagerProxy as Address,
      },
      {
        abi: avs?.avsType === AVS_TYPES.MACH ? MachTemplate.abi : BridgeTemplate.abi,
        functionName: avs?.avsType === AVS_TYPES.MACH ? 'alertConfirmer' : 'aggregator',
        address: avs?.serviceManagerProxy as Address,
      },
      {
        abi: BridgeTemplate.abi,
        functionName: 'paused',
        address: avs?.serviceManagerProxy as Address,
      },
    ],
    query: {
      select: data =>
        data?.map(cur => (typeof cur.result === 'bigint' ? Boolean(cur.result) : cur.result)) as [
          Address,
          Address,
          Address,
          Address,
          bigint,
        ],
    },
  });
  const { data: regCoordData, refetch: refetchRegCoordRoles } = useReadContracts({
    contracts: [
      {
        abi: RegistryCoordinatorAbi,
        functionName: 'owner',
        address: avs?.registryCoordinatorProxy as Address,
      },
      {
        abi: RegistryCoordinatorAbi,
        functionName: 'churnApprover',
        address: avs?.registryCoordinatorProxy as Address,
      },
      {
        abi: RegistryCoordinatorAbi,
        functionName: 'ejector',
        address: avs?.registryCoordinatorProxy as Address,
      },
    ],
  });
  const [svcMngrOwner, allowlistManager, rewardsInitiator, aggregator, paused] = svcMngrData || [];
  const [regCoordOwner, churnApprover, ejector] = regCoordData?.map(cur => cur.result) || [];

  const refetchRoles = useCallback(() => {
    refetchSvcMgrRoles();
    refetchRegCoordRoles();
  }, [refetchSvcMgrRoles, refetchRegCoordRoles]);

  return {
    svcMngrOwner,
    allowlistManager,
    paused,
    regCoordOwner,
    churnApprover,
    ejector,
    rewardsInitiator,
    aggregator,
    refetchRoles,
  };
}
