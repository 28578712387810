import { useMutation, useQuery } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { RollupType } from 'pages/raas/helpers/labels';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import {
  Account,
  CreateAccountReply,
  CreateAccountRequest,
  ListAccountsReply,
} from 'shared/types/protoc-gen/raas-rollup';
import { MUTATION_KEYS, QUERY_KEYS } from 'shared/types/react-query';

type _CreateAccountRequest = Omit<CreateAccountRequest, 'notificationInterval'> & {
  notificationInterval: string;
};

export const useBalAlerts = () => {
  const { rollupId } = useParams();
  const { addAlert } = useAlerts();
  const { data: rollup } = useRaasDeployment({ rollupId });

  const { isPending: initializePending, mutateAsync: initialize } = useMutation({
    mutationKey: [MUTATION_KEYS.RAAS_BALANCE_ALERT_CREATE_ACCOUNT, { rollupId }],
    mutationFn: async (): Promise<Account[]> => {
      const isOpstack = rollup?.type === RollupType.OPSTACK;

      const response = await bff.post<CreateAccountReply>('/bff/accounts/create', {
        notificationInterval: '300s',
        rollupId: rollupId ?? '',
        notifications: [],
        opstackBatcherThreshold: isOpstack ? '0.5' : '0',
        opstackProposerThreshold: isOpstack ? '0.5' : '0',
        orbitBatchPosterThreshold: !isOpstack ? '0.5' : '0',
      } satisfies _CreateAccountRequest);

      return response.data.accounts;
    },
    onSuccess: data => {
      if (data.length === 0) return;

      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Balance alert registered',
        desc: 'Notifications registered for this rollup.',
      });
      refetchAccounts();
    },
    onError: () => {
      refetchAccounts();
    },
    onSettled: () => {
      refetchAccounts();
    },
  });

  const {
    data: accounts,
    error: getAccountsError,
    isError: isErrorGetAccounts,
    isPending: getAccountsPending,
    refetch: refetchAccounts,
  } = useQuery<Account[], Error, Account[]>({
    queryKey: [QUERY_KEYS.RAAS_BALANCE_ALERT_LIST_ACCOUNTS, { rollupId }],
    queryFn: async () => {
      try {
        const response = await bff.get<ListAccountsReply>('/bff/accounts/list', {
          params: {
            rollupId,
          },
        });
        const accounts = response.data.accounts;

        if (accounts.length === 0) {
          return initialize();
        }

        return accounts;
      } catch (err: any) {
        console.error('err:', err);

        if (err?.response?.data?.message?.includes('not found')) {
          return initialize();
        }

        return [];
      }
    },
  });

  return {
    accounts,
    getAccountsPending,
    getAccountsError,
    isErrorGetAccounts,
    initializePending,
    refetchAccounts,
  };
};
