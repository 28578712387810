import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ExternalLink } from 'pages/raas/components';
import { settlementLayerExplorerUrl } from 'pages/raas/DeploymentDetails/views/ProposerView/constants';
import { SettlementLayer } from 'pages/raas/helpers/labels';
import { useBlockscoutOutputRoots, useRaasDeployment } from 'pages/raas/hooks';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { Section } from 'shared/components/Section';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useCopyToClipboard, useTablePagination } from 'shared/hooks/ui';
import { truncateEthAddress } from 'shared/utils/address';
import { truncateTxnHash } from 'shared/utils/strings';

export const OutputRootsSection = () => {
  const [, copy] = useCopyToClipboard();
  const { data: rollup } = useRaasDeployment();
  const { data: result, isPending } = useBlockscoutOutputRoots();
  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: result?.items ?? [],
  });

  return (
    <Section
      renderContent={
        <Box
          sx={theme => ({
            background: '#fff',
            height: '100%',
            width: '100%',
            borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
            mb: '90px',
          })}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {['L2 Output Index', 'Age', 'L2 Block #', 'L1 Txn Hash', 'Output Root'].map(
                    header => (
                      <TableCell key={header}>
                        <Typography variant="captionC">{header}</Typography>
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isPending
                  ? Array.from({ length: 10 }).map((_, index) => <TableRowSkeleton key={index} />)
                  : filteredData?.map(
                      (
                        {
                          l1_timestamp: l1Timestamp,
                          l1_tx_hash: l1TxnHash,
                          l2_block_number: l2BlockNumber,
                          l2_output_index: l2OutputIndex,
                          output_root: outputRoot,
                        },
                        index,
                      ) => {
                        return (
                          <TableRow key={index.toString()}>
                            <TableCell>{l2OutputIndex}</TableCell>
                            <TableCell>
                              {formatDistanceToNow(parseISO(l1Timestamp), { addSuffix: true })}
                            </TableCell>
                            <TableCell>
                              <ExternalLink
                                href={
                                  rollup?.l2Blockscout &&
                                  [rollup?.l2Blockscout, 'block', l2BlockNumber].join('/')
                                }
                                variant="bodySmall"
                              >
                                {l2BlockNumber}
                              </ExternalLink>
                            </TableCell>
                            <TableCell>
                              <ExternalLink
                                href={
                                  rollup?.l1Blockscout &&
                                  [
                                    settlementLayerExplorerUrl?.[
                                      rollup?.settlementLayer as SettlementLayer
                                    ] ?? settlementLayerExplorerUrl[SettlementLayer.ETHEREUM],
                                    'tx',
                                    l1TxnHash,
                                  ].join('/')
                                }
                                variant="bodySmall"
                              >
                                {truncateTxnHash(l1TxnHash)}
                              </ExternalLink>
                            </TableCell>
                            <TableCell>
                              <Stack alignItems="center" direction="row" spacing="8px">
                                <Typography variant="bodySmall">
                                  {truncateEthAddress(outputRoot)}
                                </Typography>
                                <IconButton onClick={() => copy(outputRoot)} size="small">
                                  <IconCopy sx={{ height: '20px', width: '20px' }} />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
              </TableBody>
              {!isPending && (
                <Pagination
                  paginationOptions={{
                    count: paginationCount,
                    onChange: (_, page) => setCurrentPage(page),
                  }}
                  sx={{ pl: '40px', py: '24px' }}
                />
              )}
            </Table>
          </TableContainer>
        </Box>
      }
      title="Output Roots"
    />
  );
};
