import { create } from 'zustand';

import { Timeframe } from '../hooks';

export enum TimeFilterVariant {
  OVERVIEW_METRICS_SECTION = 'OVERVIEW_METRICS_SECTION',
  ROLLUP_STATS_OVERVIEW_SECTION = 'ROLLUP_STATS_OVERVIEW_SECTION',
  ROLLUP_STATS_EOA_ACCOUNTS_SECTION = 'ROLLUP_STATS_EOA_ACCOUNTS_SECTION',
  ROLLUP_STATS_TRANSACTIONS_SECTION = 'ROLLUP_STATS_TRANSACTIONS_SECTION',
  ROLLUP_STATS_BLOCK_SECTION = 'ROLLUP_STATS_BLOCK_SECTION',
  ROLLUP_STATS_TOKENS_SECTION = 'ROLLUP_STATS_TOKENS_SECTION',
  ROLLUP_STATS_CONTRACTS_SECTION = 'ROLLUP_STATS_CONTRACTS_SECTION',
  BRIDGE_METRICS_GENERAL_SECTION = 'BRIDGE_METRICS_GENERAL_SECTION',
  BRIDGE_METRICS_DEPOSITS_SECTION = 'BRIDGE_METRICS_DEPOSITS_SECTION',
  BRIDGE_METRICS_WITHDRAWALS_SECTION = 'BRIDGE_METRICS_WITHDRAWALS_SECTION',
  FEE_TRACKER_GAS_SECTION = 'FEE_TRACKER_GAS_SECTION',
  BLOCKCHAIN_STATS_GENERAL_SECTION = 'BLOCKCHAIN_STATS_GENERAL_SECTION',
  PROPOSER_BALANCE_BALANCE_SECTION = 'PROPOSER_BALANCE_BALANCE_SECTION',
  BATCHER_BALANCE_BALANCE_SECTION = 'BATCHER_BALANCE_BALANCE_SECTION',
  L2_NODES_SECTION = 'L2_NODES_SECTION',
  RPC_USAGE_MONITORING_SECTION = 'RPC_USAGE_MONITORING_SECTION',
  BALANCE_ALERT_FEE_VAULTS_SECTION = 'BALANCE_ALERT_FEE_VAULTS_SECTION',
  DAILY_RPC_COUNT_SECTION = 'DAILY_RPC_COUNT_SECTION',
  RPM_CHOOSE_METHOD_SECTION = 'RPM_CHOOSE_METHOD_SECTION',
}

interface State {
  selectedTimeFilter: Partial<{
    [key in TimeFilterVariant]: Timeframe;
  }>;
}

interface Actions {
  setSelectedTimeFilter: (id: string, timeFilter: Timeframe) => void;
}

interface Store extends State, Actions {}

const initState: State = {
  selectedTimeFilter: {
    [TimeFilterVariant.OVERVIEW_METRICS_SECTION]: 'month',
    [TimeFilterVariant.ROLLUP_STATS_OVERVIEW_SECTION]: 'month',
    [TimeFilterVariant.ROLLUP_STATS_EOA_ACCOUNTS_SECTION]: 'month',
    [TimeFilterVariant.ROLLUP_STATS_CONTRACTS_SECTION]: 'month',
    [TimeFilterVariant.ROLLUP_STATS_TRANSACTIONS_SECTION]: 'month',
    [TimeFilterVariant.ROLLUP_STATS_BLOCK_SECTION]: 'month',
    [TimeFilterVariant.BRIDGE_METRICS_GENERAL_SECTION]: 'month',
    [TimeFilterVariant.BRIDGE_METRICS_DEPOSITS_SECTION]: 'month',
    [TimeFilterVariant.BRIDGE_METRICS_WITHDRAWALS_SECTION]: 'month',
    [TimeFilterVariant.FEE_TRACKER_GAS_SECTION]: 'month',
    [TimeFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION]: 'month',
    [TimeFilterVariant.PROPOSER_BALANCE_BALANCE_SECTION]: 'month',
    [TimeFilterVariant.BATCHER_BALANCE_BALANCE_SECTION]: 'month',
    [TimeFilterVariant.L2_NODES_SECTION]: 'month',
    [TimeFilterVariant.RPC_USAGE_MONITORING_SECTION]: 'month',
    [TimeFilterVariant.BALANCE_ALERT_FEE_VAULTS_SECTION]: 'month',
    [TimeFilterVariant.DAILY_RPC_COUNT_SECTION]: 'month',
    [TimeFilterVariant.RPM_CHOOSE_METHOD_SECTION]: 'month',
  },
};

export const useTimeFilterStore = create<Store>(set => ({
  ...initState,
  setSelectedTimeFilter: (id, timeFilter) =>
    set(state => ({
      selectedTimeFilter: {
        ...state.selectedTimeFilter,
        [id]: timeFilter,
      },
    })),
}));
