import { Grid, ListItem, ListItemProps, Typography } from '@mui/material';
import { chainLabels } from 'pages/avs/helpers/labels';
import Web3LoadingButton from 'shared/components/Web3/Web3LoadingButton';
import { useSendTx } from 'shared/hooks/useSendTx';
import { SETTLEMENT_CHAINS } from 'shared/types/deployments';
import { Address, parseEther } from 'viem';
import { useBalance } from 'wagmi';
import { GetBalanceData } from 'wagmi/query';

type TBalances = Record<string, { minEth: number } & Partial<GetBalanceData>>;

export function FundAccountRow({
  balances,
  generatedAcct,
  generatedAcctId,
  settlementChain,
  ...props
}: ListItemProps & {
  generatedAcct: any;
  generatedAcctId: string;
  balances: TBalances;
  settlementChain: SETTLEMENT_CHAINS;
}) {
  const { refetch: refetchBalance } = useBalance({
    address: String(generatedAcct?.address) as Address,
    chainId: settlementChain,
    query: {
      enabled: !!generatedAcct?.address,
    },
  });
  const { isPending: isFunding, send } = useSendTx({
    txKey: `Fund_${generatedAcct?.address}`,
    onTxConfirmed: () => refetchBalance(),
  });

  return (
    <ListItem component={Grid} container key={generatedAcctId} {...props}>
      <Grid flexDirection="column" item md={8} xs={12}>
        <Typography>
          {generatedAcctId}: {generatedAcct?.address}
        </Typography>
        <Typography>
          [Funded: {balances?.[generatedAcctId]?.formatted || '0'} /{' '}
          {balances?.[generatedAcctId]?.minEth} {balances?.[generatedAcctId]?.symbol}]
        </Typography>
      </Grid>
      <Grid container flexDirection="column" item md={4} xs={12}>
        <Web3LoadingButton
          chainId={settlementChain}
          chainName={chainLabels?.[settlementChain] || SETTLEMENT_CHAINS[settlementChain]}
          fullWidth
          hideHelperText
          loading={isFunding}
          onClick={() => {
            send({
              to: generatedAcct?.address,
              value: parseEther(String(balances?.[generatedAcctId]?.minEth || '0')),
            });
          }}
          variant="contained"
        >
          Fund
        </Web3LoadingButton>
      </Grid>
    </ListItem>
  );
}
