import { Outlet, RouteObject } from 'react-router-dom';

import { ChatView, DetailsView, EditMetadataView } from './views';

export const routes: RouteObject[] = [
  {
    path: '',
    element: <Outlet />,
    children: [
      {
        path: 'details',
        element: <DetailsView />,
      },
      {
        path: 'chat',
        element: <ChatView />,
      },
      {
        path: 'edit/metadata',
        element: <EditMetadataView />,
      },
    ],
  },
];
