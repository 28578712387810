import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface IMultilineTruncatedText extends TypographyProps {
  text: string;
  maxLines?: number;
}

export default function MultilineTruncatedText({
  maxLines = 2,
  text,
  ...props
}: IMultilineTruncatedText) {
  const [isTruncatedText, setIsTruncatedText] = useState(false);

  const textRef = useRef<HTMLDivElement | null>(null);

  const updateTruncation = () => {
    const textEl = textRef.current;

    if (textEl) {
      setIsTruncatedText(textEl.scrollHeight > textEl.offsetHeight);
    }
  };

  useEffect(() => {
    updateTruncation(); // update once on mount, then update whenever the window is resized
    window.addEventListener('resize', updateTruncation);

    return () => {
      window.removeEventListener('resize', updateTruncation);
    };
  }, []);

  return (
    <Tooltip arrow placement="bottom" title={isTruncatedText ? text : ''}>
      <Typography
        ref={textRef}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: maxLines,
          textOverflow: 'ellipsis',
        }}
        {...props}
      >
        {text}
      </Typography>
    </Tooltip>
  );
}
