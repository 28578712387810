import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useRaasDeployment } from 'pages/raas/hooks';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { NewTransactionsMetric } from './NewTransactionsMetric';
import { TransactionsGrowthMetric } from './TransactionsGrowthMetric';
import { TransactionsSuccessRateMetric } from './TransactionsSuccessRateMetric';

export const TransactionsSection = () => {
  const { isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <TransactionsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <NewTransactionsMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <TransactionsSuccessRateMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          loading={isPending}
          variant={TimeFilterVariant.ROLLUP_STATS_TRANSACTIONS_SECTION}
        />
      }
      sectionContentSx={{ p: '0px' }}
      title="Transactions"
    />
  );
};
