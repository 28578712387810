import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useListCustomerSubs } from 'pages/aaas/hooks';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import ComingSoonTooltip from 'shared/components/ComingSoonTooltip';
import IconInfo from 'shared/components/icons/IconInfo';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';

export const CreditsRemainingChip = () => {
  const theme = useTheme();
  const { data: customerSubs, isPending: isCustomerSubsPending } = useListCustomerSubs({
    enabled: showExperimentalFeatures,
  });

  return showExperimentalFeatures && isCustomerSubsPending ? (
    <Skeleton
      height="auto"
      sx={{ minWidth: '56px' }}
      variant="rectangular"
      width={theme.breakpoints.up('sm') ? '225px' : '190px'}
    />
  ) : (
    <ComingSoonTooltip
      anchorSx={{
        '&>div': {
          py: 2.3,
        },
      }}
      show={!showExperimentalFeatures}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={1}
        sx={{
          background: theme => theme.colors.schema.metalSecondary,
          border: theme => `1px solid ${theme.colors.functional.subject.border}`,
          minHeight: '56px',
          px: { md: 3, sm: 1, xs: 1 },
          py: { md: 2, sm: 1, xs: 1 },
        }}
      >
        <Typography variant={theme.breakpoints.down('md') ? 'caption' : 'body1'}>
          {customerSubs?.availableCredits ?? 0}
          {'/'}
          {customerSubs?.totalCredits ?? 0} Credit(s) Remaining
        </Typography>
        <ConditionalTooltip
          enabled
          title='You can deploy agents based on available Agent Credits (ACs) on your account. To purchase ACs, click the "Buy More" button.'
          wrapped
        >
          <IconInfo />
        </ConditionalTooltip>
      </Stack>
    </ComingSoonTooltip>
  );
};
