import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useMemo } from 'react';
import { raas } from 'shared/api';
import { OperateAAATemplateRequest } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

export type IUseSubmitAaaRequest = Omit<
  UseMutationOptions<void, Error, OperateAAATemplateRequest>,
  'mutationFn'
>;

export default function useSubmitTemplateRequest(props?: IUseSubmitAaaRequest) {
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();

  const {
    isPending: isSubmittingRequest,
    mutate: submitRequest,
    ...mutationReturn
  } = useMutation({
    mutationFn: async (reqBody: OperateAAATemplateRequest) => {
      await raas.post('/bff/aaa/templates/operate', reqBody);
    },
    onSuccess: (...args) => {
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.AAAS_PENDING_AGENT_REQUESTS] });
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.AAAS_GET_AAA_TEMPLATES] });
      props?.onSuccess?.(...args);
    },
    onError: err => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title:
          (err as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Failed to submit request.',
      });
    },
  });

  return useMemo(
    () => ({
      submitRequest,
      isSubmittingRequest,
      ...mutationReturn,
    }),
    [submitRequest, isSubmittingRequest, mutationReturn],
  );
}
