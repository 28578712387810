import { Editor } from '@monaco-editor/react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogProps,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import toJsonShema, { JSONSchema3or4 } from 'to-json-schema';

export interface ICreationMethodDialog extends DialogProps {
  onConfirm: (code: JSONSchema3or4) => void;
}

const DEFAULT_JSON = JSON.stringify(
  {
    data: {
      id: 123,
      messages: [{ text: 'Hello, World!' }, { text: 'Goodbye, World!' }],
    },
  },
  undefined,
  2,
);

export const GenerateJsonSchemaDialog: React.FC<ICreationMethodDialog> = ({
  onClose,
  onConfirm,
  open,
  ...props
}) => {
  const [code, setCode] = useState<string>(DEFAULT_JSON);
  const [markAllFieldsRequired, setMarkAllFieldsRequired] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const parsedJson = useMemo(() => {
    try {
      setErrorMsg('');

      return JSON.parse(code);
    } catch (e: any) {
      setErrorMsg(e?.message);

      return {};
    }
  }, [code]);

  return (
    <Dialog maxWidth="md" open={open} {...props}>
      <DialogContainer
        component="form"
        onSubmit={e => {
          e?.preventDefault();
          onConfirm?.(toJsonShema(parsedJson, { required: markAllFieldsRequired }));
        }}
        sx={{
          minWidth: { md: '750px', sm: undefined, xs: undefined },
        }}
      >
        <DialogHeader
          onClose={() => onClose?.({}, 'backdropClick')}
          startAdornment={
            <Stack spacing={2}>
              <Typography variant="h5">Generate JSON Schema from example JSON</Typography>
              <Typography component="div" variant="caption">
                Add an example JSON payload to generate the JSON Schema. (You may still edit the
                final schema after generation)
              </Typography>
              <Typography component="div" variant="caption">
                For more complex schemas, you may choose to copy and paste it from generative AI.
              </Typography>
            </Stack>
          }
          sx={{
            alignItems: 'start',
          }}
        />
        <Editor
          defaultLanguage="json"
          height="20rem"
          onChange={value => setCode(String(value))}
          options={{
            scrollBeyondLastLine: false,
            showFoldingControls: 'always',
          }}
          theme="vs-dark"
          value={code}
        />
        {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
        <FormControlLabel
          control={
            <Checkbox
              checked={markAllFieldsRequired}
              onChange={e => setMarkAllFieldsRequired(e?.target?.checked)}
            />
          }
          label={'Mark all fields as required'}
        />
        <Button disabled={!!errorMsg} type="submit">
          Generate
        </Button>
      </DialogContainer>
    </Dialog>
  );
};
