import { Metric } from 'shared/components/metric';

const RpcCountMetric = () => {
  // TODO: Update when BE Integration is ready
  const TEST_DATA = {
    data: [
      { date: '12/02', 'initiated txns': 110000000 },
      { date: '12/04', 'initiated txns': 100000000 },
      { date: '12/06', 'initiated txns': 80000000 },
      { date: '12/08', 'initiated txns': 60000000 },
      { date: '12/10', 'initiated txns': 40000000 },
      { date: '12/12', 'initiated txns': 75000000 },
      { date: '12/14', 'initiated txns': 95000000 },
      { date: '12/16', 'initiated txns': 105000000 },
      { date: '12/18', 'initiated txns': 85000000 },
      { date: '12/20', 'initiated txns': 65000000 },
    ],
    dataKeys: [
      {
        name: 'initiated txns',
        fill: 'water',
        color: '#5C8CE1',
      },
    ],
  };

  return (
    <Metric
      chartType="bar"
      data={TEST_DATA}
      hideLegend
      hideMoreButton
      id="rpc-count"
      xAxisProps={{
        dataKey: 'date',
      }}
      yAxisProps={{
        tickFormatter: value => new Intl.NumberFormat().format(value),
      }}
    />
  );
};

export default RpcCountMetric;
