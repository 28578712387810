import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { bff } from 'shared/api';
import {
  AAAAppOperateAction,
  OperateAAAAppReply,
  OperateAAAAppRequest,
} from 'shared/types/protoc-gen/bffaaa';
import { MUTATION_KEYS } from 'shared/types/react-query';

type TerminateArgs = Omit<OperateAAAAppRequest, 'action'>;
export interface IUseTerminateApp
  extends Omit<
    UseMutationOptions<OperateAAAAppReply, Error, TerminateArgs, unknown>,
    'mutationFn' | 'mutationKey'
  > {}

export const useTerminateApp = (
  opts?: IUseTerminateApp,
): UseMutationResult<OperateAAAAppReply, Error, TerminateArgs, unknown> =>
  useMutation({
    mutationKey: [MUTATION_KEYS.AAAS_TERMINATE_AAA_APP],
    mutationFn: async (args: TerminateArgs) => {
      const res = await bff.post<OperateAAAAppReply>('/bff/aaa/apps/operate', {
        id: args?.id,
        action: AAAAppOperateAction.AAA_APP_OPERATE_ACTION_ACTION_TERMINATE,
      } satisfies OperateAAAAppRequest);

      return res.data;
    },
    ...opts,
  });
