import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RollupStatusChip } from 'pages/raas/components';
import { SectionContentKeyValue } from 'pages/raas/DeploymentDetails/components/SectionContentKeyValue';
import { isBscSettlementLayer } from 'pages/raas/DeploymentDetails/utils';
import { IRollupInfoSection } from 'pages/raas/DeploymentDetails/views/RollupInfoView/types';
import {
  DataAvailabilityLayer,
  renderDaTypeIcon,
  renderRollupSettlementLayerIcon,
  renderRollupTypeIcon,
  RollupType,
  SettlementLayer,
} from 'pages/raas/helpers/labels';
import { useMemo } from 'react';
import { IconClose } from 'shared/components/icons/IconClose';
import { Section } from 'shared/components/Section';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { useCopyToClipboard, useDisclosure } from 'shared/hooks/ui';
import { Rollup } from 'shared/types/protoc-gen/raas-rollup';
import { zeroAddress } from 'viem';

export const RollupInfoSection = ({ loading, rollup }: IRollupInfoSection) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const truncateMaxLength = useMemo(() => {
    if (isSmallScreen) return 6;
    if (isMediumScreen) return 8;
    if (isLargeScreen) return 18;
    if (isExtraLargeScreen) return 28;

    return 20; // default value
  }, [isLargeScreen, isMediumScreen, isSmallScreen, isExtraLargeScreen]);
  const initParams = useMemo(
    () => [
      {
        label: 'Chain ID',
        value: rollup?.chainId ?? '-',
      },
      {
        label: 'Rollup Stack',
        value: rollup?.type ?? '-',
        renderIcon: renderRollupTypeIcon(rollup?.type ?? RollupType.UNRECOGNIZED),
      },
      {
        label: 'Settlement Layer',
        value: rollup?.settlementLayer ?? '-',
        renderIcon: renderRollupSettlementLayerIcon(
          rollup?.settlementLayer ?? SettlementLayer.UNRECOGNIZED,
        ),
      },
      {
        label: 'Data Availability',
        value: rollup?.dataAvailabilityLayer ?? '-',
        renderIcon: renderDaTypeIcon(
          isBscSettlementLayer(rollup) &&
            rollup?.dataAvailabilityLayer === DataAvailabilityLayer.BLOBS
            ? DataAvailabilityLayer.BLOBS_BNB
            : rollup?.dataAvailabilityLayer ?? DataAvailabilityLayer.UNRECOGNIZED,
        ),
      },
    ],
    [rollup],
  );
  const deployedResources = useMemo<
    Array<{
      label: string;
      value?: string;
      renderValue?: React.ReactNode;
      truncate?: boolean;
      copyable?: boolean;
      hyperlink?: boolean;
      truncateMaxLength?: number;
    }>
  >(
    () => [
      {
        label: 'HTTP RPC',
        value: rollup?.rpcEndpoint ?? '-',
        truncate: true,
        copyable: true,
        truncateMaxLength,
      },
      {
        label: 'WebSocket',
        value: rollup?.rpcWsEndpoint ?? '-',
        truncate: true,
        copyable: true,
        truncateMaxLength,
      },
      {
        label: 'Bridge',
        ...(rollup?.type === RollupType.ORBIT
          ? { renderValue: <BridgingAssets rollup={rollup} />, copyable: false }
          : {
              value: rollup?.bridge ?? 'https://bridge.arbitrum.io/',
              copyable: true,
              truncate: true,
              hyperlink: true,
            }),
        truncateMaxLength,
      },
      {
        label: 'Explorer',
        value: rollup?.explorer ?? '-',
        truncate: true,
        hyperlink: true,
        copyable: true,
        truncateMaxLength,
      },
    ],
    [rollup, truncateMaxLength],
  );

  return (
    <Section
      renderGridContent={
        <>
          {initParams?.map(item => (
            <SectionContentKeyValue
              key={item.label}
              label={item.label}
              loading={loading}
              renderIcon={item.renderIcon}
              value={String(item.value)}
            />
          ))}
          {deployedResources?.map(item => (
            <SectionContentKeyValue
              clipboard={item.copyable}
              hyperlink={item?.hyperlink}
              key={item.label}
              label={item.label}
              loading={loading}
              renderValue={item?.renderValue}
              truncate={item.truncate}
              truncateMaxLength={item.truncateMaxLength}
              value={item.value}
            />
          ))}
        </>
      }
      renderSubHeaderEndContent={<RollupStatusChip loading={loading} rollup={rollup} />}
      title="Rollup Info"
    />
  );
};

const BridgingAssets: React.FC<{ rollup: Rollup | undefined }> = ({ rollup }) => {
  const [, copy] = useCopyToClipboard();
  const { onClose, onOpen, open } = useDisclosure();

  const {
    AdminProxyAddress,
    BridgeAddress,
    InboxAddress,
    OutboxAddress,
    RollupAddress,
    SequencerInboxAddress,
    UtilsAddress,
    ValidatorWalletCreatorAddress,
  } = rollup?.contractAddresses ?? {};
  const { batchPoster, chainOwner, infrastructureFeeCollector, networkFeeReceiver, staker } =
    rollup?.accounts ?? {};

  const chain = {
    chainInfo: {
      minL2BaseFee: 100000000,
      networkFeeReceiver: networkFeeReceiver ?? zeroAddress,
      infrastructureFeeCollector: infrastructureFeeCollector ?? zeroAddress,
      batchPoster: batchPoster ?? zeroAddress,
      staker: staker ?? zeroAddress,
      chainOwner: chainOwner ?? zeroAddress,
      chainName: rollup?.name,
      chainId: rollup?.chainId,
      parentChainId: rollup?.parentChainId,
      rpcUrl: rollup?.rpcEndpoint,
      explorerUrl: rollup?.l2Blockscout,
      nativeToken: zeroAddress, // <- hardcode
    },
    coreContracts: {
      rollup: RollupAddress,
      inbox: InboxAddress,
      outbox: OutboxAddress,
      adminProxy: AdminProxyAddress,
      sequencerInbox: SequencerInboxAddress,
      bridge: BridgeAddress,
      utils: UtilsAddress,
      validatorWalletCreator: ValidatorWalletCreatorAddress,
    },
    tokenBridgeContracts: {
      l2Contracts: {
        customGateway: zeroAddress,
        multicall: zeroAddress,
        proxyAdmin: zeroAddress,
        router: zeroAddress,
        standardGateway: zeroAddress,
        weth: zeroAddress,
        wethGateway: zeroAddress,
      },
      l3Contracts: {
        customGateway: zeroAddress,
        multicall: zeroAddress,
        proxyAdmin: zeroAddress,
        router: zeroAddress,
        standardGateway: zeroAddress,
        weth: zeroAddress,
        wethGateway: zeroAddress,
      },
    },
  };

  const handleCopy = () => {
    copy(JSON.stringify(chain, null, 2));

    onClose();
  };

  return (
    <>
      <ConditionalTooltip enabled placement="top" title="Bridge assets to your rollup">
        <Stack alignItems="center" direction="row" justifyContent="start" pt="4px">
          <Typography
            onClick={onOpen}
            sx={{
              textAlign: 'start',
              color: theme => theme.colors.functional.text.link,
              cursor: 'pointer',
            }}
            variant="bodySmallM"
          >
            Bridge assets
          </Typography>
        </Stack>
      </ConditionalTooltip>

      <Dialog onClose={onClose} open={open}>
        <Stack spacing="40px" sx={{ p: '40px', minHeight: '304px', minWidth: '504px' }}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="h5">Bridging Instructions</Typography>
            <IconButton onClick={onClose} size="small">
              <IconClose />
            </IconButton>
          </Stack>
          <Stack component="ol" spacing="4px">
            <Box component="li">
              <Stack direction="row" spacing="8px">
                <Typography>Navigate to</Typography>
                <Typography
                  component="a"
                  href="https://bridge.arbitrum.io"
                  rel="noreferrer"
                  target="_blank"
                >
                  https://bridge.arbitrum.io
                </Typography>
              </Stack>
            </Box>
            <Box component="li">
              <Typography>
                Temporarily connect to L1 Mainnet (this enables settings under Developer Mode)
              </Typography>
            </Box>
            <Box component="li">
              <Typography>Enable Testnet Mode:</Typography>
              <Stack component="ul" spacing="4px">
                <Box component="li">
                  <Typography>Click on your address in the top right</Typography>
                </Box>
                <Box component="li">
                  <Typography>Click Settings</Typography>
                </Box>
                <Box component="li">
                  <Typography>Click on “Turn on Testnet mode”</Typography>
                </Box>
              </Stack>
            </Box>
            <Box component="li">
              <Stack spacing="4px">
                <Typography>In the “Add testnet Orbit chain” text box, paste this text:</Typography>
                <Stack
                  sx={{
                    overflow: 'auto',
                    maxHeight: '204px',
                    fontSize: '12px',
                    background: theme => theme.colors.functional.text.primary,
                    color: '#fff',
                    px: 4,
                    py: 2,
                  }}
                >
                  <pre>{JSON.stringify(chain, null, 2)}</pre>
                </Stack>
              </Stack>
            </Box>
            <Box component="li">
              <Typography>Click the “Add chain” button</Typography>
            </Box>
            <Box component="li">
              <Typography>
                Now you should be able to see the testnet appear in the networks list
              </Typography>
            </Box>
          </Stack>

          <Button onClick={handleCopy}>Copy</Button>
        </Stack>
      </Dialog>
    </>
  );
};
