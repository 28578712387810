import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QUERY_KEYS } from 'shared/types/react-query';
import { Address, Hash } from 'viem';

import { useBlockscoutAxios } from './useBlockscoutAxios';

export interface IUseBlockscoutTxList
  extends Omit<UseQueryOptions<BlockscoutTxListResponse>, 'queryFn' | 'queryKey'> {
  account: Address;
}

export const useBlockscoutTxList = ({ account, ...queryOptions }: IUseBlockscoutTxList) => {
  const { rollupId } = useParams();
  const blockscout = useBlockscoutAxios({ v2: true });

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_BLOCKSCOUT_STATS,
      {
        variant: 'tx-list',
        rollupId,
        account,
      },
    ],
    queryFn: async (): Promise<BlockscoutTxListResponse> => {
      const res = await blockscout.l1.get<BlockscoutTxListResponse>(
        `/addresses/${account}/transactions`,
      );

      return res.data;
    },
    enabled: !!blockscout?.rollup,
    ...queryOptions,
  });
};

export interface BlockscoutTxListItem {
  timestamp: string;
  fee: {
    type: string;
    value: string;
  };
  gas_limit: string;
  block: number;
  status: string;
  method: string | null;
  confirmations: number;
  type: number;
  exchange_rate: string;
  to: {
    ens_domain_name: string | null;
    hash: Hash;
    implementations: any[];
    is_contract: boolean;
    is_verified: boolean;
    metadata: any;
    name: string | null;
    private_tags: any[];
    proxy_type: any;
    public_tags: any[];
    watchlist_names: any[];
  };
  tx_burnt_fee: string;
  max_fee_per_gas: string;
  result: string;
  hash: Hash;
  gas_price: string;
  priority_fee: string;
  base_fee_per_gas: string;
  from: {
    ens_domain_name: string | null;
    hash: Hash;
    implementations: any[];
    is_contract: boolean;
    is_verified: boolean;
    metadata: any;
    name: string | null;
    private_tags: any[];
    proxy_type: any;
    public_tags: any[];
    watchlist_names: any[];
  };
  token_transfers: any;
  tx_types: any[];
  gas_used: string;
  created_contract: any;
  position: number;
  nonce: number;
  has_error_in_internal_txs: boolean;
  actions: any[];
  decoded_input: any;
  token_transfers_overflow: any;
  raw_input: Hash;
  value: string;
  max_priority_fee_per_gas: string;
  revert_reason: any;
  confirmation_duration: number[];
  tx_tag: any;
}

export interface BlockscoutTxListNextPageParams {
  block_number: number;
  fee: string;
  hash: Hash;
  index: number;
  inserted_at: string;
  items_count: number;
  value: string;
}

export interface BlockscoutTxListResponse {
  items: BlockscoutTxListItem[];
  next_page_params: BlockscoutTxListNextPageParams;
}
