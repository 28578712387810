import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchAvsMetadata } from 'pages/avs/api/avs';
import BridgeTemplate from 'pages/avs/contracts/templates/BridgeTemplate';
import { useAvsRoles } from 'pages/avs/hooks/useAvsRoles';
import { ChangeEventHandler, useState } from 'react';
import Web3LoadingButton from 'shared/components/Web3/Web3LoadingButton';
import { useWriteTx } from 'shared/hooks/useWriteTx';
import { AVSDeployment } from 'shared/types/protoc-gen/avs';
import { QUERY_KEYS } from 'shared/types/react-query';
import { Address } from 'viem';

export default function EditMetadataModal({
  avs,
  onClose,
  open,
  ...rest
}: DialogProps & { avs: AVSDeployment }) {
  const [uriInput, setUriInput] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const { svcMngrOwner } = useAvsRoles(avs);

  const { data: metadata, refetch } = useQuery({
    queryKey: [QUERY_KEYS.GET_AVS_METADATA, avs?.serviceManagerProxy],
    queryFn: fetchAvsMetadata,
    enabled: Boolean(avs?.serviceManagerProxy),
  });

  const {
    isPending: isUpdating,
    isSuccess,
    reset,
    write: updateMetadata,
  } = useWriteTx({
    contractAbi: BridgeTemplate.abi,
    functionName: 'updateAVSMetadataURI',
    contractAddress: avs?.serviceManagerProxy as Address,
    functionArgs: [uriInput],
    txKey: `updateMetadata_${avs?.serviceManagerProxy}`,
    onTxConfirmed: () => {
      refetch?.();
    },
  });

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = e => {
    const val = e?.target?.value;

    if (isSuccess) {
      reset();
    }

    setUriInput(val);
  };

  return (
    <Dialog
      disablePortal
      onClose={onClose}
      open={open}
      sx={{ '& .MuiPaper-root': { p: 3, background: '#FFFFFF' } }}
      {...rest}
    >
      <Collapse in={!isEditMode}>
        <DialogTitle>
          <Typography fontWeight={500} variant="h5">
            AVS Profile
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems="center" justifyContent="center">
            {metadata?.metadataLogo && (
              <Box component="img" src={metadata?.metadataLogo} sx={{ width: 60, height: 60 }} />
            )}
          </Stack>
          <TextField
            InputLabelProps={{ shrink: true }}
            disabled
            fullWidth
            label="AVS Name"
            placeholder="None"
            sx={{ my: 2 }}
            value={metadata?.metadataName}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            disabled
            fullWidth
            label="Website"
            placeholder="None"
            sx={{ my: 2 }}
            value={metadata?.metadataWebsite}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            disabled
            fullWidth
            label="Twitter"
            placeholder="None"
            sx={{ my: 2 }}
            value={metadata?.metadataX}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            disabled
            fullWidth
            label="Description"
            multiline
            placeholder="None"
            sx={{ my: 2 }}
            value={metadata?.metadataDescription}
          />
          <Button fullWidth onClick={() => setIsEditMode(true)}>
            Edit
          </Button>
        </DialogContent>
      </Collapse>
      <Collapse in={isEditMode}>
        <DialogTitle>
          <Typography fontWeight={500} variant="h5">
            Update AVS Metadata
          </Typography>
          <Typography
            component={Link}
            href="https://docs.eigenlayer.xyz/eigenlayer/avs-guides/avs-dashboard-onboarding#metadatauri-format"
            rel="noopener noreferrer"
            sx={{ '&:hover': { textDecoration: 'underline' } }}
            target="_blank"
            variant="caption"
          >
            View example format
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            onChange={handleChangeInput}
            placeholder="https://example.com/metadata.json"
            sx={{ mt: 2 }}
            value={uriInput}
          />
          {isSuccess && (
            <Typography
              sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                color: '#66B489',
                fontWeight: 500,
              }}
            >
              <Typography textAlign="center">🎉&nbsp;Successfully updated metadata!</Typography>
              <Typography textAlign="center">
                It may take about 20 minutes for the information to be reflected.
              </Typography>
            </Typography>
          )}
          <Web3LoadingButton
            loading={isUpdating}
            onClick={updateMetadata}
            requiredSender={svcMngrOwner}
            sx={{ mt: 2 }}
            variant="contained"
          >
            Update
          </Web3LoadingButton>
          <Button fullWidth onClick={() => setIsEditMode(false)} sx={{ mt: 2 }} variant="outlined">
            {isSuccess ? 'Back' : 'Cancel'}
          </Button>
        </DialogContent>
      </Collapse>
    </Dialog>
  );
}
