import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { bff } from 'shared/api';
import {
  AAAAppOperateAction,
  OperateAAAAppReply,
  OperateAAAAppRequest,
} from 'shared/types/protoc-gen/bffaaa';
import { MUTATION_KEYS } from 'shared/types/react-query';

type RestartArgs = Omit<OperateAAAAppRequest, 'action'>;

export interface IUseRestartApp
  extends Omit<
    UseMutationOptions<OperateAAAAppReply, Error, RestartArgs, unknown>,
    'mutationFn' | 'mutationKey'
  > {}

export const useRestartApp = (
  opts: IUseRestartApp,
): UseMutationResult<OperateAAAAppReply, Error, RestartArgs, unknown> =>
  useMutation({
    mutationKey: [MUTATION_KEYS.AAAS_RESTART_AAA_APP],
    mutationFn: async (args: RestartArgs) => {
      const res = await bff.post<OperateAAAAppReply>('/bff/aaa/apps/operate', {
        id: args.id,
        action: AAAAppOperateAction.AAA_APP_OPERATE_ACTION_ACTION_RESTART,
      });

      return res.data;
    },
    ...opts,
  });
