import { RouteObject } from 'react-router-dom';
import { RequireLoginGuard } from 'shared/guards';

import { Disclaimer } from './Disclaimer';
import { Privacy } from './Privacy';
import { SelectLayerType } from './SelectLayerType';
import { TermsOfService } from './TermsOfService';

export enum GENERAL_PATHS {
  PRIVACY = '/privacy',
  DISCLAIMER = '/disclaimer',
  TERMS_OF_SERVICE = '/terms-of-service',
  SELECT_LAYER_TYPE = '/select',
}

export const routes: RouteObject[] = [
  {
    element: (
      <RequireLoginGuard>
        <SelectLayerType />
      </RequireLoginGuard>
    ),
    path: GENERAL_PATHS.SELECT_LAYER_TYPE,
  },
  {
    element: <Privacy />,
    path: GENERAL_PATHS.PRIVACY,
  },
  {
    element: <Disclaimer />,
    path: GENERAL_PATHS.DISCLAIMER,
  },
  {
    element: <TermsOfService />,
    path: GENERAL_PATHS.TERMS_OF_SERVICE,
  },
];
