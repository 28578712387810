import { GetComponentStyleFunc } from './types';

export const muiSelect: GetComponentStyleFunc<'MuiSelect'> = theme => {
  return {
    styleOverrides: {
      root: {
        input: {
          color: theme.colors.functional.text.primary,
          fontSize: '15px',
          fontWeight: 400,
          padding: '24px',
          minHeight: '68px',
          background: '#fff',
        },
        '& > .MuiSelect-notchedOutline': {
          borderRadius: 0,
          border: `1px solid ${theme.colors.functional.subject.border}`,
        },
        '&.Mui-disabled': {
          background: theme.colors.functional.container.disabled,
        },
        '&.Mui-disabled > .MuiSelect-notchedOutline, &.Mui-focused .MuiSelect-notchedOutline': {
          border: `1px solid ${theme.colors.functional.subject.border}`,
        },
        '&:hover > .MuiSelect-notchedOutline, &.Mui-focused .MuiSelect-notchedOutline': {
          border: `1px solid ${theme.colors.functional.subject.border}`,
        },
        '&.MuiSelect-underline::before': {
          border: 'none',
        },
        '&.MuiSelect-underline:hover::before': {
          border: 'none',
        },
      },
    },
  };
};
