import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useApp } from 'pages/aaas/AaasDetails/hooks';
import { useMemo } from 'react';
import { bff } from 'shared/api';
import {
  CreateFineTuneJobReply,
  CreateFineTuneJobRequest,
  GetFineTuneSourceReply,
  ListFineTuneJobReply,
  UpdateFineTuneSourceReply,
  UpdateFineTuneSourceRequest,
} from 'shared/types/protoc-gen/bffaaa';

export const useFineTune = () => {
  const { data: app } = useApp();
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const { data, isPending: isLoadingSources } = useQuery({
    queryKey: ['FINETUNE_AGENT_SOURCES'],
    queryFn: async () => {
      const res = await bff.get<GetFineTuneSourceReply>('/bff/aaa/finetune');

      return res?.data;
    },
  });

  const { data: jobs, isPending: isLoadingJobs } = useQuery({
    queryKey: ['FINETUNE_AGENT_JOB', app?.id],
    queryFn: async () => {
      const res = await bff.get<ListFineTuneJobReply>(`/bff/aaa/ftjob/${app?.id}`);

      return res?.data;
    },
    enabled: !!app?.id,
  });

  const twitterOptions = useMemo(
    () => data?.twittersProfiles?.map(cur => ({ label: cur, value: cur })),
    [data],
  );
  const telegramOptions = useMemo(
    () => data?.telegramChannels?.map(cur => ({ label: cur.channelId, value: cur.channelId })),
    [data],
  );

  const { isPending: isUpdating, mutate: updateJob } = useMutation({
    mutationFn: async (data: Omit<CreateFineTuneJobRequest, 'appId'>) => {
      const res = await bff.post<CreateFineTuneJobRequest, AxiosResponse<CreateFineTuneJobReply>>(
        'bff/aaa/ftjob',
        { ...data, appId: app?.id },
      );

      return res?.data;
    },
    onSuccess: () => {
      addAlert({ severity: ALERT_SEVERITY.SUCCESS, title: 'Finetune job updated successfully' });
      queryClient.invalidateQueries({ queryKey: ['FINETUNE_AGENT_JOB', app?.id] });
    },
  });

  const { isPending: isUpdateSourcesPending, mutate: updateSources } = useMutation({
    mutationKey: ['FINETUNE_UPDATE_SOURCES', app?.id],
    mutationFn: async (data: UpdateFineTuneSourceRequest) => {
      const res = await bff.post<UpdateFineTuneSourceReply>('bff/aaa/finetune', data);

      return res?.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Finetune sources updated successfully',
      });
      queryClient.invalidateQueries({ queryKey: ['FINETUNE_UPDATE_SOURCES', app?.id] });
    },
  });

  return useMemo(
    () => ({
      data,
      updateJob,
      isUpdating,
      isLoadingSources,
      isLoadingJobs,
      jobs,
      twitterOptions,
      telegramOptions,
      isUpdateSourcesPending,
      updateSources,
    }),
    [
      data,
      isLoadingJobs,
      isLoadingSources,
      isUpdateSourcesPending,
      isUpdating,
      jobs,
      telegramOptions,
      twitterOptions,
      updateJob,
      updateSources,
    ],
  );
};
