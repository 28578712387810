import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { RollupType } from 'pages/raas/helpers/labels';
import { useBlockscoutTxList, useRaasDeployment } from 'pages/raas/hooks';
import { Section } from 'shared/components/Section';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useTablePagination } from 'shared/hooks/ui';
import { truncateEthAddress } from 'shared/utils/address';
import { Hash, zeroHash } from 'viem';

// TODO: revisit the endpoint and finalize impl
export const BatcherTxListSection = () => {
  const { data: rollup } = useRaasDeployment();
  // TODO: remove when finalized
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: result, isPending } = useBlockscoutTxList({
    account: rollup?.accounts?.[rollup.type === RollupType.OPSTACK ? 'batcher' : 'batchPoster'],
  });
  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: getMockData(),
  });

  return (
    <Section
      renderContent={
        <Box
          sx={theme => ({
            height: '100%',
            width: '100%',
            borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
            mb: '90px',
          })}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {['L2 Block #', 'L2 Block Txn Count', 'L1 Txn Hash', 'Age'].map(header => (
                    <TableCell key={header}>
                      <Typography variant="captionC">{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isPending
                  ? Array.from({ length: 10 }).map((_, index) => (
                      <TableRowSkeleton cellCount={4} key={index.toString()} />
                    ))
                  : filteredData?.map((v, index) => {
                      return (
                        <TableRow key={index.toString()}>
                          <TableCell>
                            <Typography
                              sx={{ color: '#5C8CE1', textDecoration: 'underline' }}
                              variant="bodySmall"
                            >
                              {v.l2BlockNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="bodySmall">{v.l2BlockTxnCount}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{ color: '#5C8CE1', textDecoration: 'underline' }}
                              variant="bodySmall"
                            >
                              {truncateEthAddress(v.l1TxHash)}
                            </Typography>
                          </TableCell>
                          <TableCell>{v.age}</TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
              <Pagination
                paginationOptions={{
                  count: paginationCount,
                  onChange: (_, page) => setCurrentPage(page),
                }}
                sx={{ pl: '40px', py: '24px' }}
              />
            </Table>
          </TableContainer>
        </Box>
      }
      title="Batcher Tx List"
    />
  );
};

const getMockData = (): {
  age: string;
  l1TxHash: Hash;
  l2BlockTxnCount: number;
  l2BlockNumber: number;
}[] => {
  return Array.from({ length: 45 }).map((_, index) => ({
    age: '3w ago',
    l1TxHash: zeroHash,
    l2BlockTxnCount: Math.ceil(Math.random() * index),
    l2BlockNumber: 1081800,
  }));
};
