import { Divider, Stack, StackProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IFormGroupContainer extends StackProps {
  title: string;
  endAdornment?: ReactNode;
}

export const FormGroupContainer: FC<IFormGroupContainer> = ({
  children,
  endAdornment,
  title,
  ...props
}) => {
  return (
    <Stack spacing={2} {...props}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>

        {endAdornment}
      </Stack>

      <Divider />

      <Stack spacing={1}>{children}</Stack>
    </Stack>
  );
};
