import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ExternalLink } from 'pages/raas/components';
import { SettlementLayer } from 'pages/raas/helpers/labels';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconEdit } from 'shared/components/icons/IconEdit';
import { Section } from 'shared/components/Section';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useDisclosure, useTablePagination } from 'shared/hooks/ui';
import { Account } from 'shared/types/protoc-gen/raas-rollup';
import { truncateEthAddress } from 'shared/utils/address';
import { formatWithPrecision, truncate } from 'shared/utils/strings';
import { getAddress } from 'viem';

import { settlementLayerExplorerUrl } from '../ProposerView/constants';
import { BalanceAlertEditDialog } from './BalanceAlertEditDialog';
import { useBalAlerts } from './useBalAlerts';

export const BalanceAlertView = () => {
  const { rollupId } = useParams();
  const {
    onClose: onCloseEditDialog,
    onOpen: onOpenEditDialog,
    open: openedEditDialog,
  } = useDisclosure();
  const { accounts, getAccountsPending } = useBalAlerts();
  const {
    filteredData: filteredAccounts,
    paginationCount,
    setCurrentPage,
  } = useTablePagination({ data: accounts ?? [] });
  const [selectedAccount, setSelectedAccount] = useState<Account>();

  const { data: rollup } = useRaasDeployment({ rollupId });

  return (
    <>
      <BalanceAlertEditDialog
        account={selectedAccount}
        onClose={onCloseEditDialog}
        open={openedEditDialog}
      />

      <Section title="Balance Alert">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['Name', 'Address', 'Balance (ETH)', 'Threshold (ETH)', 'Notifications', ' '].map(
                  header => (
                    <TableCell key={header}>
                      <Typography variant="captionC">{header}</Typography>
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {getAccountsPending
                ? Array.from({ length: 2 }).map((_, index) => (
                    <TableRowSkeleton cellCount={6} key={index} />
                  ))
                : filteredAccounts.map((account, index) => (
                    <TableRow key={index.toString()}>
                      <TableCell>
                        <Typography variant="bodySmall">{account?.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <ExternalLink
                          href={[
                            settlementLayerExplorerUrl[rollup?.settlementLayer as SettlementLayer],
                            'address',
                            getAddress(account?.address),
                          ].join('/')}
                          variant="bodySmall"
                        >
                          {truncateEthAddress(account?.address)}
                        </ExternalLink>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={theme => ({
                            color:
                              +account?.balance === 0
                                ? theme.colors.functional.text.primary
                                : account?.threshold > account?.balance
                                ? theme.colors.schema.error
                                : theme.colors.schema.success,
                          })}
                          variant="bodySmallM"
                        >
                          {formatWithPrecision(+account?.balance)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">{account?.threshold}</Typography>
                      </TableCell>
                      <TableCell>
                        <Stack spacing="8px">
                          {account?.notifications.length === 0
                            ? '-'
                            : account?.notifications.map((text, idx) => (
                                <Typography key={text} variant="bodySmall">
                                  {truncate(text, '...', 15, 0)}
                                  {idx < account?.notifications.length - 1 && ','}
                                </Typography>
                              ))}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Button
                          disableElevation
                          disabled={!account.mainNet}
                          onClick={() => {
                            setSelectedAccount(account);
                            onOpenEditDialog();
                          }}
                          size="small"
                          sx={theme => ({
                            '&, &:hover, &:disabled': {
                              background: 'transparent',
                            },
                            '&, &:hover': {
                              color: theme.colors.functional.text.primary,
                            },
                          })}
                        >
                          <Stack direction="row" spacing="8px">
                            <IconEdit sx={{ height: '20px', width: '20px' }} />
                            <Typography variant="bodySmallC"> EDIT</Typography>
                          </Stack>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            {!getAccountsPending && (
              <Pagination
                paginationOptions={{
                  count: paginationCount,
                  onChange: (_, page) => setCurrentPage(page),
                }}
                sx={{ pl: '40px', py: '24px' }}
              />
            )}
          </Table>
        </TableContainer>
      </Section>
    </>
  );
};
