import { Outlet, RouteObject } from 'react-router-dom';
import { RequireLoginGuard } from 'shared/guards';

import { AVSDeployments } from './AvsDeployments';
import { AVSDetails } from './AvsDetails';
import { NewAVS } from './NewAVS';

export enum AVS_PATHS {
  AVS_DEPLOYMENTS = '/avs',
  AVS_DETAILS = '/avs/:deploymentId',
  AVS_NEW_DEPLOYMENT = '/avs/new',
}

export const routes: RouteObject[] = [
  {
    path: AVS_PATHS.AVS_DEPLOYMENTS,
    element: (
      <RequireLoginGuard>
        <Outlet />
      </RequireLoginGuard>
    ),
    children: [
      { path: '', element: <AVSDeployments /> },
      { path: ':deploymentId', element: <AVSDetails /> },
      {
        path: 'new',
        element: <NewAVS />,
      },
    ],
  },
];
