import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useRaasDeployment } from 'pages/raas/hooks';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { CustomTokenAlert } from './CustomTokenAlert';
import { WithdrawalFinalizedTxnsGrowthMetric } from './WithdrawalFinalizedTxnsGrowthMetric';
import { WithdrawalFinalizedVolumeEthGrowthMetric } from './WithdrawalFinalizedVolumeEthGrowthMetric';
import { WithdrawalStatusMetric } from './WithdrawalStatusMetric';
import { WithdrawalTxnsGrowthMetric } from './WithdrawalTxnsGrowthMetric';
import { WithdrawalVolumeEthGrowthMetric } from './WithdrawalVolumeEthGrowthMetric';

export const WithdrawalsSection = () => {
  const { data: rollup } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          {rollup?.tokenMetadata?.isCustom && <CustomTokenAlert />}

          <MetricGridItem item>
            <WithdrawalTxnsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <WithdrawalVolumeEthGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <WithdrawalFinalizedTxnsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <WithdrawalFinalizedVolumeEthGrowthMetric />
          </MetricGridItem>
          {showExperimentalFeatures && (
            <MetricGridItem item xs={12}>
              <WithdrawalStatusMetric />
            </MetricGridItem>
          )}
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter variant={TimeFilterVariant.BRIDGE_METRICS_WITHDRAWALS_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="Withdrawals"
    />
  );
};
