import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { getL1TokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import { FormNumericTextField, FormTextField, Label } from 'shared/components/form';
import IconInfo from 'shared/components/icons/IconInfo';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { Account, UpdateAccountReply, UpdateAccountRequest } from 'shared/types/protoc-gen/raas-rollup';
import { MUTATION_KEYS } from 'shared/types/react-query';
import { truncateEthAddress } from 'shared/utils/address';
import { commaSeparatedStringPattern } from 'shared/utils/patterns';
import { formatWithPrecision } from 'shared/utils/strings';
import { zeroAddress } from 'viem';
import * as yup from 'yup';

import { useBalAlerts } from './useBalAlerts';

interface FormValues {
  threshold: string;
  notifications: string;
}

const validation = yup.object().shape({
  threshold: yup
    .number()
    .required('Threshold is required')
    .min(0, 'Threshold must be a non-negative number'),
  notifications: yup
    .string()
    .nullable()
    .matches(commaSeparatedStringPattern, 'Notifications must be a comma-separated string'),
});

export interface IBalanceAlertEditDialog extends Omit<DialogProps, 'onClose'> {
  account?: Account;
  onClose?: () => void;
}

export const BalanceAlertEditDialog = ({
  account,
  onClose,
  open,
  sx,
  ...props
}: IBalanceAlertEditDialog) => {
  const { rollupId } = useParams();
  const { addAlert } = useAlerts();
  const { data: rollup } = useRaasDeployment({ rollupId });
  const { refetchAccounts } = useBalAlerts();
  const form = useForm<FormValues>({
    resolver: yupResolver(validation as yup.ObjectSchema<FormValues>),
    mode: 'all',
  });

  useEffect(() => {
    form.setValue('threshold', account?.threshold ?? '1');
    form.setValue('notifications', account?.notifications?.join(',') ?? '');
  }, [account, form]);

  const { isPending: updateAccountPending, mutateAsync: updateAccount } = useMutation({
    mutationKey: [MUTATION_KEYS.RAAS_BALANCE_ALERT_UPDATE_ACCOUNT, { rollupId }],
    mutationFn: async (request: UpdateAccountRequest) => {
      const response = await bff.post<UpdateAccountReply>('/bff/accounts/update', request);

      return response.data.account;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Account updated',
        desc: 'The account has been successfully updated.',
      });
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to update account',
        desc: 'Please try again later.',
      });
    },
    onSettled: () => {
      refetchAccounts();
    },
  });

  const onValid: SubmitHandler<FormValues> = useCallback(
    async formValues => {
      console.log('formValues:', formValues);

      try {
        await updateAccount({
          rollupId: rollupId ?? '',
          accountId: account?.id ?? -1,
          notifications: formValues.notifications?.split(','),
          threshold: String(formValues.threshold),
        });
        form.reset();
        form.clearErrors();
        onClose?.();
      } catch (err) {
        console.error(err);
        addAlert({
          severity: ALERT_SEVERITY.ERROR,
          title: 'Failed to update account',
          desc: 'Please try again later.',
        });
      }
    },
    [account?.id, addAlert, form, onClose, rollupId, updateAccount],
  );
  const onInvalid: SubmitErrorHandler<FormValues> = useCallback(
    err => {
      console.error(err);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Invalid inputs',
        desc: 'Please check the form for errors.',
      });
    },
    [addAlert],
  );

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open} {...props} sx={{ p: 0, ...sx }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onValid, onInvalid)}>
          <DialogContainer sx={{ minWidth: '672px' }}>
            <DialogHeader onClose={onClose} title="Edit" />

            <Stack id="edit-dialog-content" spacing="24px">
              <Stack
                alignItems="center"
                direction="row"
                id="edit-dialog-details"
                justifyContent="space-between"
              >
                <Stack alignItems="start" justifyContent="center" spacing="8px">
                  <Typography variant="caption">Name</Typography>
                  <Typography variant="bodySmallM">{account?.name}</Typography>
                </Stack>

                <Stack alignItems="center" justifyContent="center" spacing="8px">
                  <Typography variant="caption">Address</Typography>
                  <Typography
                    component="a"
                    href={`${rollup?.explorer}/address/${account?.address}`}
                    rel="noopener noreferrer"
                    sx={theme => ({
                      textDecoration: 'underline',
                      color: theme.colors.functional.text.link,
                      cursor: 'pointer',
                    })}
                    target="_blank"
                    variant="bodySmallM"
                  >
                    {truncateEthAddress(account?.address ?? zeroAddress)}
                  </Typography>
                </Stack>

                <Stack alignItems="end" justifyContent="center" spacing="8px">
                  <Typography variant="caption">Balance</Typography>
                  <Typography variant="bodySmallM">
                    {[
                      formatWithPrecision(Number(account?.balance ?? '0')),
                      getL1TokenSymbol(rollup),
                    ].join(' ')}
                  </Typography>
                </Stack>
              </Stack>

              <Stack id="edit-dialog-fields" spacing="24px">
                <Stack spacing={2}>
                  <FormNumericTextField
                    endAdornment={
                      <Typography sx={{ marginRight: 2 }}>{getL1TokenSymbol(rollup)}</Typography>
                    }
                    error={!!form.formState.errors.threshold}
                    fieldConfig={{
                      name: 'threshold',
                      placeholder: 'Threshold',
                    }}
                    helperText={form.formState.errors.threshold?.message}
                    label={<Label label="Threshold" />}
                  />
                </Stack>
                <Stack spacing={2}>
                  <FormTextField
                    error={!!form.formState.errors.notifications}
                    fieldConfig={{
                      name: 'notifications',
                      placeholder: 'Comma-separated list of notifiers',
                    }}
                    helperText={form.formState.errors.notifications?.message}
                    label={
                      <Label
                        label="Notifications"
                        labelEndAdornment={
                          <ConditionalTooltip
                            enabled
                            title="For the format of notification link, please refer to https://github.com/caronc/apprise/wiki"
                            wrapped
                          >
                            <IconInfo />
                          </ConditionalTooltip>
                        }
                      />
                    }
                  />
                </Stack>
              </Stack>
            </Stack>

            <LoadingButton
              disableElevation
              loading={updateAccountPending}
              sx={{
                minHeight: '68px',
              }}
              type="submit"
              variant="contained"
            >
              <Typography variant="bodySmall">Submit</Typography>
            </LoadingButton>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};
