import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { bff } from 'shared/api';
import {
  FeeVault,
  feeVaultFromJSON,
  WithdrawHistoryReply,
  WithdrawHistoryRequest,
  WithdrawLog as _WithdrawLog,
} from 'shared/types/protoc-gen/raas-rollup';
import { QUERY_KEYS } from 'shared/types/react-query';

interface WithdrawLog extends _WithdrawLog {
  vault: FeeVault;
}

interface IUseWFHistory
  extends Omit<UseQueryOptions<unknown, Error, WithdrawLog[]>, 'queryKey' | 'queryFn'> {
  opstackId: WithdrawHistoryRequest['opstackId'];
}

export const useWFHistory = (opts: IUseWFHistory) =>
  useQuery<unknown, Error, WithdrawLog[]>({
    queryKey: [QUERY_KEYS.RAAS_WITHDRAW_FEE_HISTORY, { opstackId: opts?.opstackId }],
    queryFn: async (): Promise<WithdrawLog[]> => {
      const vaults = Object.values(FeeVault).filter(v => +v >= 0);

      const resps = await Promise.all(
        vaults.map(v =>
          bff.get<WithdrawHistoryReply>('/bff/opstack/withdrawfee/history', {
            params: {
              opstackId: opts?.opstackId,
              vault: feeVaultFromJSON(v),
            },
          }),
        ),
      );

      return resps.flatMap((res, idx) =>
        res.data.withdrawLogs.map(v => <WithdrawLog>{ ...v, vault: feeVaultFromJSON(vaults[idx]) }),
      );
    },
    ...opts,
  });
