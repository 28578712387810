import { Box } from '@mui/material';
import { Section } from 'shared/components';

const FeeWithdrawalWorkflowSection: React.FC = () => {
  return (
    <Section title="Fee Withdrawal Workflow">
      <Box sx={{ p: '24px 40px' }}>
        <Box component="img" src="/raas/withdrawWorkflow.png" sx={{ width: '100%' }} />
      </Box>
    </Section>
  );
};

export default FeeWithdrawalWorkflowSection;
