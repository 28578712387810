import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { useIsProduction } from 'shared/hooks/ui';

export const ProductionEnvGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const isProd = useIsProduction();

  return !isProd ? <>{children}</> : <Navigate to={PATHS.DEFAULT} />;
};
