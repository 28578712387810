import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { TriggerWithdrawFeeReply, TriggerWithdrawFeeRequest } from 'shared/types/protoc-gen/raas-rollup';
import { MUTATION_KEYS } from 'shared/types/react-query';

interface IUseWFTrigger
  extends Omit<
    UseMutationOptions<TriggerWithdrawFeeReply, Error, TriggerWithdrawFeeRequest, unknown>,
    'mutationFn' | 'mutationKey'
  > {}

export const useWFTrigger = (opts?: IUseWFTrigger) => {
  return useMutation<TriggerWithdrawFeeReply, Error, TriggerWithdrawFeeRequest, unknown>({
    mutationKey: [MUTATION_KEYS.RAAS_WITHDRAW_FEE_TRIGGER],
    mutationFn: async (args: TriggerWithdrawFeeRequest): Promise<TriggerWithdrawFeeReply> => {
      const res = await bff.post<TriggerWithdrawFeeReply>('/bff/opstack/withdrawfee/trigger', args);

      return res.data;
    },
    ...opts,
  });
};
