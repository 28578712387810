import { useRaasDeployment } from 'pages/raas/hooks';

import { L2OverviewSection } from './L2OverviewSection';
import { L2Section } from './L2Section';
import { useCurrentBlockheight } from './useCurrentBlockheight';

export const L2View = () => {
  const { data: rollup } = useRaasDeployment();
  const { error: getBlockHeightsError } = useCurrentBlockheight(rollup);

  return (
    <>
      {!getBlockHeightsError && <L2OverviewSection />}
      <L2Section />
    </>
  );
};
