import { Stack, StackProps, styled } from '@mui/material';
import { ReactNode } from 'react';

export const SectionTitle = styled(
  ({ children, ...props }: { children?: ReactNode } & StackProps) => {
    return <Stack {...props}>{children}</Stack>;
  },
)(
  ({ theme }) => `
  background: ${theme.colors.gradients.metal};
  align-items: center;
  flex-direction: row;
  padding: ${theme.breakpoints.up('sm') ? theme.spacing(5) : theme.spacing(3)};
  font-size: 48px;
  line-height: 56px;
  border-bottom: 1px solid ${theme.colors.functional.subject.border};
  ${theme.breakpoints.down('md')} {
    font-size: 32px;
    line-height: 44px;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 36px;
  }
`,
);
