import {
  BoxProps,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { ResponsiveContainer } from 'recharts';
import { IconMore } from 'shared/components/icons/IconMore';

export interface IMetricContainer {
  title: ReactNode;
  showSpinner?: boolean;
  showMoreButton?: boolean;
  children: ReactElement;
  description?: string;
  responsiveContainerSx?: BoxProps['sx'];
  descriptionSx?: TypographyProps['sx'];
  /**
   * @deprecated Define `onClickMoreButton` to render the more icon button.
   */
  renderMoreButtonHeaderEnd?: boolean;
  onClickMoreButton?: MouseEventHandler<HTMLButtonElement> | undefined;
  renderMenu?: ReactNode;
}

export const MetricContainer = ({
  children,
  description,
  descriptionSx,
  onClickMoreButton,
  renderMenu,
  responsiveContainerSx,
  showMoreButton = true,
  showSpinner,
  title,
}: IMetricContainer) => {
  return (
    <Stack sx={{ height: '100%', width: '100%', py: '24px' }}>
      <Stack
        alignItems="start"
        direction="row"
        justifyContent="space-between"
        sx={{
          px: '40px',
          width: '100%',
        }}
      >
        <Typography
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}
          variant="bodySmallM"
        >
          {title}
          {showSpinner && <CircularProgress size={15} />}
        </Typography>

        {showMoreButton && onClickMoreButton && (
          <IconButton onClick={onClickMoreButton} size="small">
            <IconMore sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        )}
      </Stack>
      {renderMenu}

      <Typography
        sx={{
          px: '40px',
          mb: '18px',
          width: '100%',
          color: theme => theme.colors.functional.text.lint,
          ...descriptionSx,
        }}
        variant="caption"
      >
        {description}
      </Typography>

      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '275px',
          px: '12px',
          ...responsiveContainerSx,
        }}
      >
        <ResponsiveContainer>{children}</ResponsiveContainer>
      </Stack>
    </Stack>
  );
};
