import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, MenuItem, Stack, Typography } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import { FormSelectDateField, FormTextField, Label } from 'shared/components/form';
import { FormSelectField, FormSelectItem } from 'shared/components/form/fields/FormSelectField';
import { IconX } from 'shared/components/icons/IconX';
import { Address } from 'viem';
import * as yup from 'yup';

import BountyTokenSelect from './BountyTokenSelect';

enum Platform {
  TWITTER = 'Twitter/X',
}

enum RewardDuration {
  D30 = '30D',
  D60 = '60D',
  D90 = '90D',
}

interface PlatformItem extends FormSelectItem {
  label: string;
  icon: ReactNode;
}

interface RewardDurationItem extends FormSelectItem {}

export interface FormValues {
  bountyTokenAddr: Address;
  platform: Platform;
  campaignHashtag: string;
  rewardStartDate: string;
  rewardDuration: string;
}

const schema = yup.object().shape({
  bountyTokenAddr: yup.string().address().required('Bounty token is required'),
  platform: yup
    .mixed<Platform>()
    .oneOf(
      Object.values(Platform),
      `Platform must be one of the following values: ${Object.values(Platform).join(', ')}`,
    )
    .required('Platform is required'),
  campaignHashtag: yup
    .string()
    .test('is-valid-singular-hashtag', 'Must be a valid singular hashtag', function (value) {
      if (!value) return false;

      if (value?.split(' ')?.length > 1) {
        return this.createError({
          message: 'Must be a valid singular hashtag',
          path: 'campaignHashtag',
        });
      }

      const validHashtagRegex = /^#[A-Za-z0-9_]+$/;
      const nonSpecialCharRegex = /^[a-zA-Z0-9#]*$/;

      if (!nonSpecialCharRegex.test(value)) {
        return this.createError({
          message: 'Must not contain special characters',
          path: 'campaignHashtag',
        });
      }

      if (!nonSpecialCharRegex.test(value) && !validHashtagRegex.test(value)) {
        return this.createError({
          message: 'Must be a valid hashtag',
          path: 'campaignHashtag',
        });
      }

      return validHashtagRegex.test(value);
    })
    .required('Campaign hashtag is required'),
  rewardStartDate: yup
    .date()
    .min(new Date(), 'Reward start date cannot be in the past')
    .required('Reward start date is required'),
  rewardDuration: yup
    .mixed<RewardDuration>()
    .oneOf(
      Object.values(RewardDuration),
      `Reward duration must be one of the following values: ${Object.values(RewardDuration).join(
        ', ',
      )}`,
    )
    .required('Reward duration is required'),
});

export const CreateTaskDialog: FC<DialogProps & { onValid: SubmitHandler<FormValues> }> = ({
  onClose,
  onValid,
  sx,
  ...props
}) => {
  const { addAlert } = useAlerts();

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver<FormValues>(schema as unknown as yup.ObjectSchema<FormValues>),
  });

  const onInvalid: SubmitErrorHandler<FormValues> = useCallback(
    errors => {
      console.error('errors', errors);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Form validation failed',
        desc: 'Please check the form for errors',
      });
    },
    [addAlert],
  );

  const platforms = useMemo<PlatformItem[]>(
    () => [
      {
        value: Platform.TWITTER,
        label: Platform.TWITTER,
        icon: <IconX sx={{ height: 40, width: 40 }} />,
      },
    ], // TODO: replace with actual data when BE is ready
    [],
  );

  const rewardDurations = useMemo<RewardDurationItem[]>(
    () => [
      { label: RewardDuration.D30, value: RewardDuration.D30 },
      { label: RewardDuration.D60, value: RewardDuration.D60 },
      { label: RewardDuration.D90, value: RewardDuration.D90 },
    ], // TODO: replace with actual data when BE is ready
    [],
  );

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  return (
    <>
      <Dialog maxWidth="md" onClose={onClose} sx={{ zIndex: 98, ...sx }} {...props}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onValid, onInvalid)}>
            <DialogContainer sx={{ minWidth: '700px' }}>
              <DialogHeader onClose={handleClose} title="Create new task" />
              <Stack spacing={1} sx={{ maxHeight: '55vh', overflowY: 'auto', width: '100%' }}>
                <Label label="Bounty Token">
                  <BountyTokenSelect />
                </Label>
                <Label label="Platform">
                  <FormSelectField<PlatformItem>
                    fieldConfig={{
                      name: 'platform',
                      placeholder: 'Platform',
                    }}
                    items={platforms}
                    renderItems={items =>
                      items.map(item => (
                        <MenuItem key={item.label} sx={{ p: 3 }} value={item.value}>
                          <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%' }}
                          >
                            <Stack alignItems="center" direction="row" spacing={2}>
                              {item.icon}
                              <Stack spacing={1}>
                                <Typography variant="body1">{item.label}</Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </MenuItem>
                      ))
                    }
                  />
                </Label>
                <Label label="Campaign Hashtag">
                  <FormTextField
                    fieldConfig={{ name: 'campaignHashtag', placeholder: '#Autonome' }}
                    hideLabel
                  />
                </Label>
                <Stack direction="row" spacing={3}>
                  <Label label="Reward Start Date">
                    <FormSelectDateField
                      disablePast
                      fieldConfig={{ name: 'rewardStartDate', placeholder: '01/01/2024' }}
                    />
                  </Label>
                  <Label label="Reward Duration">
                    <FormSelectField<RewardDurationItem>
                      fieldConfig={{
                        name: 'rewardDuration',
                        placeholder: RewardDuration.D30,
                      }}
                      items={rewardDurations}
                      renderItems={items =>
                        items.map(item => (
                          <MenuItem key={item.label} sx={{ p: 3 }} value={item.label}>
                            <Typography variant="body1">{item.label}</Typography>
                          </MenuItem>
                        ))
                      }
                    />
                  </Label>
                </Stack>
              </Stack>

              <LoadingButton
                fullWidth
                loading={form.formState.isSubmitting}
                type="submit"
                variant="contained"
              >
                Add
              </LoadingButton>
            </DialogContainer>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
