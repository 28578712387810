import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useRaasDeployment } from 'pages/raas/hooks';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { BaseFeeVaultMetric } from './BaseFeeVaultMetric';
import { L1FeeVaultMetric } from './L1FeeVaultMetric';
import { SequencerFeeVaultMetric } from './SequencerFeeVaultMetric';

export const FeeVaultsSection = () => {
  const { isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <BaseFeeVaultMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <L1FeeVaultMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <SequencerFeeVaultMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          loading={isPending}
          variant={TimeFilterVariant.BALANCE_ALERT_FEE_VAULTS_SECTION}
        />
      }
      sectionContentSx={{ p: 0 }}
      title="Fee vaults"
    />
  );
};
