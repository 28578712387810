import 'swiper/css';

import { ArrowBackSharp, ArrowForwardSharp } from '@mui/icons-material';
import { Box, BoxProps, IconButton, Stack, Typography } from '@mui/material';
import { FC, ReactElement, useState } from 'react';
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

interface ISwiperCarousel extends Omit<BoxProps, 'children'> {
  slides: ReactElement<typeof SwiperSlide>[];
}

export const SwiperCarousel: FC<ISwiperCarousel> = ({ slides, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box {...props}>
      <Swiper
        edgeSwipeThreshold={50}
        keyboard
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        mousewheel={{
          enabled: false,
          forceToAxis: true,
          releaseOnEdges: true,
          sensitivity: 0.5,
          thresholdDelta: 10,
        }}
        navigation
        onActiveIndexChange={event => setActiveIndex(event.activeIndex)}
        pagination={{
          clickable: true,
          horizontalClass: 'swiper-pagination-horizontal',
        }}
        spaceBetween={24}
        speed={1_000}
      >
        {slides.map(slide => slide)}

        <SwiperNavigation activeIndex={activeIndex} />
      </Swiper>
    </Box>
  );
};

const SwiperNavigation: FC<{ activeIndex: number }> = ({ activeIndex }) => {
  const swiper = useSwiper();

  return (
    <Stack alignItems="center" direction="row" justifyContent="center" mt={5} spacing={1}>
      <IconButton disabled={activeIndex === 0} onClick={() => swiper.slidePrev()}>
        <ArrowBackSharp
          sx={{
            visibility: activeIndex === 0 ? 'hidden' : 'visible',
            color: theme => theme.colors.functional.text.primary,
            width: { xs: 20, md: 40 },
            height: { xs: 20, md: 40 },
          }}
        />
      </IconButton>
      <Typography variant="bodySmallC">
        {activeIndex + 1} / {swiper.slides.length}
      </Typography>
      <IconButton
        disabled={swiper.slides.length === activeIndex + 1}
        onClick={() => swiper.slideNext()}
      >
        <ArrowForwardSharp
          sx={{
            visibility: swiper.slides.length === activeIndex + 1 ? 'hidden' : 'visible',
            color: theme => theme.colors.functional.text.primary,
            width: { xs: 20, md: 40 },
            height: { xs: 20, md: 40 },
          }}
        />
      </IconButton>
    </Stack>
  );
};
