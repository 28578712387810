import { Outlet, RouteObject } from 'react-router-dom';
import { AdminGuard } from 'shared/guards';

import RaasAdmin from './RaasAdmin/RaasAdmin';
import { routes as adminRaasRoutes } from './RaasAdmin/routes';
import ManageAgentRequests from './ManageAgentRequests';
import ManageFinetuneSources from './ManageFinetuneSources';
import ManagePromoCodes from './ManagePromoCodes';

export enum ADMIN_PATHS {
  ADMIN_RAAS = '/admin/raas',
  MANAGE_PROMO_CODES = '/admin/promo-codes',
  MANAGE_AGENT_REQUESTS = '/admin/publish-agent-requests',
  MANAGE_FINETUNE_SOURCES = '/admin/manage-finetune-sources',
}

export const routes: RouteObject[] = [
  {
    path: '/admin',
    element: (
      <AdminGuard>
        <Outlet />
      </AdminGuard>
    ),
    children: [
      {
        path: 'raas',
        element: <RaasAdmin />,
        children: [...adminRaasRoutes],
      },
      {
        element: <ManagePromoCodes />,
        path: 'promo-codes',
      },
      {
        element: <ManageAgentRequests />,
        path: 'publish-agent-requests',
      },
      {
        element: <ManageFinetuneSources />,
        path: 'manage-finetune-sources',
      },
    ],
  },
];
