import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FC, useCallback, useMemo, useState } from 'react';
import CustomStepIcon from 'shared/components/CustomStepIcon';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';

export const DisperseContractDialog: FC<DialogProps> = ({ onClose, ...props }) => {
  const { addAlert } = useAlerts();
  const [activeStep, setActiveStep] = useState<number>(0);
  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const steps = useMemo(
    () => [
      {
        title: 'Create campaign disperse contract',
        description: (
          <Typography sx={{ textDecoration: 'underline' }}>Txn hash: 0x188...888</Typography>
        ),
        actionBar: (
          <LoadingButton
            onClick={() => {
              addAlert({
                severity: ALERT_SEVERITY.SUCCESS,
                title: 'Campaign disperse contract created',
                link: {
                  label: 'Txn hash',
                  url: 'https://etherscan.io/tx/0x188...888',
                },
              });
              setActiveStep(1);
            }}
            variant="contained"
          >
            Create
          </LoadingButton>
        ),
      },
      {
        title: 'Send ARB rewards to disperse contract',
        description: (
          <Typography sx={{ textDecoration: 'underline' }}>Txn hash: 0x188...888</Typography>
        ),
        actionBar: (
          <LoadingButton
            onClick={() => {
              addAlert({
                severity: ALERT_SEVERITY.SUCCESS,
                title: 'ARB rewards sent to disperse contract',
                link: {
                  label: 'Txn hash',
                  url: 'https://etherscan.io/tx/0x188...888',
                },
              });
              handleClose();
            }}
            variant="contained"
          >
            Send
          </LoadingButton>
        ),
      },
    ],
    [addAlert, handleClose],
  );

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogContainer>
        <DialogHeader onClose={handleClose} title="Create new task" />

        <Stack spacing={5}>
          <Stepper activeStep={activeStep} connector={null} orientation="vertical">
            {steps.map((step, index) => (
              <Step
                key={index}
                sx={
                  index !== steps?.length - 1
                    ? {
                        position: 'relative',
                        '& .MuiStepLabel-iconContainer:after': {
                          content: "' '",
                          height: 'calc(100% - 24px)',
                          borderRight: '1px solid black',
                          position: 'absolute',
                          left: '11px',
                          top: '32px',
                          zIndex: 0,
                        },
                      }
                    : {}
                }
              >
                <StepLabel StepIconComponent={CustomStepIcon} sx={{ alignItems: 'flex-start' }}>
                  <Typography mt={0.25}>{step.title}</Typography> {step.description}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {steps?.[activeStep]?.actionBar}
        </Stack>
      </DialogContainer>
    </Dialog>
  );
};
