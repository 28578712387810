import { useCallback, useState } from 'react';

import { useDisclosure } from './useDisclosure';

export const usePreviewDialogFns = () => {
  const {
    onClose: onClosePreviewDialog,
    onOpen: onOpenPreviewDialog,
    open: openedPreviewDialog,
  } = useDisclosure();
  const [title, setTitle] = useState<string>();
  const [jsonOrUrl, setJsonOrUrl] = useState<string>();

  const handlePreviewMetadata = useCallback(
    (title: string, jsonOrUrl: string) => {
      setJsonOrUrl(jsonOrUrl);
      setTitle(title);
      onOpenPreviewDialog();
    },
    [onOpenPreviewDialog],
  );

  const handleDownloadJSON = useCallback((fileName: string, url: string) => {
    const link = document.createElement('a');

    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
    link.remove();
  }, []);

  return {
    onClosePreviewDialog,
    openedPreviewDialog,
    title,
    jsonOrUrl,
    handlePreviewMetadata,
    handleDownloadJSON,
  };
};
