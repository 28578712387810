import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import { PATHS, routes } from 'routes';
import { isAutonomeOrigin } from 'shared/constants/env';

import { AuthContextProvider } from './contexts/AuthContext';
import Layout from './shared/components/Layout';
import { inputGlobalStyles } from './theme/globalStyle';

export { PATHS }; // TODO: migrate all that imports PATHS from App

const App: React.FC = () => {
  const content = useRoutes(routes);

  return (
    <>
      {isAutonomeOrigin && (
        <HelmetProvider>
          <Helmet>
            <title>{isAutonomeOrigin ? 'Autonome' : 'AltLayer Wizard'}</title>
            <link href="/imgs/autonome.svg" rel="icon" />
          </Helmet>
        </HelmetProvider>
      )}
      <CssBaseline />
      {inputGlobalStyles}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
        <AuthContextProvider>
          <Layout>{content}</Layout>
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
