import { Outlet, RouteObject } from 'react-router-dom';
import { RequireLoginGuard } from 'shared/guards';
import { ExperimentalFeaturesGuard } from 'shared/guards/ExperimentalFeaturesGuard';

import { routes as detailsRoutes } from './AaasDetails/routes';
import { routes as agentCreditsRoutes } from './AgentCredits/routes';
import { AaasDeployments } from './AaasDeployments';
import { AaasDetails } from './AaasDetails';
import { AgentCredits } from './AgentCredits';
import { AaasBounties } from './Bounties';
import { NewAgentDeployment } from './NewAgentDeployment';
import { PublishCommunityAgent } from './PublishCommunityAgent';

export enum AAAS_PATHS {
  AAAS_DEPLOYMENTS = '/autonome',
  AAAS_BOUNTIES = '/autonome/bounties',
  AAAS_DETAILS = '/autonome/:appId/details',
  AAAS_DETAILS_CHAT = '/autonome/:appId/chat',
  AAAS_DETAILS_EDIT_METADATA = '/autonome/:appId/edit/metadata',
  AAAS_NEW_DEPLOYMENT = '/autonome/new',
  AAAS_PUBLISH_AGENT = '/autonome/publish',
  AAAS_AGENT_CREDITS_PURCHASE = '/autonome/credits/purchase',
  AAAS_AGENT_CREDITS_FULFILLMENT = '/autonome/credits/fulfillment',
}

export const routes: RouteObject[] = [
  {
    path: AAAS_PATHS.AAAS_DEPLOYMENTS,
    element: (
      <RequireLoginGuard>
        <Outlet />
      </RequireLoginGuard>
    ),
    children: [
      {
        path: '',
        element: <AaasDeployments />,
      },
      {
        path: 'bounties',
        element: (
          <ExperimentalFeaturesGuard>
            <AaasBounties />
          </ExperimentalFeaturesGuard>
        ),
      },
      {
        path: ':appId',
        element: <AaasDetails />,
        children: detailsRoutes,
      },
      {
        path: 'new',
        element: <NewAgentDeployment />,
      },
      {
        path: 'publish',
        element: <PublishCommunityAgent />,
      },
      {
        path: 'credits',
        element: <AgentCredits />,
        children: agentCreditsRoutes,
      },
    ],
  },
];
