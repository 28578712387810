import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';
import { useApp } from 'pages/aaas/AaasDetails/hooks/useApp';
import { getBasicAuthHeaders } from 'pages/aaas/AaasDetails/utils';

import { useAgentId } from './useAgentId';

export interface IUseFeedData
  extends Omit<
    UseMutationOptions<{ status: string }, Error, { message: string }, unknown>,
    'mutationFn' | 'mutationKey'
  > {}

export const useFeedData = (opts?: IUseFeedData) => {
  const { data: app } = useApp();
  const { data: agentId } = useAgentId();

  return useMutation({
    mutationKey: ['FEED_AGENT_DATA', { appId: app?.id }],
    mutationFn: async (args: { message: string }) => {
      const splitByNewlineOrPeriod = args?.message?.split(/[\n.]+/);
      const randId = String(Math.floor(Math.random() * 100000000));
      const timestamp = Date.now();
      const res = await axios.post<{ status: string }>(
        `${app?.endpoints?.apiUrl}/data/${agentId}`,
        splitByNewlineOrPeriod
          ?.filter(msg => !!msg?.trim())
          ?.map((msg, index) => ({
            id: randId + String(index),
            text: msg,
            timestamp,
          })),
        {
          headers: getBasicAuthHeaders(app),
        },
      );

      return res.data;
    },
    ...opts,
  });
};
