import { useRaasDeployment } from 'pages/raas/hooks';
import { useMemo } from 'react';

import { AssetsSection } from './AssetsSection';
import { DaDetailsSection } from './DaDetailsSection';
import { GeneralSection } from './GeneralSection';
import { MetadataSection } from './MetadataSection';
import { VersionSection } from './VersionSection';

export const RollupInfoView = () => {
  const { data: rollup, isPending } = useRaasDeployment();

  const displayMetadataSection = useMemo(
    () => !!rollup?.contractAddresses || Object.keys(rollup?.metadata ?? {}).length > 0,
    [rollup],
  );

  const displayVersionSection = useMemo(
    () => Object.keys(rollup?.version ?? {}).length > 0,
    [rollup],
  );

  const displayDaDetailsSection = useMemo(
    () => Object.keys(rollup?.dataAvailabilityMetadata ?? {}).length > 0,
    [rollup],
  );

  return (
    <>
      <GeneralSection loading={isPending} rollup={rollup} />
      <AssetsSection loading={isPending} rollup={rollup} />
      {displayMetadataSection && <MetadataSection loading={isPending} rollup={rollup} />}
      {displayVersionSection && <VersionSection loading={isPending} rollup={rollup} />}
      {displayDaDetailsSection && <DaDetailsSection loading={isPending} rollup={rollup} />}
    </>
  );
};
