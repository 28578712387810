import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useApp } from 'pages/aaas/AaasDetails/hooks/useApp';
import { getBasicAuthHeaders } from 'pages/aaas/AaasDetails/utils';
import { QUERY_KEYS } from 'shared/types/react-query';

export const useAgentId = () => {
  const { data: app } = useApp();

  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_APP_AGENTS, app?.id],
    queryFn: async (): Promise<string> => {
      const res = await axios.get<{ agents: { id: string; name: string }[] }>(
        `${app?.endpoints?.apiUrl}/agents`,
        {
          headers: getBasicAuthHeaders(app),
        },
      );

      return res.data?.agents?.[0]?.id;
    },
    enabled: !!app?.id && app?.template?.id?.toLowerCase() === 'eliza', // /agents endpoint is only supported by Eliza
    retry: 24,
    retryDelay: 15_000,
  });
};
