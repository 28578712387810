import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconCheckedCircle({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ width: '80px', height: '80px', ...sx }}
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="40" cy="40" fill="none" r="39.3" stroke="#202723" strokeWidth="1.4" />
      <path
        d="M19.2939 41.4119L33.6645 55.7824L62.2287 27.2182"
        fill="none"
        stroke="#202723"
        strokeWidth="1.4"
      />
    </SvgIcon>
  );
}
