import { Editor, EditorProps } from '@monaco-editor/react';
import { FormHelperText, Stack, StackProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Controller,
  DeepRequired,
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  get,
  Merge,
  Path,
  useFormContext,
} from 'react-hook-form';

interface TFormEditor<TFormValues extends FieldValues> {
  fieldPath: Path<TFormValues>;
  editorProps?: Partial<EditorProps>;
}

export function FormJsonEditor<TFormValues extends FieldValues>({
  editorProps,
  fieldPath,
  ...props
}: TFormEditor<TFormValues> & StackProps) {
  const [code, setCode] = useState<string>();

  const form = useFormContext<TFormValues>();
  const config = form.watch<Path<TFormValues>>(fieldPath);

  useEffect(
    () => {
      if (config) {
        try {
          setCode(JSON.stringify(config, null, 2));
        } catch (e) {
          setCode(prev => prev);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const fieldErrors:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<DeepRequired<TFormValues>[string]>>
    | undefined = get<FieldErrors<TFormValues>>(form.formState.errors, fieldPath);
  const hasErrors = !!fieldErrors;

  return (
    <Controller
      control={form.control}
      name={fieldPath}
      render={({ field }) => {
        return (
          <Stack width="100%" {...props}>
            <Editor
              {...field}
              defaultLanguage="json"
              height="80vh"
              onChange={value => {
                setCode(value);

                if (value) {
                  try {
                    form.setValue(fieldPath, JSON.parse(value), {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  } catch (err: any) {
                    console.warn(`err:`, err);
                    form.setError(fieldPath, { message: err?.message });
                  }
                }
              }}
              options={{
                scrollBeyondLastLine: false,
                showFoldingControls: 'always',
              }}
              theme="vs-dark"
              value={hasErrors ? code : JSON.stringify(field.value, undefined, 2)}
              {...editorProps}
            />
            {hasErrors && (
              <FormHelperText sx={{ color: theme => theme.colors.schema.error, pl: 1 }}>
                {fieldErrors?.message ? (
                  <Typography variant="caption">
                    {get<FieldErrors<TFormValues>>(form.formState.errors, fieldPath)?.message}
                  </Typography>
                ) : Object.keys(fieldErrors ?? {}).length === 1 ? (
                  Object.entries(fieldErrors ?? {}).map(([key, value], index) => (
                    <Typography key={['config', key, index].join('-')} variant="caption">
                      {value?.message}
                    </Typography>
                  ))
                ) : (
                  <ul>
                    {Object.entries(fieldErrors ?? {}).map(([key, value], index) => (
                      <li key={[key, index].join('-')}>
                        <Typography variant="caption">{value?.message}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </FormHelperText>
            )}
          </Stack>
        );
      }}
    />
  );
}
