import { Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { IconCircleHealthStatus } from 'shared/components/icons/IconCircleHealthStatus';

export interface IStatusLabel extends StackProps {
  loading?: boolean;
}

export const StatusLabel = ({ children, loading, sx, ...props }: IStatusLabel) =>
  loading ? (
    <Skeleton
      sx={{
        py: '3px',
        px: '8px',
        alignItems: 'center',
        width: '15%',
      }}
      variant="rectangular"
    />
  ) : (
    <Stack
      direction="row"
      spacing={'8px'}
      sx={{
        py: '3px',
        px: '8px',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
    >
      {loading ? (
        <Skeleton height="12px" variant="circular" width="12px" />
      ) : (
        <IconCircleHealthStatus />
      )}

      {loading ? (
        <Skeleton variant="text" width="15%" />
      ) : (
        <Typography variant="captionC">{children}</Typography>
      )}
    </Stack>
  );
