import { Button, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import IconCheckedNoBorder from 'shared/components/icons/IconCheckedNoBorder';
import IconEllipsis from 'shared/components/icons/IconEllipsis';
import { IconUsdc } from 'shared/components/icons/IconUsdc';
import { IconX } from 'shared/components/icons/IconX';

interface IBountyCard {
  hasJoined?: boolean;
  isTaskView?: boolean;
  onClickActionButton?: () => void;
}

export const BountyCard: React.FC<IBountyCard> = ({
  hasJoined,
  isTaskView,
  onClickActionButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      spacing={1}
      sx={{
        border: theme => `1px solid ${theme.colors.functional.subject.border}`,
        minHeight: '176px',
        background: '#fff',
        '&:hover': {
          background: '#fafafa',
        },
        p: 3,
      }}
    >
      <Stack alignItems="start" direction="row" justifyContent="space-between">
        <Stack spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              '& > .MuiStack-root': {
                background: theme => theme.colors.gradients.cream,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                px: 1,
                py: 0.5,
              },
            }}
          >
            <Stack direction="row">
              <IconUsdc sx={{ height: '16px', width: '16px' }} />
              <Typography variant="captionC">1,000 USDC</Typography>
            </Stack>
            <Stack>
              <IconX sx={{ height: '16px', width: '16px' }} />
            </Stack>
          </Stack>
          <Typography variant="h5">#Autonome campaign</Typography>
        </Stack>

        {isTaskView ? (
          <IconButton onClick={handleOpenMenu} sx={{ cursor: 'pointer' }}>
            <IconEllipsis />
          </IconButton>
        ) : hasJoined ? (
          <Stack direction="row">
            <IconCheckedNoBorder />
            <Typography sx={{ fontSize: '13px', lineHeight: '18px' }}>Joined</Typography>
          </Stack>
        ) : (
          <Button onClick={onClickActionButton} size="small" sx={{ maxWidth: '113px' }}>
            <Typography>Join</Typography>
          </Button>
        )}

        <Menu
          MenuListProps={{
            'aria-labelledby': 'ellipsis-button',
          }}
          anchorEl={anchorEl}
          id="ellipsis-menu"
          onClose={handleCloseMenu}
          open={open}
        >
          <MenuItem>Manage</MenuItem>
        </Menu>
      </Stack>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '3',
          WebkitBoxOrient: 'vertical',
        }}
        textAlign="justify"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati nihil nulla, unde aperiam
        eaque ipsam provident ab temporibus fugiat laborum velit suscipit possimus totam distinctio
        quod a voluptate explicabo eum?
      </Typography>
    </Stack>
  );
};
