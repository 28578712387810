import { create } from 'zustand';

export enum BannerVariant {
  AAA_WARNING = 'AAA_WARNING',
}

interface State {
  banners: {
    [key in BannerVariant]: boolean;
  };
}

interface Action {
  setBanner: (variant: BannerVariant, value: boolean) => void;
}

const init: State = {
  banners: {
    [BannerVariant.AAA_WARNING]: false,
  },
};

export const useBannerStore = create<State & Action>(set => ({
  setBanner: (variant, value) =>
    set(state => ({ banners: { ...state.banners, [variant]: value } })),
  ...init,
}));
