import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const ErrorCaption: FC<TypographyProps & { error?: boolean; message?: string }> = ({
  error,
  message,
  ...props
}) => {
  return (
    <>
      {error && (
        <Typography
          sx={{
            mx: 2,
            color: theme => theme.colors.schema.error,
          }}
          variant="caption"
          {...props}
        >
          {message}
        </Typography>
      )}
    </>
  );
};
