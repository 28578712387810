import { Grid } from '@mui/material';
import { useCurrentBlockheight } from 'pages/raas/DeploymentDetails/views/L2View/useCurrentBlockheight';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { MetricWidget } from 'shared/components/metric/MetricWidget';
import { Section } from 'shared/components/Section';

export const L2OverviewSection = () => {
  const { rollupId } = useParams();
  const { data: rollup } = useRaasDeployment({ rollupId });
  const { data: blockHeights, isPending: getBlockHeightsPending } = useCurrentBlockheight(rollup);

  return (
    <Section
      renderContent={
        <Grid container>
          {!blockHeights || getBlockHeightsPending
            ? Array.from({ length: 3 }).map((_, idx) => (
                <MetricWidget key={idx.toString()} loading tooltip="..." />
              ))
            : blockHeights?.map(({ blockHeight, type }) => {
                return (
                  type && (
                    <MetricWidget
                      key={type}
                      label={`latest ${type} block`}
                      value={blockHeight.toLocaleString()}
                    />
                  )
                );
              })}
        </Grid>
      }
      title="Overview"
    />
  );
};
