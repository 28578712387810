import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { ListSubscriptionReply } from 'shared/types/protoc-gen/bffsubscription';
import { QUERY_KEYS } from 'shared/types/react-query';

interface IUseListCustomerSubs
  extends Omit<
    UseQueryOptions<ListSubscriptionReply & { totalCredits: number }>,
    'queryFn' | 'queryKey'
  > {}

export const useListCustomerSubs = (opts?: IUseListCustomerSubs) =>
  useQuery({
    queryKey: [QUERY_KEYS.BFF_USER_SUBSCRIPTIONS],
    queryFn: async () => {
      const res = await bff.get<ListSubscriptionReply>('/bff/subscriptions');

      return {
        ...res.data,
        totalCredits: Number(res.data.availableCredits ?? 0) + Number(res.data.usedCredit ?? 0),
      };
    },
    ...opts,
  });
