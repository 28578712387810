import { AAAApp } from 'shared/types/protoc-gen/bffaaa';

export const getBasicAuthHeaders = (app: AAAApp | undefined) => {
  const basicAuth = `${app?.auth?.basicAuthUsername}:${app?.auth?.basicAuthPassword}`;
  const encodedAuth = btoa(basicAuth);

  return {
    Authorization: `Basic ${encodedAuth}`,
  };
};
