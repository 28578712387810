import { Dialog, DialogProps, Stack, Typography } from '@mui/material';
import { useListApps } from 'pages/aaas/hooks';
import { templateNamesToIconsMap } from 'pages/aaas/mappings';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import ImgWithFallback from 'shared/components/ImgWithFallback';
import {
  AAAApp,
  AAATemplateCategory,
  aAATemplateCategoryToJSON,
} from 'shared/types/protoc-gen/bffaaa';

interface IAgentOptInDialog extends DialogProps {
  onClose: () => void;
  onSelectAgent: (agent: AAAApp) => void;
}

export const AgentOptInDialog: React.FC<IAgentOptInDialog> = ({ onClose, onSelectAgent, open }) => {
  const { data } = useListApps();

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <DialogContainer sx={{ minWidth: '750px' }}>
        <DialogHeader onClose={onClose} title="Choose agent to Opt in" />
        <Stack spacing={1}>
          {data?.apps?.map(app => (
            <Stack
              direction="row"
              gap={2}
              key={app.id}
              onClick={() => onSelectAgent(app)}
              sx={{
                p: 1,
                border: theme => `1px solid ${theme.colors.functional.subject.border}`,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#fafafa',
                },
              }}
            >
              {app?.template?.id && (
                <ImgWithFallback
                  src={
                    String(app.template?.category) ===
                    aAATemplateCategoryToJSON(AAATemplateCategory.AAA_TEMPLATE_TYPE_BUILTIN)
                      ? templateNamesToIconsMap[app?.template?.id]
                      : app?.template?.iconLink
                  }
                  sx={{ height: '48px', width: '48px' }}
                />
              )}
              <Stack>
                <Typography variant="body2">{app.name}</Typography>

                <Typography variant="caption">{app?.template?.name}</Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </DialogContainer>
    </Dialog>
  );
};
