import { BoxProps, Grid } from '@mui/material';
import { SwiperCarousel } from 'pages/aaas/components/SwiperCarousel';
import { FC, useMemo } from 'react';
import { partitionArray } from 'shared/utils/array';
import { SwiperSlide } from 'swiper/react';

import { BountyCard } from './BountyCard';

const unpartitionedBounties = Array.from(Array(13));

export const UserTaskBoardTab: FC<Omit<BoxProps, 'children'>> = props => {
  const partitioned = useMemo(() => partitionArray(unpartitionedBounties, 6), []);

  return (
    <SwiperCarousel
      py={3}
      slides={partitioned.map((bounties, pIdx) => {
        return (
          <SwiperSlide key={pIdx.toString()}>
            <Grid container spacing={{ md: 3, xs: 2 }}>
              {bounties.map((_, index) => (
                <Grid item key={index} md={4} sm={6} xs={6}>
                  <BountyCard isTaskView />
                </Grid>
              ))}
            </Grid>
          </SwiperSlide>
        );
      })}
      {...props}
    />
  );
};
