import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import { memo, useMemo, useState } from 'react';
import { FaSortUp } from 'react-icons/fa';
import { FaSort, FaSortDown } from 'react-icons/fa6';
import ReactTimeAgo from 'react-time-ago';
import { axiosRaas, bff } from 'shared/api';
import CopyableText from 'shared/components/Copyable/CopyableText';
import TRow from 'shared/components/Table/TRow';
import { QUERY_KEYS } from 'shared/types/react-query';
import { ColumnConfig } from 'shared/types/table';

import { PROMO_TYPE, PromoCode } from '.';

type PromoCodeWithType = PromoCode & { type: PROMO_TYPE };

export default memo(function PromoCodeList() {
  // TODO: uncomment this when backend pagination support is added

  // const [page, setPage] = useState<number>(0);
  // const [limit, setLimit] = useState<number>(10);
  // const { data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
  //   queryKey: [QUERY_KEYS.GET_PROMO_CODES, limit],
  //   queryFn: () => {
  //     return new Promise(resolve => {
  //       setTimeout(() => {
  //         resolve({ codes: ['123', '456'] });
  //       }, 1000);
  //     });
  //   },
  //   initialPageParam: 1,
  //   placeholderData: keepPreviousData,
  //   getNextPageParam: (lastPage, allPages: any[]) => {
  //     return allPages[page]?.nextPageToken;
  //   },
  // });

  const { isPending, promoCodes } = useQueries({
    queries: [
      {
        queryKey: [QUERY_KEYS.GET_AVS_PROMO_CODES],
        queryFn: async () => {
          const res = await axiosRaas.get<{ codes: PromoCode[] }>('/avs/promos');

          return res?.data?.codes?.map(cur => ({ ...cur, type: PROMO_TYPE.AVS }));
        },
      },
      {
        queryKey: [QUERY_KEYS.GET_BFF_PROMO_CODES],
        queryFn: async () => {
          const res = await bff.get<{ codes: PromoCode[] }>('/bff/promo');

          return res?.data?.codes?.map(cur => ({ ...cur, type: PROMO_TYPE.RAAS_AAA }));
        },
      },
    ],
    combine: results => {
      return {
        promoCodes: results
          .reduce((acc, result) => {
            return [...acc, ...(result?.data?.map(cur => ({ ...cur })) || [])];
          }, [] as PromoCodeWithType[])
          ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
        isPending: results.some(result => result.isPending),
      };
    },
  });

  // TODO: Uncomment this when backend pagination support is added
  // const handlePageChange = (event: any, newPage: number): void => {
  //   if (newPage === page + 1 && !data?.pages?.[newPage]) {
  //     console.debug('fetching next page');
  //     fetchNextPage();
  //   }

  //   setPage(newPage);
  // };

  // const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   setLimit(parseInt(event.target.value));
  // };

  const columnConfig: ColumnConfig<PromoCodeWithType>[] = useMemo(
    () => [
      {
        id: 'code',
        Header: 'Code',
        Cell: row => (
          <Stack direction="row">
            <CopyableText anchorGridProps={{ sx: { width: 'unset', ml: 0.5 } }} text={row?.code} />
          </Stack>
        ),
      },
      {
        id: 'type',
        Header: 'Type',
        Cell: row => (
          <Stack direction="row">
            <Typography variant="captionC">{row?.type?.replace('PROMO_TYPE_', '')}</Typography>
          </Stack>
        ),
        sortable: true,
      },
      // TODO: uncomment this when the backend is ready
      // {
      //   id: 'used',
      //   Header: 'Used',
      //   Cell: row => <>{row?.email && row?.flashlayerId ? <CheckCircleRoundedIcon /> : ''}</>,
      //   align: 'right',
      // },
      {
        id: 'email',
        Header: 'Email',
        Cell: row => <CopyableText text={row?.email} />,
        sortable: true,
      },
      // TODO: uncomment this when the backend is ready
      // {
      //   id: 'id',
      //   Header: 'ID',
      //   Cell: row => <CopyableText text={row?.flashlayerId === '0' ? '' : row?.flashlayerId} />,
      // },
      {
        id: 'createdAt',
        Header: 'Created At (UTC)',
        Cell: row => (
          <Tooltip placement="right-end" title={row?.createdAt}>
            <Box component="span">
              <ReactTimeAgo date={new Date(row?.createdAt)} locale="en-SG" />
            </Box>
          </Tooltip>
        ),
        sortable: true,
      },
      {
        id: 'expireAt',
        Header: 'Expiry (UTC)',
        Cell: row => (
          <Tooltip placement="right-end" title={row?.expireAt}>
            <Box component="span">
              <ReactTimeAgo date={new Date(row?.expireAt)} locale="en-SG" />
            </Box>
          </Tooltip>
        ),
        sortable: true,
      },
      // TODO: uncomment this when the backend is ready
      // {
      //   id: 'actions',
      //   Header: ' ',
      //   Cell: row => (
      //     <Button
      //       onClick={() => onClickEdit(row)}
      //       sx={{ width: '4rem', minWidth: 'unset' }}
      //       variant="outlined"
      //     >
      //       Edit
      //     </Button>
      //   ),
      //   align: 'right',
      // },
    ],
    [],
  );

  const [{ sortCol, sortOrder }, setSortBy] = useState<{
    sortCol: keyof PromoCodeWithType;
    sortOrder: 'asc' | 'desc';
  }>({ sortCol: 'createdAt', sortOrder: 'desc' });

  const sortedPromoCodes = useMemo(() => {
    return promoCodes?.sort((a, b) => {
      const aValue = a?.[sortCol];
      const bValue = b?.[sortCol];

      return sortOrder === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
    });
  }, [promoCodes, sortCol, sortOrder]);

  return (
    <Grid alignItems="center" container justifyContent="center" spacing={1}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columnConfig.map(col => (
                <TableCell align={col.align} key={col.id} sx={{ width: col.width }}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    onClick={() => {
                      if (col?.sortable) {
                        setSortBy(() => {
                          if (sortCol === col.id) {
                            return { sortCol, sortOrder: sortOrder === 'asc' ? 'desc' : 'asc' };
                          }

                          return { sortCol: col.id, sortOrder: 'desc' };
                        });
                      }
                    }}
                    sx={{ cursor: col?.sortable ? 'pointer' : 'auto' }}
                  >
                    {col.Header}
                    {col?.sortable &&
                      (sortCol !== col.id ? (
                        <FaSort style={{ color: '#999' }} />
                      ) : sortOrder === 'asc' ? (
                        <FaSortUp style={{ transform: 'translateY(25%)' }} />
                      ) : (
                        <FaSortDown style={{ transform: 'translateY(-25%)' }} />
                      ))}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPromoCodes?.map(code => (
              <TRow<PromoCodeWithType>
                columnConfig={columnConfig}
                key={code?.code}
                rowData={code}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPending && (
        <Grid alignItems="center" container justifyContent="center" sx={{ height: 120 }}>
          <CircularProgress />
        </Grid>
      )}
      {!isPending && !promoCodes?.length && (
        <Stack alignItems="center" justifyContent="center" width="100%">
          No promo codes to display
        </Stack>
      )}

      {/* TODO: Uncomment when backend pagination support is added */}
      {/* {!hidePagination && (
        <Box p={2}>
          <TablePagination
            component="div"
            count={Number(data?.pages?.[0]?.totalSize)}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </Box>
      )} */}
    </Grid>
  );
});
