import { RaasMetricsData, RaasMetricsResult } from 'pages/raas/hooks';
import React, { useMemo } from 'react';
import { Metric } from 'shared/components/metric';
import { minuteFormatter } from 'shared/utils/recharts';

import { useRpmByMethods } from './hooks';

interface IRpmByMethodsMetric {
  method?: string;
}

export const RpmByMethodsMetric: React.FC<IRpmByMethodsMetric> = ({ method }) => {
  // TODO: Update to do new integration for per Method
  const { data, isPending, isRefetching, refetch } = useRpmByMethods();

  const graphData = useMemo(() => {
    if (!method) {
      return data;
    }

    return transformDataToSingleMethod(data, method);
  }, [data, method]);

  return (
    <Metric
      chartType="area"
      data={graphData}
      description={'Requests per minute for each method'}
      hideLegend
      id="requests-by-methods"
      isRefetching={isRefetching}
      loading={isPending}
      moreMenuProps={{
        onClickRefetch: () => refetch(),
      }}
      responsiveContainerSx={{ minHeight: '250px' }}
      title="Requests by methods"
      xAxisProps={{
        tickFormatter: minuteFormatter,
      }}
    />
  );
};

const transformDataToSingleMethod = (
  data: RaasMetricsResult<RaasMetricsData> | undefined,
  method: string,
) => {
  if (!data) return;

  const filteredDataKeys = [
    {
      name: method,
      fill: 'water',
      color: '#5C8CE1',
    },
  ];

  const transformedData = data.data.map(item => {
    const transformedItem: { date: string; [key: string]: any } = { date: item.date };

    if (method in item) {
      transformedItem[method] = item[method];
    }

    return transformedItem;
  });

  return {
    data: transformedData,
    dataKeys: filteredDataKeys,
  };
};
