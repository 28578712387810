import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, Stack, Typography } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import { FormTextField } from 'shared/components/form';
import * as yup from 'yup';

import { useTweet } from './useTweet';

interface FormValues {
  prompt: string;
}

const schema = yup.object().shape({
  prompt: yup.string().required(),
});

export const PromptTweetDialog: FC<DialogProps> = ({ onClose, ...props }) => {
  const { addAlert } = useAlerts();

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
  });

  const prompt = form.watch('prompt');

  const { isPromptTweeting, promptTweet } = useTweet();

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const onInvalid: SubmitErrorHandler<FormValues> = useCallback(
    errors => {
      console.error('errors', errors);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Invalid form',
        desc: 'Please fill out the required fields',
      });
    },
    [addAlert],
  );

  const onSubmit: SubmitHandler<FormValues> = data => {
    promptTweet(data?.prompt);
  };

  return (
    <Dialog onClose={onClose} {...props}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onInvalid)}>
          <DialogContainer>
            <DialogHeader alignItems="start" onClose={handleClose} title="Prompt Agent Tweet" />
            <Stack spacing={3}>
              <FormTextField
                fieldConfig={{ name: 'prompt' }}
                label="What should your agent tweet about?"
                minRows={5}
                multiline
                placeholder="When will ALT flip ETH?"
              />

              <LoadingButton
                disabled={!prompt}
                fullWidth
                loading={isPromptTweeting}
                type="submit"
                variant="contained"
              >
                <Typography variant="body1">Submit</Typography>
              </LoadingButton>
            </Stack>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};
