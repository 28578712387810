import { Button, IconButton, Stack, StackProps, Typography } from '@mui/material';
import usePagination, { UsePaginationProps } from '@mui/material/usePagination';
import { IconChevronLeft } from 'shared/components/icons/IconChevronLeft';
import { IconChevronRight } from 'shared/components/icons/IconChevronRight';

export const Pagination = ({
  paginationOptions,
  ...props
}: Omit<StackProps, 'children'> & { paginationOptions?: UsePaginationProps }) => {
  const { items } = usePagination({
    count: 10,
    ...paginationOptions,
  });

  return (
    <Stack alignItems="center" direction="row" justifyContent="start" {...props}>
      {items.map(({ page, selected, type, ...item }, index) => {
        let children = null;

        switch (type) {
          case 'start-ellipsis':
          case 'end-ellipsis':
            children = '…';
            break;
          case 'page':
            children = (
              <Button
                size="small"
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                }}
                type="button"
                {...item}
                sx={theme => ({
                  color: selected ? '#fff' : theme.colors.functional.text.primary,
                  background: selected ? theme.colors.functional.container.dark : 'none',
                  minWidth: '19px',
                  minHeight: '20px',
                  '&:hover': {
                    color: selected ? '#fff' : theme.colors.functional.text.primary,
                    background: selected ? theme.colors.functional.container.dark : '#e7e7e7',
                  },
                })}
              >
                <Typography variant="captionC">{page}</Typography>
              </Button>
            );
            break;
          case 'previous':
            children = (
              <IconButton
                size="small"
                type="button"
                {...item}
                sx={theme => ({
                  ml: '4px',
                  '&:hover': {
                    color: selected ? '#fff' : theme.colors.functional.text.primary,
                    background: selected ? theme.colors.functional.container.dark : '#e7e7e7',
                  },
                })}
              >
                <IconChevronLeft
                  sx={{
                    color: 'transparent',
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>
            );
            break;
          case 'next':
            children = (
              <IconButton
                size="small"
                type="button"
                {...item}
                sx={theme => ({
                  ml: '4px',
                  '&:hover': {
                    color: selected ? '#fff' : theme.colors.functional.text.primary,
                    background: selected ? theme.colors.functional.container.dark : '#e7e7e7',
                  },
                })}
              >
                <IconChevronRight
                  sx={{
                    color: 'transparent',
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>
            );
            break;
          default:
            children = (
              <Button size="small" type="button" {...item}>
                <Typography variant="captionC">{type}</Typography>
              </Button>
            );
            break;
        }

        return <div key={index}>{children}</div>;
      })}
    </Stack>
  );
};
