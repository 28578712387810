import { Box, BoxProps, Grid, Typography } from '@mui/material';
import { avsTypeLabels } from 'pages/avs/helpers/labels';
import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import SelectableCard from 'shared/components/Card/SelectableCard';
import { Option } from 'shared/components/Option';
import { AVS_QUORUMS, AVS_TYPES } from 'shared/types/avs';

import { AVSFormValues } from '..';

interface AvsTypeOption {
  icon: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  value: AVS_TYPES;
  disabled?: boolean;
  sig: 'bls' | 'ecdsa';
}

export const avsTypeOptions: AvsTypeOption[] = [
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/altLayer.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">{avsTypeLabels[AVS_TYPES.MACH]}</Typography>,
    value: AVS_TYPES.MACH,
    sig: 'bls',
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/altLayer.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">{avsTypeLabels[AVS_TYPES.BRIDGE]}</Typography>,
    value: AVS_TYPES.BRIDGE,
    sig: 'bls',
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/altLayer.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">{avsTypeLabels[AVS_TYPES.COPROCESSOR]}</Typography>,
    value: AVS_TYPES.COPROCESSOR,
    sig: 'bls',
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/altLayer.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">{avsTypeLabels[AVS_TYPES.GENERIC]}</Typography>,
    value: AVS_TYPES.GENERIC,
    sig: 'bls',
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/altLayer.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">{avsTypeLabels[AVS_TYPES.CUSTOM_BLS]}</Typography>,
    value: AVS_TYPES.CUSTOM_BLS,
    sig: 'bls',
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/altLayer.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: (
      <Typography variant="bodySmall">{avsTypeLabels[AVS_TYPES.ECDSA_HELLO_WORLD]}</Typography>
    ),
    value: AVS_TYPES.ECDSA_HELLO_WORLD,
    sig: 'ecdsa',
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/altLayer.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">{avsTypeLabels[AVS_TYPES.CUSTOM_ECDSA]}</Typography>,
    value: AVS_TYPES.CUSTOM_ECDSA,
    sig: 'ecdsa',
  },
];

export default function SelectAVSType(props: BoxProps) {
  const form = useFormContext<AVSFormValues>();
  const avsType = useWatch<AVSFormValues, 'avsType'>({ control: form.control, name: 'avsType' });

  const handleSelectAvsType = (opt: AvsTypeOption) => () => {
    if (opt?.value === avsType) {
      return;
    }

    form.setValue('avsType', opt.value, { shouldTouch: true, shouldDirty: true });

    if (avsTypeOptions?.find(cur => cur.value === opt.value)?.sig === 'ecdsa') {
      form.setValue('strategies', [AVS_QUORUMS.ETH_LST], { shouldTouch: true }); // reset to default
    }

    if (form.getFieldState('chains') && opt.value !== AVS_TYPES.MACH) {
      form.unregister('chains');
    }

    if (
      form.getFieldState('ecdsaWeight') &&
      avsTypeOptions?.find(cur => cur.value === opt.value)?.sig !== 'ecdsa'
    ) {
      form.unregister('ecdsaWeight');
    }

    if (form.getFieldState('compiledOutput')) {
      form.unregister('compiledOutput');
      form.unregister('initArgs');
      form.unregister('constructorArgs');
    }

    if (form.getFieldState('compiledOutput') && opt.value !== AVS_TYPES.CUSTOM_BLS) {
      form.unregister('aggregatorHandlerName');
    }
  };

  return (
    <Option
      optionTitle={
        <Typography fontWeight={500} variant="body1">
          CHOOSE YOUR AVS TYPE
        </Typography>
      }
      {...props}
    >
      <Grid container {...form.register('avsType')}>
        <Grid item xs={12}>
          <Typography variant="bodySmallC">ECDSA service manager templates</Typography>
        </Grid>
        {avsTypeOptions
          ?.filter(cur => cur.sig === 'ecdsa')
          ?.map(option => (
            <Grid
              item
              key={option.value}
              xs={12 / avsTypeOptions?.filter(cur => cur.sig === 'bls')?.length}
            >
              <SelectableCard
                icon={option.icon}
                isSelected={option.value === avsType}
                onClick={handleSelectAvsType(option)}
                sx={{
                  height: '100%',
                }}
                title={option.title}
              />
            </Grid>
          ))}
        <Grid item mt={1} xs={12}>
          <Typography variant="bodySmallC">BLS service manager templates</Typography>
        </Grid>
        {avsTypeOptions
          ?.filter(cur => cur.sig === 'bls')
          ?.map(option => (
            <Grid
              item
              key={option.value}
              xs={12 / avsTypeOptions?.filter(cur => cur.sig === 'bls')?.length}
            >
              <SelectableCard
                icon={option.icon}
                isSelected={option.value === avsType}
                onClick={handleSelectAvsType(option)}
                sx={{
                  height: '100%',
                }}
                title={option.title}
              />
            </Grid>
          ))}
      </Grid>
    </Option>
  );
}
