import { Character } from 'pages/aaas/components/ConfigSimpleMode/types';
import { AAATemplate } from 'shared/types/protoc-gen/bffaaa';

export type AgentTemplate = AAATemplate;

export enum DeploymentType {
  TEE = 'Verifiable(TEE)',
  NON_TEE = 'Non-Verifiable',
}

export interface CharacterFormValues {
  templateId?: string;
  persona: PersonaFormValues;
  config?: Partial<Character>;
}

export interface PersonaFormValues {
  bio: string;
  lore: string;
  recentJobId?: string; // Agent persona character generation job ID
  selectedPersonaId?: string; // Selected agent persona ID for UI state
}

export interface FormValues extends CharacterFormValues {
  agentPrefixName?: string;
  deploymentType?: DeploymentType;
  dockerImg?: string;
}

export interface EnvVarsFormValues {
  [x: string]: string;
}
