import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchGlobalPublicAvs } from 'pages/avs/api/avs';
import { ListAllAVSDeploymentAdminResponse } from 'shared/types/protoc-gen/avs';
import { QUERY_KEYS } from 'shared/types/react-query';

export interface IUseGlobalPublicAvs
  extends Omit<UseQueryOptions<ListAllAVSDeploymentAdminResponse, Error>, 'queryKey' | 'queryFn'> {}

export const useGlobalPublicAvs = (options?: IUseGlobalPublicAvs) =>
  useQuery<ListAllAVSDeploymentAdminResponse>({
    queryKey: [QUERY_KEYS.GET_GLOBAL_AVS],
    queryFn: fetchGlobalPublicAvs,
    retry: 3,
    retryDelay: 3000,
    ...options,
  });
