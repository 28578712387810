import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const IconAgentAvatar: FC<SvgIconProps> = props => (
  <SvgIcon
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M33.1488 1.73714L32.5184 0.179688L31.8565 1.72404L27.3565 12.224L28.6433 12.7755L30.3901 8.69976H34.4566L36.1011 12.7624L37.3988 12.2371L33.1488 1.73714ZM33.89 7.29976L32.4815 3.81987L30.9901 7.29976H33.89ZM2.1499 3.79976H2.8499H17.4573V5.19976H3.5499V35.7998H8.6499V26.9998V26.2998H9.3499H30.3499H31.0499V26.9998V35.7998H36.1499V22.7516H37.5499V36.4998V37.1998H36.8499H2.8499H2.1499V36.4998V4.49976V3.79976ZM29.6499 27.6998V35.7998H10.0499V27.6998H29.6499ZM13.0999 13.0498H12.3999V13.7498V17.2998H11.3499V18.6998H12.3999V23.4998V24.1998H13.0999H26.3499H27.0499V23.4998V18.6998H28.3499V17.2998H27.0499V13.7498V13.0498H26.3499H23.5499V9.49976H22.1499L22.1499 13.0498H17.5499L17.5499 9.49976H16.1499V13.0498H13.0999ZM13.7999 22.7998V14.4498H25.6499V22.7998H13.7999ZM19.8499 5.19976H22.8499V3.79976L19.8499 3.79976V5.19976ZM25.3499 5.19976H24.3499V3.79976H25.3499V5.19976ZM36.1499 19.9998V17.4998H37.5499V19.9998H36.1499ZM36.1499 13.9998V14.9998H37.5499V13.9998H36.1499ZM16.1499 17.9998V16.9998H17.5499V17.9998H16.1499ZM22.1499 16.9998V17.9998H23.5499V16.9998H22.1499Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
