import { Box, Skeleton, Stack, SvgIcon, Typography } from '@mui/material';
import { useBatchBalances } from 'pages/raas/DeploymentDetails/hooks/useBatchBalances';
import { getL1TokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useMemo } from 'react';
import { formatWithPrecision } from 'shared/utils/strings';
import { colors } from 'theme/colors';
import { Address } from 'viem';

export interface IAccountBalanceSubHeaderChip {
  account: Address;
}

export const AccountBalanceSubHeaderChip = ({ account }: IAccountBalanceSubHeaderChip) => {
  const { data: rollup } = useRaasDeployment();
  const { data: result, isPending } = useBatchBalances({
    accounts: [account],
    viem: false,
    rollup,
  });
  const formattedBalance = useMemo(() => formatWithPrecision(result?.[0].balance ?? 0n), [result]);

  return (
    <Box
      sx={{
        ml: '16px',
        background: '#fff',
        px: '6px',
        py: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
        {isPending ? (
          <Skeleton sx={{ height: '8px', width: '8px' }} variant="circular" />
        ) : (
          <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
            <circle
              cx="4"
              cy="4"
              fill={+formattedBalance > 0.05 ? colors.schema.waterPrimary : colors.schema.failure}
              r="4"
            />
          </SvgIcon>
        )}
        {isPending ? (
          <Skeleton variant="text" width="35px" />
        ) : (
          <Typography variant="caption">
            {`${formattedBalance} ${getL1TokenSymbol(rollup)}`}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
