import { Box, Button, Stack, Typography } from '@mui/material';
import { chainLabels, daTypeLabels, rollupTypeLabels } from 'pages/avs/helpers/labels';
import { useMemo } from 'react';
import { FieldName, useFormContext, useFormState, useWatch } from 'react-hook-form';
import IconCheckedCircle from 'shared/components/icons/IconCheckedCircle';
import IconNay from 'shared/components/icons/IconNay';
import IconUncheckedCircle from 'shared/components/icons/IconUncheckedCircle';
import { SETTLEMENT_CHAINS } from 'shared/types/deployments';
import { DAType, RollupType } from 'shared/types/protoc-gen/rollup';

import { FormValues } from './types';

export function Checked({ valid }: { valid?: boolean }) {
  return valid ? (
    <IconCheckedCircle />
  ) : valid === undefined ? (
    <IconUncheckedCircle />
  ) : (
    <IconNay />
  );
}

const labelMap: Record<FieldName<FormValues>, string> = {
  rollupType: 'Selected stack:',
  isTestnet: 'Network:',
  network: 'Settlement Chain:',
  babylonEnabled: 'Babylon Integration:',
  // babylonFpDetails: 'Fp details:', // TODO: revert when feature is ready
  daType: 'Data availability solution:',
  networkName: 'Network name:',
  challengePeriod: 'Challenge period:',
  chainId: 'Chain ID:',
};

function getDisplayValue(
  fieldName: FieldName<FormValues>,
  value: string | number | boolean | null,
) {
  switch (fieldName) {
    case 'daType':
      return daTypeLabels[value as DAType];
    case 'isTestnet':
      return value ? 'Testnet' : 'Mainnet';
    case 'network':
      return chainLabels[value as SETTLEMENT_CHAINS];
    case 'rollupType':
      return rollupTypeLabels[value as RollupType];
    case 'babylonEnabled':
      return value ? 'Yes' : 'No';
    // case 'babylonFpDetails':
    //   return value !== null ? 'Text' : undefined; // TODO: revert when feature is ready
    default:
      return value;
  }
}

export default function SummaryPanel({
  isSubmitDisabled,
  isSubmitting,
}: {
  isSubmitting?: boolean;
  isSubmitDisabled?: boolean;
}) {
  const form = useFormContext();
  const formValues = useWatch<FormValues>({ control: form.control });
  const formState = useFormState({ control: form.control });

  const summaryItems = useMemo(
    () =>
      Object.entries(formValues)?.map(([name, value]) => {
        const fieldName = name as FieldName<FormValues>;

        return {
          isError: formState?.errors?.[name]?.message,
          fieldName,
          value: getDisplayValue(fieldName, value),
        };
      }),
    [formValues, formState],
  );

  const scrollToStep = (fieldName: FieldName<FormValues>) => () => {
    const el = document.getElementById(`step_${fieldName}`);

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.08)',
        background: 'linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%)',
        m: { md: 5 },
        position: 'sticky',
        top: 40,
      }}
    >
      <Stack alignItems="center" direction="row" gap={1} sx={{ px: 5, py: 2 }}>
        <Typography pt={5} variant="captionC">
          Summary
        </Typography>
      </Stack>
      {summaryItems?.map(cur => (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          key={cur.fieldName}
          onClick={scrollToStep(cur.fieldName)}
          sx={{
            px: 5,
            py: 2,
            borderTop: '1px solid rgba(32, 39, 35, 0.08)',
            cursor: 'pointer',
          }}
        >
          <Checked valid={cur.isError ? false : cur.value === undefined ? undefined : true} />
          <Typography variant="caption">{labelMap?.[cur?.fieldName]}</Typography>
          <Typography variant="captionM">{String(cur.value ?? '')}</Typography>
        </Stack>
      ))}
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        sx={{ px: 5, py: 2, borderTop: '1px solid rgba(32, 39, 35, 0.08)' }}
      >
        <Button disabled={isSubmitting || isSubmitDisabled} fullWidth type="submit">
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Stack>
    </Box>
  );
}
