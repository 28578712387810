import dayjs from 'dayjs';
import { useHealthz } from 'pages/aaas/AaasDetails/hooks';
import { useDnsLookup } from 'pages/aaas/AaasDetails/hooks/useDnsLookup';
import { useChatStore } from 'pages/aaas/AaasDetails/useChatStore';
import { useEffect } from 'react';
import { AAAApp } from 'shared/types/protoc-gen/bffaaa';

export const useStatus = (app?: AAAApp) => {
  const { deployed, deploying, reset, setDeployed, setDeploying } = useChatStore();
  const {
    error: healthzError,
    failureCount: healthzFailureCount,
    isPending: healthzPending,
    isSuccess: healthzSuccess,
  } = useHealthz({ appId: app?.id });
  const {
    data: dnsLookupResult,
    isError: dnsLookupError,
    refetch: refetchDnsLookup,
  } = useDnsLookup(app?.endpoints?.apiUrl);

  useEffect(() => {
    reset(app?.id);
  }, [app, reset]);

  useEffect(() => {
    if (dnsLookupResult) setDeployed(app?.id);
  }, [app, healthzSuccess, setDeployed, dnsLookupResult]);

  useEffect(() => {
    if (!dnsLookupResult) setDeploying(app?.id);
  }, [app, healthzError, setDeploying, dnsLookupError, dnsLookupResult]);

  useEffect(() => {
    console.debug('minute:', dayjs().diff(dayjs(app?.createdAt), 'minute'));

    if (dnsLookupResult || healthzSuccess) {
      setDeployed(app?.id);

      return;
    }

    if (
      healthzError ||
      !dnsLookupResult ||
      dnsLookupError ||
      dayjs().diff(dayjs(app?.createdAt), 'minute') < 8
    ) {
      refetchDnsLookup();
      setDeploying(app?.id);
    } else {
      setDeployed(app?.id);
    }
  }, [
    app,
    healthzError,
    healthzPending,
    setDeployed,
    setDeploying,
    dnsLookupError,
    healthzSuccess,
    dnsLookupResult,
    refetchDnsLookup,
  ]);

  return {
    isPending: healthzPending,
    failureCount: healthzFailureCount,
    deployed:
      (!healthzError || !dnsLookupError) && app && typeof deployed === 'object'
        ? deployed[app?.id]
        : false,
    deploying:
      (healthzError || dnsLookupError) && app && typeof deploying === 'object'
        ? deploying[app?.id]
        : false,
  };
};
