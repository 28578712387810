import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useBlockscoutMetrics } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';
import { roundDecimals } from 'shared/utils/strings';

export interface IAverageGasPriceMetric {
  timeFilterVariant?: TimeFilterVariant;
}

export const AverageGasPriceMetric = ({ timeFilterVariant }: IAverageGasPriceMetric) => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const {
    end: to,
    start: from,
    timeframe,
  } = useTimeFilterOptions(
    selectedTimeFilter[timeFilterVariant ?? TimeFilterVariant.FEE_TRACKER_GAS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'averageGasPrice',
    l2: true,
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Average gas price for the period (Gwei)'}
      id={'average-gas-price'}
      loading={isPending}
      title={'Average gas price'}
      tooltipValueFormatter={value => `${roundDecimals(value)} Gwei`}
      yAxisProps={{
        tickFormatter: value => `${value} Gwei`,
      }}
    />
  );
};
