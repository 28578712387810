import { useQuery } from '@tanstack/react-query';
import { Character } from 'pages/aaas/components/ConfigSimpleMode/types';
import { bff } from 'shared/api';
import {
  GetCharacterGenerateJobReply,
  ListCharacterGenerateJobsReply,
} from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';
import { tryParseJSON } from 'shared/utils/json';

export type GeneratedCharacter = Omit<Character, 'modelProvider' | 'plugins'>;

export const useListGeneratedCharacters = () =>
  useQuery({
    queryKey: [QUERY_KEYS.AAAS_LIST_GENERATED_CHARACTERS],
    queryFn: async () => {
      const res = await bff.get<ListCharacterGenerateJobsReply>(`bff/aaa/characters`);

      const resps = await Promise.all(
        res.data.jobs
          .filter(cur => cur.isFinished)
          .map(cur => bff.get<GetCharacterGenerateJobReply>(`bff/aaa/characters/${cur.jobId}`)),
      );

      return resps.map(res => tryParseJSON(res.data.characterConfig) as GeneratedCharacter);
    },
  });
