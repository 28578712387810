import { getRollupSettlementLayerChain, SettlementLayer } from 'pages/raas/helpers/labels';
import { Rollup } from 'shared/types/protoc-gen/raas-rollup';

export const isBscSettlementLayer: (rollup: Rollup | undefined) => boolean = rollup =>
  rollup?.type === SettlementLayer.BINANCE_MAINNET ||
  rollup?.type === SettlementLayer.BINANCE_TESTNET;

export const getTokenSymbol = (
  rollup: Rollup | undefined,
  defaultSymbol: string = 'ETH',
): string => {
  return rollup?.tokenMetadata?.symbol ?? defaultSymbol;
};

export const getNoDataTextForCustomToken = (rollup: Rollup | undefined) =>
  rollup?.tokenMetadata?.isCustom ? 'Not supported.' : undefined;

export const getL1TokenSymbol = (rollup: Rollup | undefined) =>
  getRollupSettlementLayerChain(rollup?.settlementLayer as SettlementLayer)?.nativeCurrency
    ?.symbol ?? 'ETH';
