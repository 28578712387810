import { Navigate, RouteObject } from 'react-router-dom';
import { isAutonomeOrigin } from 'shared/constants/env';

import { AAAS_PATHS, routes as aaasRoutes } from './pages/aaas/routes';
import { ADMIN_PATHS, routes as adminRoutes } from './pages/admin/routes';
import { AUTH_PATHS, routes as authRoutes } from './pages/auth/routes';
import { AVS_PATHS, routes as avsRoutes } from './pages/avs/routes';
import { GENERAL_PATHS, routes as grnlRoutes } from './pages/general/routes';
import { RAAS_PATHS, routes as raasRoutes } from './pages/raas/routes';

export const getDefaultPath = (): string => {
  if (isAutonomeOrigin) {
    return AAAS_PATHS.AAAS_DEPLOYMENTS;
  }

  return GENERAL_PATHS.SELECT_LAYER_TYPE;
};

export const PATHS = {
  DEFAULT: getDefaultPath(),
  ...GENERAL_PATHS,
  ...AUTH_PATHS,
  ...AAAS_PATHS,
  ...RAAS_PATHS,
  ...AVS_PATHS,
  ...ADMIN_PATHS,
};

const DefaultRoute: React.FC = () => {
  return <Navigate to={PATHS.DEFAULT} />;
};

export const routes: RouteObject[] = [
  ...authRoutes,
  ...grnlRoutes,
  ...aaasRoutes,
  ...raasRoutes,
  ...avsRoutes,
  ...adminRoutes,

  {
    element: <DefaultRoute />,
    path: '*',
  },
];
