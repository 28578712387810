import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QUERY_KEYS } from 'shared/types/react-query';
import { Hash } from 'viem';

import { useBlockscoutAxios } from './useBlockscoutAxios';

export interface BlockscoutOutputRootsResponse {
  items: BlockscoutOutputRootsItem[];
  next_page_params: BlockscoutOutputRootsNextPageParams;
}

export interface IUseBlockscoutOutputRoots
  extends Omit<UseQueryOptions<BlockscoutOutputRootsResponse>, 'queryFn' | 'queryKey'> {}

export const useBlockscoutOutputRoots = (queryOptions?: IUseBlockscoutOutputRoots) => {
  const { rollupId } = useParams();
  const blockscout = useBlockscoutAxios({ v2: true });

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_BLOCKSCOUT_STATS,
      {
        variant: 'tokens',
        rollupId,
      },
    ],
    queryFn: async (): Promise<BlockscoutOutputRootsResponse> => {
      const res = await blockscout.l2.get<BlockscoutOutputRootsResponse>(`/optimism/output-roots`);

      return res.data;
    },
    enabled: !!blockscout?.rollup,
    ...queryOptions,
  });
};

export interface BlockscoutOutputRootsItem {
  l1_block_number: number;
  l1_timestamp: string;
  l1_tx_hash: Hash;
  l2_block_number: number;
  l2_output_index: number;
  output_root: Hash;
}

export interface BlockscoutOutputRootsNextPageParams {
  index: number;
  items_count: number;
}
