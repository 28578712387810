import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Section } from 'shared/components';

import { useRpmByMethods } from './hooks';

export const RpmByMethodsSection = () => {
  const { data } = useRpmByMethods();

  const filteredData = Object.entries(
    data?.data.filter(
      v => dayjs(v.date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY'),
    )?.[0] ?? {},
  );

  return (
    <Section title="RPM by method for the last minute">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {['Method Name', 'Requests/Min'].map(header => (
                <TableCell key={header}>
                  <Typography variant="captionC">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(1)
              .sort((a, b) => Number(b[1]) - Number(a[1]))
              .map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value.toLocaleString()}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
};
