import { Grid, Skeleton, Typography } from '@mui/material';
import { useListAppTypes } from 'pages/aaas/hooks';
import { TemplateCard } from 'pages/aaas/NewAgentDeployment/components/TemplateCard';
import { FormValues } from 'pages/aaas/NewAgentDeployment/types';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ErrorCaption } from 'shared/components/Error';
import { Option as FormLabel } from 'shared/components/Option';
import TabbedSection from 'shared/components/TabbedSection';
import { AAATemplate } from 'shared/types/protoc-gen/bffaaa';

export type SelectTemplateHandler = (template: AAATemplate) => void;

export const SelectTemplate: FC<{ onSelectTemplate: SelectTemplateHandler }> = ({
  onSelectTemplate,
}) => {
  const [searchParams] = useSearchParams();
  const searchParamsTemplate = useMemo(() => searchParams?.get('template'), [searchParams]);
  const { data, isPending } = useListAppTypes();

  const form = useFormContext<FormValues>();

  const selected = form.watch('templateId');
  const error = form.formState.errors.templateId;

  const {
    appStore: appStoreTemplates,
    builtin: defaultTemplates,
    selfOwned: selfOwnedTemplates,
  } = data || {};

  const [hasAppStoreTemplates, hasSelfOwnedTemplates] = useMemo(
    () => [
      appStoreTemplates && appStoreTemplates.length > 0,
      selfOwnedTemplates && selfOwnedTemplates.length > 0,
    ],
    [appStoreTemplates, selfOwnedTemplates],
  );

  const renderTemplate = useCallback(
    (template: AAATemplate, selected: boolean) => (
      <Grid
        item
        key={template?.id}
        md={6}
        sx={theme => ({
          ...(selected
            ? {
                border: `1px solid ${theme.colors.functional.text.link}`,
              }
            : {
                borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
                borderRight: `1px solid ${theme.colors.functional.subject.border}`,
              }),
        })}
        xs={12}
      >
        <TemplateCard
          onClick={() => {
            if (selected) {
              return form.setValue('templateId', undefined);
            }

            form.setValue('templateId', String(template?.id));
            onSelectTemplate(template);
          }}
          selected={selected}
          template={template}
        />
      </Grid>
    ),
    [form, onSelectTemplate],
  );
  const renderTemplatesList = useCallback(
    (templates: AAATemplate[] | undefined) =>
      templates?.map(template => renderTemplate(template, selected === template?.id)),
    [renderTemplate, selected],
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Built-in',
        content: (
          <Grid container>
            {isPending
              ? Array.from({ length: 6 }).map((_, idx) => (
                  <Grid item key={idx.toString()} xs={6}>
                    <Skeleton sx={{ minHeight: '150px' }} variant="rectangular" />
                  </Grid>
                ))
              : renderTemplatesList(defaultTemplates)}
          </Grid>
        ),
      },
      {
        label: 'Community',
        content: hasAppStoreTemplates ? (
          <Grid container>{renderTemplatesList(appStoreTemplates)}</Grid>
        ) : (
          <NoDataText>There are no community frameworks available</NoDataText>
        ),
      },
      {
        label: 'Uploaded',
        content: hasSelfOwnedTemplates ? (
          <Grid container>{renderTemplatesList(selfOwnedTemplates)}</Grid>
        ) : (
          <>
            <NoDataText>You have not uploaded any frameworks</NoDataText>
          </>
        ),
      },
    ],
    [
      appStoreTemplates,
      defaultTemplates,
      hasAppStoreTemplates,
      hasSelfOwnedTemplates,
      isPending,
      renderTemplatesList,
      selfOwnedTemplates,
    ],
  );

  return (
    <FormLabel
      id="step_template"
      optionTitle="Select a template"
      sx={{ display: searchParamsTemplate ? 'none' : 'block' }}
    >
      <TabbedSection tabs={tabs} />
      <ErrorCaption error={!!error} message={error?.message} />
    </FormLabel>
  );
};

const NoDataText: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '150px',
    }}
  >
    {children}
  </Typography>
);
