import { Outlet, RouteObject } from 'react-router-dom';
import { RequireLoginGuard } from 'shared/guards';

import { routes as detailsRoutes } from './DeploymentDetails/routes';
import { ScrollToTopWrapper } from './components';
import { DeploymentDetails } from './DeploymentDetails';
import { NewDeployment } from './NewDeployment';
import { RaasDeployments } from './RaasDeployments';

export enum RAAS_PATHS {
  RAAS_DEPLOYMENTS = '/rollups',
  RAAS_DETAILS = '/rollups/:deploymentId',
  RAAS_NEW_DEPLOYMENT = '/rollups/new',
}

export const routes: RouteObject[] = [
  {
    path: RAAS_PATHS.RAAS_DEPLOYMENTS,
    element: (
      <RequireLoginGuard>
        <ScrollToTopWrapper>
          <Outlet />
        </ScrollToTopWrapper>
      </RequireLoginGuard>
    ),
    children: [
      {
        path: '',
        element: <RaasDeployments />,
      },
      {
        path: ':rollupId',
        element: <DeploymentDetails />,
        children: detailsRoutes,
      },
      {
        path: 'new',
        element: <NewDeployment />,
      },
    ],
  },
];
