import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { useRaasDeployment } from 'pages/raas/hooks';

import { CurrentPlanSection } from './CurrentPlanSection';
import { MetricsSection } from './MetricsSection';
import { RollupInfoSection } from './RollupInfoSection';

export const OverviewView = () => {
  const { data: rollup, isPending } = useRaasDeployment();

  return (
    <>
      <RollupInfoSection loading={isPending} rollup={rollup} />
      {showExperimentalFeatures && <CurrentPlanSection />}
      <MetricsSection />
    </>
  );
};
