import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { ListProductReply } from 'shared/types/protoc-gen/bffsubscription';
import { QUERY_KEYS } from 'shared/types/react-query';

interface IUseListProductPaymentLinks
  extends Omit<UseQueryOptions<ListProductReply>, 'queryFn' | 'queryKey'> {}

export const useListProductPaymentLinks = (opts?: IUseListProductPaymentLinks) =>
  useQuery({
    queryKey: [QUERY_KEYS.BFF_PRODUCT_PAYMENT_LINKS],
    queryFn: async () => {
      const res = await bff.get<ListProductReply>('/bff/subscriptions/products');

      return res.data;
    },
    ...opts,
  });
