import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { getNoDataTextForCustomToken, getTokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { useRaasDeployment, useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

export const WithdrawalVolumeEthGrowthMetric = () => {
  const { rollupId } = useParams();
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step, timeframe } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.BRIDGE_METRICS_WITHDRAWALS_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_WITHDRAWAL_VOLUME,
    timeframe,
    rollupId,
    start,
    end,
    step,
  });

  return (
    <Metric
      data={!rollup?.name.includes('hashkey') ? result : undefined}
      description={`Growth trend of total withdrawal volume in ${getTokenSymbol(rollup)}`}
      id="withdrawals-volume-growth"
      loading={isPending}
      noDataText={getNoDataTextForCustomToken(rollup)}
      title={`Withdrawals volume (${getTokenSymbol(rollup)}) growth`}
    />
  );
};
