import { LoadingButton } from '@mui/lab';
import { Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Section } from 'shared/components';
import { AnimatedIconChevronDown } from 'shared/components/icons/AnimatedIconChevronDown';
import { MetricGridItem } from 'shared/components/metric';
import { useMenuDisclosure } from 'shared/hooks/ui/useMenuDisclosure';

import { SectionButtonTimeFilter } from '../../components';
import { TimeFilterVariant } from '../../useTimeFilterStore';
import { RpmByMethodsMetric } from './RpmByMethodsMetric';
import { useRpmByMethods } from './useRpmByMethods';

const ChooseMethodSection: React.FC = () => {
  const { data, isPending } = useRpmByMethods();
  const {
    anchorEl: methodsMenuAnchorEl,
    onClose: onCloseMethodsMenu,
    onOpen: onOpenMethodsMenu,
    open: openedMethodsMenu,
  } = useMenuDisclosure();

  const methods = Array.from(new Set(data?.data.flatMap(item => Object.keys(item)))).filter(
    val => val !== 'date',
  );

  const [selectedMethod, setSelectedMethod] = useState<string>('');

  useEffect(() => {
    if (!selectedMethod && methods.length > 0) {
      setSelectedMethod(methods[0]);
    }
  }, [methods, selectedMethod]);

  return (
    <Section
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          options={['month', 'day', 'hour']}
          variant={TimeFilterVariant.RPM_CHOOSE_METHOD_SECTION}
        />
      }
      renderSubHeaderStartContent={
        <>
          <LoadingButton
            loading={isPending}
            onClick={onOpenMethodsMenu}
            sx={theme => ({
              backgroundColor: 'transparent !important',
              '&, &:hover': {
                background: '#fff',
                color: theme.colors.functional.text.primary,
                p: 0,
              },
            })}
          >
            <Stack direction="row" spacing="32px" sx={{ p: '16px' }}>
              <Typography variant="bodySmallM">{selectedMethod}</Typography>
              <AnimatedIconChevronDown open={openedMethodsMenu} />
            </Stack>
          </LoadingButton>
          <Menu
            anchorEl={methodsMenuAnchorEl}
            onClose={onCloseMethodsMenu}
            open={openedMethodsMenu}
          >
            {methods.map(method => {
              return (
                <MenuItem
                  key={method}
                  onClick={() => {
                    setSelectedMethod(method);
                    onCloseMethodsMenu();
                  }}
                >
                  {method}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      }
    >
      <Grid container>
        <MetricGridItem item xs={12}>
          <RpmByMethodsMetric method={selectedMethod} />
        </MetricGridItem>
      </Grid>
    </Section>
  );
};

export default ChooseMethodSection;
