import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, darken, FormHelperText, Link, Stack, Typography } from '@mui/material';
import { useAccountModal } from '@rainbow-me/rainbowkit';
import { MouseEventHandler, useMemo, useState } from 'react';
import { truncate } from 'shared/utils/strings';
import { Address, parseEther } from 'viem';
import { holesky } from 'viem/chains';
import { useAccount, useBalance } from 'wagmi';

import { ConditionalConnectButton } from './ConditionalConnectButton';

interface Web3ButtonWRequiredRole extends LoadingButtonProps {
  requiredSender?: Address;
  requiredRole?: string;
  chainId?: number;
  chainName?: string;
  hideHelperText?: boolean;
}

export default function Web3LoadingButton({
  chainId = holesky.id,
  chainName,
  children,
  hideHelperText = false,
  loading,
  onClick,
  requiredRole = 'owner',
  requiredSender,
  sx,
  ...props
}: Web3ButtonWRequiredRole) {
  const { openAccountModal } = useAccountModal();
  const { address } = useAccount();
  const isRightAddressConnected = useMemo(
    () => !requiredSender || (requiredSender && address === requiredSender),
    [address, requiredSender],
  );
  const { data: balance } = useBalance({ address });

  const [showFaucetLink, setShowFaucetLink] = useState(false);

  const handleClickAccount: MouseEventHandler<HTMLSpanElement> = e => {
    e?.stopPropagation();
    openAccountModal?.();
  };

  const handleClickButton: MouseEventHandler<HTMLButtonElement> = e => {
    if (!isRightAddressConnected) {
      openAccountModal?.();

      return;
    }

    if (!balance || balance.value < parseEther('0.000001')) {
      setShowFaucetLink(true);

      return;
    }

    if (balance?.value >= parseEther('0.000001') && showFaucetLink) {
      setShowFaucetLink(false);
    }

    onClick?.(e);
  };

  return (
    <ConditionalConnectButton
      chainId={chainId}
      chainName={chainName}
      hideHelperText={hideHelperText}
      sx={sx}
    >
      <LoadingButton
        fullWidth
        loading={loading}
        onClick={handleClickButton}
        sx={{
          position: 'relative',
          ...(!isRightAddressConnected && {
            background: '#F1605F',
            '&:hover': {
              background: darken('#F1605F', 0.2),
            },
          }),
          ...sx,
        }}
        {...props}
      >
        <Box sx={{ position: 'relative', width: '100%' }}>
          <Box sx={{ position: 'relative', top: -3 }}>
            {isRightAddressConnected ? children : 'Switch Account'}
          </Box>
          <Typography
            component="span"
            onClick={handleClickAccount}
            sx={{
              whiteSpace: 'nowrap',
              position: 'absolute',
              top: 25,
              cursor: 'pointer',
              left: '50%',
              lineHeight: '11px',
              fontSize: 11,
              overflow: 'hidden',
              transform: 'translate(-50%)',
              '&:hover': { textDecoration: 'underline' },
            }}
            variant="caption"
          >
            (Connected: {truncate(address || '', undefined, 4, 4)})
          </Typography>
        </Box>
      </LoadingButton>
      {showFaucetLink && (
        <Stack alignItems="center" direction="row" justifyContent="center">
          <FormHelperText error>
            Insufficient funds.&nbsp;
            <Link
              href="https://cloud.google.com/application/web3/faucet/ethereum/holesky"
              onClick={() => setShowFaucetLink(false)}
              rel="noopener noreferrer"
              sx={{
                color: 'red',
                textDecoration: 'underline',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
              }}
              target="_blank"
            >
              <Typography
                component="span"
                sx={{ '&:hover': { fontWeight: 500 } }}
                variant="caption"
              >
                Get more
              </Typography>
            </Link>{' '}
          </FormHelperText>
        </Stack>
      )}
      {!isRightAddressConnected && (
        <Stack alignItems="center" direction="row" justifyContent="center">
          <FormHelperText error>
            Please connect using the {requiredRole} account (
            {truncate(requiredSender || '', '...', 5, 3)})
          </FormHelperText>
        </Stack>
      )}
    </ConditionalConnectButton>
  );
}
