import { Grid } from '@mui/material';
import { metrics, useBlockscoutCounters } from 'pages/raas/hooks';
import { MetricWidget } from 'shared/components/metric/MetricWidget';
import { Section } from 'shared/components/Section';

export const OverviewSection = () => {
  const { data: result, isPending: getRollupStatisticsPending } = useBlockscoutCounters();

  return (
    <Section
      renderContent={
        <Grid container>
          {!result || getRollupStatisticsPending
            ? metrics.map(metric => <MetricWidget key={metric} loading tooltip="..." />)
            : result?.map(({ description, id, title, value }) => {
                return (
                  <MetricWidget
                    key={id}
                    label={title}
                    tooltip={description}
                    value={Number(value ?? 0).toLocaleString()}
                  />
                );
              })}
        </Grid>
      }
      title="Overview"
    />
  );
};
