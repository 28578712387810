import { yupResolver } from '@hookform/resolvers/yup';
import { CameraAlt } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import { FormTextField } from 'shared/components/form';
import IconCheckedCircle from 'shared/components/icons/IconCheckedCircle';
import { IconCirclePlus } from 'shared/components/icons/IconCirclePlus';
import IconEth from 'shared/components/icons/IconEth';
import IconEthBlobs from 'shared/components/icons/IconEthBlobs';
import { IconSolana } from 'shared/components/icons/IconSolana';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { truncate } from 'shared/utils/strings';
import { recoverMessageAddress, toHex } from 'viem';
import { useAccount, useEnsName, useSignMessage } from 'wagmi';
import * as yup from 'yup';

interface FormValues {
  name: string;
  websiteUrl: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  websiteUrl: yup.string().url().nullable(),
});

export const EditAgentProfileDialog: FC<DialogProps & { onValid: SubmitHandler<FormValues> }> = ({
  onClose,
  onValid,
  ...props
}) => {
  const { addAlert } = useAlerts();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sm } = useBreakpoints();
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { signMessageAsync } = useSignMessage();
  const { data: ensName } = useEnsName({ address });

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
  });

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const solProvider = useMemo(() => {
    if (!('phantom' in window)) return null;

    return (window.phantom as any)?.solana;
  }, []);

  const {
    data: linkWithEthData,
    isPending: isLinkWithEthPending,
    isSuccess: isLinkWithEthSuccess,
    mutate: linkWithEth,
  } = useMutation({
    mutationKey: ['LINK_WITH_ETH', { address }],
    mutationFn: async () => {
      // ! This is a dummy implementation for demonstration purposes
      // TODO: fetch signing message from BE with unique generated nonce
      // TODO: handle authorization and verification of signed message
      // TODO: get recovered address from BE or let BE handle saving rewardsAddr for bounty

      const message = `Sign message to link Ethereum address.\n\n${toHex(
        Math.round(new Date().getTime() * Math.random()),
      )}`;

      const signature = await signMessageAsync({
        account: address,
        message,
      });

      const recoveredAddr = await recoverMessageAddress({ message, signature });

      return address?.toLowerCase() === recoveredAddr.toLowerCase();
    },
  });

  const { data: solAccountData, isPending: isSolAccountPending } = useQuery<object>({
    queryKey: ['GET_SOLANA_ACCOUNT'],
    queryFn: async () => {
      // TODO: need a better implementation for fetching solana account
      const resp = await solProvider.request({ method: 'connect' });

      return resp?.publicKey;
    },
    enabled: !!solProvider && false, // TODO: temporarily false since not needed yet
  });

  const {
    data: connectSolWalletData,
    isPending: isConnectSolWalletPending,
    mutate: connectSolWallet,
  } = useMutation<object>({
    mutationKey: ['CONNECT_SOLANA_WALLET'],
    mutationFn: async () => {
      const resp = await solProvider.request({ method: 'connect' });

      return resp?.publicKey;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Connected Phantom wallet',
        desc: 'You have successfully connected your Phantom wallet.',
      });
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to connect Phantom wallet',
        desc: 'Please make sure you have installed Phantom wallet in your browser extension.',
      });
    },
  });

  const {
    data: linkWithSolData,
    isPending: isLinkWithSolPending,
    isSuccess: isLinkWithSolSuccess,
    mutate: linkWithSol,
  } = useMutation({
    mutationKey: ['LINK_WITH_SOLANA', { address }],
    mutationFn: async () => {
      // ! proof of concept implementation
      // TODO: this should likely be handled in BE for signature verification

      const message = `Sign message to link Solana address.\n\n${toHex(
        Math.round(new Date().getTime() * Math.random()),
      )}`;

      const encodedMessage = new TextEncoder().encode(message);

      const signedMessage = await solProvider.request({
        method: 'signMessage',
        params: {
          message: encodedMessage,
          display: 'hex',
        },
      });

      const [recoveredSolAddr, solAccountAddr] = [
        signedMessage?.publicKey?.toString()?.toLowerCase(),
        (solAccountData ?? connectSolWalletData)?.toString()?.toLowerCase(),
      ];

      return {
        verified: recoveredSolAddr === solAccountAddr,
        solAddr: signedMessage?.publicKey?.toString(),
      };
    },
  });

  const onInvalid: SubmitErrorHandler<FormValues> = useCallback(
    errors => {
      console.error('errors', errors);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Invalid form',
        desc: 'Please fill out the required fields',
      });
    },
    [addAlert],
  );

  return (
    <Dialog maxWidth={'md'} onClose={onClose} {...props}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onValid, onInvalid)}>
          <DialogContainer sx={{ minWidth: { md: '550px', sm: undefined } }}>
            <DialogHeader onClose={handleClose} title="Edit Profile" />

            <Stack spacing={3}>
              <Stack alignItems="start">
                <Stack alignItems="center" direction="row" justifyContent="center" spacing={3}>
                  <IconButton
                    size="large"
                    sx={{ p: 3, background: theme => theme.colors.gradients.metal }}
                  >
                    <CameraAlt />
                  </IconButton>

                  <Typography variant="bodySmallC">Upload Logo</Typography>
                </Stack>
              </Stack>

              <Stack spacing={1}>
                <FormTextField fieldConfig={{ name: 'name', placeholder: 'agentname' }} hideLabel />
                <FormTextField
                  fieldConfig={{ name: 'websiteUrl', placeholder: 'Website' }}
                  hideLabel
                />
                {ensName && (
                  <PanelField
                    checked
                    icon={<IconEth sx={{ height: 24, width: 24 }} />}
                    label={String(ensName)}
                  />
                )}
                <PanelField
                  checked={
                    // TODO: update impl when BE is ready
                    linkWithEthData && isLinkWithEthSuccess
                  }
                  icon={<IconEthBlobs sx={{ height: 24, width: 24 }} />}
                  label={
                    // TODO: update impl when BE is ready
                    isConnected
                      ? !!address && linkWithEthData && isLinkWithEthSuccess
                        ? truncate(address)
                        : 'Link Ethereum Address'
                      : 'Connect Wallet'
                  }
                  loading={isLinkWithEthPending}
                  onClickAction={isConnected ? linkWithEth : openConnectModal}
                  tooltip={
                    // TODO: update impl when BE is ready
                    address
                  }
                />
                <PanelField
                  checked={
                    // TODO: update impl when BE is ready
                    linkWithSolData?.verified && isLinkWithSolSuccess
                  }
                  icon={<IconSolana sx={{ height: 24, width: 24 }} />}
                  label={
                    // TODO: update impl when BE is ready
                    !!connectSolWalletData || !!solAccountData
                      ? !!linkWithSolData?.solAddr &&
                        linkWithSolData.verified &&
                        isLinkWithSolSuccess
                        ? truncate(linkWithSolData.solAddr)
                        : 'Link Solana Address'
                      : 'Connect Phantom Wallet'
                  }
                  loading={isLinkWithSolPending || isSolAccountPending || isConnectSolWalletPending}
                  onClickAction={
                    !!connectSolWalletData || !!solAccountData ? linkWithSol : connectSolWallet
                  }
                  tooltip={
                    // TODO: update impl when BE is ready
                    address
                  }
                />
              </Stack>

              <FormControl>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <FormLabel id="agent-public-radio-group-label">
                    <Typography variant="body1">Make your agent public?</Typography>
                  </FormLabel>

                  <RadioGroup
                    aria-labelledby="agent-public-radio-group-label"
                    name="agentPublic"
                    row
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={<Typography variant="body1">YES</Typography>}
                      value={true}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label={<Typography variant="body1">NO</Typography>}
                      value={false}
                    />
                  </RadioGroup>
                </Stack>
              </FormControl>

              <Button>
                <Typography variant="body1">Confirm</Typography>
              </Button>
            </Stack>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};

const PanelField: FC<{
  icon: ReactNode;
  loading?: boolean;
  label: string;
  checked?: boolean;
  tooltip?: string;
  onClickAction?: () => void;
}> = ({ checked, icon, label, loading, onClickAction, tooltip }) => (
  <Stack
    sx={{
      border: theme => `1px solid ${theme.colors.functional.subject.border}`,
      '&, &:hover': {
        background: '#fff',
      },
      '&:hover': {
        border: theme => `1px solid ${theme.colors.functional.subject.borderActive}`,
      },
      minHeight: '68px',
    }}
    width="100%"
  >
    <Stack direction="row" flex={1} justifyContent="space-between" p={3}>
      <Stack alignItems="center" direction="row" justifyContent="center" spacing={1}>
        {icon}
        <ConditionalTooltip enabled={!!tooltip} placement="top-start" title={tooltip}>
          <Typography variant="body1">{label}</Typography>
        </ConditionalTooltip>
      </Stack>

      <Stack alignItems="center" justifyContent="center">
        {loading ? (
          <CircularProgress size={20} sx={{ height: 20, width: 20 }} />
        ) : checked ? (
          <IconCheckedCircle sx={{ height: '20px', width: '20px' }} />
        ) : (
          <IconCirclePlus
            onClick={onClickAction}
            sx={{ cursor: 'pointer', height: '20px', width: '20px' }}
          />
        )}
      </Stack>
    </Stack>
  </Stack>
);
