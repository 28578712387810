import { AcceptInvite } from 'pages/auth/AcceptInvite';
import { CreateOrganization } from 'pages/auth/CreateOrganization';
import { Login } from 'pages/auth/Login';
import { RouteObject } from 'react-router-dom';

export enum AUTH_PATHS {
  LOGIN = '/login',
  CREATE_ORG = '/create-org',
  ACCEPT_INVITE = '/invite/:id',
}

export const routes: RouteObject[] = [
  {
    element: <Login />,
    path: AUTH_PATHS.LOGIN,
  },
  {
    element: <AcceptInvite />,
    path: AUTH_PATHS.ACCEPT_INVITE,
  },
  {
    element: <CreateOrganization />,
    path: AUTH_PATHS.CREATE_ORG,
  },
];
