import { SettlementLayer } from 'pages/raas/helpers/labels';

export const settlementLayerExplorerUrl: Record<SettlementLayer, string> = {
  [SettlementLayer.ARBITRUM_ONE]: 'https://arbiscan.io/',
  [SettlementLayer.ARBITRUM_SEPOLIA]: 'https://sepolia.arbiscan.io/',
  [SettlementLayer.BINANCE_MAINNET]: 'https://bscscan.com/',
  [SettlementLayer.BINANCE_TESTNET]: 'https://testnet.bscscan.com/',
  [SettlementLayer.ETHEREUM]: 'https://etherscan.io/',
  [SettlementLayer.SEPOLIA]: 'https://sepolia.etherscan.io/',
  [SettlementLayer.UNRECOGNIZED]: 'https://etherscan.io/',
};
