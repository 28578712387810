import { Box, Grid } from '@mui/material';
import { PropsWithChildren } from 'react';

import { AgentCarouselCard, AgentCarouselCardProps } from './AgentCarouselCard';

export default function AgentCarouselItem({
  group,
  groupSize = 3,
  index,
}: PropsWithChildren<{
  group: AgentCarouselCardProps[];
  index: number;
  groupSize?: number;
}>) {
  return (
    <Box
      className={`carousel-item`}
      data-index={index}
      sx={{
        mx: 2,
        flexShrink: 0,
        width: '100%',
        scrollSnapAlign: 'center',
        height: { xl: '325px', lg: '400px', md: '450px', xs: '500px' },
      }}
    >
      <Grid
        alignItems="center"
        container
        justifyContent={group?.length < groupSize ? 'flex-start' : 'space-around'}
        spacing={4}
        sx={{ height: '100%' }}
      >
        {group?.map(card => (
          <Grid
            alignItems="center"
            container
            item
            justifyContent="center"
            key={card.id}
            sx={{ height: '100%' }}
            xs={12 / groupSize}
          >
            <AgentCarouselCard card={card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
