import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useRaasDeployment, useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';
import { formatWithPrecision } from 'shared/utils/strings';

export const L1FeeVaultMetric = () => {
  const { rollupId } = useParams();
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step, timeframe } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.BALANCE_ALERT_FEE_VAULTS_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_L1FEEVAULT_BALANCE,
    timeframe,
    rollupId,
    start,
    end,
    step,
  });

  return (
    <Metric
      chartType="area"
      data={result}
      id={'l1-fee-vault'}
      legendDirection={'column'}
      legendSpacing={'4px'}
      loading={isPending}
      title={'L1 fee vault balance'}
      tooltipValueFormatter={value =>
        [formatWithPrecision(value), rollup?.tokenMetadata?.symbol].join(' ')
      }
      yAxisProps={{
        tickFormatter: value =>
          [formatWithPrecision(value), rollup?.tokenMetadata?.symbol].join(' '),
      }}
    />
  );
};
