import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { CreateAAAAppReply, CreateAAAAppRequest } from 'shared/types/protoc-gen/bffaaa';
import { MUTATION_KEYS } from 'shared/types/react-query';

export interface IUseCreateApp
  extends Omit<
    UseMutationOptions<CreateAAAAppReply, Error, CreateAAAAppRequest, unknown>,
    'mutationFn' | 'mutationKey'
  > {}

export const useCreateApp = (
  opts?: IUseCreateApp,
): UseMutationResult<CreateAAAAppReply, Error, CreateAAAAppRequest, unknown> => {
  return useMutation<CreateAAAAppReply, Error, CreateAAAAppRequest, unknown>({
    mutationKey: [MUTATION_KEYS.AAAS_CREATE_AAA_APP],
    mutationFn: async (args: CreateAAAAppRequest) => {
      const res = await bff.post<CreateAAAAppReply>('/bff/aaa/apps', args);

      return res.data;
    },
    ...opts,
  });
};
