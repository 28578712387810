import { Stack, StackProps, TooltipProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import IconInfo from 'shared/components/icons/IconInfo';
import { MarkdownRenderer } from 'shared/components/MarkdownRenderer';
import { HtmlTooltip } from 'shared/components/Tooltip/HtmlTooltip';

interface IFormLabelMdTooltip extends Omit<StackProps, 'children' | 'title'> {
  title: ReactNode;
  tooltipAnchor?: ReactNode;
  tooltipMarkdown?: string;
  tooltipProps?: TooltipProps;
}

export const FormLabelMdTooltip: FC<IFormLabelMdTooltip> = ({
  title,
  tooltipAnchor,
  tooltipMarkdown,
  tooltipProps,
  ...props
}) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" {...props}>
      <Typography variant="captionC">{title}</Typography>

      {tooltipMarkdown && (
        <HtmlTooltip
          placement="right"
          title={<MarkdownRenderer markdown={tooltipMarkdown} />}
          {...tooltipProps}
        >
          <Stack alignItems="center" justifyContent="center">
            {tooltipAnchor || <IconInfo sx={{ height: 16, width: 16 }} />}
          </Stack>
        </HtmlTooltip>
      )}
    </Stack>
  );
};
