import { Stack, StackProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ILabel extends StackProps {
  label: ReactNode;
  labelEndAdornment?: ReactNode;
}

export const Label: FC<ILabel> = ({ children, label, labelEndAdornment, ...props }) => (
  <Stack alignItems="start" spacing={1} width="100%" {...props}>
    <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
      <Typography variant="captionC">{label}</Typography>
      {labelEndAdornment}
    </Stack>
    {children}
  </Stack>
);
