import { Stack, StackProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IButtonGroupContainer extends StackProps {
  children: ReactNode;
}

/**
 * @description Expects children elements that are buttons with the class .MuiButton-root, etc.
 */
export const ButtonGroupContainer: FC<IButtonGroupContainer> = ({ children, sx, ...props }) => {
  return (
    <Stack
      sx={{
        maxWidth: '100%',
        background: theme => theme.colors.gradients.sheet,
        borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
        ...sx,
      }}
      {...props}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="end"
        sx={theme => ({
          '& > .MuiButton-root:first-of-type': {
            borderLeft: `1px solid ${theme.colors.functional.subject.border}`,
          },
          '& > .MuiButton-root': {
            color: theme.colors.functional.text.primary,
            '&, &:hover': { background: theme.colors.gradients.metal },
            borderRight: `1px solid ${theme.colors.functional.subject.border}`,
          },
        })}
      >
        {children}
      </Stack>
    </Stack>
  );
};
