import { AVS_QUORUMS, AVS_TYPES, strategiesByQuorums } from 'shared/types/avs';
import { AVSDeployment } from 'shared/types/protoc-gen/avs';
import { Address } from 'viem';

export const getQuorumsByStrategies = (strategies: readonly Address[]) => {
  const quorums = new Set<number>();

  strategies?.forEach(cur => {
    for (const quorum in strategiesByQuorums) {
      if (strategiesByQuorums[Number(quorum) as AVS_QUORUMS]?.includes(cur)) {
        quorums.add(Number(quorum));

        break; // only add the first match, skip the rest
      }
    }
  });

  return Array.from(quorums);
};

export const getOnChainQuorums = (offChainQuorums: AVS_QUORUMS[], avs: AVSDeployment) => {
  return offChainQuorums?.map((cur, index) => {
    const foundIndex = avs?.quorumNumbers?.findIndex(item => item === cur);

    return (foundIndex === -1 ? index : foundIndex) as AVS_QUORUMS;
  });
};

export const getOffChainQuorum = (onChainQuorum: AVS_QUORUMS, avs: AVSDeployment) =>
  avs?.quorumNumbers?.[onChainQuorum] as AVS_QUORUMS;

export const avsTypeToSrc: Record<AVS_TYPES, { repoLink?: string; raw?: string }> = {
  [AVS_TYPES.MACH]: {
    raw: 'https://raw.githubusercontent.com/alt-research/mach-avs/m2-dev/contracts/src/core/MachServiceManager.sol',
    repoLink:
      'https://github.com/alt-research/mach-avs/blob/m2-dev/contracts/src/core/MachServiceManager.sol',
  },
  [AVS_TYPES.BRIDGE]: {
    raw: 'https://raw.githubusercontent.com/alt-research/wizard-templates/master/src/templates/BridgeTemplate.sol',
    repoLink:
      'https://github.com/alt-research/wizard-templates/blob/master/src/templates/BridgeTemplate.sol',
  },
  [AVS_TYPES.COPROCESSOR]: {
    raw: 'https://raw.githubusercontent.com/alt-research/wizard-templates/master/src/templates/CoprocessorTemplate.sol',
    repoLink:
      'https://github.com/alt-research/wizard-templates/blob/master/src/templates/CoprocessorTemplate.sol',
  },
  [AVS_TYPES.GENERIC]: {
    raw: 'https://raw.githubusercontent.com/alt-research/wizard-templates/master/src/templates/GenericTemplate.sol',
    repoLink:
      'https://github.com/alt-research/wizard-templates/blob/master/src/templates/GenericTemplate.sol',
  },
  [AVS_TYPES.ECDSA_HELLO_WORLD]: {
    raw: 'https://raw.githubusercontent.com/alt-research/wizard-templates/master/src/templates/HelloWorldTemplate.sol',
    repoLink:
      'https://github.com/alt-research/wizard-templates/blob/master/src/templates/HelloWorldTemplate.sol',
  },
  [AVS_TYPES.CUSTOM_BLS]: {},
  [AVS_TYPES.CUSTOM_ECDSA]: {},
};
