import { Box, Button, Typography } from '@mui/material';
import { AaasLayout } from 'pages/aaas/layout';
import { useMemo } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import TabbedSection from 'shared/components/TabbedSection';
import { useDisclosure } from 'shared/hooks/ui';

import {
  BountyBoardTab,
  CreateTaskDialog,
  DisperseContractDialog,
  UserTaskBoardTab,
} from './components';

export const AaasBounties = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    onClose: onCloseCreateTaskDialog,
    onOpen: onOpenCreateTaskDialog,
    open: isOpenCreateTaskDialog,
  } = useDisclosure({
    defaultOpen: searchParams.get('dialog')?.split(',').includes('create-new-task'),
    onOpen: () => setSearchParams({ dialog: 'create-new-task' }, { replace: true }),
    onClose: () => setSearchParams({}, { replace: true }),
  });
  const {
    onClose: onCloseDisperseContractDialog,
    onOpen: onOpenDisperseContractDialog,
    open: isOpenDisperseContractDialog,
  } = useDisclosure();

  const tabs = useMemo(() => {
    return [
      {
        label: 'Board',
        content: <BountyBoardTab />,
      },
      {
        label: 'My Tasks',
        content: <UserTaskBoardTab />,
      },
    ];
  }, []);

  return (
    <AaasLayout>
      <CreateTaskDialog
        onClose={onCloseCreateTaskDialog}
        onValid={formValues => {
          console.log('formValues', formValues);

          onCloseCreateTaskDialog();
          setTimeout(() => {
            onOpenDisperseContractDialog();
          }, 750);
        }}
        open={isOpenCreateTaskDialog}
      />
      <DisperseContractDialog
        onClose={onCloseDisperseContractDialog}
        open={isOpenDisperseContractDialog}
      />

      <Box px={{ lg: 5, xs: 2, sm: 3, background: '#fafafa' }} py={5}>
        <TabbedSection
          endAdornment={
            <Button
              onClick={onOpenCreateTaskDialog}
              sx={{ '&, &:hover': { borderColor: theme => theme.colors.functional.text.primary } }}
              variant="outlined"
            >
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: 2,
                }}
                variant="bodySmallC"
              >
                <IoAddCircleSharp size={30} />
                Create New Task
              </Typography>
            </Button>
          }
          tabs={tabs}
        />
      </Box>
    </AaasLayout>
  );
};
