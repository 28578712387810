import {
  IconButton,
  Skeleton,
  Stack,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FC } from 'react';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { useCopyToClipboard } from 'shared/hooks/ui';
import { truncate as textTruncate } from 'shared/utils/strings';

export interface IStackRowFieldValue {
  field: string;
  value: string;
  copyable?: boolean;
  truncate?: boolean;
  showTooltip?: boolean;
  href?: string;
  textTruncateFrontChars?: number;
  textTruncateBackChars?: number;
  valueTypographyProps?: TypographyProps;
  tooltipProps?: TooltipProps;
  loading?: boolean;
}

export const StackRowFieldValue = ({
  copyable,
  field,
  href,
  loading,
  showTooltip,
  textTruncateBackChars,
  textTruncateFrontChars,
  tooltipProps,
  truncate,
  value,
  valueTypographyProps,
}: IStackRowFieldValue) => {
  const [, copy] = useCopyToClipboard();

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography variant="bodySmall" width="100%">
        {field}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="start"
        spacing="8px"
        sx={{
          width: '100%',
        }}
        textAlign="start"
      >
        {loading ? (
          <Skeleton variant="text" width="35%" />
        ) : (
          <>
            <ConditionalTooltip enabled={showTooltip} title={value} {...tooltipProps}>
              <TypographyValue
                href={href}
                textTruncateBackChars={textTruncateBackChars}
                textTruncateFrontChars={textTruncateFrontChars}
                truncate={truncate}
                value={value}
                {...valueTypographyProps}
              />
            </ConditionalTooltip>

            {copyable && (
              <IconButton onClick={() => copy(value)} size="small">
                <IconCopy
                  sx={theme => ({
                    height: '20px',
                    width: '20px',
                    color: theme.colors.functional.container.primary,
                  })}
                />
              </IconButton>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

const TypographyValue: FC<
  Pick<
    IStackRowFieldValue,
    'href' | 'truncate' | 'textTruncateBackChars' | 'textTruncateFrontChars' | 'value'
  > &
    TypographyProps
> = ({ href, textTruncateBackChars, textTruncateFrontChars, truncate, value, ...props }) => (
  <Typography
    {...(href && {
      component: 'a',
      target: '_blank',
      rel: 'noreferrer',
      href,
      sx: {
        color: theme => theme.colors.functional.text.link,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    })}
    {...props}
    variant="bodySmall"
  >
    {truncate ? textTruncate(value, '....', textTruncateFrontChars, textTruncateBackChars) : value}
  </Typography>
);
