import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const IconCircleMinus: FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" fill="#202723" r="12" />
    <path
      clipRule="evenodd"
      d="M16.0417 12.2473H7.95752V11.2473H16.0417V12.2473Z"
      fill="white"
      fillRule="evenodd"
    />
  </SvgIcon>
);
