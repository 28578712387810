import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { QUERY_KEYS } from 'shared/types/react-query';
import { Address } from 'viem';

export enum TokenListVariant {
  MAINNET = 'mainnet',
  HOLESKY = 'holesky',
  SOLANA_MAINNET = 'solana-mainnet',
}

export interface Token<T = Address> {
  address: T;
  symbol: string;
  decimals: number;
  logoURI: string;
  balance?: bigint; // This is optional, by default it's empty
}

export const useAppTokens = <T = Address>(variant: TokenListVariant) => {
  const isValidVariant = Object.values(TokenListVariant).includes(variant);

  return useQuery({
    queryKey: [QUERY_KEYS.GET_APP_TOKENS, { fileName: variant }],
    queryFn: async () => {
      const res = await axios.get<Token<T>[]>(`${process.env.PUBLIC_URL}/tokens/${variant}.json`, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });

      return res.data;
    },
    enabled: isValidVariant,
  });
};
