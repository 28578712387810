import { useRaasDeployment } from 'pages/raas/hooks';

import { OutputRootsSection } from './OutputRootsSection';
import { ProposerBalanceSection } from './ProposerBalanceSection';

export const ProposerView = () => {
  const { data: rollup } = useRaasDeployment();

  return (
    <>
      <ProposerBalanceSection />
      {!['xterio-', 'cyber-'].some(name => rollup?.name.includes(name)) && <OutputRootsSection />}
    </>
  );
};
