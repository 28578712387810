import { Button, Stack, Typography, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import { IconExternalLink } from 'shared/components/icons/IconExternalLink';
import { Section } from 'shared/components/Section';

export const StartFullnodeView = () => {
  const theme = useTheme();

  return (
    <Section
      renderContent={
        <>
          <Row step="1. Generate the .env by clicking the button below:">
            <Stack direction="row" spacing="24px">
              <Button variant="contained">Generate</Button>
            </Stack>
          </Row>

          <Row step="2. Follow this guide to launch the fullnode:">
            <a href="https://wizard.altlayer.io" rel="noreferrer" target="_blank">
              <Stack direction="row" spacing="8px" sx={{ cursor: 'pointer' }}>
                <Typography
                  sx={{ color: theme.colors.functional.text.primary, textDecoration: 'underline' }}
                  variant="bodySmallM"
                >
                  OP Stack Fullnode Synchronization Guide
                </Typography>
                <IconExternalLink sx={{ height: '20px', width: '20px' }} />
              </Stack>
            </a>
          </Row>
        </>
      }
      subHeaderSx={{ height: '80px' }}
      title="How to launch my own fullnode?"
      titleTypographyVariant="h5"
    />
  );
};

export const Row = ({ children, step }: { step: string; children: ReactElement }) => {
  return (
    <Stack
      direction="column"
      spacing="24px"
      sx={{
        background: '#fff',
        px: '40px',
        py: '24px',
        borderBottom: '1px solid rgba(32, 39, 35, 0.08)',
      }}
    >
      <Typography variant="bodySmall">{step}</Typography>
      {children}
    </Stack>
  );
};
