import { AaasLayout } from 'pages/aaas/layout';
import { Outlet, useNavigate } from 'react-router-dom';
import { Section } from 'shared/components';

export const AgentCredits = () => {
  const navigate = useNavigate();

  return (
    <AaasLayout
      pageBannerProps={{
        onClickBack: () => navigate(-1),
      }}
    >
      <Section sx={{ background: 'none' }} title="Agent Credits">
        <Outlet />
      </Section>
    </AaasLayout>
  );
};
