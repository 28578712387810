import { Box, StackProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export enum AgentCarouselTags {
  FEATURED = 'featured',
  COMMUNITY = 'community',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  REJECTED = 'rejected',
  PENDING_APPROVAL = 'pendingApproval',
  UNRECOGNIZED = 'unrecognized',
}

export default function CarouselCardTag({ children, sx, ...props }: PropsWithChildren<StackProps>) {
  return (
    <Box
      sx={{
        py: '5px',
        px: '16px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export const tagMap = {
  [AgentCarouselTags.FEATURED]: (
    <CarouselCardTag sx={{ background: theme => theme.colors.gradients.cream }}>
      Featured
    </CarouselCardTag>
  ),
  [AgentCarouselTags.COMMUNITY]: (
    <CarouselCardTag sx={{ background: theme => theme.colors.gradients.metal }}>
      Community
    </CarouselCardTag>
  ),
  [AgentCarouselTags.PUBLISHED]: (
    <CarouselCardTag sx={{ background: '#66B489' }}>Published</CarouselCardTag>
  ),
  [AgentCarouselTags.UNPUBLISHED]: (
    <CarouselCardTag sx={{ background: theme => theme.colors.gradients.metal }}>
      Unpublished
    </CarouselCardTag>
  ),
  [AgentCarouselTags.REJECTED]: (
    <CarouselCardTag sx={{ background: '#F1605F' }}>Rejected</CarouselCardTag>
  ),
  [AgentCarouselTags.PENDING_APPROVAL]: (
    <CarouselCardTag sx={{ background: theme => theme.colors.gradients.water }}>
      Pending Approval
    </CarouselCardTag>
  ),
  [AgentCarouselTags.UNRECOGNIZED]: null,
};
