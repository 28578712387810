import { Box, Button, IconButton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { Section } from 'shared/components';
import { IconCopy } from 'shared/components/icons/IconCopy';

import WithdrawalSubSectionHeader from './WithdrawalSubSectionHeader';

interface IOverviewItem {
  balance: string;
  variant: FeeCollections;
  l1FeeVault?: string;
  baseFeeVault?: string;
  sequencerFeeVault?: string;
  keys?: Array<string>;
  sx?: SxProps<Theme>;
}

interface IFeeVaultRow {
  title: string;
  value: string;
}

interface IFeeReceiptKey {
  address: string;
}

enum FeeCollections {
  Vault = 'Vault',
  Receipt = 'Receipt',
}

// TODO: Just skeleton UI -> Update to BE data later

const FeeCollectionSection: React.FC = () => {
  return (
    <Section title="Fee Collection">
      <WithdrawalSubSectionHeader title="Overview" />

      <Stack direction="row" width="100%">
        <OverviewItem
          balance="10.23 ETH"
          baseFeeVault="3 ETH"
          l1FeeVault="5.23 ETH"
          sequencerFeeVault="2 ETH"
          sx={{ p: '24px 40px' }}
          variant={FeeCollections.Vault}
        />
        <OverviewItem
          balance="0.2343 ETH"
          sx={{
            p: '24px 24px',
            borderLeft: theme => `1px solid ${theme.colors.functional.subject.border}`,
          }}
          variant={FeeCollections.Receipt}
        />
      </Stack>

      <WithdrawalSubSectionHeader title="Manual Collection" />

      <Stack spacing="24px" sx={{ p: '24px 40px' }} width="100%">
        <Stack spacing="16px" width="100%">
          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">From</Typography>
            </Box>

            <Stack direction="row" spacing={1} width="100%">
              <Typography
                sx={{
                  color: theme => theme.colors.functional.text.link,
                  textDecoration: 'underline',
                }}
              >
                0x34c7C......F295d1
              </Typography>
              <Typography variant="captionC">L1 FEE VAULT</Typography>
            </Stack>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">To</Typography>
            </Box>

            <Stack direction="row" spacing={1} width="100%">
              <Typography
                sx={{
                  color: theme => theme.colors.functional.text.link,
                  textDecoration: 'underline',
                }}
              >
                0x34c7C......F295d1
              </Typography>
              <Typography variant="captionC">FEE RECEIPT</Typography>
            </Stack>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">Collection amount</Typography>
            </Box>

            <Typography variant="bodySmallM" width="100%">
              15.23 ETH
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">Minimum withdrawal balance</Typography>
            </Box>

            <Typography variant="bodySmallM" width="100%">
              10 ETH
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">Target Network</Typography>
            </Box>

            <Typography variant="bodySmallM" width="100%">
              Sepolia
            </Typography>
          </Stack>
        </Stack>

        <Button
          sx={{
            width: '152px',
            backgroundColor: theme => theme.colors.schema.leafPrimary,
            '&:hover': {
              backgroundColor: theme => theme.colors.schema.leafPrimary,
            },
          }}
        >
          <Typography
            fontSize="14px"
            sx={{ color: theme => theme.colors.functional.text.primary }}
            variant="bodySmall"
          >
            Withdraw Now
          </Typography>
        </Button>
      </Stack>

      <WithdrawalSubSectionHeader title="Auto Collection" />

      <Box sx={{ p: '24px 40px' }}>
        <Stack>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography sx={{ display: 'inline' }} variant="bodySmall">
              Withdraw on the{' '}
              <Typography
                fontSize="15px"
                fontWeight="500"
                lineHeight="20px"
                sx={{
                  display: 'inline',
                  textDecoration: 'underline',
                  color: theme => theme.colors.functional.text.link,
                }}
              >
                15th
              </Typography>{' '}
              of each month
            </Typography>

            <Typography variant="bodySmallC">EDIT</Typography>
          </Stack>
        </Stack>
      </Box>
    </Section>
  );
};

const OverviewItem: React.FC<IOverviewItem> = ({
  balance,
  baseFeeVault,
  l1FeeVault,
  sequencerFeeVault,
  sx,
  variant,
}) => {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Stack direction="column" spacing="24px">
        <Typography sx={{ fontWeight: 500, fontSize: '15px', lineHeight: '20px' }}>
          {variant === FeeCollections.Receipt ? 'Fee Receipt' : 'Fee Vault'}
        </Typography>

        <Stack>
          <Stack spacing="8px">
            <Typography variant="caption">Total balance</Typography>
            <Typography variant="h5">{balance}</Typography>
          </Stack>
        </Stack>

        <Box>
          {variant === FeeCollections.Vault ? (
            <Stack spacing="16px" sx={{ width: '273px' }}>
              <FeeVaultRow title="L1 Fee Vault" value={l1FeeVault ?? '0 ETH'} />
              <FeeVaultRow title="Base Fee Vault" value={baseFeeVault ?? '0 ETH'} />
              <FeeVaultRow title="Sequencer Fee Vault" value={sequencerFeeVault ?? '0 ETH'} />
            </Stack>
          ) : (
            <Stack direction="row" spacing="24px">
              <Typography variant="caption">Keys</Typography>

              <Stack spacing="9px">
                <FeeReceiptKey address="0x35CF415F7D9a50237ae580Fc0591960b5a5a3E6F" />
                <FeeReceiptKey address="0xb5b4BFB38DBa030D29E65f4D28F59C628B744706" />
                <FeeReceiptKey address="0xb5b4BFB38DBa030D29E65f4D28F59C628B744706" />
                <FeeReceiptKey address="0xBA9DEbfc80D83994ef00D2a92e063DC1ca239826" />
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

const FeeVaultRow: React.FC<IFeeVaultRow> = ({ title, value }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="caption">{title}</Typography>
      <Typography variant="captionM">{value}</Typography>
    </Stack>
  );
};

const FeeReceiptKey: React.FC<IFeeReceiptKey> = ({ address }) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1} sx={{ height: '18px' }}>
      <Typography sx={{ color: '#5C8CE1' }} variant="captionM">
        {address}
      </Typography>
      <IconButton size="small">
        <IconCopy
          sx={theme => ({
            height: '18px',
            width: '18px',
            color: theme.colors.functional.container.primary,
          })}
        />
      </IconButton>
    </Stack>
  );
};

export default FeeCollectionSection;
