import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconAye({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ height: '18px', width: '18px', color: '#202723', ...sx }}
      {...props}
      viewBox="0 0 18 18"
    >
      <circle cx="9" cy="8.5" fill="currentColor" r="8" stroke="currentColor" />
      <path d="M4.59961 8.79999L7.65335 11.8537L13.7232 5.78384" stroke="white" />
    </SvgIcon>
  );
}
