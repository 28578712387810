import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

export interface PageBannerTabItem {
  label: string;
  onClick: () => void;
  endAdornment?: ReactNode;
  disabled?: boolean;
}

export interface ITabNavigation {
  activeIndex?: number;
  activeColor: string;
  items?: Array<PageBannerTabItem>;
}

export const TabNavigation: FC<ITabNavigation> = ({ activeColor, activeIndex, items }) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    sx={{
      background: theme => theme.colors.gradients.metal,
      '& > .MuiButton-root': {
        minHeight: { md: '74px', sm: '56px' },
        px: { md: 5, sm: 3, xs: 2 },
      },
    }}
  >
    {items?.map(({ disabled, endAdornment, label, onClick }, index) => (
      <TabItem
        disabled={disabled}
        endAdornment={endAdornment}
        key={label}
        onClick={onClick}
        sx={{
          '&, &:hover': {
            background: theme =>
              index === activeIndex ? activeColor : theme.colors.gradients.metal,
          },
        }}
      >
        {label}
      </TabItem>
    ))}
  </Stack>
);

const TabItem: FC<ButtonProps & { endAdornment: ReactNode }> = ({
  children,
  disabled,
  endAdornment,
  ...props
}) => (
  <Button disabled={disabled} fullWidth {...props}>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="start"
      spacing={2}
      sx={{
        width: '100%',
      }}
    >
      <Typography
        sx={{
          color: theme => theme.colors.functional.text.primary,
        }}
        variant="bodyMediumC"
      >
        {children}
      </Typography>

      {endAdornment}
    </Stack>
  </Button>
);
