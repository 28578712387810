import { Link as MuiLink, LinkProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

export default function HeaderLink({
  children,
  external,
  href,
  ...props
}: PropsWithChildren<LinkProps & { external?: boolean }>) {
  return (
    <MuiLink
      {...(external
        ? { href, rel: 'noopener noreferrer', target: '_blank' }
        : { component: Link, to: href })}
      sx={{
        background: theme => theme.colors.gradients.sheet,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        px: 5,
        '&:hover': {
          textDecoration: 'underline',
          background: theme => theme.colors.gradients.metal,
        },
      }}
      {...props}
    >
      {children}
    </MuiLink>
  );
}
