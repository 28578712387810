import { create } from 'zustand';

interface State {
  envVars: Record<string, string>;
}

interface Action {
  setEnvVar: (key: string, value: string) => void;
  setEnvVars: (envVars: Record<string, string>) => void;
  reset: () => void;
}

const initialState: State = {
  envVars: {},
};

export const useEnvVarsStore = create<State & Action>(set => ({
  setEnvVar: (key, value) => set(state => ({ ...state, [key]: value })),
  setEnvVars: envVars => set(state => ({ ...state, ...envVars })),
  reset: () => set(initialState),
  ...initialState,
}));
