import { useMutation } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useApp } from 'pages/aaas/AaasDetails/hooks';
import { useMemo } from 'react';
import { bff } from 'shared/api';

export const useTweet = () => {
  const { data: app } = useApp();
  const { addAlert } = useAlerts();

  const { isPending: isTweeting, mutate: tweet } = useMutation({
    mutationFn: async () => {
      const res = await bff.post('/bff/aaa/tweet', { appId: app?.id });

      return res?.data;
    },
    onSuccess: () => {
      addAlert({ title: 'Tweet successful', severity: ALERT_SEVERITY.SUCCESS });
    },
  });

  const { isPending: isPromptTweeting, mutate: promptTweet } = useMutation({
    mutationFn: async (prompt: string) => {
      const res = await bff.post('/bff/aaa/tweet', { appId: app?.id, customPrompt: prompt });

      return res?.data;
    },
    onSuccess: () => {
      addAlert({ title: 'Tweet successful', severity: ALERT_SEVERITY.SUCCESS });
    },
  });

  return useMemo(
    () => ({
      tweet,
      promptTweet,
      isTweeting,
      isPromptTweeting,
    }),
    [isPromptTweeting, isTweeting, promptTweet, tweet],
  );
};
