import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const theme = useTheme();

  return {
    xs: useMediaQuery(theme.breakpoints.down('sm')),
    sm: useMediaQuery(theme.breakpoints.down('md')),
    md: useMediaQuery(theme.breakpoints.up('sm')),
    lg: useMediaQuery(theme.breakpoints.up('md')),
    xl: useMediaQuery(theme.breakpoints.up('lg')),
  };
};
