import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { AAAS_PATHS } from 'pages/aaas/routes';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ComingSoonTooltip from 'shared/components/ComingSoonTooltip';
import { IconArrowRight } from 'shared/components/icons/IconArrowRight';

export const PurchaseCreditsButton: FC<{ label?: string } & Omit<ButtonProps, 'children'>> = ({
  label = 'Upgrade',
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <ComingSoonTooltip anchorSx={{ width: '100%' }} show={!showExperimentalFeatures}>
      <Button
        fullWidth
        onClick={() => navigate(AAAS_PATHS.AAAS_AGENT_CREDITS_PURCHASE)}
        sx={{
          '&, &:hover': {
            border: theme => `1px solid ${theme.colors.functional.text.primary}`,
          },
        }}
        variant="outlined"
        {...props}
      >
        <Stack alignItems="center" direction="row" justifyContent="center" spacing={2}>
          <Typography variant="bodySmallC">{label}</Typography>
          <IconArrowRight height={24} width={24} />
        </Stack>
      </Button>
    </ComingSoonTooltip>
  );
};
