import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconEllipsis({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="24"
      sx={{ height: '24px', width: '24px', ...sx }}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6 11.75C6 12.7165 5.2165 13.5 4.25 13.5C3.2835 13.5 2.5 12.7165 2.5 11.75C2.5 10.7835 3.2835 10 4.25 10C5.2165 10 6 10.7835 6 11.75ZM13.5 11.75C13.5 12.7165 12.7165 13.5 11.75 13.5C10.7835 13.5 10 12.7165 10 11.75C10 10.7835 10.7835 10 11.75 10C12.7165 10 13.5 10.7835 13.5 11.75ZM19.25 13.5C20.2165 13.5 21 12.7165 21 11.75C21 10.7835 20.2165 10 19.25 10C18.2835 10 17.5 10.7835 17.5 11.75C17.5 12.7165 18.2835 13.5 19.25 13.5Z"
        fill="#202723"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
