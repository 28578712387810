import { Stack, StackProps } from '@mui/material';

export const DialogContainer: React.FC<StackProps> = ({ children, ...props }) => (
  <Stack
    spacing="40px"
    {...props}
    sx={{
      minWidth: { md: '504px', sm: undefined, xs: undefined },
      p: { md: 5, sm: 3, xs: 3 },
      ...props.sx,
    }}
  >
    {children}
  </Stack>
);
