import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FC, useState } from 'react';

export const PasswordTextField: FC<TextFieldProps> = ({
  InputProps,
  minRows,
  multiline,
  ...props
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const onToggle = () => setVisible(!visible);

  const showTextArea = multiline && minRows && visible;

  return (
    <TextField
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={onToggle} size="small">
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      minRows={showTextArea ? minRows : undefined}
      multiline={showTextArea ? multiline : undefined}
      type={visible ? 'text' : 'password'}
      {...props}
    />
  );
};
