import { Box, BoxProps, SxProps, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

interface IComingSoonTooltip {
  children: JSX.Element;
  show?: boolean;
  anchorSx?: SxProps;
  anchorWrapperSx?: SxProps;
  anchorWrapperProps?: Partial<BoxProps>;
  anchorProps?: Partial<BoxProps>;
  tooltipProps?: Partial<TooltipProps>;
}

const ComingSoonTooltip: FC<IComingSoonTooltip> = ({
  anchorProps,
  anchorSx,
  anchorWrapperProps,
  anchorWrapperSx,
  children,
  show = true,
  tooltipProps,
}) =>
  show ? (
    <Tooltip arrow title="Coming Soon!" {...tooltipProps}>
      <Box
        sx={{
          '&': {
            cursor: 'not-allowed',
            opacity: theme => theme.palette.action.disabledOpacity,
          },
          display: 'block',
          ...anchorWrapperSx,
        }}
        {...anchorWrapperProps}
      >
        <Box sx={{ pointerEvents: 'none', ...anchorSx }} {...anchorProps}>
          {children}
        </Box>
      </Box>
    </Tooltip>
  ) : (
    children
  );

export default ComingSoonTooltip;
