import {
  Button,
  ButtonProps,
  CircularProgress,
  darken,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';

interface IAgentPersonaButton extends ButtonProps {
  title: string;
  description: string | string[];
  selected?: boolean;
  loading?: boolean;
}

export const AgentPersonaButton: FC<IAgentPersonaButton> = ({
  description,
  loading,
  selected,
  title,
  ...props
}) => {
  return (
    <Button
      disabled={loading}
      fullWidth
      sx={{
        minHeight: 150,
        minWidth: 250,
        p: 0,
        background: '#fff',
        border: theme => `1px solid ${theme.colors.functional.subject.border}`,
        color: theme => theme.colors.functional.text.primary,
        '&:hover': {
          background: theme => theme.colors.schema.metalPrimary,
          border: theme => `1px solid ${theme.colors.functional.subject.borderActive}`,
        },
        '&,&:hover': {
          border: theme =>
            selected ? `1px solid ${theme.colors.functional.text.link}` : undefined,
        },
      }}
      {...props}
    >
      <Stack
        alignItems="start"
        direction="row"
        justifyContent="start"
        spacing={5}
        sx={{
          p: 3,
          height: '100%',
        }}
      >
        <Stack alignItems="start" justifyContent="start" spacing={1}>
          <Typography variant="bodySmallM">{title}</Typography>
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              overflow: 'hidden',
              color: theme =>
                !loading ? darken(theme.colors.functional.text.third, 0.05) : undefined,
            }}
            textAlign="justify"
            variant="caption"
          >
            {Array.isArray(description) ? description.join('\n\n') : description}
          </Typography>
        </Stack>

        <Stack>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Radio
              checked={selected}
              sx={theme => ({
                color: theme.palette.text.primary,
                '&.Mui-checked': {
                  color: theme.colors.functional.text.link,
                },
              })}
            />
          )}
        </Stack>
      </Stack>
    </Button>
  );
};
