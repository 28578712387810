import { Grid, GridProps, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { useCopyToClipboard } from 'shared/hooks/ui';

export interface ISectionContentRow extends Omit<GridProps, 'children'> {
  label: string;
  value: string;
  item?: boolean;
  renderContent?: ReactNode;
  renderStart?: ReactNode;
  renderEnd?: ReactNode;
  onClick?: () => void;
  clipboard?: boolean;
  hyperlink?: boolean;
  loading?: boolean;
}

export const SectionContentRow = ({
  clipboard,
  hyperlink,
  item,
  label,
  loading,
  onClick,
  renderContent,
  renderEnd,
  renderStart,
  sx,
  value,
  ...props
}: ISectionContentRow) => {
  const [, copy] = useCopyToClipboard();

  const handleCopyToClipboard = useCallback(() => copy(value ?? ''), [copy, value]);

  return (
    value && (
      <Grid
        container
        flexDirection="row"
        item={item}
        sx={{
          cursor: 'pointer',
          width: '100%',
          py: '24px',
          px: '40px',
          borderBottom: '1px solid rgba(32, 39, 35, 0.08)',
          ...sx,
        }}
        {...props}
        onClick={onClick}
      >
        <Grid xs={4}>
          {loading ? (
            <Skeleton variant="text" width="35%" />
          ) : (
            renderStart || <Typography variant="bodySmall">{label}</Typography>
          )}
        </Grid>
        <Grid xs={8}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            {loading ? (
              <Skeleton variant="text" width="40%" />
            ) : (
              renderContent || (
                <Typography
                  {...(hyperlink ? { component: 'a', href: value, target: '_blank' } : undefined)}
                  onClick={clipboard && !hyperlink ? handleCopyToClipboard : undefined}
                  sx={theme => ({
                    cursor: clipboard ? 'pointer' : 'text',
                    color: hyperlink
                      ? theme.colors.functional.text.link
                      : theme.colors.functional.text.primary,
                  })}
                  variant="bodySmallM"
                >
                  {value}
                </Typography>
              )
            )}
            {loading ? (
              <Skeleton height="20px" variant="rectangular" width="20px" />
            ) : (
              renderEnd ||
              (clipboard && (
                <IconButton onClick={handleCopyToClipboard} size="small">
                  <IconCopy sx={{ height: '20px', width: '20px' }} />
                </IconButton>
              ))
            )}
          </Stack>
        </Grid>
      </Grid>
    )
  );
};
