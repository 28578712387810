import { debounce } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { axiosAuth } from 'shared/api';

export default function useValidateOrgName() {
  const {
    data,
    error,
    isPending,
    mutate: validateOrgName,
    reset,
  } = useMutation({
    mutationFn: async (orgName: string) => {
      const res = await axiosAuth.get<{ valid: boolean }>(`/org/validate/${orgName}`);

      return res?.data?.valid;
    },
    onError: (err: AxiosError<{ message: string }>) => {
      console.error(err?.response?.data?.message);
    },
  });

  const debouncedValidateOrgName = useMemo(() => debounce(validateOrgName, 500), [validateOrgName]);

  return useMemo(
    () => ({
      error,
      reset,
      validateOrgName: debouncedValidateOrgName,
      isPending,
      data,
    }),
    [error, reset, debouncedValidateOrgName, isPending, data],
  );
}
