import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';

import { DepositsSection } from './DepositsSection';
import { GeneralSection } from './GeneralSection';
import { WithdrawalsSection } from './WithdrawalsSection';

export const BridgeMetricsView: React.FC = () => {
  return (
    <>
      {showExperimentalFeatures && <GeneralSection />}
      <DepositsSection />
      <WithdrawalsSection />
    </>
  );
};
