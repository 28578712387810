import { Grid, Stack } from '@mui/material';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { RpmByMethodsMetric, RpmCountMetric, RpmMetric } from './metrics';

export const RpcUsageMonitoringSection = () => {
  return (
    <Section title="RPC Usage Monitoring">
      <Stack spacing="24px" sx={{ width: '100%' }}>
        <Grid container>
          {
            // TODO: remove showExperimentalFeatures when backend is ready for integration
            showExperimentalFeatures && (
              <Grid xs={6}>
                <RpmCountMetric />
              </Grid>
            )
          }

          <MetricGridItem item xs={6}>
            <RpmMetric />
          </MetricGridItem>

          <MetricGridItem item xs={6}>
            <RpmByMethodsMetric />
          </MetricGridItem>
        </Grid>
      </Stack>
    </Section>
  );
};
