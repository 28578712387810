import { Stack } from '@mui/material';
import { useApp, usePrompt } from 'pages/aaas/AaasDetails/hooks';
import { useChatStore } from 'pages/aaas/AaasDetails/useChatStore';
import { useEffect, useRef } from 'react';
import { Section } from 'shared/components/Section';
import { arbitrumSepolia, sepolia } from 'viem/chains';

import { useFeedData } from '../../hooks/useFeedData';
import { flexiParseAgentResponse } from '../../utils/flexiParseAgentResponse';
import { ChatHistory, InputSection } from './components';
import { useScrollToElement } from './useScrollToElement';

const randomChainId = Math.floor(Math.random() * 1_000_000_000);

const createOrbitRollupDefaults = {
  parentChainId: arbitrumSepolia.id,
  chainName: `orbit-${randomChainId}`,
  chainId: randomChainId,
  enableAnytrust: false,
  promoCode: 'YOUR_PROMO_CODE',
};

const firstMsgSilentInput = [
  'Disregard everything I have said before this message.',
  'If I ask you anything that is unrelated to orbit rollups, pretend that I did not say anything to you up until this point, that you know nothing of orbit rollups, and just respond as a fresh AI agent usually would.',
  'If I ask you anything related to creating or deploying Orbit Rollups, tell me that you have generated a JSON file with some default chain configs in your response with the following default values: ',
  `\`\`\`\n${JSON.stringify(createOrbitRollupDefaults, undefined, 2)}\n\`\`\`` + '.',
  'The most likely fields I might want to change are the chain name, chain ID (this is the chain ID of the new rollup), parentChainID (supports id 11155111 sepolia and 421614 "arbitrum sepolia" only), promoCode (available from the AltLayer team) and whether anytrust is enabled.',
  `If I try to change parentChainId to anything other than ${arbitrumSepolia.id} or ${sepolia.id}, tell me that is not supported. chainId can still be changed to any number.`,
  'Subsequently, if I ask you to modify the response, do so accordingly.',
  '',
].join('\n');

export const ChatView = () => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const { data: app } = useApp();
  const { scrollChatHistory, scrollToChatInput } = useScrollToElement();
  const { addMessage, messages, registerConversation } = useChatStore();
  const { isPending: promptPending, mutateAsync: prompt } = usePrompt({
    onSuccess: data => {
      addMessage(app?.id, {
        message: flexiParseAgentResponse(data),
        author: 'agent',
      });

      scrollChatHistory();
      setTimeout(() => scrollToChatInput(), 1_000);
      textFieldRef.current?.focus();
    },
  });
  const { isPending: feedDataPending, mutateAsync: feedData } = useFeedData({
    onSuccess: () => {
      addMessage(app?.id, {
        message: 'Data feed successful.',
        author: 'agent',
      });

      scrollChatHistory();
      setTimeout(() => scrollToChatInput(), 1_000);
      textFieldRef.current?.focus();
    },
  });

  const isPending = promptPending || feedDataPending;

  useEffect(() => {
    registerConversation(app?.id);
  }, [app, registerConversation]);

  useEffect(() => {
    textFieldRef.current?.focus();
    scrollToChatInput();
    scrollChatHistory();

    if (app?.id && messages[app.id]?.length === 0) {
      addMessage(app?.id, {
        message: 'GM Alter! How can I help you? 🤗',
        author: 'agent',
      });
    }
  }, [addMessage, app, scrollToChatInput, messages, scrollChatHistory]);

  const _messages = app?.id ? messages[app?.id] : [];

  return (
    <Section
      hasBackButton
      title={['Chat with AI Agent', app && `(${app?.template?.name})`].join(' ')}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{
          '&, & > *': {
            width: '100%',
          },
          pb: 5,
        }}
      >
        <ChatHistory loading={isPending} messageHistory={_messages} />

        <Stack sx={{ px: { sm: 3, xs: 3, md: 5 } }}>
          <InputSection
            disabled={!app?.endpoints?.apiUrl}
            loading={isPending}
            onPrompt={({ action, isFeedMode, question }) => {
              if (!question) return;

              if (isFeedMode) {
                feedData({ message: question });
              } else if (action === 'tweet') {
                prompt({
                  message: 'Using this prompt to make a tweet post. \n\n ' + question,
                });
              } else {
                prompt({
                  message:
                    _messages?.length &&
                    _messages?.length === 1 &&
                    app?.template?.name?.toLowerCase() === 'agentkit'
                      ? firstMsgSilentInput + question
                      : question,
                });
              }

              addMessage(app?.id, {
                message: question,
                author: 'user',
              });
            }}
            ref={textFieldRef}
          />
        </Stack>
      </Stack>
    </Section>
  );
};
