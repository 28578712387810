import { Button, Stack, Typography } from '@mui/material';
import { templateNamesToIconsMap } from 'pages/aaas/mappings';
import ImgWithFallback from 'shared/components/ImgWithFallback';
import {
  AAAApp,
  AAATemplateCategory,
  aAATemplateCategoryToJSON,
} from 'shared/types/protoc-gen/bffaaa';

export interface IAgentCard {
  app: AAAApp;
  startAdornment?: React.ReactNode;
  onClick?: () => void;
}

export const AgentCard: React.FC<IAgentCard> = ({ app, onClick, startAdornment }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        width: '100%',
        height: 'auto',
        px: '40px',
        py: '24px',
        background: theme => theme.colors.gradients.sheet,
        color: theme => theme.colors.functional.text.primary,
        '&:hover': { background: '#fff' },
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="start" spacing="16px" width="100%">
        {startAdornment ||
          (app?.template?.id && (
            <ImgWithFallback
              src={
                String(app.template?.category) ===
                aAATemplateCategoryToJSON(AAATemplateCategory.AAA_TEMPLATE_TYPE_BUILTIN)
                  ? templateNamesToIconsMap[app?.template?.id]
                  : app?.template?.iconLink
              }
              sx={{ height: '48px', width: '48px' }}
            />
          ))}

        <Stack alignItems="start">
          <Typography variant="body2">{app.name}</Typography>

          <Typography variant="caption">{app?.template?.name}</Typography>
        </Stack>
      </Stack>
    </Button>
  );
};
