import { Box, CircularProgress, Link, Stack, Tooltip, Typography } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useListFeaturedTemplates } from 'pages/aaas/hooks/useListFeaturedTemplates';
import { templateNamesToIconsMap } from 'pages/aaas/mappings';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function AutonomeLogin({
  handleGoogleLoginSuccess,
}: PropsWithChildren & {
  handleGoogleLoginSuccess: (credentialResponse: CredentialResponse) => void;
}) {
  const { data, isPending } = useListFeaturedTemplates();

  const featuredTemplates = data?.map(cur => ({
    ...cur,
    iconLink: cur?.iconLink || templateNamesToIconsMap?.[cur?.name],
  }));

  return (
    <Stack>
      <Stack
        sx={{
          background: {
            xs: 'url(/autonome-login/welcome-bg-mobile.png) no-repeat',
            sm: 'url(/autonome-login/welcome-bg.png) no-repeat',
          },
          '&': { backgroundSize: { md: 'contain', xs: 'cover' } },
          width: '100%',
          aspectRatio: { sm: '8 / 3', xs: '36 / 50' },
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: { xl: 12, lg: 8, xs: 4 },
          py: 4,
        }}
      >
        {isPending ? (
          <CircularProgress size={50} />
        ) : (
          <>
            <Stack>
              <Typography textAlign="center" variant="h3">
                Welcome to
              </Typography>
              <Typography
                sx={{ '&': { fontSize: '64px', fontWeight: 500 } }}
                textAlign="center"
                variant="h1"
              >
                Autonome
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={{ md: 8, xs: 4 }}>
              {featuredTemplates?.map(cur => (
                <Tooltip key={cur?.id} title={cur?.name}>
                  <Box
                    component="img"
                    key={cur?.id}
                    src={cur?.iconLink}
                    sx={{ width: 64, height: 64 }}
                  />
                </Tooltip>
              ))}
            </Stack>
          </>
        )}
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <Box
          sx={{
            width: { xs: '100%', md: 'max-content' },
            p: { xs: 2, md: 0 },
            mt: 8,
            "& div[role='button']": {
              px: 8,
              py: 4,
              borderRadius: 0,
            },
            "& div[role='button'] > div:nth-of-type(2) > div": {
              background: '#202124',
            },
            "& div[role='button']:hover > div:nth-of-type(2) > div , & div[role='button']:focus > div:nth-of-type(2) > div":
              {
                background: 'rgba(32, 33, 36, 0.02)',
              },
          }}
        >
          <GoogleLogin
            onError={() => {
              console.error('Login Failed');
            }}
            onSuccess={handleGoogleLoginSuccess}
            shape="pill"
            theme="filled_black"
          />
        </Box>
        <Link component={RouterLink} sx={{ my: 8 }} to="/disclaimer">
          TERMS OF SERVICE
        </Link>
      </Stack>
    </Stack>
  );
}
