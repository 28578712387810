import { Box, Container, Typography } from '@mui/material';

import RequestList from './RequestList';

const ManageAgentRequests = () => {
  return (
    <Box sx={{ background: theme => theme.colors.gradients.sheet, p: 5 }}>
      <Container maxWidth="xl">
        <Typography sx={{ my: 5 }} variant="h3">
          Agent framework requests
        </Typography>
        <Box justifyContent="flex-start">
          <RequestList />
        </Box>
      </Container>
    </Box>
  );
};

export default ManageAgentRequests;
