import { Box, CircularProgress, Stack } from '@mui/material';
import { useListAppTypes } from 'pages/aaas/hooks';
import { AaasLayout } from 'pages/aaas/layout';
import { AAAS_PATHS } from 'pages/aaas/routes';
import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageBannerVariant } from 'shared/components/Layout/PageBanner';

export const NewAgentDeploymentLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { isPending } = useListAppTypes();

  return (
    <AaasLayout
      pageBannerProps={{
        onClickBack: () => navigate(AAAS_PATHS.AAAS_DEPLOYMENTS, { replace: true }),
        title: 'Deploy an agent',
        variant: PageBannerVariant.AAAS,
      }}
    >
      <Box bgcolor="#fafafa" pb={4} sx={{ position: 'relative' }}>
        {isPending ? (
          <Stack px={5} py={3}>
            <CircularProgress />
          </Stack>
        ) : (
          children
        )}
      </Box>
    </AaasLayout>
  );
};
