import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { AverageGasPriceMetric } from 'pages/raas/DeploymentDetails/views/FeeTrackerView/AverageGasPriceMetric';
import { RollupType } from 'pages/raas/helpers/labels';
import { useRaasDeployment } from 'pages/raas/hooks';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { BridgeTVLMetric } from './BridgeTVLMetric';
import { TxPoolMetric } from './TxPoolMetric';

export const MetricsSection = () => {
  const { data: rollup, isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          {showExperimentalFeatures && (
            <MetricGridItem item>
              <BridgeTVLMetric />
            </MetricGridItem>
          )}
          {rollup?.type === RollupType.OPSTACK && (
            <MetricGridItem item>
              <TxPoolMetric />
            </MetricGridItem>
          )}
          <MetricGridItem item>
            <AverageGasPriceMetric timeFilterVariant={TimeFilterVariant.OVERVIEW_METRICS_SECTION} />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        !isPending && (
          <SectionButtonTimeFilter variant={TimeFilterVariant.OVERVIEW_METRICS_SECTION} />
        )
      }
      sectionContentSx={{ p: '0px' }}
      title="Metrics"
    />
  );
};
