import { FormHelperText, MenuItem, Select, SelectProps, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { FormFieldConfig } from 'shared/components/form/fields/types';

export interface FormSelectItem {
  label: string;
  value: string | number | readonly string[] | undefined;
  description?: string;
}

type IFormSelectField<T extends FormSelectItem> = {
  items: T[];
  renderItems?: (items: T[]) => ReactNode;
  fieldConfig: FormFieldConfig;
  controllerProps?: Partial<ControllerProps>;
} & SelectProps<T>;

export const FormSelectField = <T extends FormSelectItem>({
  controllerProps,
  fieldConfig,
  items,
  renderItems,
  ...selectProps
}: IFormSelectField<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldConfig.name}
      render={({ field, fieldState }) => (
        <Stack spacing={'3px'} width="100%">
          <Select<T>
            {...field}
            error={!!fieldState.error || fieldState.invalid}
            fullWidth
            inputRef={field.ref}
            onBlur={() => field.onBlur()}
            placeholder={fieldConfig.placeholder}
            ref={undefined}
            sx={{
              background: '#fff',
              minHeight: '68px',
            }}
            value={field.value}
            {...selectProps}
          >
            {renderItems
              ? renderItems(items)
              : items.map(item => (
                  <MenuItem
                    key={item.label}
                    sx={{ p: 3, display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
          </Select>
          <FormHelperText error={!!fieldState.error} sx={{ pl: 1.5 }}>
            <Typography fontSize={12} variant="caption">
              {fieldState.error?.message}
            </Typography>
          </FormHelperText>
        </Stack>
      )}
      {...controllerProps}
    />
  );
};
