import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { AutocompleteOption } from 'shared/components/CustomAutocomplete';
import { QUERY_KEYS } from 'shared/types/react-query';
import { getTimeZoneDiffFromLocal, timeZoneOptions } from 'shared/utils/dates';

import { ExpiryDatePicker } from './ExpiryDatePicker';
import { PromoCode } from '.';

const EditCodeExpiryDialog: FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedCode: PromoCode;
}> = ({ isOpen, selectedCode, setIsOpen }) => {
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const { isPending: isExtendingExpiry, mutate: extendExpiry } = useMutation({
    mutationFn: ({ code, expireAt }: { code: string; expireAt: string }) => {
      console.debug('code,expireAt: ', code, expireAt);

      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ codes: ['123', '456'] });
        }, 1000);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_BFF_PROMO_CODES] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_AVS_PROMO_CODES] });
      addAlert({ severity: ALERT_SEVERITY.SUCCESS, title: 'Successfully edited promo code' });
      setIsOpen(false);
    },
  });
  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(
    new Date(selectedCode?.expireAt),
  );

  useEffect(() => {
    setSelectedDateTime(new Date(selectedCode?.expireAt));
  }, [selectedCode]);

  const localTimezone = timeZoneOptions.find(
    (cur: AutocompleteOption) => cur.value === Intl.DateTimeFormat().resolvedOptions()?.timeZone,
  );
  const [selectedTimezone, setSelectedTimezone] = useState<AutocompleteOption | undefined>(
    localTimezone,
  );

  const handleConfirm = () => {
    if (!selectedDateTime) {
      return;
    }

    const dateTime: Date = new Date(selectedDateTime);
    const timeDiffMins = getTimeZoneDiffFromLocal(selectedTimezone?.value as string);

    console.debug('timezone offset: ', timeDiffMins);
    const localDateTime = new Date(dateTime.setMinutes(dateTime.getMinutes() - timeDiffMins));

    console.debug('dateTime ISO String: ', localDateTime?.toISOString());

    extendExpiry({ code: selectedCode?.code, expireAt: localDateTime?.toISOString() });
  };

  return (
    <ConfirmationDialog
      handleClose={() => setIsOpen(false)}
      isLoading={isExtendingExpiry}
      onConfirm={handleConfirm}
      open={isOpen}
      sx={{ '&& .MuiDialog-paper': { maxWidth: 'unset', width: '60rem' } }}
      title={`Edit expiry date for promo code ${selectedCode?.code}`}
    >
      <ExpiryDatePicker
        selectedDateTime={selectedDateTime}
        selectedTimezone={selectedTimezone}
        setSelectedDateTime={setSelectedDateTime}
        setSelectedTimezone={setSelectedTimezone}
      />
    </ConfirmationDialog>
  );
};

export default EditCodeExpiryDialog;
