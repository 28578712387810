// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: bffaaa.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "bff.v1";

export enum AAAAppOperateAction {
  AAA_APP_OPERATE_ACTION_UNSPECIFIED = 0,
  AAA_APP_OPERATE_ACTION_ACTION_TERMINATE = 1,
  AAA_APP_OPERATE_ACTION_ACTION_RESTART = 2,
  UNRECOGNIZED = -1,
}

export function aAAAppOperateActionFromJSON(object: any): AAAAppOperateAction {
  switch (object) {
    case 0:
    case "AAA_APP_OPERATE_ACTION_UNSPECIFIED":
      return AAAAppOperateAction.AAA_APP_OPERATE_ACTION_UNSPECIFIED;
    case 1:
    case "AAA_APP_OPERATE_ACTION_ACTION_TERMINATE":
      return AAAAppOperateAction.AAA_APP_OPERATE_ACTION_ACTION_TERMINATE;
    case 2:
    case "AAA_APP_OPERATE_ACTION_ACTION_RESTART":
      return AAAAppOperateAction.AAA_APP_OPERATE_ACTION_ACTION_RESTART;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAAAppOperateAction.UNRECOGNIZED;
  }
}

export function aAAAppOperateActionToJSON(object: AAAAppOperateAction): string {
  switch (object) {
    case AAAAppOperateAction.AAA_APP_OPERATE_ACTION_UNSPECIFIED:
      return "AAA_APP_OPERATE_ACTION_UNSPECIFIED";
    case AAAAppOperateAction.AAA_APP_OPERATE_ACTION_ACTION_TERMINATE:
      return "AAA_APP_OPERATE_ACTION_ACTION_TERMINATE";
    case AAAAppOperateAction.AAA_APP_OPERATE_ACTION_ACTION_RESTART:
      return "AAA_APP_OPERATE_ACTION_ACTION_RESTART";
    case AAAAppOperateAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAATemplateOperateAction {
  AAA_TEMPLATE_OPERATE_ACTION_UNSPECIFIED = 0,
  AAA_TEMPLATE_OPERATE_ACTION_PUBLISH = 1,
  AAA_TEMPLATE_OPERATE_ACTION_APPROVE = 2,
  AAA_TEMPLATE_OPERATE_ACTION_DELETE = 3,
  AAA_TEMPLATE_OPERATE_ACTION_REJECT = 4,
  UNRECOGNIZED = -1,
}

export function aAATemplateOperateActionFromJSON(object: any): AAATemplateOperateAction {
  switch (object) {
    case 0:
    case "AAA_TEMPLATE_OPERATE_ACTION_UNSPECIFIED":
      return AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_UNSPECIFIED;
    case 1:
    case "AAA_TEMPLATE_OPERATE_ACTION_PUBLISH":
      return AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_PUBLISH;
    case 2:
    case "AAA_TEMPLATE_OPERATE_ACTION_APPROVE":
      return AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_APPROVE;
    case 3:
    case "AAA_TEMPLATE_OPERATE_ACTION_DELETE":
      return AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_DELETE;
    case 4:
    case "AAA_TEMPLATE_OPERATE_ACTION_REJECT":
      return AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_REJECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAATemplateOperateAction.UNRECOGNIZED;
  }
}

export function aAATemplateOperateActionToJSON(object: AAATemplateOperateAction): string {
  switch (object) {
    case AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_UNSPECIFIED:
      return "AAA_TEMPLATE_OPERATE_ACTION_UNSPECIFIED";
    case AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_PUBLISH:
      return "AAA_TEMPLATE_OPERATE_ACTION_PUBLISH";
    case AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_APPROVE:
      return "AAA_TEMPLATE_OPERATE_ACTION_APPROVE";
    case AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_DELETE:
      return "AAA_TEMPLATE_OPERATE_ACTION_DELETE";
    case AAATemplateOperateAction.AAA_TEMPLATE_OPERATE_ACTION_REJECT:
      return "AAA_TEMPLATE_OPERATE_ACTION_REJECT";
    case AAATemplateOperateAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CreationAAAMethod {
  CREATION_AAA_METHOD_UNSPECIFIED = 0,
  CREATION_AAA_METHOD_PROMO_CODE = 1,
  CREATION_AAA_METHOD_FREE_TRIAL = 2,
  CREATION_AAA_METHOD_SUBSCRIPTION = 3,
  UNRECOGNIZED = -1,
}

export function creationAAAMethodFromJSON(object: any): CreationAAAMethod {
  switch (object) {
    case 0:
    case "CREATION_AAA_METHOD_UNSPECIFIED":
      return CreationAAAMethod.CREATION_AAA_METHOD_UNSPECIFIED;
    case 1:
    case "CREATION_AAA_METHOD_PROMO_CODE":
      return CreationAAAMethod.CREATION_AAA_METHOD_PROMO_CODE;
    case 2:
    case "CREATION_AAA_METHOD_FREE_TRIAL":
      return CreationAAAMethod.CREATION_AAA_METHOD_FREE_TRIAL;
    case 3:
    case "CREATION_AAA_METHOD_SUBSCRIPTION":
      return CreationAAAMethod.CREATION_AAA_METHOD_SUBSCRIPTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CreationAAAMethod.UNRECOGNIZED;
  }
}

export function creationAAAMethodToJSON(object: CreationAAAMethod): string {
  switch (object) {
    case CreationAAAMethod.CREATION_AAA_METHOD_UNSPECIFIED:
      return "CREATION_AAA_METHOD_UNSPECIFIED";
    case CreationAAAMethod.CREATION_AAA_METHOD_PROMO_CODE:
      return "CREATION_AAA_METHOD_PROMO_CODE";
    case CreationAAAMethod.CREATION_AAA_METHOD_FREE_TRIAL:
      return "CREATION_AAA_METHOD_FREE_TRIAL";
    case CreationAAAMethod.CREATION_AAA_METHOD_SUBSCRIPTION:
      return "CREATION_AAA_METHOD_SUBSCRIPTION";
    case CreationAAAMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAATemplateCategory {
  AAA_TEMPLATE_TYPE_UNSPECIFIED = 0,
  AAA_TEMPLATE_TYPE_BUILTIN = 1,
  AAA_TEMPLATE_TYPE_APP_STORE = 2,
  UNRECOGNIZED = -1,
}

export function aAATemplateCategoryFromJSON(object: any): AAATemplateCategory {
  switch (object) {
    case 0:
    case "AAA_TEMPLATE_TYPE_UNSPECIFIED":
      return AAATemplateCategory.AAA_TEMPLATE_TYPE_UNSPECIFIED;
    case 1:
    case "AAA_TEMPLATE_TYPE_BUILTIN":
      return AAATemplateCategory.AAA_TEMPLATE_TYPE_BUILTIN;
    case 2:
    case "AAA_TEMPLATE_TYPE_APP_STORE":
      return AAATemplateCategory.AAA_TEMPLATE_TYPE_APP_STORE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAATemplateCategory.UNRECOGNIZED;
  }
}

export function aAATemplateCategoryToJSON(object: AAATemplateCategory): string {
  switch (object) {
    case AAATemplateCategory.AAA_TEMPLATE_TYPE_UNSPECIFIED:
      return "AAA_TEMPLATE_TYPE_UNSPECIFIED";
    case AAATemplateCategory.AAA_TEMPLATE_TYPE_BUILTIN:
      return "AAA_TEMPLATE_TYPE_BUILTIN";
    case AAATemplateCategory.AAA_TEMPLATE_TYPE_APP_STORE:
      return "AAA_TEMPLATE_TYPE_APP_STORE";
    case AAATemplateCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAATemplateHostType {
  AAA_TEMPLATE_HOST_TYPE_UNSPECIFIED = 0,
  AAA_TEMPLATE_HOST_TYPE_CHATBOX = 1,
  AAA_TEMPLATE_HOST_TYPE_HOSTYPE = 2,
  UNRECOGNIZED = -1,
}

export function aAATemplateHostTypeFromJSON(object: any): AAATemplateHostType {
  switch (object) {
    case 0:
    case "AAA_TEMPLATE_HOST_TYPE_UNSPECIFIED":
      return AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_UNSPECIFIED;
    case 1:
    case "AAA_TEMPLATE_HOST_TYPE_CHATBOX":
      return AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_CHATBOX;
    case 2:
    case "AAA_TEMPLATE_HOST_TYPE_HOSTYPE":
      return AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_HOSTYPE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAATemplateHostType.UNRECOGNIZED;
  }
}

export function aAATemplateHostTypeToJSON(object: AAATemplateHostType): string {
  switch (object) {
    case AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_UNSPECIFIED:
      return "AAA_TEMPLATE_HOST_TYPE_UNSPECIFIED";
    case AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_CHATBOX:
      return "AAA_TEMPLATE_HOST_TYPE_CHATBOX";
    case AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_HOSTYPE:
      return "AAA_TEMPLATE_HOST_TYPE_HOSTYPE";
    case AAATemplateHostType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAATemplateStatus {
  AAA_TEMPLATE_STATUS_UNSPECIFIED = 0,
  AAA_TEMPLATE_STATUS_SUBMITTED = 1,
  AAA_TEMPLATE_STATUS_PENDING = 2,
  AAA_TEMPLATE_STATUS_PUBLISHED = 3,
  AAA_TEMPLATE_STATUS_REJECTED = 4,
  AAA_TEMPLATE_STATUS_DELETED = 5,
  UNRECOGNIZED = -1,
}

export function aAATemplateStatusFromJSON(object: any): AAATemplateStatus {
  switch (object) {
    case 0:
    case "AAA_TEMPLATE_STATUS_UNSPECIFIED":
      return AAATemplateStatus.AAA_TEMPLATE_STATUS_UNSPECIFIED;
    case 1:
    case "AAA_TEMPLATE_STATUS_SUBMITTED":
      return AAATemplateStatus.AAA_TEMPLATE_STATUS_SUBMITTED;
    case 2:
    case "AAA_TEMPLATE_STATUS_PENDING":
      return AAATemplateStatus.AAA_TEMPLATE_STATUS_PENDING;
    case 3:
    case "AAA_TEMPLATE_STATUS_PUBLISHED":
      return AAATemplateStatus.AAA_TEMPLATE_STATUS_PUBLISHED;
    case 4:
    case "AAA_TEMPLATE_STATUS_REJECTED":
      return AAATemplateStatus.AAA_TEMPLATE_STATUS_REJECTED;
    case 5:
    case "AAA_TEMPLATE_STATUS_DELETED":
      return AAATemplateStatus.AAA_TEMPLATE_STATUS_DELETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAATemplateStatus.UNRECOGNIZED;
  }
}

export function aAATemplateStatusToJSON(object: AAATemplateStatus): string {
  switch (object) {
    case AAATemplateStatus.AAA_TEMPLATE_STATUS_UNSPECIFIED:
      return "AAA_TEMPLATE_STATUS_UNSPECIFIED";
    case AAATemplateStatus.AAA_TEMPLATE_STATUS_SUBMITTED:
      return "AAA_TEMPLATE_STATUS_SUBMITTED";
    case AAATemplateStatus.AAA_TEMPLATE_STATUS_PENDING:
      return "AAA_TEMPLATE_STATUS_PENDING";
    case AAATemplateStatus.AAA_TEMPLATE_STATUS_PUBLISHED:
      return "AAA_TEMPLATE_STATUS_PUBLISHED";
    case AAATemplateStatus.AAA_TEMPLATE_STATUS_REJECTED:
      return "AAA_TEMPLATE_STATUS_REJECTED";
    case AAATemplateStatus.AAA_TEMPLATE_STATUS_DELETED:
      return "AAA_TEMPLATE_STATUS_DELETED";
    case AAATemplateStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface CreateTweetRequest {
  appId: string;
  customPrompt: string;
}

export interface CreateTweetReply {
}

export interface AAATemplate {
  id: string;
  name: string;
  description: string;
  type: AAATemplateHostType;
  category: AAATemplateCategory;
  envList: string[];
  chatSubpath: string;
  configExample: string;
  iconLink: string;
  status: AAATemplateStatus;
  requestSchema: { [key: string]: any } | undefined;
  responseSchema: { [key: string]: any } | undefined;
  port: number;
  featured: boolean;
}

export interface AAAApp {
  id: string;
  name: string;
  endpoints: AAAApp_AAAAppEndpoint | undefined;
  template: AAATemplate | undefined;
  createdAt: Date | undefined;
  expireAt: Date | undefined;
  auth: AAAApp_AAAAppAuth | undefined;
  creationMethod: CreationAAAMethod;
  character?: string | undefined;
  metadata?: AAAApp_AAAAppMetadata | undefined;
}

export interface AAAApp_AAAAppEndpoint {
  apiUrl: string;
}

export interface AAAApp_AAAAppAuth {
  basicAuthUsername: string;
  basicAuthPassword: string;
}

export interface AAAApp_AAAAppMetadata {
  twitterAccount: string;
}

export interface OperateAAATemplateRequest {
  id: string;
  action: AAATemplateOperateAction;
}

export interface OperateAAATemplateReply {
}

export interface CreationAAAParams {
  promoCode: string;
  agentCharacter?: string | undefined;
}

export interface CreateAAAAppRequest {
  name: string;
  templateId: string;
  envList: { [key: string]: any } | undefined;
  config: string;
  creationMethod: CreationAAAMethod;
  creationParams: CreationAAAParams | undefined;
}

export interface CreateAAAAppReply {
  app: AAAApp | undefined;
}

export interface ListAAAAppRequest {
}

export interface ListAAAAppReply {
  apps: AAAApp[];
}

export interface UpdateAAAAppRequest {
  id: string;
  config: string;
}

export interface UpdateAAAAppReply {
}

export interface OperateAAAAppRequest {
  id: string;
  action: AAAAppOperateAction;
}

export interface OperateAAAAppReply {
  ret: string;
}

export interface CreateAAATemplateRequest {
  name: string;
  description: string;
  type: AAATemplateHostType;
  envList: string[];
  chatSubpath: string;
  configExample: string;
  iconLink: string;
  dockerImage: string;
  requestSchema: { [key: string]: any } | undefined;
  responseSchema: { [key: string]: any } | undefined;
  port: number;
}

export interface CreateAAATemplateReply {
  template: AAATemplate | undefined;
}

export interface ListAAATemplateRequest {
  isPending: boolean;
}

export interface ListAAATemplateReply {
  builtin: AAATemplate[];
  appStore: AAATemplate[];
  selfOwned: AAATemplate[];
}

export interface ListAAAAppLogRequest {
  id: string;
}

export interface ListAAAAppLogReply {
  log: string;
}

export interface Task {
  id: string;
  name: string;
  description: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  startDate: Date | undefined;
  endDate:
    | Date
    | undefined;
  /** Mapping participant_id to Participant object */
  participants: { [key: string]: Participant };
}

export interface Task_ParticipantsEntry {
  key: string;
  value: Participant | undefined;
}

export interface Participant {
  id: string;
  /** Reference to the task */
  taskId: string;
  name: string;
  impressions: number;
  /** Stores the ID of the last post */
  lastPostId: string;
}

export interface CreateTaskRequest {
  name: string;
  startDate: Date | undefined;
  endDate:
    | Date
    | undefined;
  /** Initial participants during task creation */
  participants: { [key: string]: Participant };
}

export interface CreateTaskRequest_ParticipantsEntry {
  key: string;
  value: Participant | undefined;
}

export interface CreateTaskReply {
  task: Task | undefined;
}

export interface GetTaskRequest {
  id: string;
}

export interface UpdateTaskRequest {
  id: string;
  name: string;
  description: string;
  startDate: Date | undefined;
  endDate:
    | Date
    | undefined;
  /** Update participants or their impressions */
  participants: { [key: string]: Participant };
}

export interface UpdateTaskRequest_ParticipantsEntry {
  key: string;
  value: Participant | undefined;
}

export interface UpdateTaskReply {
  task: Task | undefined;
}

export interface TelegramChannel {
  channelId: string;
  channelName: string;
}

export interface UpdateFineTuneSourceRequest {
  twittersProfiles: string[];
  telegramChannels: TelegramChannel[];
}

export interface UpdateFineTuneSourceReply {
}

export interface GetFineTuneSourceRequest {
}

export interface GetFineTuneSourceReply {
  twittersProfiles: string[];
  telegramChannels: TelegramChannel[];
}

export interface CreateFineTuneJobRequest {
  twittersProfiles: string[];
  telegramChannelIds: string[];
  appId: string;
}

export interface CreateFineTuneJobReply {
}

export interface ValidateFineTuneJobReply {
  isValidate: boolean;
  reason: string;
}

export interface ListFineTuneJobRequest {
  appId: string;
}

export interface ListFineTuneJobReply {
  twittersProfiles: string[];
  telegramChannelIds: string[];
}

export interface CreateGenerateCharacterJobRequest {
  twitterAccount: string;
}

export interface CreateGenerateCharacterJobReply {
  jobId: string;
}

export interface GetCharacterGenerateJobRequest {
  jobId: string;
}

export interface GetCharacterGenerateJobReply {
  /** Is the generation job is finished, if not true, need wait */
  isFinished: boolean;
  /** Is the generation job failed */
  isFailed: boolean;
  /** The reason for the generation job failed, if not */
  failedReason: string;
  /** failed, will be empty */
  twitterAccount: string;
  /** The character config generated, if finished with no failure */
  characterConfig: string;
}

export interface CharacterGenerateJobBaseInfo {
  jobId: string;
  isFinished: boolean;
  twitterAccount: string;
}

export interface ListCharacterGenerateJobsRequest {
}

export interface ListCharacterGenerateJobsReply {
  jobs: CharacterGenerateJobBaseInfo[];
}

function createBaseCreateTweetRequest(): CreateTweetRequest {
  return { appId: "", customPrompt: "" };
}

export const CreateTweetRequest = {
  encode(message: CreateTweetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appId !== "") {
      writer.uint32(10).string(message.appId);
    }
    if (message.customPrompt !== "") {
      writer.uint32(18).string(message.customPrompt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTweetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTweetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customPrompt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTweetRequest {
    return {
      appId: isSet(object.appId) ? globalThis.String(object.appId) : "",
      customPrompt: isSet(object.customPrompt) ? globalThis.String(object.customPrompt) : "",
    };
  },

  toJSON(message: CreateTweetRequest): unknown {
    const obj: any = {};
    if (message.appId !== "") {
      obj.appId = message.appId;
    }
    if (message.customPrompt !== "") {
      obj.customPrompt = message.customPrompt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTweetRequest>, I>>(base?: I): CreateTweetRequest {
    return CreateTweetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTweetRequest>, I>>(object: I): CreateTweetRequest {
    const message = createBaseCreateTweetRequest();
    message.appId = object.appId ?? "";
    message.customPrompt = object.customPrompt ?? "";
    return message;
  },
};

function createBaseCreateTweetReply(): CreateTweetReply {
  return {};
}

export const CreateTweetReply = {
  encode(_: CreateTweetReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTweetReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTweetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateTweetReply {
    return {};
  },

  toJSON(_: CreateTweetReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTweetReply>, I>>(base?: I): CreateTweetReply {
    return CreateTweetReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTweetReply>, I>>(_: I): CreateTweetReply {
    const message = createBaseCreateTweetReply();
    return message;
  },
};

function createBaseAAATemplate(): AAATemplate {
  return {
    id: "",
    name: "",
    description: "",
    type: 0,
    category: 0,
    envList: [],
    chatSubpath: "",
    configExample: "",
    iconLink: "",
    status: 0,
    requestSchema: undefined,
    responseSchema: undefined,
    port: 0,
    featured: false,
  };
}

export const AAATemplate = {
  encode(message: AAATemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.type !== 0) {
      writer.uint32(32).int32(message.type);
    }
    if (message.category !== 0) {
      writer.uint32(40).int32(message.category);
    }
    for (const v of message.envList) {
      writer.uint32(50).string(v!);
    }
    if (message.chatSubpath !== "") {
      writer.uint32(58).string(message.chatSubpath);
    }
    if (message.configExample !== "") {
      writer.uint32(66).string(message.configExample);
    }
    if (message.iconLink !== "") {
      writer.uint32(74).string(message.iconLink);
    }
    if (message.status !== 0) {
      writer.uint32(80).int32(message.status);
    }
    if (message.requestSchema !== undefined) {
      Struct.encode(Struct.wrap(message.requestSchema), writer.uint32(90).fork()).ldelim();
    }
    if (message.responseSchema !== undefined) {
      Struct.encode(Struct.wrap(message.responseSchema), writer.uint32(98).fork()).ldelim();
    }
    if (message.port !== 0) {
      writer.uint32(104).uint32(message.port);
    }
    if (message.featured !== false) {
      writer.uint32(112).bool(message.featured);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AAATemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAAATemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.envList.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.chatSubpath = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.configExample = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.iconLink = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.requestSchema = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.responseSchema = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.port = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.featured = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AAATemplate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      type: isSet(object.type) ? aAATemplateHostTypeFromJSON(object.type) : 0,
      category: isSet(object.category) ? aAATemplateCategoryFromJSON(object.category) : 0,
      envList: globalThis.Array.isArray(object?.envList) ? object.envList.map((e: any) => globalThis.String(e)) : [],
      chatSubpath: isSet(object.chatSubpath) ? globalThis.String(object.chatSubpath) : "",
      configExample: isSet(object.configExample) ? globalThis.String(object.configExample) : "",
      iconLink: isSet(object.iconLink) ? globalThis.String(object.iconLink) : "",
      status: isSet(object.status) ? aAATemplateStatusFromJSON(object.status) : 0,
      requestSchema: isObject(object.requestSchema) ? object.requestSchema : undefined,
      responseSchema: isObject(object.responseSchema) ? object.responseSchema : undefined,
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      featured: isSet(object.featured) ? globalThis.Boolean(object.featured) : false,
    };
  },

  toJSON(message: AAATemplate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.type !== 0) {
      obj.type = aAATemplateHostTypeToJSON(message.type);
    }
    if (message.category !== 0) {
      obj.category = aAATemplateCategoryToJSON(message.category);
    }
    if (message.envList?.length) {
      obj.envList = message.envList;
    }
    if (message.chatSubpath !== "") {
      obj.chatSubpath = message.chatSubpath;
    }
    if (message.configExample !== "") {
      obj.configExample = message.configExample;
    }
    if (message.iconLink !== "") {
      obj.iconLink = message.iconLink;
    }
    if (message.status !== 0) {
      obj.status = aAATemplateStatusToJSON(message.status);
    }
    if (message.requestSchema !== undefined) {
      obj.requestSchema = message.requestSchema;
    }
    if (message.responseSchema !== undefined) {
      obj.responseSchema = message.responseSchema;
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    if (message.featured !== false) {
      obj.featured = message.featured;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AAATemplate>, I>>(base?: I): AAATemplate {
    return AAATemplate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AAATemplate>, I>>(object: I): AAATemplate {
    const message = createBaseAAATemplate();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.type = object.type ?? 0;
    message.category = object.category ?? 0;
    message.envList = object.envList?.map((e) => e) || [];
    message.chatSubpath = object.chatSubpath ?? "";
    message.configExample = object.configExample ?? "";
    message.iconLink = object.iconLink ?? "";
    message.status = object.status ?? 0;
    message.requestSchema = object.requestSchema ?? undefined;
    message.responseSchema = object.responseSchema ?? undefined;
    message.port = object.port ?? 0;
    message.featured = object.featured ?? false;
    return message;
  },
};

function createBaseAAAApp(): AAAApp {
  return {
    id: "",
    name: "",
    endpoints: undefined,
    template: undefined,
    createdAt: undefined,
    expireAt: undefined,
    auth: undefined,
    creationMethod: 0,
    character: undefined,
    metadata: undefined,
  };
}

export const AAAApp = {
  encode(message: AAAApp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.endpoints !== undefined) {
      AAAApp_AAAAppEndpoint.encode(message.endpoints, writer.uint32(26).fork()).ldelim();
    }
    if (message.template !== undefined) {
      AAATemplate.encode(message.template, writer.uint32(34).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.expireAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expireAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.auth !== undefined) {
      AAAApp_AAAAppAuth.encode(message.auth, writer.uint32(58).fork()).ldelim();
    }
    if (message.creationMethod !== 0) {
      writer.uint32(64).int32(message.creationMethod);
    }
    if (message.character !== undefined) {
      writer.uint32(74).string(message.character);
    }
    if (message.metadata !== undefined) {
      AAAApp_AAAAppMetadata.encode(message.metadata, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AAAApp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAAAApp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endpoints = AAAApp_AAAAppEndpoint.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.template = AAATemplate.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.expireAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.auth = AAAApp_AAAAppAuth.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.creationMethod = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.character = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadata = AAAApp_AAAAppMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AAAApp {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      endpoints: isSet(object.endpoints) ? AAAApp_AAAAppEndpoint.fromJSON(object.endpoints) : undefined,
      template: isSet(object.template) ? AAATemplate.fromJSON(object.template) : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      expireAt: isSet(object.expireAt) ? fromJsonTimestamp(object.expireAt) : undefined,
      auth: isSet(object.auth) ? AAAApp_AAAAppAuth.fromJSON(object.auth) : undefined,
      creationMethod: isSet(object.creationMethod) ? creationAAAMethodFromJSON(object.creationMethod) : 0,
      character: isSet(object.character) ? globalThis.String(object.character) : undefined,
      metadata: isSet(object.metadata) ? AAAApp_AAAAppMetadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: AAAApp): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.endpoints !== undefined) {
      obj.endpoints = AAAApp_AAAAppEndpoint.toJSON(message.endpoints);
    }
    if (message.template !== undefined) {
      obj.template = AAATemplate.toJSON(message.template);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.expireAt !== undefined) {
      obj.expireAt = message.expireAt.toISOString();
    }
    if (message.auth !== undefined) {
      obj.auth = AAAApp_AAAAppAuth.toJSON(message.auth);
    }
    if (message.creationMethod !== 0) {
      obj.creationMethod = creationAAAMethodToJSON(message.creationMethod);
    }
    if (message.character !== undefined) {
      obj.character = message.character;
    }
    if (message.metadata !== undefined) {
      obj.metadata = AAAApp_AAAAppMetadata.toJSON(message.metadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AAAApp>, I>>(base?: I): AAAApp {
    return AAAApp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AAAApp>, I>>(object: I): AAAApp {
    const message = createBaseAAAApp();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.endpoints = (object.endpoints !== undefined && object.endpoints !== null)
      ? AAAApp_AAAAppEndpoint.fromPartial(object.endpoints)
      : undefined;
    message.template = (object.template !== undefined && object.template !== null)
      ? AAATemplate.fromPartial(object.template)
      : undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.expireAt = object.expireAt ?? undefined;
    message.auth = (object.auth !== undefined && object.auth !== null)
      ? AAAApp_AAAAppAuth.fromPartial(object.auth)
      : undefined;
    message.creationMethod = object.creationMethod ?? 0;
    message.character = object.character ?? undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? AAAApp_AAAAppMetadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseAAAApp_AAAAppEndpoint(): AAAApp_AAAAppEndpoint {
  return { apiUrl: "" };
}

export const AAAApp_AAAAppEndpoint = {
  encode(message: AAAApp_AAAAppEndpoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.apiUrl !== "") {
      writer.uint32(10).string(message.apiUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AAAApp_AAAAppEndpoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAAAApp_AAAAppEndpoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apiUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AAAApp_AAAAppEndpoint {
    return { apiUrl: isSet(object.apiUrl) ? globalThis.String(object.apiUrl) : "" };
  },

  toJSON(message: AAAApp_AAAAppEndpoint): unknown {
    const obj: any = {};
    if (message.apiUrl !== "") {
      obj.apiUrl = message.apiUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AAAApp_AAAAppEndpoint>, I>>(base?: I): AAAApp_AAAAppEndpoint {
    return AAAApp_AAAAppEndpoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AAAApp_AAAAppEndpoint>, I>>(object: I): AAAApp_AAAAppEndpoint {
    const message = createBaseAAAApp_AAAAppEndpoint();
    message.apiUrl = object.apiUrl ?? "";
    return message;
  },
};

function createBaseAAAApp_AAAAppAuth(): AAAApp_AAAAppAuth {
  return { basicAuthUsername: "", basicAuthPassword: "" };
}

export const AAAApp_AAAAppAuth = {
  encode(message: AAAApp_AAAAppAuth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.basicAuthUsername !== "") {
      writer.uint32(10).string(message.basicAuthUsername);
    }
    if (message.basicAuthPassword !== "") {
      writer.uint32(18).string(message.basicAuthPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AAAApp_AAAAppAuth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAAAApp_AAAAppAuth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.basicAuthUsername = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.basicAuthPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AAAApp_AAAAppAuth {
    return {
      basicAuthUsername: isSet(object.basicAuthUsername) ? globalThis.String(object.basicAuthUsername) : "",
      basicAuthPassword: isSet(object.basicAuthPassword) ? globalThis.String(object.basicAuthPassword) : "",
    };
  },

  toJSON(message: AAAApp_AAAAppAuth): unknown {
    const obj: any = {};
    if (message.basicAuthUsername !== "") {
      obj.basicAuthUsername = message.basicAuthUsername;
    }
    if (message.basicAuthPassword !== "") {
      obj.basicAuthPassword = message.basicAuthPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AAAApp_AAAAppAuth>, I>>(base?: I): AAAApp_AAAAppAuth {
    return AAAApp_AAAAppAuth.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AAAApp_AAAAppAuth>, I>>(object: I): AAAApp_AAAAppAuth {
    const message = createBaseAAAApp_AAAAppAuth();
    message.basicAuthUsername = object.basicAuthUsername ?? "";
    message.basicAuthPassword = object.basicAuthPassword ?? "";
    return message;
  },
};

function createBaseAAAApp_AAAAppMetadata(): AAAApp_AAAAppMetadata {
  return { twitterAccount: "" };
}

export const AAAApp_AAAAppMetadata = {
  encode(message: AAAApp_AAAAppMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.twitterAccount !== "") {
      writer.uint32(10).string(message.twitterAccount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AAAApp_AAAAppMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAAAApp_AAAAppMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.twitterAccount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AAAApp_AAAAppMetadata {
    return { twitterAccount: isSet(object.twitterAccount) ? globalThis.String(object.twitterAccount) : "" };
  },

  toJSON(message: AAAApp_AAAAppMetadata): unknown {
    const obj: any = {};
    if (message.twitterAccount !== "") {
      obj.twitterAccount = message.twitterAccount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AAAApp_AAAAppMetadata>, I>>(base?: I): AAAApp_AAAAppMetadata {
    return AAAApp_AAAAppMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AAAApp_AAAAppMetadata>, I>>(object: I): AAAApp_AAAAppMetadata {
    const message = createBaseAAAApp_AAAAppMetadata();
    message.twitterAccount = object.twitterAccount ?? "";
    return message;
  },
};

function createBaseOperateAAATemplateRequest(): OperateAAATemplateRequest {
  return { id: "", action: 0 };
}

export const OperateAAATemplateRequest = {
  encode(message: OperateAAATemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.action !== 0) {
      writer.uint32(16).int32(message.action);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperateAAATemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperateAAATemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OperateAAATemplateRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      action: isSet(object.action) ? aAATemplateOperateActionFromJSON(object.action) : 0,
    };
  },

  toJSON(message: OperateAAATemplateRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.action !== 0) {
      obj.action = aAATemplateOperateActionToJSON(message.action);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OperateAAATemplateRequest>, I>>(base?: I): OperateAAATemplateRequest {
    return OperateAAATemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperateAAATemplateRequest>, I>>(object: I): OperateAAATemplateRequest {
    const message = createBaseOperateAAATemplateRequest();
    message.id = object.id ?? "";
    message.action = object.action ?? 0;
    return message;
  },
};

function createBaseOperateAAATemplateReply(): OperateAAATemplateReply {
  return {};
}

export const OperateAAATemplateReply = {
  encode(_: OperateAAATemplateReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperateAAATemplateReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperateAAATemplateReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): OperateAAATemplateReply {
    return {};
  },

  toJSON(_: OperateAAATemplateReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<OperateAAATemplateReply>, I>>(base?: I): OperateAAATemplateReply {
    return OperateAAATemplateReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperateAAATemplateReply>, I>>(_: I): OperateAAATemplateReply {
    const message = createBaseOperateAAATemplateReply();
    return message;
  },
};

function createBaseCreationAAAParams(): CreationAAAParams {
  return { promoCode: "", agentCharacter: undefined };
}

export const CreationAAAParams = {
  encode(message: CreationAAAParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.promoCode !== "") {
      writer.uint32(10).string(message.promoCode);
    }
    if (message.agentCharacter !== undefined) {
      writer.uint32(18).string(message.agentCharacter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreationAAAParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreationAAAParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.promoCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agentCharacter = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreationAAAParams {
    return {
      promoCode: isSet(object.promoCode) ? globalThis.String(object.promoCode) : "",
      agentCharacter: isSet(object.agentCharacter) ? globalThis.String(object.agentCharacter) : undefined,
    };
  },

  toJSON(message: CreationAAAParams): unknown {
    const obj: any = {};
    if (message.promoCode !== "") {
      obj.promoCode = message.promoCode;
    }
    if (message.agentCharacter !== undefined) {
      obj.agentCharacter = message.agentCharacter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreationAAAParams>, I>>(base?: I): CreationAAAParams {
    return CreationAAAParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreationAAAParams>, I>>(object: I): CreationAAAParams {
    const message = createBaseCreationAAAParams();
    message.promoCode = object.promoCode ?? "";
    message.agentCharacter = object.agentCharacter ?? undefined;
    return message;
  },
};

function createBaseCreateAAAAppRequest(): CreateAAAAppRequest {
  return { name: "", templateId: "", envList: undefined, config: "", creationMethod: 0, creationParams: undefined };
}

export const CreateAAAAppRequest = {
  encode(message: CreateAAAAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.templateId !== "") {
      writer.uint32(18).string(message.templateId);
    }
    if (message.envList !== undefined) {
      Struct.encode(Struct.wrap(message.envList), writer.uint32(26).fork()).ldelim();
    }
    if (message.config !== "") {
      writer.uint32(34).string(message.config);
    }
    if (message.creationMethod !== 0) {
      writer.uint32(40).int32(message.creationMethod);
    }
    if (message.creationParams !== undefined) {
      CreationAAAParams.encode(message.creationParams, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAAAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAAAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.envList = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.config = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.creationMethod = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.creationParams = CreationAAAParams.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAAAppRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      envList: isObject(object.envList) ? object.envList : undefined,
      config: isSet(object.config) ? globalThis.String(object.config) : "",
      creationMethod: isSet(object.creationMethod) ? creationAAAMethodFromJSON(object.creationMethod) : 0,
      creationParams: isSet(object.creationParams) ? CreationAAAParams.fromJSON(object.creationParams) : undefined,
    };
  },

  toJSON(message: CreateAAAAppRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.envList !== undefined) {
      obj.envList = message.envList;
    }
    if (message.config !== "") {
      obj.config = message.config;
    }
    if (message.creationMethod !== 0) {
      obj.creationMethod = creationAAAMethodToJSON(message.creationMethod);
    }
    if (message.creationParams !== undefined) {
      obj.creationParams = CreationAAAParams.toJSON(message.creationParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAAAppRequest>, I>>(base?: I): CreateAAAAppRequest {
    return CreateAAAAppRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAAAppRequest>, I>>(object: I): CreateAAAAppRequest {
    const message = createBaseCreateAAAAppRequest();
    message.name = object.name ?? "";
    message.templateId = object.templateId ?? "";
    message.envList = object.envList ?? undefined;
    message.config = object.config ?? "";
    message.creationMethod = object.creationMethod ?? 0;
    message.creationParams = (object.creationParams !== undefined && object.creationParams !== null)
      ? CreationAAAParams.fromPartial(object.creationParams)
      : undefined;
    return message;
  },
};

function createBaseCreateAAAAppReply(): CreateAAAAppReply {
  return { app: undefined };
}

export const CreateAAAAppReply = {
  encode(message: CreateAAAAppReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.app !== undefined) {
      AAAApp.encode(message.app, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAAAppReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAAAppReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.app = AAAApp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAAAppReply {
    return { app: isSet(object.app) ? AAAApp.fromJSON(object.app) : undefined };
  },

  toJSON(message: CreateAAAAppReply): unknown {
    const obj: any = {};
    if (message.app !== undefined) {
      obj.app = AAAApp.toJSON(message.app);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAAAppReply>, I>>(base?: I): CreateAAAAppReply {
    return CreateAAAAppReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAAAppReply>, I>>(object: I): CreateAAAAppReply {
    const message = createBaseCreateAAAAppReply();
    message.app = (object.app !== undefined && object.app !== null) ? AAAApp.fromPartial(object.app) : undefined;
    return message;
  },
};

function createBaseListAAAAppRequest(): ListAAAAppRequest {
  return {};
}

export const ListAAAAppRequest = {
  encode(_: ListAAAAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAAAAppRequest {
    return {};
  },

  toJSON(_: ListAAAAppRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppRequest>, I>>(base?: I): ListAAAAppRequest {
    return ListAAAAppRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppRequest>, I>>(_: I): ListAAAAppRequest {
    const message = createBaseListAAAAppRequest();
    return message;
  },
};

function createBaseListAAAAppReply(): ListAAAAppReply {
  return { apps: [] };
}

export const ListAAAAppReply = {
  encode(message: ListAAAAppReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.apps) {
      AAAApp.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apps.push(AAAApp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAAppReply {
    return { apps: globalThis.Array.isArray(object?.apps) ? object.apps.map((e: any) => AAAApp.fromJSON(e)) : [] };
  },

  toJSON(message: ListAAAAppReply): unknown {
    const obj: any = {};
    if (message.apps?.length) {
      obj.apps = message.apps.map((e) => AAAApp.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppReply>, I>>(base?: I): ListAAAAppReply {
    return ListAAAAppReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppReply>, I>>(object: I): ListAAAAppReply {
    const message = createBaseListAAAAppReply();
    message.apps = object.apps?.map((e) => AAAApp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateAAAAppRequest(): UpdateAAAAppRequest {
  return { id: "", config: "" };
}

export const UpdateAAAAppRequest = {
  encode(message: UpdateAAAAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.config !== "") {
      writer.uint32(18).string(message.config);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAAAAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAAAAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAAAAppRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      config: isSet(object.config) ? globalThis.String(object.config) : "",
    };
  },

  toJSON(message: UpdateAAAAppRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.config !== "") {
      obj.config = message.config;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAAAAppRequest>, I>>(base?: I): UpdateAAAAppRequest {
    return UpdateAAAAppRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAAAAppRequest>, I>>(object: I): UpdateAAAAppRequest {
    const message = createBaseUpdateAAAAppRequest();
    message.id = object.id ?? "";
    message.config = object.config ?? "";
    return message;
  },
};

function createBaseUpdateAAAAppReply(): UpdateAAAAppReply {
  return {};
}

export const UpdateAAAAppReply = {
  encode(_: UpdateAAAAppReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAAAAppReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAAAAppReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAAAAppReply {
    return {};
  },

  toJSON(_: UpdateAAAAppReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAAAAppReply>, I>>(base?: I): UpdateAAAAppReply {
    return UpdateAAAAppReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAAAAppReply>, I>>(_: I): UpdateAAAAppReply {
    const message = createBaseUpdateAAAAppReply();
    return message;
  },
};

function createBaseOperateAAAAppRequest(): OperateAAAAppRequest {
  return { id: "", action: 0 };
}

export const OperateAAAAppRequest = {
  encode(message: OperateAAAAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.action !== 0) {
      writer.uint32(16).int32(message.action);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperateAAAAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperateAAAAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OperateAAAAppRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      action: isSet(object.action) ? aAAAppOperateActionFromJSON(object.action) : 0,
    };
  },

  toJSON(message: OperateAAAAppRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.action !== 0) {
      obj.action = aAAAppOperateActionToJSON(message.action);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OperateAAAAppRequest>, I>>(base?: I): OperateAAAAppRequest {
    return OperateAAAAppRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperateAAAAppRequest>, I>>(object: I): OperateAAAAppRequest {
    const message = createBaseOperateAAAAppRequest();
    message.id = object.id ?? "";
    message.action = object.action ?? 0;
    return message;
  },
};

function createBaseOperateAAAAppReply(): OperateAAAAppReply {
  return { ret: "" };
}

export const OperateAAAAppReply = {
  encode(message: OperateAAAAppReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ret !== "") {
      writer.uint32(10).string(message.ret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperateAAAAppReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperateAAAAppReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ret = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OperateAAAAppReply {
    return { ret: isSet(object.ret) ? globalThis.String(object.ret) : "" };
  },

  toJSON(message: OperateAAAAppReply): unknown {
    const obj: any = {};
    if (message.ret !== "") {
      obj.ret = message.ret;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OperateAAAAppReply>, I>>(base?: I): OperateAAAAppReply {
    return OperateAAAAppReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperateAAAAppReply>, I>>(object: I): OperateAAAAppReply {
    const message = createBaseOperateAAAAppReply();
    message.ret = object.ret ?? "";
    return message;
  },
};

function createBaseCreateAAATemplateRequest(): CreateAAATemplateRequest {
  return {
    name: "",
    description: "",
    type: 0,
    envList: [],
    chatSubpath: "",
    configExample: "",
    iconLink: "",
    dockerImage: "",
    requestSchema: undefined,
    responseSchema: undefined,
    port: 0,
  };
}

export const CreateAAATemplateRequest = {
  encode(message: CreateAAATemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    for (const v of message.envList) {
      writer.uint32(34).string(v!);
    }
    if (message.chatSubpath !== "") {
      writer.uint32(42).string(message.chatSubpath);
    }
    if (message.configExample !== "") {
      writer.uint32(50).string(message.configExample);
    }
    if (message.iconLink !== "") {
      writer.uint32(58).string(message.iconLink);
    }
    if (message.dockerImage !== "") {
      writer.uint32(66).string(message.dockerImage);
    }
    if (message.requestSchema !== undefined) {
      Struct.encode(Struct.wrap(message.requestSchema), writer.uint32(74).fork()).ldelim();
    }
    if (message.responseSchema !== undefined) {
      Struct.encode(Struct.wrap(message.responseSchema), writer.uint32(82).fork()).ldelim();
    }
    if (message.port !== 0) {
      writer.uint32(88).uint32(message.port);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAATemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAATemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.envList.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.chatSubpath = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.configExample = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.iconLink = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.dockerImage = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.requestSchema = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.responseSchema = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.port = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAATemplateRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      type: isSet(object.type) ? aAATemplateHostTypeFromJSON(object.type) : 0,
      envList: globalThis.Array.isArray(object?.envList) ? object.envList.map((e: any) => globalThis.String(e)) : [],
      chatSubpath: isSet(object.chatSubpath) ? globalThis.String(object.chatSubpath) : "",
      configExample: isSet(object.configExample) ? globalThis.String(object.configExample) : "",
      iconLink: isSet(object.iconLink) ? globalThis.String(object.iconLink) : "",
      dockerImage: isSet(object.dockerImage) ? globalThis.String(object.dockerImage) : "",
      requestSchema: isObject(object.requestSchema) ? object.requestSchema : undefined,
      responseSchema: isObject(object.responseSchema) ? object.responseSchema : undefined,
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
    };
  },

  toJSON(message: CreateAAATemplateRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.type !== 0) {
      obj.type = aAATemplateHostTypeToJSON(message.type);
    }
    if (message.envList?.length) {
      obj.envList = message.envList;
    }
    if (message.chatSubpath !== "") {
      obj.chatSubpath = message.chatSubpath;
    }
    if (message.configExample !== "") {
      obj.configExample = message.configExample;
    }
    if (message.iconLink !== "") {
      obj.iconLink = message.iconLink;
    }
    if (message.dockerImage !== "") {
      obj.dockerImage = message.dockerImage;
    }
    if (message.requestSchema !== undefined) {
      obj.requestSchema = message.requestSchema;
    }
    if (message.responseSchema !== undefined) {
      obj.responseSchema = message.responseSchema;
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAATemplateRequest>, I>>(base?: I): CreateAAATemplateRequest {
    return CreateAAATemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAATemplateRequest>, I>>(object: I): CreateAAATemplateRequest {
    const message = createBaseCreateAAATemplateRequest();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.type = object.type ?? 0;
    message.envList = object.envList?.map((e) => e) || [];
    message.chatSubpath = object.chatSubpath ?? "";
    message.configExample = object.configExample ?? "";
    message.iconLink = object.iconLink ?? "";
    message.dockerImage = object.dockerImage ?? "";
    message.requestSchema = object.requestSchema ?? undefined;
    message.responseSchema = object.responseSchema ?? undefined;
    message.port = object.port ?? 0;
    return message;
  },
};

function createBaseCreateAAATemplateReply(): CreateAAATemplateReply {
  return { template: undefined };
}

export const CreateAAATemplateReply = {
  encode(message: CreateAAATemplateReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.template !== undefined) {
      AAATemplate.encode(message.template, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAATemplateReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAATemplateReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.template = AAATemplate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAATemplateReply {
    return { template: isSet(object.template) ? AAATemplate.fromJSON(object.template) : undefined };
  },

  toJSON(message: CreateAAATemplateReply): unknown {
    const obj: any = {};
    if (message.template !== undefined) {
      obj.template = AAATemplate.toJSON(message.template);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAATemplateReply>, I>>(base?: I): CreateAAATemplateReply {
    return CreateAAATemplateReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAATemplateReply>, I>>(object: I): CreateAAATemplateReply {
    const message = createBaseCreateAAATemplateReply();
    message.template = (object.template !== undefined && object.template !== null)
      ? AAATemplate.fromPartial(object.template)
      : undefined;
    return message;
  },
};

function createBaseListAAATemplateRequest(): ListAAATemplateRequest {
  return { isPending: false };
}

export const ListAAATemplateRequest = {
  encode(message: ListAAATemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isPending !== false) {
      writer.uint32(8).bool(message.isPending);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAATemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAATemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isPending = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAATemplateRequest {
    return { isPending: isSet(object.isPending) ? globalThis.Boolean(object.isPending) : false };
  },

  toJSON(message: ListAAATemplateRequest): unknown {
    const obj: any = {};
    if (message.isPending !== false) {
      obj.isPending = message.isPending;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAATemplateRequest>, I>>(base?: I): ListAAATemplateRequest {
    return ListAAATemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAATemplateRequest>, I>>(object: I): ListAAATemplateRequest {
    const message = createBaseListAAATemplateRequest();
    message.isPending = object.isPending ?? false;
    return message;
  },
};

function createBaseListAAATemplateReply(): ListAAATemplateReply {
  return { builtin: [], appStore: [], selfOwned: [] };
}

export const ListAAATemplateReply = {
  encode(message: ListAAATemplateReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.builtin) {
      AAATemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.appStore) {
      AAATemplate.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.selfOwned) {
      AAATemplate.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAATemplateReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAATemplateReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.builtin.push(AAATemplate.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.appStore.push(AAATemplate.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selfOwned.push(AAATemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAATemplateReply {
    return {
      builtin: globalThis.Array.isArray(object?.builtin) ? object.builtin.map((e: any) => AAATemplate.fromJSON(e)) : [],
      appStore: globalThis.Array.isArray(object?.appStore)
        ? object.appStore.map((e: any) => AAATemplate.fromJSON(e))
        : [],
      selfOwned: globalThis.Array.isArray(object?.selfOwned)
        ? object.selfOwned.map((e: any) => AAATemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAAATemplateReply): unknown {
    const obj: any = {};
    if (message.builtin?.length) {
      obj.builtin = message.builtin.map((e) => AAATemplate.toJSON(e));
    }
    if (message.appStore?.length) {
      obj.appStore = message.appStore.map((e) => AAATemplate.toJSON(e));
    }
    if (message.selfOwned?.length) {
      obj.selfOwned = message.selfOwned.map((e) => AAATemplate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAATemplateReply>, I>>(base?: I): ListAAATemplateReply {
    return ListAAATemplateReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAATemplateReply>, I>>(object: I): ListAAATemplateReply {
    const message = createBaseListAAATemplateReply();
    message.builtin = object.builtin?.map((e) => AAATemplate.fromPartial(e)) || [];
    message.appStore = object.appStore?.map((e) => AAATemplate.fromPartial(e)) || [];
    message.selfOwned = object.selfOwned?.map((e) => AAATemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListAAAAppLogRequest(): ListAAAAppLogRequest {
  return { id: "" };
}

export const ListAAAAppLogRequest = {
  encode(message: ListAAAAppLogRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppLogRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppLogRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAAppLogRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: ListAAAAppLogRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppLogRequest>, I>>(base?: I): ListAAAAppLogRequest {
    return ListAAAAppLogRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppLogRequest>, I>>(object: I): ListAAAAppLogRequest {
    const message = createBaseListAAAAppLogRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseListAAAAppLogReply(): ListAAAAppLogReply {
  return { log: "" };
}

export const ListAAAAppLogReply = {
  encode(message: ListAAAAppLogReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.log !== "") {
      writer.uint32(10).string(message.log);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppLogReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppLogReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.log = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAAppLogReply {
    return { log: isSet(object.log) ? globalThis.String(object.log) : "" };
  },

  toJSON(message: ListAAAAppLogReply): unknown {
    const obj: any = {};
    if (message.log !== "") {
      obj.log = message.log;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppLogReply>, I>>(base?: I): ListAAAAppLogReply {
    return ListAAAAppLogReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppLogReply>, I>>(object: I): ListAAAAppLogReply {
    const message = createBaseListAAAAppLogReply();
    message.log = object.log ?? "";
    return message;
  },
};

function createBaseTask(): Task {
  return {
    id: "",
    name: "",
    description: "",
    createdAt: undefined,
    updatedAt: undefined,
    startDate: undefined,
    endDate: undefined,
    participants: {},
  };
}

export const Task = {
  encode(message: Task, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(50).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(58).fork()).ldelim();
    }
    Object.entries(message.participants).forEach(([key, value]) => {
      Task_ParticipantsEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Task {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = Task_ParticipantsEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.participants[entry8.key] = entry8.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Task {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
      participants: isObject(object.participants)
        ? Object.entries(object.participants).reduce<{ [key: string]: Participant }>((acc, [key, value]) => {
          acc[key] = Participant.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Task): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.startDate !== undefined) {
      obj.startDate = message.startDate.toISOString();
    }
    if (message.endDate !== undefined) {
      obj.endDate = message.endDate.toISOString();
    }
    if (message.participants) {
      const entries = Object.entries(message.participants);
      if (entries.length > 0) {
        obj.participants = {};
        entries.forEach(([k, v]) => {
          obj.participants[k] = Participant.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Task>, I>>(base?: I): Task {
    return Task.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Task>, I>>(object: I): Task {
    const message = createBaseTask();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.participants = Object.entries(object.participants ?? {}).reduce<{ [key: string]: Participant }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Participant.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseTask_ParticipantsEntry(): Task_ParticipantsEntry {
  return { key: "", value: undefined };
}

export const Task_ParticipantsEntry = {
  encode(message: Task_ParticipantsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Participant.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Task_ParticipantsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTask_ParticipantsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Participant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Task_ParticipantsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Participant.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Task_ParticipantsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Participant.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Task_ParticipantsEntry>, I>>(base?: I): Task_ParticipantsEntry {
    return Task_ParticipantsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Task_ParticipantsEntry>, I>>(object: I): Task_ParticipantsEntry {
    const message = createBaseTask_ParticipantsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Participant.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseParticipant(): Participant {
  return { id: "", taskId: "", name: "", impressions: 0, lastPostId: "" };
}

export const Participant = {
  encode(message: Participant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.taskId !== "") {
      writer.uint32(18).string(message.taskId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.impressions !== 0) {
      writer.uint32(32).int64(message.impressions);
    }
    if (message.lastPostId !== "") {
      writer.uint32(42).string(message.lastPostId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Participant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParticipant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.taskId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.impressions = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastPostId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Participant {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      taskId: isSet(object.taskId) ? globalThis.String(object.taskId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      impressions: isSet(object.impressions) ? globalThis.Number(object.impressions) : 0,
      lastPostId: isSet(object.lastPostId) ? globalThis.String(object.lastPostId) : "",
    };
  },

  toJSON(message: Participant): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.taskId !== "") {
      obj.taskId = message.taskId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.impressions !== 0) {
      obj.impressions = Math.round(message.impressions);
    }
    if (message.lastPostId !== "") {
      obj.lastPostId = message.lastPostId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Participant>, I>>(base?: I): Participant {
    return Participant.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Participant>, I>>(object: I): Participant {
    const message = createBaseParticipant();
    message.id = object.id ?? "";
    message.taskId = object.taskId ?? "";
    message.name = object.name ?? "";
    message.impressions = object.impressions ?? 0;
    message.lastPostId = object.lastPostId ?? "";
    return message;
  },
};

function createBaseCreateTaskRequest(): CreateTaskRequest {
  return { name: "", startDate: undefined, endDate: undefined, participants: {} };
}

export const CreateTaskRequest = {
  encode(message: CreateTaskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
    }
    Object.entries(message.participants).forEach(([key, value]) => {
      CreateTaskRequest_ParticipantsEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTaskRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = CreateTaskRequest_ParticipantsEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.participants[entry5.key] = entry5.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTaskRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
      participants: isObject(object.participants)
        ? Object.entries(object.participants).reduce<{ [key: string]: Participant }>((acc, [key, value]) => {
          acc[key] = Participant.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: CreateTaskRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.startDate !== undefined) {
      obj.startDate = message.startDate.toISOString();
    }
    if (message.endDate !== undefined) {
      obj.endDate = message.endDate.toISOString();
    }
    if (message.participants) {
      const entries = Object.entries(message.participants);
      if (entries.length > 0) {
        obj.participants = {};
        entries.forEach(([k, v]) => {
          obj.participants[k] = Participant.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTaskRequest>, I>>(base?: I): CreateTaskRequest {
    return CreateTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTaskRequest>, I>>(object: I): CreateTaskRequest {
    const message = createBaseCreateTaskRequest();
    message.name = object.name ?? "";
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.participants = Object.entries(object.participants ?? {}).reduce<{ [key: string]: Participant }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Participant.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseCreateTaskRequest_ParticipantsEntry(): CreateTaskRequest_ParticipantsEntry {
  return { key: "", value: undefined };
}

export const CreateTaskRequest_ParticipantsEntry = {
  encode(message: CreateTaskRequest_ParticipantsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Participant.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTaskRequest_ParticipantsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTaskRequest_ParticipantsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Participant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTaskRequest_ParticipantsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Participant.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CreateTaskRequest_ParticipantsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Participant.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTaskRequest_ParticipantsEntry>, I>>(
    base?: I,
  ): CreateTaskRequest_ParticipantsEntry {
    return CreateTaskRequest_ParticipantsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTaskRequest_ParticipantsEntry>, I>>(
    object: I,
  ): CreateTaskRequest_ParticipantsEntry {
    const message = createBaseCreateTaskRequest_ParticipantsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Participant.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseCreateTaskReply(): CreateTaskReply {
  return { task: undefined };
}

export const CreateTaskReply = {
  encode(message: CreateTaskReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.task !== undefined) {
      Task.encode(message.task, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTaskReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTaskReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.task = Task.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTaskReply {
    return { task: isSet(object.task) ? Task.fromJSON(object.task) : undefined };
  },

  toJSON(message: CreateTaskReply): unknown {
    const obj: any = {};
    if (message.task !== undefined) {
      obj.task = Task.toJSON(message.task);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTaskReply>, I>>(base?: I): CreateTaskReply {
    return CreateTaskReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTaskReply>, I>>(object: I): CreateTaskReply {
    const message = createBaseCreateTaskReply();
    message.task = (object.task !== undefined && object.task !== null) ? Task.fromPartial(object.task) : undefined;
    return message;
  },
};

function createBaseGetTaskRequest(): GetTaskRequest {
  return { id: "" };
}

export const GetTaskRequest = {
  encode(message: GetTaskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTaskRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTaskRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: GetTaskRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTaskRequest>, I>>(base?: I): GetTaskRequest {
    return GetTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTaskRequest>, I>>(object: I): GetTaskRequest {
    const message = createBaseGetTaskRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseUpdateTaskRequest(): UpdateTaskRequest {
  return { id: "", name: "", description: "", startDate: undefined, endDate: undefined, participants: {} };
}

export const UpdateTaskRequest = {
  encode(message: UpdateTaskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(42).fork()).ldelim();
    }
    Object.entries(message.participants).forEach(([key, value]) => {
      UpdateTaskRequest_ParticipantsEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTaskRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = UpdateTaskRequest_ParticipantsEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.participants[entry6.key] = entry6.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTaskRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
      participants: isObject(object.participants)
        ? Object.entries(object.participants).reduce<{ [key: string]: Participant }>((acc, [key, value]) => {
          acc[key] = Participant.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: UpdateTaskRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.startDate !== undefined) {
      obj.startDate = message.startDate.toISOString();
    }
    if (message.endDate !== undefined) {
      obj.endDate = message.endDate.toISOString();
    }
    if (message.participants) {
      const entries = Object.entries(message.participants);
      if (entries.length > 0) {
        obj.participants = {};
        entries.forEach(([k, v]) => {
          obj.participants[k] = Participant.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTaskRequest>, I>>(base?: I): UpdateTaskRequest {
    return UpdateTaskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTaskRequest>, I>>(object: I): UpdateTaskRequest {
    const message = createBaseUpdateTaskRequest();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.participants = Object.entries(object.participants ?? {}).reduce<{ [key: string]: Participant }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Participant.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUpdateTaskRequest_ParticipantsEntry(): UpdateTaskRequest_ParticipantsEntry {
  return { key: "", value: undefined };
}

export const UpdateTaskRequest_ParticipantsEntry = {
  encode(message: UpdateTaskRequest_ParticipantsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Participant.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTaskRequest_ParticipantsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTaskRequest_ParticipantsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Participant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTaskRequest_ParticipantsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Participant.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: UpdateTaskRequest_ParticipantsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Participant.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTaskRequest_ParticipantsEntry>, I>>(
    base?: I,
  ): UpdateTaskRequest_ParticipantsEntry {
    return UpdateTaskRequest_ParticipantsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTaskRequest_ParticipantsEntry>, I>>(
    object: I,
  ): UpdateTaskRequest_ParticipantsEntry {
    const message = createBaseUpdateTaskRequest_ParticipantsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Participant.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseUpdateTaskReply(): UpdateTaskReply {
  return { task: undefined };
}

export const UpdateTaskReply = {
  encode(message: UpdateTaskReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.task !== undefined) {
      Task.encode(message.task, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTaskReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTaskReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.task = Task.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTaskReply {
    return { task: isSet(object.task) ? Task.fromJSON(object.task) : undefined };
  },

  toJSON(message: UpdateTaskReply): unknown {
    const obj: any = {};
    if (message.task !== undefined) {
      obj.task = Task.toJSON(message.task);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTaskReply>, I>>(base?: I): UpdateTaskReply {
    return UpdateTaskReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTaskReply>, I>>(object: I): UpdateTaskReply {
    const message = createBaseUpdateTaskReply();
    message.task = (object.task !== undefined && object.task !== null) ? Task.fromPartial(object.task) : undefined;
    return message;
  },
};

function createBaseTelegramChannel(): TelegramChannel {
  return { channelId: "", channelName: "" };
}

export const TelegramChannel = {
  encode(message: TelegramChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.channelId !== "") {
      writer.uint32(10).string(message.channelId);
    }
    if (message.channelName !== "") {
      writer.uint32(18).string(message.channelName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TelegramChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTelegramChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.channelId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.channelName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TelegramChannel {
    return {
      channelId: isSet(object.channelId) ? globalThis.String(object.channelId) : "",
      channelName: isSet(object.channelName) ? globalThis.String(object.channelName) : "",
    };
  },

  toJSON(message: TelegramChannel): unknown {
    const obj: any = {};
    if (message.channelId !== "") {
      obj.channelId = message.channelId;
    }
    if (message.channelName !== "") {
      obj.channelName = message.channelName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TelegramChannel>, I>>(base?: I): TelegramChannel {
    return TelegramChannel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TelegramChannel>, I>>(object: I): TelegramChannel {
    const message = createBaseTelegramChannel();
    message.channelId = object.channelId ?? "";
    message.channelName = object.channelName ?? "";
    return message;
  },
};

function createBaseUpdateFineTuneSourceRequest(): UpdateFineTuneSourceRequest {
  return { twittersProfiles: [], telegramChannels: [] };
}

export const UpdateFineTuneSourceRequest = {
  encode(message: UpdateFineTuneSourceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.twittersProfiles) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.telegramChannels) {
      TelegramChannel.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFineTuneSourceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFineTuneSourceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.twittersProfiles.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.telegramChannels.push(TelegramChannel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateFineTuneSourceRequest {
    return {
      twittersProfiles: globalThis.Array.isArray(object?.twittersProfiles)
        ? object.twittersProfiles.map((e: any) => globalThis.String(e))
        : [],
      telegramChannels: globalThis.Array.isArray(object?.telegramChannels)
        ? object.telegramChannels.map((e: any) => TelegramChannel.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateFineTuneSourceRequest): unknown {
    const obj: any = {};
    if (message.twittersProfiles?.length) {
      obj.twittersProfiles = message.twittersProfiles;
    }
    if (message.telegramChannels?.length) {
      obj.telegramChannels = message.telegramChannels.map((e) => TelegramChannel.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFineTuneSourceRequest>, I>>(base?: I): UpdateFineTuneSourceRequest {
    return UpdateFineTuneSourceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFineTuneSourceRequest>, I>>(object: I): UpdateFineTuneSourceRequest {
    const message = createBaseUpdateFineTuneSourceRequest();
    message.twittersProfiles = object.twittersProfiles?.map((e) => e) || [];
    message.telegramChannels = object.telegramChannels?.map((e) => TelegramChannel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFineTuneSourceReply(): UpdateFineTuneSourceReply {
  return {};
}

export const UpdateFineTuneSourceReply = {
  encode(_: UpdateFineTuneSourceReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFineTuneSourceReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFineTuneSourceReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateFineTuneSourceReply {
    return {};
  },

  toJSON(_: UpdateFineTuneSourceReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateFineTuneSourceReply>, I>>(base?: I): UpdateFineTuneSourceReply {
    return UpdateFineTuneSourceReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFineTuneSourceReply>, I>>(_: I): UpdateFineTuneSourceReply {
    const message = createBaseUpdateFineTuneSourceReply();
    return message;
  },
};

function createBaseGetFineTuneSourceRequest(): GetFineTuneSourceRequest {
  return {};
}

export const GetFineTuneSourceRequest = {
  encode(_: GetFineTuneSourceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFineTuneSourceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFineTuneSourceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetFineTuneSourceRequest {
    return {};
  },

  toJSON(_: GetFineTuneSourceRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFineTuneSourceRequest>, I>>(base?: I): GetFineTuneSourceRequest {
    return GetFineTuneSourceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFineTuneSourceRequest>, I>>(_: I): GetFineTuneSourceRequest {
    const message = createBaseGetFineTuneSourceRequest();
    return message;
  },
};

function createBaseGetFineTuneSourceReply(): GetFineTuneSourceReply {
  return { twittersProfiles: [], telegramChannels: [] };
}

export const GetFineTuneSourceReply = {
  encode(message: GetFineTuneSourceReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.twittersProfiles) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.telegramChannels) {
      TelegramChannel.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFineTuneSourceReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFineTuneSourceReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.twittersProfiles.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.telegramChannels.push(TelegramChannel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFineTuneSourceReply {
    return {
      twittersProfiles: globalThis.Array.isArray(object?.twittersProfiles)
        ? object.twittersProfiles.map((e: any) => globalThis.String(e))
        : [],
      telegramChannels: globalThis.Array.isArray(object?.telegramChannels)
        ? object.telegramChannels.map((e: any) => TelegramChannel.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetFineTuneSourceReply): unknown {
    const obj: any = {};
    if (message.twittersProfiles?.length) {
      obj.twittersProfiles = message.twittersProfiles;
    }
    if (message.telegramChannels?.length) {
      obj.telegramChannels = message.telegramChannels.map((e) => TelegramChannel.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFineTuneSourceReply>, I>>(base?: I): GetFineTuneSourceReply {
    return GetFineTuneSourceReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFineTuneSourceReply>, I>>(object: I): GetFineTuneSourceReply {
    const message = createBaseGetFineTuneSourceReply();
    message.twittersProfiles = object.twittersProfiles?.map((e) => e) || [];
    message.telegramChannels = object.telegramChannels?.map((e) => TelegramChannel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateFineTuneJobRequest(): CreateFineTuneJobRequest {
  return { twittersProfiles: [], telegramChannelIds: [], appId: "" };
}

export const CreateFineTuneJobRequest = {
  encode(message: CreateFineTuneJobRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.twittersProfiles) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.telegramChannelIds) {
      writer.uint32(18).string(v!);
    }
    if (message.appId !== "") {
      writer.uint32(26).string(message.appId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFineTuneJobRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFineTuneJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.twittersProfiles.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.telegramChannelIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.appId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateFineTuneJobRequest {
    return {
      twittersProfiles: globalThis.Array.isArray(object?.twittersProfiles)
        ? object.twittersProfiles.map((e: any) => globalThis.String(e))
        : [],
      telegramChannelIds: globalThis.Array.isArray(object?.telegramChannelIds)
        ? object.telegramChannelIds.map((e: any) => globalThis.String(e))
        : [],
      appId: isSet(object.appId) ? globalThis.String(object.appId) : "",
    };
  },

  toJSON(message: CreateFineTuneJobRequest): unknown {
    const obj: any = {};
    if (message.twittersProfiles?.length) {
      obj.twittersProfiles = message.twittersProfiles;
    }
    if (message.telegramChannelIds?.length) {
      obj.telegramChannelIds = message.telegramChannelIds;
    }
    if (message.appId !== "") {
      obj.appId = message.appId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateFineTuneJobRequest>, I>>(base?: I): CreateFineTuneJobRequest {
    return CreateFineTuneJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFineTuneJobRequest>, I>>(object: I): CreateFineTuneJobRequest {
    const message = createBaseCreateFineTuneJobRequest();
    message.twittersProfiles = object.twittersProfiles?.map((e) => e) || [];
    message.telegramChannelIds = object.telegramChannelIds?.map((e) => e) || [];
    message.appId = object.appId ?? "";
    return message;
  },
};

function createBaseCreateFineTuneJobReply(): CreateFineTuneJobReply {
  return {};
}

export const CreateFineTuneJobReply = {
  encode(_: CreateFineTuneJobReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFineTuneJobReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFineTuneJobReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateFineTuneJobReply {
    return {};
  },

  toJSON(_: CreateFineTuneJobReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateFineTuneJobReply>, I>>(base?: I): CreateFineTuneJobReply {
    return CreateFineTuneJobReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFineTuneJobReply>, I>>(_: I): CreateFineTuneJobReply {
    const message = createBaseCreateFineTuneJobReply();
    return message;
  },
};

function createBaseValidateFineTuneJobReply(): ValidateFineTuneJobReply {
  return { isValidate: false, reason: "" };
}

export const ValidateFineTuneJobReply = {
  encode(message: ValidateFineTuneJobReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isValidate !== false) {
      writer.uint32(8).bool(message.isValidate);
    }
    if (message.reason !== "") {
      writer.uint32(18).string(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateFineTuneJobReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateFineTuneJobReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isValidate = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidateFineTuneJobReply {
    return {
      isValidate: isSet(object.isValidate) ? globalThis.Boolean(object.isValidate) : false,
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
    };
  },

  toJSON(message: ValidateFineTuneJobReply): unknown {
    const obj: any = {};
    if (message.isValidate !== false) {
      obj.isValidate = message.isValidate;
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidateFineTuneJobReply>, I>>(base?: I): ValidateFineTuneJobReply {
    return ValidateFineTuneJobReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidateFineTuneJobReply>, I>>(object: I): ValidateFineTuneJobReply {
    const message = createBaseValidateFineTuneJobReply();
    message.isValidate = object.isValidate ?? false;
    message.reason = object.reason ?? "";
    return message;
  },
};

function createBaseListFineTuneJobRequest(): ListFineTuneJobRequest {
  return { appId: "" };
}

export const ListFineTuneJobRequest = {
  encode(message: ListFineTuneJobRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appId !== "") {
      writer.uint32(18).string(message.appId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFineTuneJobRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFineTuneJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.appId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFineTuneJobRequest {
    return { appId: isSet(object.appId) ? globalThis.String(object.appId) : "" };
  },

  toJSON(message: ListFineTuneJobRequest): unknown {
    const obj: any = {};
    if (message.appId !== "") {
      obj.appId = message.appId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFineTuneJobRequest>, I>>(base?: I): ListFineTuneJobRequest {
    return ListFineTuneJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFineTuneJobRequest>, I>>(object: I): ListFineTuneJobRequest {
    const message = createBaseListFineTuneJobRequest();
    message.appId = object.appId ?? "";
    return message;
  },
};

function createBaseListFineTuneJobReply(): ListFineTuneJobReply {
  return { twittersProfiles: [], telegramChannelIds: [] };
}

export const ListFineTuneJobReply = {
  encode(message: ListFineTuneJobReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.twittersProfiles) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.telegramChannelIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFineTuneJobReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFineTuneJobReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.twittersProfiles.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.telegramChannelIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListFineTuneJobReply {
    return {
      twittersProfiles: globalThis.Array.isArray(object?.twittersProfiles)
        ? object.twittersProfiles.map((e: any) => globalThis.String(e))
        : [],
      telegramChannelIds: globalThis.Array.isArray(object?.telegramChannelIds)
        ? object.telegramChannelIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ListFineTuneJobReply): unknown {
    const obj: any = {};
    if (message.twittersProfiles?.length) {
      obj.twittersProfiles = message.twittersProfiles;
    }
    if (message.telegramChannelIds?.length) {
      obj.telegramChannelIds = message.telegramChannelIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListFineTuneJobReply>, I>>(base?: I): ListFineTuneJobReply {
    return ListFineTuneJobReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListFineTuneJobReply>, I>>(object: I): ListFineTuneJobReply {
    const message = createBaseListFineTuneJobReply();
    message.twittersProfiles = object.twittersProfiles?.map((e) => e) || [];
    message.telegramChannelIds = object.telegramChannelIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateGenerateCharacterJobRequest(): CreateGenerateCharacterJobRequest {
  return { twitterAccount: "" };
}

export const CreateGenerateCharacterJobRequest = {
  encode(message: CreateGenerateCharacterJobRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.twitterAccount !== "") {
      writer.uint32(10).string(message.twitterAccount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateGenerateCharacterJobRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateGenerateCharacterJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.twitterAccount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateGenerateCharacterJobRequest {
    return { twitterAccount: isSet(object.twitterAccount) ? globalThis.String(object.twitterAccount) : "" };
  },

  toJSON(message: CreateGenerateCharacterJobRequest): unknown {
    const obj: any = {};
    if (message.twitterAccount !== "") {
      obj.twitterAccount = message.twitterAccount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateGenerateCharacterJobRequest>, I>>(
    base?: I,
  ): CreateGenerateCharacterJobRequest {
    return CreateGenerateCharacterJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateGenerateCharacterJobRequest>, I>>(
    object: I,
  ): CreateGenerateCharacterJobRequest {
    const message = createBaseCreateGenerateCharacterJobRequest();
    message.twitterAccount = object.twitterAccount ?? "";
    return message;
  },
};

function createBaseCreateGenerateCharacterJobReply(): CreateGenerateCharacterJobReply {
  return { jobId: "" };
}

export const CreateGenerateCharacterJobReply = {
  encode(message: CreateGenerateCharacterJobReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateGenerateCharacterJobReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateGenerateCharacterJobReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateGenerateCharacterJobReply {
    return { jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "" };
  },

  toJSON(message: CreateGenerateCharacterJobReply): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateGenerateCharacterJobReply>, I>>(base?: I): CreateGenerateCharacterJobReply {
    return CreateGenerateCharacterJobReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateGenerateCharacterJobReply>, I>>(
    object: I,
  ): CreateGenerateCharacterJobReply {
    const message = createBaseCreateGenerateCharacterJobReply();
    message.jobId = object.jobId ?? "";
    return message;
  },
};

function createBaseGetCharacterGenerateJobRequest(): GetCharacterGenerateJobRequest {
  return { jobId: "" };
}

export const GetCharacterGenerateJobRequest = {
  encode(message: GetCharacterGenerateJobRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCharacterGenerateJobRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCharacterGenerateJobRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCharacterGenerateJobRequest {
    return { jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "" };
  },

  toJSON(message: GetCharacterGenerateJobRequest): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCharacterGenerateJobRequest>, I>>(base?: I): GetCharacterGenerateJobRequest {
    return GetCharacterGenerateJobRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCharacterGenerateJobRequest>, I>>(
    object: I,
  ): GetCharacterGenerateJobRequest {
    const message = createBaseGetCharacterGenerateJobRequest();
    message.jobId = object.jobId ?? "";
    return message;
  },
};

function createBaseGetCharacterGenerateJobReply(): GetCharacterGenerateJobReply {
  return { isFinished: false, isFailed: false, failedReason: "", twitterAccount: "", characterConfig: "" };
}

export const GetCharacterGenerateJobReply = {
  encode(message: GetCharacterGenerateJobReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isFinished !== false) {
      writer.uint32(8).bool(message.isFinished);
    }
    if (message.isFailed !== false) {
      writer.uint32(16).bool(message.isFailed);
    }
    if (message.failedReason !== "") {
      writer.uint32(26).string(message.failedReason);
    }
    if (message.twitterAccount !== "") {
      writer.uint32(34).string(message.twitterAccount);
    }
    if (message.characterConfig !== "") {
      writer.uint32(42).string(message.characterConfig);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCharacterGenerateJobReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCharacterGenerateJobReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isFinished = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isFailed = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.failedReason = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.twitterAccount = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.characterConfig = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCharacterGenerateJobReply {
    return {
      isFinished: isSet(object.isFinished) ? globalThis.Boolean(object.isFinished) : false,
      isFailed: isSet(object.isFailed) ? globalThis.Boolean(object.isFailed) : false,
      failedReason: isSet(object.failedReason) ? globalThis.String(object.failedReason) : "",
      twitterAccount: isSet(object.twitterAccount) ? globalThis.String(object.twitterAccount) : "",
      characterConfig: isSet(object.characterConfig) ? globalThis.String(object.characterConfig) : "",
    };
  },

  toJSON(message: GetCharacterGenerateJobReply): unknown {
    const obj: any = {};
    if (message.isFinished !== false) {
      obj.isFinished = message.isFinished;
    }
    if (message.isFailed !== false) {
      obj.isFailed = message.isFailed;
    }
    if (message.failedReason !== "") {
      obj.failedReason = message.failedReason;
    }
    if (message.twitterAccount !== "") {
      obj.twitterAccount = message.twitterAccount;
    }
    if (message.characterConfig !== "") {
      obj.characterConfig = message.characterConfig;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCharacterGenerateJobReply>, I>>(base?: I): GetCharacterGenerateJobReply {
    return GetCharacterGenerateJobReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCharacterGenerateJobReply>, I>>(object: I): GetCharacterGenerateJobReply {
    const message = createBaseGetCharacterGenerateJobReply();
    message.isFinished = object.isFinished ?? false;
    message.isFailed = object.isFailed ?? false;
    message.failedReason = object.failedReason ?? "";
    message.twitterAccount = object.twitterAccount ?? "";
    message.characterConfig = object.characterConfig ?? "";
    return message;
  },
};

function createBaseCharacterGenerateJobBaseInfo(): CharacterGenerateJobBaseInfo {
  return { jobId: "", isFinished: false, twitterAccount: "" };
}

export const CharacterGenerateJobBaseInfo = {
  encode(message: CharacterGenerateJobBaseInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.isFinished !== false) {
      writer.uint32(16).bool(message.isFinished);
    }
    if (message.twitterAccount !== "") {
      writer.uint32(26).string(message.twitterAccount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CharacterGenerateJobBaseInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCharacterGenerateJobBaseInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isFinished = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.twitterAccount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CharacterGenerateJobBaseInfo {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      isFinished: isSet(object.isFinished) ? globalThis.Boolean(object.isFinished) : false,
      twitterAccount: isSet(object.twitterAccount) ? globalThis.String(object.twitterAccount) : "",
    };
  },

  toJSON(message: CharacterGenerateJobBaseInfo): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.isFinished !== false) {
      obj.isFinished = message.isFinished;
    }
    if (message.twitterAccount !== "") {
      obj.twitterAccount = message.twitterAccount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CharacterGenerateJobBaseInfo>, I>>(base?: I): CharacterGenerateJobBaseInfo {
    return CharacterGenerateJobBaseInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CharacterGenerateJobBaseInfo>, I>>(object: I): CharacterGenerateJobBaseInfo {
    const message = createBaseCharacterGenerateJobBaseInfo();
    message.jobId = object.jobId ?? "";
    message.isFinished = object.isFinished ?? false;
    message.twitterAccount = object.twitterAccount ?? "";
    return message;
  },
};

function createBaseListCharacterGenerateJobsRequest(): ListCharacterGenerateJobsRequest {
  return {};
}

export const ListCharacterGenerateJobsRequest = {
  encode(_: ListCharacterGenerateJobsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCharacterGenerateJobsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCharacterGenerateJobsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListCharacterGenerateJobsRequest {
    return {};
  },

  toJSON(_: ListCharacterGenerateJobsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCharacterGenerateJobsRequest>, I>>(
    base?: I,
  ): ListCharacterGenerateJobsRequest {
    return ListCharacterGenerateJobsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCharacterGenerateJobsRequest>, I>>(
    _: I,
  ): ListCharacterGenerateJobsRequest {
    const message = createBaseListCharacterGenerateJobsRequest();
    return message;
  },
};

function createBaseListCharacterGenerateJobsReply(): ListCharacterGenerateJobsReply {
  return { jobs: [] };
}

export const ListCharacterGenerateJobsReply = {
  encode(message: ListCharacterGenerateJobsReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.jobs) {
      CharacterGenerateJobBaseInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCharacterGenerateJobsReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCharacterGenerateJobsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobs.push(CharacterGenerateJobBaseInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCharacterGenerateJobsReply {
    return {
      jobs: globalThis.Array.isArray(object?.jobs)
        ? object.jobs.map((e: any) => CharacterGenerateJobBaseInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListCharacterGenerateJobsReply): unknown {
    const obj: any = {};
    if (message.jobs?.length) {
      obj.jobs = message.jobs.map((e) => CharacterGenerateJobBaseInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCharacterGenerateJobsReply>, I>>(base?: I): ListCharacterGenerateJobsReply {
    return ListCharacterGenerateJobsReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCharacterGenerateJobsReply>, I>>(
    object: I,
  ): ListCharacterGenerateJobsReply {
    const message = createBaseListCharacterGenerateJobsReply();
    message.jobs = object.jobs?.map((e) => CharacterGenerateJobBaseInfo.fromPartial(e)) || [];
    return message;
  },
};

export type AAAServiceDefinition = typeof AAAServiceDefinition;
export const AAAServiceDefinition = {
  name: "AAAService",
  fullName: "bff.v1.AAAService",
  methods: {
    listAAAApp: {
      name: "ListAAAApp",
      requestType: ListAAAAppRequest,
      requestStream: false,
      responseType: ListAAAAppReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([18, 18, 16, 47, 118, 49, 47, 98, 102, 102, 47, 97, 97, 97, 47, 97, 112, 112, 115]),
          ],
        },
      },
    },
    createAAAApp: {
      name: "CreateAAAApp",
      requestType: CreateAAAAppRequest,
      requestStream: false,
      responseType: CreateAAAAppReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              21,
              34,
              16,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              97,
              112,
              112,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    operateAAAApp: {
      name: "OperateAAAApp",
      requestType: OperateAAAAppRequest,
      requestStream: false,
      responseType: OperateAAAAppReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              29,
              34,
              24,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              97,
              112,
              112,
              115,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    updateAAAApp: {
      name: "UpdateAAAApp",
      requestType: UpdateAAAAppRequest,
      requestStream: false,
      responseType: UpdateAAAAppReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              97,
              112,
              112,
              115,
              47,
              117,
              112,
              100,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    listAAATemplate: {
      name: "ListAAATemplate",
      requestType: ListAAATemplateRequest,
      requestStream: false,
      responseType: ListAAATemplateReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              23,
              18,
              21,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              116,
              101,
              109,
              112,
              108,
              97,
              116,
              101,
              115,
            ]),
          ],
        },
      },
    },
    createAAATemplate: {
      name: "CreateAAATemplate",
      requestType: CreateAAATemplateRequest,
      requestStream: false,
      responseType: CreateAAATemplateReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              26,
              34,
              21,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              116,
              101,
              109,
              112,
              108,
              97,
              116,
              101,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    operateAAATemplate: {
      name: "OperateAAATemplate",
      requestType: OperateAAATemplateRequest,
      requestStream: false,
      responseType: OperateAAATemplateReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              34,
              34,
              29,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              116,
              101,
              109,
              112,
              108,
              97,
              116,
              101,
              115,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    listAAAAppLog: {
      name: "ListAAAAppLog",
      requestType: ListAAAAppLogRequest,
      requestStream: false,
      responseType: ListAAAAppLogReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              27,
              18,
              25,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              97,
              112,
              112,
              47,
              108,
              111,
              103,
              115,
              47,
              123,
              105,
              100,
              125,
            ]),
          ],
        },
      },
    },
    createTask: {
      name: "CreateTask",
      requestType: CreateTaskRequest,
      requestStream: false,
      responseType: CreateTaskReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              22,
              34,
              17,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              116,
              97,
              115,
              107,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    getTask: {
      name: "GetTask",
      requestType: GetTaskRequest,
      requestStream: false,
      responseType: Task,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              24,
              18,
              22,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              116,
              97,
              115,
              107,
              115,
              47,
              123,
              105,
              100,
              125,
            ]),
          ],
        },
      },
    },
    getFineTuneSource: {
      name: "GetFineTuneSource",
      requestType: GetFineTuneSourceRequest,
      requestStream: false,
      responseType: GetFineTuneSourceReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              22,
              18,
              20,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              102,
              105,
              110,
              101,
              116,
              117,
              110,
              101,
            ]),
          ],
        },
      },
    },
    updateFineTuneSource: {
      name: "UpdateFineTuneSource",
      requestType: UpdateFineTuneSourceRequest,
      requestStream: false,
      responseType: UpdateFineTuneSourceReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              25,
              34,
              20,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              102,
              105,
              110,
              101,
              116,
              117,
              110,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    createFineTuneJob: {
      name: "CreateFineTuneJob",
      requestType: CreateFineTuneJobRequest,
      requestStream: false,
      responseType: CreateFineTuneJobReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              22,
              34,
              17,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              102,
              116,
              106,
              111,
              98,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    validateFineTuneJob: {
      name: "ValidateFineTuneJob",
      requestType: CreateFineTuneJobRequest,
      requestStream: false,
      responseType: ValidateFineTuneJobReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              22,
              26,
              17,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              102,
              116,
              106,
              111,
              98,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    listFineTuneJob: {
      name: "ListFineTuneJob",
      requestType: ListFineTuneJobRequest,
      requestStream: false,
      responseType: ListFineTuneJobReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              18,
              26,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              102,
              116,
              106,
              111,
              98,
              47,
              123,
              97,
              112,
              112,
              95,
              105,
              100,
              125,
            ]),
          ],
        },
      },
    },
    createTweet: {
      name: "CreateTweet",
      requestType: CreateTweetRequest,
      requestStream: false,
      responseType: CreateTweetReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              22,
              34,
              17,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              116,
              119,
              101,
              101,
              116,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    createGenerateCharacterJob: {
      name: "CreateGenerateCharacterJob",
      requestType: CreateGenerateCharacterJobRequest,
      requestStream: false,
      responseType: CreateGenerateCharacterJobReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              27,
              34,
              22,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              99,
              104,
              97,
              114,
              97,
              99,
              116,
              101,
              114,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    getCharacterGenerateJob: {
      name: "GetCharacterGenerateJob",
      requestType: GetCharacterGenerateJobRequest,
      requestStream: false,
      responseType: GetCharacterGenerateJobReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              33,
              18,
              31,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              99,
              104,
              97,
              114,
              97,
              99,
              116,
              101,
              114,
              115,
              47,
              123,
              106,
              111,
              98,
              95,
              105,
              100,
              125,
            ]),
          ],
        },
      },
    },
    listCharacterGenerateJobs: {
      name: "ListCharacterGenerateJobs",
      requestType: ListCharacterGenerateJobsRequest,
      requestStream: false,
      responseType: ListCharacterGenerateJobsReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              24,
              18,
              22,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              47,
              99,
              104,
              97,
              114,
              97,
              99,
              116,
              101,
              114,
              115,
            ]),
          ],
        },
      },
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
