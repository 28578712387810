import { useQuery } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { QUERY_KEYS } from 'shared/types/react-query';

export interface IUseAppLogs {
  appId?: string;
}

export const useAppLogs = (opts: IUseAppLogs) => {
  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_AAA_APP_LOGS, { id: opts.appId }],
    queryFn: async () => {
      const res = await bff.get<{ log: string }>(`/bff/aaa/app/logs/${opts.appId}`);

      return res.data;
    },
    refetchInterval: 10 * 1000,
  });
};
