import { Skeleton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { renderRollupStatusLabel, RollupStatus } from 'pages/raas/helpers/labels';
import { Rollup } from 'shared/types/protoc-gen/raas-rollup';
import { QUERY_KEYS } from 'shared/types/react-query';

export interface IRollupStatusChip {
  rollup?: Rollup;
  loading?: boolean;
  hideDate?: boolean;
}

export const RollupStatusChip = ({ hideDate = true, loading, rollup }: IRollupStatusChip) => {
  const {
    data: rollupStatus,
    isError: getRollupStatusError,
    isPending: getRollupStatusPending,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_ROLLUP_STATUS, { rollupId: rollup?.id }],
    queryFn: async (): Promise<RollupStatus> => {
      const [rpc, explorer] = [
        axios.create({ baseURL: rollup?.rpcEndpoint }),
        axios.create({ baseURL: rollup?.l2Blockscout ?? rollup?.explorer }),
      ];

      const [rpcResponse, explorerResponse] = await Promise.all([
        rpc.post('/', {
          jsonrpc: '2.0',
          method: 'eth_blockNumber',
          params: [],
          id: 1,
        }),
        explorer.get('/api/v2/blocks'),
      ]);

      return rpcResponse.status === 200 && explorerResponse.status === 200
        ? RollupStatus.ACTIVE
        : RollupStatus.DEPLOYING;
    },
    enabled: Boolean(rollup),
  });

  return (
    <Stack alignItems="center" direction="row" display="flex" justifyContent="end" spacing="16px">
      {!hideDate && rollup?.startupAt && (
        <Typography variant="caption">
          {[
            'Since',
            new Date(rollup.startupAt).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
            }),
          ].join(' ')}
        </Typography>
      )}
      {loading || getRollupStatusPending ? (
        <Skeleton height="24px" variant="rectangular" width="68px" />
      ) : (
        renderRollupStatusLabel(
          getRollupStatusError ? RollupStatus.DEPLOYING : rollupStatus ?? RollupStatus.UNRECOGNIZED,
        )
      )}
    </Stack>
  );
};
