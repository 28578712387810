import { Chat, Insights, Twitter } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import {
  alpha,
  ButtonProps,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { PERMISSIONS, useAuthContext } from 'contexts/AuthContext';
import { useApp } from 'pages/aaas/AaasDetails/hooks';
import { useAgentId } from 'pages/aaas/AaasDetails/hooks/useAgentId';
import { useAgentMetadata } from 'pages/aaas/AaasDetails/hooks/useAgentMetadata';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';

import { useScrollToElement } from '../useScrollToElement';

enum ChatAction {
  Tweet = 'tweet',
  Send = 'send',
  FeedData = 'feed-data',
}

export interface SubmitOpts {
  question: string | undefined;
  setQuestion: Dispatch<SetStateAction<string | undefined>>;
  isFeedMode: boolean;
  action?: 'tweet' | 'send';
}

interface IChatModeController {
  chatMode: ChatAction;
  hasTwitter: boolean;
  setChatMode: React.Dispatch<React.SetStateAction<ChatAction>>;
}

interface IChatModeItem extends ButtonProps {
  children: ReactNode;
  tooltip?: string;
  value: ChatAction;
}

export const InputSection: React.FC<
  Omit<TextFieldProps, 'onSubmit'> & {
    onPrompt: (args: SubmitOpts) => void;
    loading: boolean;
  }
> = ({ loading, onPrompt, ...props }) => {
  const { scrollChatHistory, scrollToChatInput } = useScrollToElement();
  const { user } = useAuthContext();

  const { data: app } = useApp();
  const { data: agentId } = useAgentId();
  const { data: currentMetadata } = useAgentMetadata(app, agentId);

  const [question, setQuestion] = useState<string | undefined>([''].join('\n'));

  const [chatMode, setChatMode] = useState<ChatAction>(ChatAction.Send);

  const isDisabled = !question || loading;

  const handleSubmitPrompt = useCallback(() => {
    const action =
      chatMode === ChatAction.FeedData || chatMode === ChatAction.Send
        ? ChatAction.Send
        : ChatAction.Tweet;

    const isFeedMode = chatMode === ChatAction.FeedData;

    onPrompt({ question, setQuestion, isFeedMode, action });
    setQuestion('');
    scrollChatHistory();
    scrollToChatInput();
  }, [onPrompt, question, scrollChatHistory, scrollToChatInput, chatMode]);

  return (
    <TextField
      InputProps={{
        sx: {
          pb: 5,
        },
        startAdornment: user?.permissions?.includes(PERMISSIONS.WRITE_ORG_DEPLOYMENTS) &&
          app?.template?.name?.toLowerCase() === 'eliza' && (
            <FormControlLabel
              control={
                <ChatModeController
                  chatMode={chatMode}
                  hasTwitter={!!currentMetadata?.character?.clients?.includes('twitter')}
                  setChatMode={setChatMode}
                />
              }
              label={null}
              labelPlacement="end"
              sx={{
                position: 'absolute',
                minWidth: '250px',
                bottom: 1,
                left: 4,
              }}
            />
          ),
        endAdornment: (
          <>
            <IconButton
              disabled={props?.disabled || isDisabled}
              onClick={() => handleSubmitPrompt()}
              sx={{
                position: 'absolute',
                bottom: 1,
                right: 1,
              }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <SendIcon
                  sx={theme => ({
                    fill: isDisabled ? alpha(theme.palette.primary.light, 0.3) : 'primary',
                    color: theme.colors.functional.text.primary,
                  })}
                />
              )}
            </IconButton>
          </>
        ),
      }}
      autoFocus
      id="chat-input"
      minRows={5}
      multiline
      onChange={e => setQuestion(e.target.value)}
      onKeyDown={e => {
        if (loading) return e.preventDefault();

        // shift + enter
        if (e.key === 'Enter' && e.shiftKey) {
          return; // Allow newline
        }

        if (e.key === 'Enter') {
          e.preventDefault();
          handleSubmitPrompt();
        }
      }}
      placeholder={
        chatMode === ChatAction.FeedData
          ? 'Send a message to feed new data to your AI Agent'
          : chatMode === ChatAction.Tweet
          ? 'Send a tweet with your AI agent'
          : 'Send a message to your AI Agent'
      }
      sx={theme => ({
        color: theme.colors.functional.text.primary,
        borderColor: theme.colors.functional.subject.border,
        outlineColor: theme.colors.functional.subject.chosen,

        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: theme.colors.functional.text.primary,
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.colors.functional.text.primary,
          },
        },
      })}
      value={question}
      variant="outlined"
      {...props}
    />
  );
};

const ChatModeController: React.FC<IChatModeController> = ({
  chatMode,
  hasTwitter,
  setChatMode,
}) => {
  return (
    <ToggleButtonGroup
      exclusive
      onChange={(_, newMode) => setChatMode(newMode)}
      sx={{ mr: '10px' }}
      value={chatMode}
    >
      <Stack direction="row" sx={{ ml: '8px' }}>
        <ChatModeItem tooltip="Make conversation with the agent." value={ChatAction.Send}>
          Generic
        </ChatModeItem>
        {hasTwitter && (
          <ChatModeItem tooltip="Ask your agent to send a tweet." value={ChatAction.Tweet}>
            Tweet
          </ChatModeItem>
        )}
        <ChatModeItem tooltip="Feeds new data to the agent." value={ChatAction.FeedData}>
          Data Feed
        </ChatModeItem>
      </Stack>
    </ToggleButtonGroup>
  );
};

const ChatModeItem: React.FC<IChatModeItem> = ({ children, tooltip, value }) => {
  const buttonIcon: Record<ChatAction, ReactNode> = {
    [ChatAction.FeedData]: <Insights sx={{ width: '20px', height: '20px' }} />,
    [ChatAction.Send]: <Chat sx={{ width: '17px', height: '17px' }} />,
    [ChatAction.Tweet]: <Twitter sx={{ width: '20px', height: '20px' }} />,
  };

  return (
    <ConditionalTooltip enabled title={tooltip} wrapped>
      <ToggleButton
        sx={{
          height: '20px',
          borderRadius: '0',
        }}
        value={value}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          {buttonIcon[value]}

          <Typography textTransform="none" variant="caption">
            {children}
          </Typography>
        </Stack>
      </ToggleButton>
    </ConditionalTooltip>
  );
};
