import { Box } from '@mui/material';
import { MarkdownRenderer } from 'shared/components/MarkdownRenderer';
import { px } from 'theme/margins';

import AutonomeTermsofService from './terms';

export function TermsOfService() {
  return (
    <Box px={px}>
      <MarkdownRenderer markdown={AutonomeTermsofService} />
    </Box>
  );
}
