import {
  Box,
  CircularProgress,
  InputAdornment,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { BountyTokenItem } from 'pages/aaas/Bounties/components/BountyTokenItem';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomAutocomplete } from 'shared/components/CustomAutocomplete';
import { Token, TokenListVariant, useAppTokens } from 'shared/hooks/tokens';
import { useEthTokenBalances } from 'shared/hooks/tokens/useEthTokenBalances';
import { truncate } from 'shared/utils/strings';
import { Address, erc20Abi, isAddress } from 'viem';
import { useAccount, useReadContract } from 'wagmi';

import { FormValues } from './CreateTaskDialog';

interface TokenOption extends Token {
  label: string;
  value: Address;
  balance: bigint;
  isCreatedOption?: boolean;
}

export default function BountyTokenSelect() {
  const { chain } = useAccount();
  const form = useFormContext<FormValues>();
  const bountyTokenAddr = form.watch('bountyTokenAddr');

  const { data: _tokens, isPending: isLoadingAppTokens } = useAppTokens(
    (chain?.name?.toLowerCase() as TokenListVariant) ?? TokenListVariant.HOLESKY,
  );
  const { data: createdTokenSymbol, isLoading: isFetchingCustomTokenSymbol } = useReadContract({
    abi: erc20Abi,
    address: bountyTokenAddr,
    functionName: 'symbol',
    query: {
      enabled: isAddress(bountyTokenAddr) && !_tokens?.find(cur => cur.address === bountyTokenAddr),
    },
  });

  const createdTokenDetails = useMemo(
    () =>
      createdTokenSymbol
        ? ({ symbol: createdTokenSymbol, address: bountyTokenAddr } as Token)
        : null,
    [bountyTokenAddr, createdTokenSymbol],
  );

  const tokens = useMemo(
    () => (createdTokenDetails ? [...(_tokens || []), createdTokenDetails] : _tokens),
    [createdTokenDetails, _tokens],
  );

  const { data: ethTokenBalances, isPending: isLoadingBalances } = useEthTokenBalances({
    tokens,
  });

  const tokenOptions: TokenOption[] = useMemo(() => {
    return (
      tokens?.map((cur, index) => ({
        ...cur,
        balance: ethTokenBalances?.[index]?.balance || 0n,
        label: cur.symbol,
        value: cur.address,
      })) || ([] as TokenOption[])
    );
  }, [tokens, ethTokenBalances]);

  const selectedOption = useMemo(
    () => tokenOptions?.find(token => token.address === bountyTokenAddr) || null,
    [bountyTokenAddr, tokenOptions],
  );

  const isLoading = isLoadingBalances || isLoadingAppTokens || isFetchingCustomTokenSymbol;

  return (
    <CustomAutocomplete
      afterChange={option => {
        if (!option) {
          form.setValue('bountyTokenAddr', '0x0', { shouldValidate: true });
        }
      }}
      creatable
      errorMsg={form.formState.errors.bountyTokenAddr?.message}
      loading={isLoadingBalances || isLoadingAppTokens || isFetchingCustomTokenSymbol}
      options={tokenOptions || ([] as TokenOption[])}
      renderInput={params => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              ...(!Array.isArray(selectedOption) &&
                selectedOption?.logoURI && {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        component="img"
                        src={selectedOption?.logoURI}
                        sx={{ width: 30, height: 30 }}
                      />
                    </InputAdornment>
                  ),
                }),
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            helperText={truncate(selectedOption?.address || '')}
            hiddenLabel
            placeholder="Select or enter an erc20 token address"
            sx={{
              position: 'relative',
              '&& .MuiFormHelperText-root': {
                position: 'absolute',
                bottom: 0,
                left: selectedOption?.logoURI ? '38px' : 0,
              },
            }}
            value=""
          />
        );
      }}
      renderOption={(props, option: TokenOption) => {
        return (
          <ListItem
            {...props}
            key={option.address}
            onClick={() =>
              form.setValue('bountyTokenAddr', option.value, {
                shouldValidate: true,
              })
            }
            sx={{
              p: 2,
              cursor: 'pointer',
            }}
          >
            {option.isCreatedOption ? (
              <Typography>Use ERC20 token with address: {option.value}</Typography>
            ) : (
              <BountyTokenItem token={option} />
            )}
          </ListItem>
        );
      }}
      sx={{ background: theme => theme.colors.gradients.sheet }}
      value={selectedOption}
    />
  );
}
