import { Box } from '@mui/material';
import Ansi from 'ansi-to-react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Section } from 'shared/components';

import { useApp, useAppLogs } from '../hooks';

const ansiColorsMap = {
  color: 'white', // default if no class is provided
  '& .ansi-black-fg': { color: 'black' },
  '& .ansi-black-bg': { backgroundColor: 'black' },
  '& .ansi-red-fg': { color: 'red' },
  '& .ansi-red-bg': { backgroundColor: 'red' },
  '& .ansi-green-fg': { color: 'lightgreen' },
  '& .ansi-green-bg': { backgroundColor: 'green' },
  '& .ansi-yellow-fg': { color: 'yellow' },
  '& .ansi-yellow-bg': { backgroundColor: 'yellow' },
  '& .ansi-blue-fg': { color: 'lightblue' },
  '& .ansi-blue-bg': { backgroundColor: 'blue' },
  '& .ansi-magenta-fg': { color: 'magenta' },
  '& .ansi-magenta-bg': { backgroundColor: 'magenta' },
  '& .ansi-cyan-fg': { color: 'cyan' },
  '& .ansi-cyan-bg': { backgroundColor: 'cyan' },
  '& .ansi-white-fg': { color: 'white' },
  '& .ansi-white-bg': { backgroundColor: 'white' },
  '& .ansi-bright-black-fg': { color: 'dimgray' },
  '& .ansi-bright-red-fg': { color: 'lightcoral' },
  '& .ansi-bright-green-fg': { color: 'lightgreen' },
  '& .ansi-bright-yellow-fg': { color: 'lightyellow' },
  '& .ansi-bright-blue-fg': { color: 'lightskyblue' },
  '& .ansi-bright-magenta-fg': { color: 'violet' },
  '& .ansi-bright-cyan-fg': { color: 'paleturquoise' },
  '& .ansi-bright-white-fg': { color: 'whitesmoke' },
};

export const AgentHistoryLogSection: React.FC = () => {
  const { appId } = useParams();

  const { data: app } = useApp({ appId });

  const { data } = useAppLogs({ appId });

  const wasRecentlyDeployed = Date.now() - new Date(app?.createdAt || 0)?.getTime() < 60 * 1000; // whether app was deployed in past 60s

  const logs = useMemo(
    () =>
      data?.log
        .slice(1, -1)
        ?.replaceAll(/\\n/g, '\n')
        .replace(/\\u[0-9a-fA-F]{4}/g, match => {
          // Convert the matched Unicode escape sequence to the actual character
          return String.fromCharCode(parseInt(match.slice(3), 16));
        }),
    [data],
  );
  const logsNotFound = logs?.includes('404: No pods found with prefix');

  return (
    <Section title="Agent logs">
      <Box
        component={'pre'}
        sx={{
          px: 4,
          pb: 8,
          mx: { md: 5, sm: 3, xs: 3 },
          my: 3,
          background: '#1e1e1e',
          maxHeight: '50vh',
          fontSize: 10,
          overflowY: 'scroll',
          overflowX: 'hidden',
          overscrollBehavior: 'contain',
          ...ansiColorsMap,
        }}
      >
        <Ansi useClasses>
          {wasRecentlyDeployed && logsNotFound
            ? 'Your agent is being initialized. Logs will be available shortly.'
            : logs}
        </Ansi>
      </Box>
    </Section>
  );
};
