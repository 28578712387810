import { useQuery } from '@tanstack/react-query';
import { RaasMetricsData, RaasMetricsResult, Timeframe } from 'pages/raas/hooks';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

interface IUseYAxisMinValue {
  rollupId: string | undefined;
  panel: Panel;
  result: RaasMetricsResult<RaasMetricsData> | undefined;
  timeframe?: Timeframe;
}

export const useYAxisMinValue = ({ panel, result, rollupId, timeframe }: IUseYAxisMinValue) =>
  useQuery({
    queryKey: ['Y_AXIS_MIN_VALUE', { rollupId, panel, timeframe }],
    queryFn: () => {
      if (!result?.data || !result?.dataKeys) return 0;

      let minValue = Infinity;

      for (const v of result?.data ?? []) {
        for (const dataKey of result?.dataKeys ?? []) {
          const value = +v[dataKey.name];

          if (value < minValue) {
            minValue = value;
          }
        }
      }

      return minValue === Infinity ? 0 : minValue;
    },
    enabled: !!result,
  });
