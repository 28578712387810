import { Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { Section } from 'shared/components';

import { SectionButtonTimeFilter } from '../../components';
import { TimeFilterVariant } from '../../useTimeFilterStore';
import RpcCountMetric from './RpcCountMetric';

interface IRpcCountCard {
  title: string;
  value: string;
  sx?: SxProps<Theme>;
}

const RpcCountSection: React.FC = () => {
  return (
    <Grid container>
      <Grid
        item
        sx={{ borderRight: theme => `1px solid ${theme.colors.functional.subject.border}` }}
        xs={8}
      >
        <Section
          renderSubHeaderEndContent={
            <SectionButtonTimeFilter
              options={['month', 'day', 'hour']}
              variant={TimeFilterVariant.DAILY_RPC_COUNT_SECTION}
            />
          }
          title="Daily RPC Count"
        >
          <Grid container>
            <Grid item xs={12}>
              <RpcCountMetric />
            </Grid>
          </Grid>
        </Section>
      </Grid>

      <Grid item xs={4}>
        <Stack height="100%" sx={{ backgroundColor: '#FFFFFF' }}>
          <RpcCountCard
            sx={{ borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}` }}
            title="RPC Count this month"
            value="100,000"
          />

          <RpcCountCard title="RPC Count today" value="12,400,000" />
        </Stack>
      </Grid>
    </Grid>
  );
};

const RpcCountCard: React.FC<IRpcCountCard> = ({ sx, title, value }) => {
  return (
    <Stack alignItems="center" direction="row" height="100%" sx={{ p: '40px', ...sx }}>
      <Stack spacing="20px">
        <Typography variant="captionC">{title}</Typography>
        <Typography fontSize="40px" variant="h4">
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default RpcCountSection;
