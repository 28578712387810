import { Button, ButtonProps, Stack, StackProps, Typography } from '@mui/material';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { Timeframe } from 'pages/raas/hooks';

export interface ISectionButtonTimeFilter extends Omit<StackProps, 'children'> {
  variant?: TimeFilterVariant;
  loading?: boolean;
  options?: Array<Timeframe>;
}

export const SectionButtonTimeFilter = ({
  loading,
  options = ['month', 'week'],
  variant,
  ...props
}: ISectionButtonTimeFilter) => {
  const { selectedTimeFilter, setSelectedTimeFilter } = useTimeFilterStore();

  return (
    <Stack direction="row" spacing="16px" {...props}>
      {options.includes('month') && (
        <_Button
          active={variant && selectedTimeFilter[variant] === 'month'}
          disabled={loading}
          onClick={() => variant && setSelectedTimeFilter(variant, 'month')}
        >
          Month
        </_Button>
      )}
      {options.includes('week') && (
        <_Button
          active={variant && selectedTimeFilter[variant] === 'week'}
          disabled={loading}
          onClick={() => variant && setSelectedTimeFilter(variant, 'week')}
        >
          Week
        </_Button>
      )}
      {options.includes('day') && (
        <_Button
          active={variant && selectedTimeFilter[variant] === 'day'}
          disabled={loading}
          onClick={() => variant && setSelectedTimeFilter(variant, 'day')}
        >
          Day
        </_Button>
      )}
      {options.includes('hour') && (
        <_Button
          active={variant && selectedTimeFilter[variant] === 'hour'}
          disabled={loading}
          onClick={() => variant && setSelectedTimeFilter(variant, 'hour')}
        >
          Hour
        </_Button>
      )}
    </Stack>
  );
};

const _Button = ({ active, children, sx, ...props }: ButtonProps & { active?: boolean }) => (
  <Button
    size="small"
    {...props}
    sx={{
      color: !active ? theme => theme.colors.functional.text.primary : undefined,
      background: !active ? 'transparent' : undefined,
      height: '20px',
      minWidth: '43px',
      py: '1px',
      px: '8px',
      m: 0,
      '&:hover': {
        color: !active ? theme => theme.colors.functional.text.primary : undefined,
        background: !active ? 'transparent' : undefined,
      },
      ...sx,
    }}
  >
    <Typography variant="captionC">{children}</Typography>
  </Button>
);
