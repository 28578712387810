import { Button, Grid, Stack } from '@mui/material';
import { CreditsRemainingChip } from 'pages/aaas/components/CreditsRemainingChip';
import { PurchaseCreditsButton } from 'pages/aaas/components/PurchaseCreditsButton';
import { useListApps } from 'pages/aaas/hooks';
import { AaasLayout } from 'pages/aaas/layout';
import { IoAddSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { SubHeader } from 'shared/components/Layout/SubHeader';

import { AgentCard } from './components/AgentCard';
import { AgentFrameworksSection } from './components/AgentFrameworksSection';

export const AaasDeployments = () => {
  const navigate = useNavigate();
  const { data, isPending: getAppsPending } = useListApps();

  return (
    <>
      <AaasLayout>
        <AgentFrameworksSection />
        <SubHeader
          endAdornment={
            <Grid
              container
              item
              lg={5.5}
              md={7.5}
              mt={{ xs: 1, sm: 0 }}
              spacing={3}
              xl={4.5}
              xs={12}
            >
              <Grid item sm={6} xs={12}>
                <CreditsRemainingChip />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PurchaseCreditsButton />
              </Grid>
            </Grid>
          }
          isLoading={getAppsPending}
        >
          {'Your deployments'}
        </SubHeader>
        <Grid
          container
          sx={{
            background: '#fafafa',
            pb: '50px',
            '& > *': theme => ({
              borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
              borderRight: `1px solid ${theme.colors.functional.subject.border}`,
            }),
          }}
        >
          {data?.apps?.map(app => (
            <Grid item key={app.id} md={3} sm={6} xs={12}>
              <AgentCard
                app={app}
                onClick={() => navigate(PATHS.AAAS_DETAILS.replace(':appId', app.id))}
              />
            </Grid>
          ))}

          <Grid item md={3} sm={6} xs={12}>
            <Button
              onClick={() => navigate(PATHS.AAAS_NEW_DEPLOYMENT)}
              sx={theme => ({
                minHeight: '96px',
                width: '100%',
                background: '#fff',
                color: theme.colors.functional.text.primary,
                '&:hover': { background: '#fafafa' },
              })}
            >
              <Stack alignItems="center" height="100%" justifyContent="center">
                <IoAddSharp size={24} />
              </Stack>
            </Button>
          </Grid>
        </Grid>
      </AaasLayout>
    </>
  );
};
