import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren, useState } from 'react';

export default function ImgWithFallback({
  fallbackSrc = '/favicon.svg',
  src,
  sx,
}: PropsWithChildren<BoxProps<'img'> & { fallbackSrc?: string }>) {
  const [iconSrc, setIconSrc] = useState(src);

  return (
    <Box
      component="img"
      onError={() => {
        setIconSrc(fallbackSrc);
      }}
      src={iconSrc}
      sx={{ width: 64, height: 64, ...sx }}
    />
  );
}
