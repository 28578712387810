import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { useRollupPublicClient } from 'pages/raas/DeploymentDetails/hooks/useRollupPublicClient';
import { Rollup } from 'shared/types/protoc-gen/raas-rollup';
import { QUERY_KEYS } from 'shared/types/react-query';
import { Address } from 'viem';

export interface IUseBatchBalances
  extends Omit<UseQueryOptions<BatchBalancesResult>, 'queryKey' | 'queryFn'> {
  rollup: Rollup | undefined;
  accounts: Address[] | { label: string; account: Address }[];
  viem?: boolean;
  l1?: boolean;
}

export const useBatchBalances = ({
  accounts,
  l1 = true,
  rollup,
  viem = true,
  ...queryOptions
}: IUseBatchBalances) => {
  const client = useRollupPublicClient(rollup);

  return useQuery({
    queryKey: [QUERY_KEYS.VIEM_BATCH_BALANCES, { accounts }],
    queryFn: async (): Promise<BatchBalancesResult> => {
      if (!rollup) throw new Error('Provided rollup must not be undefined');

      if (viem) {
        const balances = await Promise.all(
          accounts.map(address =>
            client?.getBalance({
              address: typeof address === 'string' ? address : address.account,
            }),
          ),
        );

        return accounts.map<BatchBalanceMap>((account, index) => ({
          label: typeof account === 'string' ? undefined : account.label,
          account: typeof account === 'string' ? account : account.account,
          balance: balances?.[index],
        }));
      } else {
        const client = axios.create({ baseURL: rollup[l1 ? 'l1Blockscout' : 'l2Blockscout'] });

        const balances = await Promise.all(
          accounts.map(address =>
            client.get<{ message: string; result: string; status: string }>(
              '/api/v1/developer/api',
              {
                params: {
                  module: 'account',
                  action: 'balance',
                  address: typeof address === 'string' ? address : address.account,
                  tag: 'latest',
                },
              },
            ),
          ),
        );

        return accounts.map<BatchBalanceMap>((account, index) => ({
          label: typeof account === 'string' ? undefined : account.label,
          account: typeof account === 'string' ? account : account.account,
          balance: BigInt(balances?.[index]?.data?.result ?? 0),
        }));
      }
    },
    enabled: !!rollup,
    ...queryOptions,
  });
};

export interface BatchBalanceMap {
  label?: string;
  account: Address;
  balance: bigint | undefined;
}

export type BatchBalancesResult = BatchBalanceMap[];
