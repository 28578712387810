import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { bff } from 'shared/api';
import {
  RegisterWithdrawalFeeRollupReply,
  RegisterWithdrawalFeeRollupRequest,
} from 'shared/types/protoc-gen/raas-rollup';
import { MUTATION_KEYS } from 'shared/types/react-query';

interface IUseWFRegister
  extends Omit<
    UseMutationOptions<
      RegisterWithdrawalFeeRollupReply,
      Error,
      RegisterWithdrawalFeeRollupRequest,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  > {}

export const useWFRegister = (opts?: IUseWFRegister) => {
  return useMutation<
    RegisterWithdrawalFeeRollupReply,
    Error,
    RegisterWithdrawalFeeRollupRequest,
    unknown
  >({
    mutationKey: [MUTATION_KEYS.RAAS_WITHDRAW_FEE_REGISTER],
    mutationFn: async (
      args: RegisterWithdrawalFeeRollupRequest,
    ): Promise<RegisterWithdrawalFeeRollupReply> => {
      if (!args?.opstackId) throw new Error('opstackId is required');

      const res = await bff.post<RegisterWithdrawalFeeRollupReply>(
        '/bff/opstack/withdrawfee/register',
        args,
      );

      return res.data;
    },
    ...opts,
  });
};
