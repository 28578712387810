import { LoadingButton } from '@mui/lab';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { useCreateApp } from 'pages/aaas/hooks';
import { FC, useMemo } from 'react';
import { FieldName, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import IconCheckedCircle from 'shared/components/icons/IconCheckedCircle';
import IconNay from 'shared/components/icons/IconNay';
import IconUncheckedCircle from 'shared/components/icons/IconUncheckedCircle';

import { FormValues } from '../types';

export function Checked({ valid }: { valid?: boolean }) {
  return valid ? (
    <IconCheckedCircle />
  ) : valid === undefined ? (
    <IconUncheckedCircle />
  ) : (
    <IconNay />
  );
}

function getDisplayValue(
  fieldName: FieldName<FormValues>,
  value: string | boolean | object | number | null,
  isError?: boolean,
) {
  switch (fieldName) {
    case 'config':
      return isError ? 'Invalid' : 'Valid';

    default:
      return value;
  }
}

export const SummaryPanel: FC<
  BoxProps & {
    isSubmitting?: boolean;
    isSubmitDisabled?: boolean;
  }
> = ({ isSubmitDisabled, isSubmitting, sx, ...props }) => {
  const form = useFormContext<FormValues>();
  const formValues = useWatch<FormValues>({ control: form.control });
  const formState = useFormState({ control: form.control });

  const { isPending: deployAgentPending } = useCreateApp();

  const [searchParams] = useSearchParams();
  const searchParamsTemplate = useMemo(() => searchParams?.get('template'), [searchParams]);
  const labelMap: Record<FieldName<FormValues>, string> = useMemo(
    // Order of the keys of this map is also the order of appearance on the summary panel.
    () => ({
      ...(searchParamsTemplate
        ? { templateId: 'Template:', deploymentType: 'Deployment type:' }
        : { deploymentType: 'Deployment type:', templateId: 'Template:' }),
      agentPrefixName: 'Agent prefix name:',
      dockerImg: 'Docker image:',
      config: 'Config:',
    }),
    [searchParamsTemplate],
  );

  const summaryItems = useMemo(
    () =>
      Object.entries(formValues)
        ?.map(([name, value]) => {
          const fieldName = name as keyof FormValues;
          const isError =
            fieldName === 'config'
              ? Object.keys(formState.errors?.config ?? {}).length > 0
              : !!(formState?.errors as any)?.[fieldName]?.message;

          return {
            isError,
            fieldName,
            value: getDisplayValue(fieldName, value, isError),
          };
        })
        ?.sort(
          (a, b) =>
            Object.keys(labelMap || {})?.findIndex(cur => cur === a.fieldName) -
            Object.keys(labelMap || {})?.findIndex(cur => cur === b.fieldName),
        ),
    [formValues, formState?.errors, labelMap],
  );

  const scrollToStep = (fieldName: FieldName<FormValues>) => () => {
    const el = document.getElementById(`step_${fieldName}`);

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        m: { md: 5 },
        top: 40,
        border: theme => `1px solid ${theme.colors.functional.subject.border}`,
        background: theme => theme.colors.gradients.metal,
        ...sx,
      }}
      {...props}
    >
      <Stack alignItems="center" direction="row" gap={1} sx={{ px: 5, py: 2 }}>
        <Typography pt={5} variant="captionC">
          Summary
        </Typography>
      </Stack>
      {summaryItems?.map(cur => (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          key={cur.fieldName}
          onClick={scrollToStep(cur.fieldName)}
          sx={{
            px: 5,
            py: 2,
            borderTop: '1px solid rgba(32, 39, 35, 0.08)',
            cursor: 'pointer',
          }}
        >
          <Checked valid={cur.isError ? false : cur.value === undefined ? undefined : true} />
          {cur.isError}
          <Typography variant="caption">
            {labelMap?.[cur?.fieldName] ?? `${cur?.fieldName}`}
          </Typography>
          {labelMap?.[cur?.fieldName] && (
            <Typography variant="captionM">{String(cur.value ?? '')}</Typography>
          )}
        </Stack>
      ))}
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        sx={{ px: 5, py: 2, borderTop: '1px solid rgba(32, 39, 35, 0.08)' }}
      >
        <LoadingButton
          disabled={isSubmitting || isSubmitDisabled}
          fullWidth
          loading={deployAgentPending}
          type="submit"
          variant="contained"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};
