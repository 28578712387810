import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogProps, DialogTitle, List, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { chainLabels } from 'pages/avs/helpers/labels';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { raas } from 'shared/api';
import { SETTLEMENT_CHAINS } from 'shared/types/deployments';
import {
  CreateOrbitReply,
  CreateOrbitRequest,
  GenerateKeyReply,
} from 'shared/types/protoc-gen/raas-rollup';
import { DAType, RollupType } from 'shared/types/protoc-gen/rollup';
import { QUERY_KEYS } from 'shared/types/react-query';
import { Address, parseEther } from 'viem';
import { useBalance } from 'wagmi';
import { GetBalanceData } from 'wagmi/query';

import { FundAccountRow } from './FundAccountRow';
import { FormValues } from './types';

export function FundOrbitAccountsDialog({
  data,
  onClose,
  open,
  promoCode,
  selectedRollupType,
  settlementChain,
  ...rest
}: DialogProps & {
  selectedRollupType: RollupType;
  settlementChain: SETTLEMENT_CHAINS;
  data: FormValues;
  promoCode: string;
}) {
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isPending: isCreatingRollup, mutate: create } = useMutation({
    mutationFn: async (req: CreateOrbitRequest): Promise<CreateOrbitReply> => {
      return await raas.post('/bff/orbit', req);
    },
    onSuccess: (_, reqPayload) => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: `Successfully initiated deployment of "${reqPayload?.chainName}"`,
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_DEPLOYMENTS] });
      navigate(PATHS.RAAS_DEPLOYMENTS);
    },
  });

  const { data: rollupKeys, isPending: isGeneratingKeys } = useQuery({
    queryKey: [QUERY_KEYS.GET_ROLLUP_KEYS, selectedRollupType],
    queryFn: async (context): Promise<GenerateKeyReply> => {
      const rollupType = context?.queryKey?.[1];

      const res = await raas.get(`/bff/keys/${rollupType}`);

      return res?.data;
    },
    select: res => res?.keys,
    enabled: selectedRollupType !== undefined,
    staleTime: Infinity,
  });

  useEffect(() => {
    // Log rollups keys for dev purposes (To recover testnet funds after deployment)
    console.log('Rollup keys: ', rollupKeys);
  }, [rollupKeys]);

  const { data: batchPosterBal } = useBalance({
    address: String(rollupKeys?.batchPoster?.address) as Address,
    chainId: settlementChain,
    query: {
      enabled: !!rollupKeys?.batchPoster?.address,
    },
  });
  const { data: stakerBal } = useBalance({
    address: String(rollupKeys?.staker?.address) as Address,
    chainId: settlementChain,
    query: {
      enabled: !!rollupKeys?.staker?.address,
    },
  });
  const { data: deployerBal } = useBalance({
    address: String(rollupKeys?.deployer?.address) as Address,
    chainId: settlementChain,
    query: {
      enabled: !!rollupKeys?.deployer?.address,
    },
  });

  const balances: Record<string, { minEth: number } & Partial<GetBalanceData>> = useMemo(
    () => ({
      staker: { ...stakerBal, minEth: 0.05 },
      deployer: { ...deployerBal, minEth: 0.7 },
      batchPoster: { ...batchPosterBal, minEth: 0.05 },
    }),
    [stakerBal, deployerBal, batchPosterBal],
  );
  const hasMinBalances = useMemo(
    () =>
      Object.values(balances || {})?.every(
        bal => bal?.value && bal?.value >= parseEther(String(bal?.minEth)),
      ),
    [balances],
  );

  const handleDeploy = () => {
    create({
      promoCode,
      deployerKey: String(rollupKeys?.deployer?.privateKey),
      chainName: String(data?.networkName),
      parentChainId: Number(data?.network),
      chainId: Number(data?.chainId),
      batchPosterKey: String(rollupKeys?.batchPoster?.privateKey),
      stakerKey: String(rollupKeys?.staker?.privateKey),
      daserverPrivateKey: String(rollupKeys?.daKey?.privateKey),
      daserverPublicKey: String(rollupKeys?.daKey?.publicKey),
      keysetBytes: String(rollupKeys?.daKey?.keysetBytes),
      enableAnytrust: data?.daType === DAType.TYPE_ANYTRUST,
      l1conn: '',
    });
  };

  return (
    <Dialog
      disablePortal
      onClose={onClose}
      open={open}
      sx={{ '& .MuiPaper-root': { p: 3, background: '#FFFFFF', maxWidth: '90vw' } }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h5">
          Fund Orbit accounts on {chainLabels[settlementChain]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List sx={{ background: '#fff' }}>
          {Object.entries(rollupKeys || {})?.map(
            ([key, value]) =>
              value?.address && (
                <FundAccountRow
                  balances={balances}
                  generatedAcct={value}
                  generatedAcctId={key}
                  key={key}
                  settlementChain={settlementChain}
                />
              ),
          )}
        </List>
        <LoadingButton
          disabled={isGeneratingKeys || !hasMinBalances}
          fullWidth
          loading={isCreatingRollup}
          onClick={handleDeploy}
          variant="contained"
        >
          Deploy
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
}
