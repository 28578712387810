import { Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import IconCheckedCircle from 'shared/components/icons/IconCheckedCircle';
import IconInfo from 'shared/components/icons/IconInfo';

export interface IValidationChip extends PropsWithChildren {
  valid: boolean;
}

export const ValidationChip = ({ children, valid }: IValidationChip) => {
  const color = valid ? '#66B489' : '#F1605F';

  return (
    <Stack direction="row" spacing="8px">
      {valid ? (
        <IconCheckedCircle
          fill={color}
          sx={{
            height: '20px',
            width: '20px',
            color,
          }}
        />
      ) : (
        <IconInfo
          fill={color}
          sx={{
            height: '20px',
            width: '20px',
            color,
          }}
        />
      )}
      <Typography color={color} variant="bodySmallM">
        {children}
      </Typography>
    </Stack>
  );
};
