import { RollupType } from 'pages/raas/helpers/labels';

import { OPTIMISM_MD } from './optimism-md';
import { ORBIT_MD } from './orbit-md';
import { SCROLL_MD } from './scroll-md';

export const faqMarkdown: Record<RollupType, string> = {
  [RollupType.OPSTACK]: OPTIMISM_MD,
  [RollupType.ORBIT]: ORBIT_MD,
  [RollupType.ZKSTACK]: SCROLL_MD,
  [RollupType.CDK]: SCROLL_MD,
  [RollupType.UNRECOGNIZED]: `#### FAQ will be available soon for this rollup type.`,
};
