import { RouteObject } from 'react-router-dom';

import { PurchaseCreditsView } from './views/PurchaseCreditsView';
import { PurchaseSuccessfulView } from './views/PurchaseSuccessfulView';

export const routes: RouteObject[] = [
  {
    path: 'purchase',
    element: <PurchaseCreditsView />,
  },
  {
    path: 'fulfillment',
    element: <PurchaseSuccessfulView />,
  },
];
