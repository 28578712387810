import { AaasLayout } from 'pages/aaas/layout';
import { Outlet, useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { PageBannerVariant } from 'shared/components/Layout/PageBanner';

import { useApp } from './hooks/useApp';

export const AaasDetails = () => {
  const navigate = useNavigate();
  const { data: app, isPending } = useApp();

  return (
    <AaasLayout
      pageBannerProps={{
        loading: isPending,
        onClickBack: () => navigate(PATHS.AAAS_DEPLOYMENTS, { replace: true }),
        title: app?.name ?? '',
        variant: PageBannerVariant.AAAS,
      }}
    >
      <Outlet />
    </AaasLayout>
  );
};
