import { useQuery } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { ListAAAFeaturedTemplateReply } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

export const useListFeaturedTemplates = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_FEATURED_TEMPLATES],
    queryFn: async () => {
      const res = await bff.get<ListAAAFeaturedTemplateReply>('/bff/aaa/templates/featured');

      return res?.data?.templates;
    },
  });
};
