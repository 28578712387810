import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { AaasLayout } from 'pages/aaas/layout';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { FormTextField } from 'shared/components/form';
import { FormJsonEditor } from 'shared/components/form/fields/FormJsonEditor';
import { PageBannerVariant } from 'shared/components/Layout/PageBanner';
import { Option } from 'shared/components/Option';
import { SectionTitle } from 'shared/components/SectionTitle';
import { useDisclosure } from 'shared/hooks/ui';
import * as yup from 'yup';

import EnvVarsFieldArray from './EnvVarsFieldArray';
import { GenerateJsonSchemaDialog } from './GenerateJsonSchemaDialog';
import { PublishAgentSummaryPanel } from './PublishAgentSummaryPanel';
import { FormValues } from './types';
import usePublishAgent from './usePublishAgent';
import { validationSchema } from './validation';

const DEFAULT_JSON_SCHEMA = {
  type: 'object',
  properties: {
    text: {
      type: 'string',
    },
  },
};

export const PublishCommunityAgent = () => {
  const navigate = useNavigate();
  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      imageName: '',
      imageDesc: '',
      dockerImg: '',
      agentLogo: '',
      livenessProbePath: '',
      port: undefined,
      githubUrl: '',
      chatRequestSchema: DEFAULT_JSON_SCHEMA,
      chatResponseSchema: DEFAULT_JSON_SCHEMA,
    },
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
  });

  const { isPending: isPendingPublish, publish } = usePublishAgent();

  const {
    onClose: onCloseReqDialog,
    onOpen: openReqDialog,
    open: isReqDialogOpen,
  } = useDisclosure();
  const {
    onClose: onCloseResDialog,
    onOpen: openResDialog,
    open: isResDialogOpen,
  } = useDisclosure();

  return (
    <>
      <GenerateJsonSchemaDialog
        onClose={onCloseReqDialog}
        onConfirm={jsonSchema => {
          form.setValue('chatRequestSchema', jsonSchema, { shouldValidate: true });
          onCloseReqDialog();
        }}
        open={isReqDialogOpen}
      />
      <GenerateJsonSchemaDialog
        onClose={onCloseResDialog}
        onConfirm={jsonSchema => {
          form.setValue('chatResponseSchema', jsonSchema, { shouldValidate: true });
          onCloseResDialog();
        }}
        open={isResDialogOpen}
      />

      <AaasLayout
        pageBannerProps={{
          onClickBack: () => navigate(PATHS.AAAS_DEPLOYMENTS, { replace: true }),
          title: 'Upload your framework',
          variant: PageBannerVariant.AAAS,
        }}
      >
        <Box bgcolor="#fafafa" pb={4} sx={{ position: 'relative' }}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(publish, err => console.error('err:', err))}>
              <Box margin="0 auto">
                <Grid container>
                  <Grid
                    item
                    md={8}
                    sx={{
                      '& > *:not(:first-child)': {
                        mr: {
                          xs: 3,
                          sm: 3,
                          md: 0,
                        },
                      },
                    }}
                    xs={12}
                  >
                    <SectionTitle>Agent framework metadata</SectionTitle>
                    <FormTextField
                      fieldConfig={{
                        name: 'imageName',
                        label: 'Name',
                        placeholder: 'agent-ottie-007',
                      }}
                      id="step_imageName"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'imageDesc',
                        label: 'Description',
                        placeholder:
                          'A sophisticated furry chat agent specializing in handling mission-critical conversations.',
                      }}
                      id="step_imageDesc"
                      maxRows={10}
                      minRows={3}
                      multiline
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'dockerImg',
                        label: 'Docker Image',
                        placeholder: 'dockerhub_org_name/image_name:tag',
                      }}
                      id="step_dockerImg"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'agentLogo',
                        label: 'Agent logo',
                        placeholder: 'https://example.com/ottie007.png',
                      }}
                      id="step_agentLogo"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'livenessProbePath',
                        label: 'Chat endpoint',
                        placeholder: '/poke',
                      }}
                      id="step_livenessProbePath"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'port',
                        label: 'Port',
                        placeholder: '3000',
                      }}
                      id="step_port"
                    />
                    <Option
                      endAdornment={
                        <Button onClick={openReqDialog} variant="outlined">
                          Generate
                        </Button>
                      }
                      id="step_chatRequestSchema"
                      optionTitle="Chat request schema"
                    >
                      <FormJsonEditor<FormValues>
                        editorProps={{ height: '20rem' }}
                        fieldPath="chatRequestSchema"
                      />
                    </Option>
                    <Option
                      endAdornment={
                        <Button onClick={openResDialog} variant="outlined">
                          Generate
                        </Button>
                      }
                      id="step_chatResponseSchema"
                      optionTitle="Chat response schema"
                    >
                      <FormJsonEditor<FormValues>
                        editorProps={{ height: '20rem' }}
                        fieldPath="chatResponseSchema"
                      />
                    </Option>

                    <FormTextField
                      fieldConfig={{
                        name: 'githubUrl',
                        label: 'Github URL (Optional)',
                        placeholder: 'https://github.com/alt-research',
                      }}
                      id="step_githubUrl"
                    />
                    <EnvVarsFieldArray />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <PublishAgentSummaryPanel
                      isSubmitting={isPendingPublish}
                      sx={{
                        mt: {
                          xs: 10,
                        },
                        m: {
                          xs: 3,
                          md: 5,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <DevTool control={form.control} />
            </form>
          </FormProvider>
        </Box>
      </AaasLayout>
    </>
  );
};
