import { BoxProps, Grid } from '@mui/material';
import { SwiperCarousel } from 'pages/aaas/components/SwiperCarousel';
import { FC, useMemo } from 'react';
import { useDisclosure } from 'shared/hooks/ui';
import { partitionArray } from 'shared/utils/array';
import { SwiperSlide } from 'swiper/react';

import { AgentOptInDialog } from './AgentOptInDialog';
import { BountyCard } from './BountyCard';

const unpartitionedBounties = Array.from(Array(13));

export const BountyBoardTab: FC<Omit<BoxProps, 'children'>> = props => {
  const partitioned = useMemo(() => partitionArray(unpartitionedBounties, 6), []);

  const {
    onClose: onCloseAgentOptInDialog,
    onOpen: onOpenAgentOptInDialog,
    open: isOpenAgentOptInDialog,
  } = useDisclosure();

  return (
    <>
      <AgentOptInDialog
        onClose={onCloseAgentOptInDialog}
        onSelectAgent={app => {
          // TODO: handle when BE is ready
          console.log('app:', app);
        }}
        open={isOpenAgentOptInDialog}
      />
      <SwiperCarousel
        py={3}
        slides={partitioned.map((bounties, pIdx) => {
          return (
            <SwiperSlide key={pIdx.toString()}>
              <Grid container spacing={{ md: 3, xs: 2 }}>
                {bounties.map((_, index) => (
                  <Grid item key={index} md={4} sm={6} xs={6}>
                    <BountyCard
                      hasJoined={index % 2 === 0}
                      onClickActionButton={onOpenAgentOptInDialog}
                    />
                  </Grid>
                ))}
              </Grid>
            </SwiperSlide>
          );
        })}
        {...props}
      />
    </>
  );
};
