import { useQuery } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { ListAAAAppReply } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

export const useListApps = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_AAA_APPS],
    queryFn: async () => {
      const res = await bff.get<ListAAAAppReply>('/bff/aaa/apps');

      return res.data;
    },
  });
};
