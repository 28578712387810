import { Metric } from 'shared/components/metric';
import { minuteFormatter } from 'shared/utils/recharts';

import { useRpm } from './hooks';

export const RpmMetric = () => {
  const { data, isPending, isRefetching, refetch } = useRpm();

  return (
    <Metric
      chartType="area"
      data={data}
      description="Requests per minute for the last 1 hour"
      id="real-time-rpm"
      isRefetching={isRefetching}
      loading={isPending}
      moreMenuProps={{
        onClickRefetch: () => refetch(),
      }}
      responsiveContainerSx={{ minHeight: '250px' }}
      title="Real-time RPM"
      xAxisProps={{
        tickFormatter: minuteFormatter,
      }}
    />
  );
};
