import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export interface IExternalLink extends TypographyProps {
  href?: string;
  children: React.ReactNode;
  target?: string;
}

export const ExternalLink: React.FC<IExternalLink> = ({
  children,
  href,
  sx,
  ...typographyProps
}) => {
  return (
    <Typography
      component="a"
      href={href}
      rel="noopener noreferrer"
      sx={{
        color: theme => theme.colors.functional.text.link,
        '&:hover': {
          textDecoration: 'underline',
        },
        ...sx,
      }}
      target="_blank"
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};
