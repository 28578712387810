import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { IconClose } from 'shared/components/icons/IconClose';
import { useCopyToClipboard } from 'shared/hooks/ui';
import { QUERY_KEYS } from 'shared/types/react-query';

export interface IPreviewDialog extends Omit<DialogProps, 'onClose'> {
  title: string | undefined;
  jsonOrUrl: string | undefined;
  onClose?: () => void;
}

export const PreviewDialog = ({
  jsonOrUrl,
  onClose,
  open,
  sx,
  title,
  ...props
}: IPreviewDialog) => {
  const [, copy] = useCopyToClipboard();

  const { data: asyncJsonData, isPending: asyncJsonPending } = useQuery({
    queryKey: [QUERY_KEYS.PREVIEW_METADATA, { title }],
    queryFn: async () => {
      if (jsonOrUrl) {
        try {
          const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$',
            'i',
          ); // fragment locator

          if (!urlPattern.test(jsonOrUrl)) {
            return jsonOrUrl;
          }

          const url = new URL(jsonOrUrl);
          const response = await fetch(url.toString());

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const json = await response.json();

          return JSON.stringify(json, null, 2);
        } catch (e) {
          // If json is not a valid URL, return it as is
          return jsonOrUrl;
        }
      }
    },
    enabled: open,
  });

  const handleCopyJSON = useCallback(() => {
    copy(asyncJsonData ?? '');
    onClose?.();
  }, [asyncJsonData, copy, onClose]);

  return (
    <Dialog onClose={onClose} open={open} {...props} sx={{ width: '100%', p: 0, ...sx }}>
      <Stack spacing="40px" sx={{ p: '40px', minWidth: '504px', minHeight: '500px' }}>
        <Stack
          alignItems="center"
          direction="row"
          id="edit-dialog-header"
          justifyContent="space-between"
          spacing="40px"
        >
          <Typography variant="h5">
            {`${title?.charAt(0).toUpperCase()}${title?.slice(1).toLowerCase()} metadata`.trim()}
          </Typography>

          <IconButton onClick={onClose}>
            <IconClose />
          </IconButton>
        </Stack>

        <Stack id="edit-dialog-content" spacing="24px">
          {asyncJsonPending ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '325px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <pre
              style={{
                background: '#f7f7f7',
                borderRadius: '4px',
                padding: '8px',
                minWidth: '504px',
                height: '325px',
                overflowX: 'auto',
              }}
            >
              <code>{asyncJsonData ?? '{}'}</code>
            </pre>
          )}
        </Stack>

        <Button
          onClick={handleCopyJSON}
          sx={{
            minHeight: '68px',
          }}
        >
          <Typography variant="bodySmall">Copy JSON</Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};
