import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { getL1TokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { RollupType } from 'pages/raas/helpers/labels';
import { useBlockscoutBalanceMetrics, useRaasDeployment } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';
import { addressFormatter } from 'shared/utils/recharts';
import { formatTokenWithSymbol } from 'shared/utils/strings';

export const BatcherBalanceHistoryMetric = () => {
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const {
    end: to,
    start: from,
    timeframe,
  } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.BATCHER_BALANCE_BALANCE_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutBalanceMetrics({
    account: rollup?.accounts?.[rollup?.type === RollupType.OPSTACK ? 'batcher' : 'batchPoster'],
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      curveType="natural"
      data={result}
      description={"Batcher's balance over time"}
      id={'batcher-balance-history'}
      loading={isPending}
      title={'Balance history'}
      tooltipKeyFormatter={addressFormatter}
      tooltipValueFormatter={value => formatTokenWithSymbol(value, getL1TokenSymbol(rollup))}
      yAxisProps={{
        tickFormatter: value => formatTokenWithSymbol(value, rollup?.tokenMetadata?.symbol),
      }}
    />
  );
};
