import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';
import { formatAbbreviatedNumber } from 'shared/utils/strings';

export const BatcherPendingBlockCountMetric = () => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step, timeframe } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.BATCHER_BALANCE_BALANCE_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_BATCHER_PENDING_BLOCK,
    rollupId,
    timeframe,
    start,
    end,
    step,
  });

  return (
    <Metric
      curveType="natural"
      data={result}
      description={'The number of pending blocks waiting to be processed by the batcher'}
      id={'batcher-pending-block-count'}
      loading={isPending}
      title={'Batcher pending block count'}
      yAxisProps={{
        tickFormatter: value => formatAbbreviatedNumber(value),
      }}
    />
  );
};
