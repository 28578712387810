import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useRaasDeployment } from 'pages/raas/hooks';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { CustomTokenAlert } from './CustomTokenAlert';
import { DepositTxnsGrowthMetric } from './DepositTxnsGrowthMetric';
import { DepositVolumeEthGrowthMetric } from './DepositVolumeEthGrowthMetric';
import { NewDepositedAccountsMetric } from './NewDepositedAccountsMetric';

export const DepositsSection = () => {
  const { data: rollup } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          {rollup?.tokenMetadata?.isCustom && <CustomTokenAlert />}

          <MetricGridItem item>
            <DepositTxnsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <DepositVolumeEthGrowthMetric />
          </MetricGridItem>
          {showExperimentalFeatures && (
            <MetricGridItem item>
              <NewDepositedAccountsMetric />
            </MetricGridItem>
          )}
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter variant={TimeFilterVariant.BRIDGE_METRICS_DEPOSITS_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="Deposits"
    />
  );
};
