import { BoxProps, Checkbox, Grid, Typography } from '@mui/material';
import { avsStrategyLabels } from 'pages/avs/helpers/labels';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckboxCard from 'shared/components/Card/CheckboxCard';
import { Option } from 'shared/components/Option';
import { AVS_QUORUMS } from 'shared/types/avs';

import { AVSFormValues } from '..';
import { avsTypeOptions } from './SelectAVSType';

export const _strategyOptions = [
  {
    label: (
      <Typography>
        {avsStrategyLabels[AVS_QUORUMS.ETH_LST]}{' '}
        <Typography component="span" sx={{ opacity: 0.4 }}>
          (DEFAULT)
        </Typography>
      </Typography>
    ),
    value: AVS_QUORUMS.ETH_LST,
  },
  { label: avsStrategyLabels[AVS_QUORUMS.EIGEN], value: AVS_QUORUMS.EIGEN },
  { label: avsStrategyLabels[AVS_QUORUMS.REALT], value: AVS_QUORUMS.REALT },
];

export default function SelectStrategies(props: BoxProps) {
  const { register, setValue } = useFormContext();
  const selectedStrategies = useWatch<AVSFormValues, 'strategies'>({ name: 'strategies' });
  const avsType = useWatch<AVSFormValues, 'avsType'>({ name: 'avsType' });
  const strategyOptions = useMemo(
    () =>
      avsTypeOptions?.find(cur => cur.value === avsType)?.sig === 'ecdsa'
        ? [_strategyOptions?.[0]]
        : _strategyOptions,
    [avsType],
  );

  const handleSelectStrat = (value: AVS_QUORUMS) => () => {
    if (selectedStrategies.includes(value)) {
      setValue('strategies', selectedStrategies.filter(cur => cur !== value)?.sort(), {
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setValue('strategies', [...selectedStrategies, value]?.sort(), {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <Option optionTitle="Supported Strategies" {...props}>
      <Grid container id="step_strategies" {...register('strategies')} ref={null} sx={{}}>
        {strategyOptions?.map((strat, index) => {
          const isNextOptionSelected = selectedStrategies?.includes(
            strategyOptions?.[index + 1]?.value,
          );

          return (
            <Grid item key={strat?.value} xs={12}>
              <CheckboxCard
                content={<Typography px={1}>{strat.label}</Typography>}
                disabled={strat.value === AVS_QUORUMS.ETH_LST}
                isSelected={selectedStrategies?.includes(strat.value) || false}
                leftButtonGroup={
                  <Checkbox
                    checked={selectedStrategies?.includes(strat.value) || false}
                    onChange={handleSelectStrat(strat.value)}
                    size="small"
                    sx={{ ml: 1.5 }}
                  />
                }
                onClick={handleSelectStrat(strat.value)}
                sx={{
                  py: 1,
                  svg: {},
                  ...(isNextOptionSelected && { borderBottom: 'none' }),
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Option>
  );
}
