import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ExternalLink } from 'pages/raas/components';
import { getTokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { settlementLayerExplorerUrl } from 'pages/raas/DeploymentDetails/views/ProposerView/constants';
import { SettlementLayer } from 'pages/raas/helpers/labels';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useMemo } from 'react';
import { Section } from 'shared/components/Section';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useTablePagination } from 'shared/hooks/ui';
import { FeeVault } from 'shared/types/protoc-gen/raas-rollup';
import { truncateTxnHash } from 'shared/utils/strings';

import { useWFHistory } from '../hooks';

const feeVaultLabel = {
  [FeeVault.BASE_FEE_VAULT]: 'BaseFeeVault',
  [FeeVault.L1_FEE_VAULT]: 'L1FeeVault',
  [FeeVault.SEQUENCER_FEE_VAULT]: 'SequencerFeeVault',
  [FeeVault.UNRECOGNIZED]: 'Unrecognized',
};

export const WithdrawHistorySection = () => {
  const { data: rollup } = useRaasDeployment();
  const { data: withdrawLogs, isPending } = useWFHistory({
    opstackId: rollup?.id ?? '',
    enabled: Boolean(rollup?.id),
  });
  const sortedWithdrawLogs = useMemo(() => {
    return withdrawLogs?.sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix());
  }, [withdrawLogs]);
  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: sortedWithdrawLogs ?? [],
  });

  return (
    <Section
      renderContent={
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  'Fee Vault',
                  'Tx Hash',
                  `Amount (${getTokenSymbol(rollup)})`,
                  'Status',
                  'Time',
                ].map(header => (
                  <TableCell key={header}>
                    <Typography variant="captionC">{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isPending ? (
                Array.from({ length: 10 }).map((_, index) => <TableRowSkeleton key={index} />)
              ) : filteredData?.length > 0 ? (
                filteredData?.map(
                  ({ actionName, amount, l2TxHash, timestamp, txHash, vault }, index) => (
                    <TableRow key={index.toString()}>
                      <TableCell>
                        <Typography variant="bodySmall">{feeVaultLabel[vault]}</Typography>
                      </TableCell>
                      <TableCell>
                        <ExternalLink
                          href={`${
                            actionName === 'Initiated'
                              ? rollup?.explorer
                              : settlementLayerExplorerUrl[
                                  rollup?.settlementLayer as SettlementLayer
                                ]
                          }/tx/${actionName === 'Initiated' ? l2TxHash ?? '' : txHash}`}
                          variant="bodySmall"
                        >
                          {truncateTxnHash(actionName === 'Initiated' ? l2TxHash ?? '' : txHash)}
                        </ExternalLink>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">{amount}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">{actionName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">
                          {dayjs(timestamp).format('YYYY/MM/DD HH:mm:ss')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ),
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography>No data.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {!isPending && (
              <Pagination
                paginationOptions={{
                  count: paginationCount,
                  onChange: (_, page) => setCurrentPage(page),
                }}
                sx={{ pl: '40px', py: '24px' }}
              />
            )}
          </Table>
        </TableContainer>
      }
      title="Withdraw History"
    />
  );
};
