import { Button, darken, Stack, StackProps, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode, useState } from 'react';

export interface TabbedSectionItem {
  label: ReactNode;
  content: ReactNode;
  onClick?: () => void;
}

interface ITabbedSection {
  id?: string;
  tabs: TabbedSectionItem[];
  endAdornment?: ReactNode;
}

export default function TabbedSection({
  endAdornment,
  id = 'tabbed-section',
  sx,
  tabs,
  ...props
}: PropsWithChildren<ITabbedSection & StackProps>) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={sx} {...props}>
        <Stack
          direction="row"
          sx={{
            maxWidth: '100%',
            overflowX: 'auto',
            '&>button.activeTab,&>button.activeTab:hover': {
              background: theme => theme.colors.gradients.cream,
              border: theme => `2px solid ${darken(theme.colors.schema.creamPrimary, 0.1)}`,
              opacity: 1,
            },
            '&>button, &>button:hover': {
              background: theme => theme.colors.gradients.metal,
              color: theme => theme.colors.functional.text.primary,
              opacity: 0.7,
              transition: 'none',
            },
            '&>button:hover': {
              filter: 'invert(0.02)',
            },
          }}
        >
          {tabs?.map((tab, index) => (
            <Button
              className={activeTab === index ? 'activeTab' : ''}
              key={[id, index].join('-')} // index is ok because there will be no change in order
              onClick={() => {
                setActiveTab(index);
                tab.onClick?.();
              }}
            >
              <Typography variant="bodyMediumC">{tab.label}</Typography>
            </Button>
          ))}
        </Stack>
        {endAdornment}
      </Stack>

      {tabs?.[activeTab]?.content}
    </>
  );
}
