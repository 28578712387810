import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import { useApp } from 'pages/aaas/AaasDetails/hooks/useApp';
import { getBasicAuthHeaders } from 'pages/aaas/AaasDetails/utils';
import { MUTATION_KEYS } from 'shared/types/react-query';

import { useChatEndpoint } from './useChatEndpoint';

interface PromptError extends Error {
  code: string;
}

export interface IUsePrompt
  extends Omit<
    UseMutationOptions<PromptReply, PromptError, PromptRequest, unknown>,
    'mutationFn' | 'mutationKey'
  > {
  appId?: string;
}

export const usePrompt = (
  opts?: IUsePrompt,
): UseMutationResult<PromptReply, PromptError, PromptRequest, unknown> => {
  const { data: app } = useApp({ appId: opts?.appId });
  const { data: chatEndpoint } = useChatEndpoint({ app });

  return useMutation({
    mutationKey: [MUTATION_KEYS.AAAS_AI_AGENT_PROMPT, { appId: opts?.appId }],
    mutationFn: async (args: { message: string }) => {
      if (!app?.endpoints?.apiUrl) throw new Error('The app has no host endpoint');
      if (!chatEndpoint) throw new Error('Failed to fetch endpoint URL');

      const res = await axios.post<PromptReply>(
        chatEndpoint,
        String(app.template?.id) === 'Eliza' ? { text: args.message } : { message: args.message },
        {
          headers: getBasicAuthHeaders(app),
        },
      );

      return res.data;
    },
    retry: 5, // The default retryDelay is set to double (starting at 1000ms) with each attempt, but not exceed 30 seconds: https://tanstack.com/query/latest/docs/framework/react/guides/query-retries
    ...opts,
  });
};

export interface PromptRequest {
  message: string;
}

interface PromptReplyAgentkit {
  response: string[];
  status: 'success';
}
type PromptReplyEliza = {
  user: string;
  text: string;
  action: string;
}[];

type PromptReplyCustom =
  | {
      [key: string]: any;
    }
  | string
  | string[]
  | { [key: string]: any }[];
export type PromptReply = PromptReplyAgentkit | PromptReplyEliza | PromptReplyCustom;
