import { Box, Grid, GridProps, Stack, Tooltip, Typography } from '@mui/material';
import { PATHS } from 'App';
import { useRaasDeployments } from 'pages/raas/hooks';
import { useNavigate } from 'react-router-dom';
import { AccordionSection } from 'shared/components/AccordionSection';
import { DeploymentGridActionButton, DeploymentGridContainer } from 'shared/components/Deployment';
import IconInfo from 'shared/components/icons/IconInfo';
import { PageBanner, PageBannerVariant } from 'shared/components/Layout/PageBanner';
import { SubHeader } from 'shared/components/Layout/SubHeader';
import { useDisclosure } from 'shared/hooks/ui';
import { Rollup } from 'shared/types/protoc-gen/raas-rollup';

import { RaasDeploymentCard } from './RaasDeploymentCard';

export function RaasDeployments() {
  const navigate = useNavigate();
  const { onToggle: onToggleDemoChainAccordion, open: openedDemoChainAccordion } = useDisclosure();
  const { data: deployments, isLoading: isLoadingRollupDeployments } = useRaasDeployments();

  return (
    <>
      <PageBanner variant={PageBannerVariant.RAAS} />
      <SubHeader isLoading={isLoadingRollupDeployments}>Your Deployments</SubHeader>
      <DeploymentGridContainer>
        {deployments
          ?.filter(v => v.visibilityType === 'Private')
          ?.map(deployment => (
            <DeploymentGridItem
              deployment={deployment}
              key={[deployment.id, deployment.name].join('-')}
            />
          ))}

        <DeploymentGridActionButton onClick={() => navigate(PATHS.RAAS_NEW_DEPLOYMENT)} />
      </DeploymentGridContainer>

      <AccordionSection
        onClickHeader={onToggleDemoChainAccordion}
        open={deployments?.length === 0 || openedDemoChainAccordion}
        renderTitle={
          <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
            <Typography variant="h5">Demo chains</Typography>
            <Tooltip
              arrow
              placement="top"
              title="We offer a variety of demo chains for you to explore, featuring diverse rollup stacks such as OP Stack, Arbitrum Orbit, ZKsync, and Scroll, among others. Additionally, these chains support various data availability solutions, including Ethereum blobs, Celestia, Avail, and EigenDA. Explore the options and discover the solution that best suits your needs."
            >
              <Box alignItems="center" display="flex" justifyContent="center">
                <IconInfo sx={{ height: '24px', width: '24px' }} />
              </Box>
            </Tooltip>
          </Stack>
        }
        title="Demo chains"
      >
        <DeploymentGridContainer>
          {deployments
            ?.filter(v => v.visibilityType === 'Public')
            ?.map(deployment => (
              <DeploymentGridItem
                deployment={deployment}
                key={[deployment.id, deployment.name].join('-')}
              />
            ))}
        </DeploymentGridContainer>
      </AccordionSection>
    </>
  );
}

const DeploymentGridItem: React.FC<{ deployment: Rollup } & GridProps> = ({
  deployment,
  ...props
}) => (
  <Grid item xs={4} {...props}>
    <RaasDeploymentCard rollup={deployment} />
  </Grid>
);
