import { BASE_FEE_ADDR } from 'pages/raas/constants/addresses';
import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useBlockscoutBalanceMetrics, useRaasDeployment } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';
import { addressFormatter } from 'shared/utils/recharts';
import { formatTokenWithSymbol } from 'shared/utils/strings';

export const BaseFeeBalanceMetric = () => {
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const {
    end: to,
    start: from,
    timeframe,
  } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.FEE_TRACKER_GAS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutBalanceMetrics({
    account: BASE_FEE_ADDR,
    l2: true,
    timeframe,
    to,
    from,
  });

  return (
    <Metric
      data={result}
      description={'Base fee balance per period'}
      id={'base-fee-balance'}
      loading={isPending}
      title={'Base fee balance'}
      tooltipKeyFormatter={addressFormatter}
      tooltipValueFormatter={value => formatTokenWithSymbol(value, rollup?.tokenMetadata?.symbol)}
      yAxisProps={{
        tickFormatter: value => formatTokenWithSymbol(value, rollup?.tokenMetadata?.symbol),
      }}
    />
  );
};
