import { useQuery } from '@tanstack/react-query';
import { Token } from 'shared/hooks/tokens/useAppTokens';
import { QUERY_KEYS } from 'shared/types/react-query';
import { Address, createPublicClient, erc20Abi, http } from 'viem';
import { useAccount } from 'wagmi';

interface IUseEthTokenBalances {
  tokens: Token<Address>[] | undefined;
}

export const useEthTokenBalances = ({ tokens }: IUseEthTokenBalances) => {
  const { address, chain } = useAccount();

  return useQuery({
    queryKey: [
      QUERY_KEYS.WEB3_FETCH_BATCH_TOKEN_BALANCES,
      { chainId: chain?.id },
      tokens?.map(cur => cur?.address)?.join(','),
    ],
    queryFn: async (): Promise<Token[] | null> => {
      if (!address || !chain) return null;

      const publicClient = createPublicClient({
        chain,
        transport: http(),
      });

      return Promise.all(
        (tokens ?? []).map(async token => {
          const balance = await publicClient.readContract({
            abi: erc20Abi,
            address: token.address,
            functionName: 'balanceOf',
            args: [address],
          });

          return {
            ...token,
            balance,
          } satisfies Token;
        }),
      );
    },
    enabled: !!chain && !!address,
  });
};
