import { Box, Link, Stack, Typography } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useAuthContext, User } from 'contexts/AuthContext';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { getDefaultPath } from 'routes';
import { axiosAuth } from 'shared/api';
import { PageBanner } from 'shared/components/Layout/PageBanner';
import { authUtil } from 'shared/utils/auth';

export function AcceptInvite() {
  const { setIsLoggedIn, setUser } = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();
  const { addAlert } = useAlerts();

  const { mutate: acceptInvite } = useMutation({
    mutationFn: async () => {
      return await axiosAuth.post<{ user: User; accessToken: string }>('/org/accept-invite', {
        code: params?.id,
      });
    },
    onSuccess: res => {
      setUser(res?.data?.user);
      authUtil.setAccessToken(res?.data?.accessToken);
      navigate(getDefaultPath());
    },
  });

  const handleGoogleLoginSuccess = async (credentialResponse: CredentialResponse) => {
    const idToken = credentialResponse?.credential;

    let res: AxiosResponse<{ accessToken: string; user: User }>;

    try {
      res = await axiosAuth.get<{ accessToken: string; user: User }>('/auth/login', {
        headers: { Authorization: `Bearer ${idToken}` },
      });
    } catch (_err) {
      const err = _err as AxiosError<{ message: string }>;

      addAlert({
        title: `Login failed (${err?.response?.statusText})`,
        severity: ALERT_SEVERITY.ERROR,
        desc: `${err?.response?.data?.message}`,
      });

      return;
    }

    const { accessToken, user } = res?.data || {};

    authUtil.setAccessToken(accessToken);
    setUser(user);
    setIsLoggedIn(true);

    acceptInvite();
  };

  return (
    <>
      <PageBanner title="Join a team" />
      <Stack alignItems="center" flex={1} justifyContent="center" py={16}>
        <Box sx={{ py: 8, px: 16, mx: 'auto', bgcolor: '#fff' }}>
          <Typography>Sign in with google to accept your invitation</Typography>
          <Stack alignItems="center" justifyContent="center">
            <GoogleLogin
              onError={() => {
                console.error('Login Failed');
              }}
              onSuccess={handleGoogleLoginSuccess}
            />
          </Stack>
        </Box>
        <Box mt={12}>
          <Link component={RouterLink} to="/disclaimer">
            TERMS OF SERVICE
          </Link>
        </Box>
      </Stack>
    </>
  );
}
