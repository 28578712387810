import { RpcUsageMonitoringSection } from 'pages/raas/DeploymentDetails/views/RpcUsageMonitoringView/RpcUsageMonitoringSection';

import { showExperimentalFeatures } from '../../helpers/visibility';
import ChooseMethodSection from './ChooseMethodSection';
import RpcCountSection from './RpcCountSection';
import { RpmByMethodsSection } from './RpmByMethodsSection';

export const RpcUsageMonitoringView = () => {
  return showExperimentalFeatures ? (
    <>
      <RpcCountSection />
      <ChooseMethodSection />
      <RpmByMethodsSection />
    </>
  ) : (
    <>
      <RpcUsageMonitoringSection />
      <RpmByMethodsSection />
    </>
  );
};
