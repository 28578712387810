import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useYAxisMinValue } from '../../hooks/useYAxisMinValue';

export const L2FinalizedMetric = () => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step, timeframe } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.L2_NODES_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_L2_FINALIZED,
    timeframe,
    rollupId,
    start,
    end,
    step,
  });
  const { data: yAxisMinValue = 0 } = useYAxisMinValue({
    panel: Panel.PANEL_OPSTACK_L2_FINALIZED,
    timeframe,
    result,
    rollupId,
  });

  return (
    <Metric
      chartType="line"
      curveType="natural"
      data={result}
      description={'Block height of L2 finalized'}
      id={'l2-finalized'}
      legendDirection={'column'}
      legendSpacing={'4px'}
      loading={isPending}
      title={'L2 finalized'}
      yAxisProps={{
        domain: [yAxisMinValue],
      }}
    />
  );
};
