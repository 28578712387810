import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthContext, USER_ROLE } from 'contexts/AuthContext';
import { useMemo } from 'react';
import { axiosAuth } from 'shared/api';
import { UserRecord } from 'shared/types/user';

export default function useTeamMembers() {
  const { user: currentUser } = useAuthContext();
  const queryClient = useQueryClient();

  const { data: members, isPending: isFetchTeamMembersPending } = useQuery({
    queryKey: ['ORG_USERS', currentUser?.org_name],
    queryFn: async () => {
      const res = await axiosAuth.get<{ users: UserRecord[] }>(`/org/${currentUser?.org_id}/users`);

      return res?.data;
    },
    select: data => data?.users,
    enabled: Boolean(currentUser?.org_name && currentUser?.org_id),
  });

  return useMemo(
    () => ({
      members,
      isFetchTeamMembersPending,
      invalidateTeamMembersQuery: () =>
        queryClient.invalidateQueries({ queryKey: ['ORG_USERS', currentUser?.org_name] }),
      orgAdminEmail: members?.find(member =>
        [USER_ROLE.ORG_ADMIN, USER_ROLE.ROOT, USER_ROLE.WIZARD_MANAGER].includes(
          member.role as USER_ROLE,
        ),
      )?.email,
    }),
    [members, isFetchTeamMembersPending, queryClient, currentUser?.org_name],
  );
}
