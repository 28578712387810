import { Box, Stack, Typography } from '@mui/material';
import { ExternalLink } from 'pages/raas/components';
import { FC } from 'react';
import { Token } from 'shared/hooks/tokens';
import {
  commify,
  constructEndpointSafe,
  formatWithPrecision,
  truncate,
} from 'shared/utils/strings';
import { useAccount } from 'wagmi';

export const BountyTokenItem: FC<{ token: Token | undefined }> = ({ token }) => {
  const { chain } = useAccount();

  return (
    token && (
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <Stack direction="row" spacing={2}>
          {token.logoURI && (
            <Box component="img" src={token.logoURI} sx={{ height: 38, width: 38 }} />
          )}
          <Stack alignItems="start">
            <Typography variant="bodySmallM">{token.symbol}</Typography>
            <ExternalLink
              href={
                chain?.blockExplorers?.default.url &&
                constructEndpointSafe(
                  chain?.blockExplorers?.default.url || '',
                  'address',
                  token?.address || '',
                )
              }
              sx={{ color: theme => theme.colors.functional.text.third }}
              variant="caption"
            >
              {truncate(token.address || '')}
            </ExternalLink>
          </Stack>
        </Stack>

        {token?.balance !== undefined && (
          <Typography variant="body1">
            {commify(formatWithPrecision(token.balance, 4, token.decimals))} {token.symbol}
          </Typography>
        )}
      </Stack>
    )
  );
};
