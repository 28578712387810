import { StackProps } from '@mui/material';
import { Character } from 'pages/aaas/components/ConfigSimpleMode/types';
import { FC } from 'react';
import { FormJsonEditor } from 'shared/components/form/fields/FormJsonEditor';

interface FormValues {
  config: Partial<Character>;
}

export const ConfigAdvancedMode: FC<StackProps> = ({ ...props }) => (
  <FormJsonEditor<FormValues> fieldPath="config" {...props} />
);
