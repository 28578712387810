import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useListApps, useTerminateApp } from 'pages/aaas/hooks';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { FormTextFieldBase } from 'shared/components/form';
import { IconClose } from 'shared/components/icons/IconClose';
import { AAAApp } from 'shared/types/protoc-gen/bffaaa';

export interface IDeleteAppDialog extends DialogProps {
  app?: AAAApp;
}

interface DeleteFormValues {
  appName: string;
}

export const DeleteAppDialog: React.FC<IDeleteAppDialog> = ({ app, onClose, ...props }) => {
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const { refetch: refetchListApps } = useListApps();
  const { isPending: terminateAppPending, mutateAsync: terminateApp } = useTerminateApp({
    onSuccess: () => {
      refetchListApps();
      addAlert({ severity: ALERT_SEVERITY.SUCCESS, title: 'App deleted' });
      localStorage.removeItem(`chat.${app?.id}.messages`);
      onClose?.({}, 'backdropClick');
      navigate(PATHS.AAAS_DEPLOYMENTS, { replace: true });
    },
  });

  const form = useForm<DeleteFormValues>({
    defaultValues: {
      appName: '',
    },
  });

  const appName = form.watch('appName');

  const handleClose = () => onClose?.({}, 'backdropClick');

  const onValid: SubmitHandler<DeleteFormValues> = formValues => {
    if (app?.name === formValues.appName) {
      return terminateApp({ id: app.id });
    }
  };

  const onInvalid: SubmitErrorHandler<DeleteFormValues> = err => {
    console.error('Err:', err);
  };

  return (
    <Dialog onClose={onClose} {...props}>
      <Stack spacing="40px" sx={{ p: '40px', maxWidth: '504px' }}>
        <Stack alignItems="start" direction="row" justifyContent="space-between" spacing="40px">
          <Typography variant="h5">
            Are you sure you want to permanently delete this app?
          </Typography>

          <IconButton onClick={handleClose} size="small">
            <IconClose />
          </IconButton>
        </Stack>

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onValid, onInvalid)}>
            <Stack alignItems="center" direction="column" gap={2} width="100%">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'appName',
                  placeholder: `Enter ${app ? `"${app.name}"` : 'project name'} to delete`,
                }}
              />

              <LoadingButton
                disableElevation
                disabled={app?.name !== appName}
                fullWidth
                loading={terminateAppPending}
                type="submit"
                variant="text"
              >
                <Typography variant="bodySmall">Yes</Typography>
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Dialog>
  );
};
