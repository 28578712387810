import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useFineTune } from 'pages/aaas/AaasDetails/views/DetailsView/useFinetune';
import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormTextFieldBase } from 'shared/components/form';
import { IconCircleMinus } from 'shared/components/icons/IconCircleMinus';
import { GetFineTuneSourceReply } from 'shared/types/protoc-gen/bffaaa';
import * as yup from 'yup';

const ManageFinetuneSources = () => {
  const { data, isLoadingSources, isUpdateSourcesPending, updateSources } = useFineTune();

  const form = useForm<GetFineTuneSourceReply>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        telegramChannels: yup.array().of(
          yup
            .object()
            .shape({
              channelId: yup.string().required(),
              channelName: yup.string().required(),
            })
            .required(),
        ),
        twittersProfiles: yup.array().of(yup.string().required()),
      }) as unknown as yup.ObjectSchema<GetFineTuneSourceReply>,
    ),
  });
  const formValues = form.watch();

  const addTwitterProfile = useCallback(() => {
    form.setValue('twittersProfiles', [...formValues.twittersProfiles, '']);
  }, [form, formValues.twittersProfiles]);

  const removeTwitterProfile = useCallback(
    (index: number) => {
      form.setValue(
        'twittersProfiles',
        formValues.twittersProfiles.filter((_, i) => i !== index),
      );
    },
    [form, formValues.twittersProfiles],
  );

  const addTelegramChannel = useCallback(() => {
    form.setValue('telegramChannels', [
      ...formValues.telegramChannels,
      { channelId: '', channelName: '' },
    ]);
  }, [form, formValues.telegramChannels]);

  const removeTelegramChannel = useCallback(
    (index: number) => {
      form.setValue(
        'telegramChannels',
        formValues.telegramChannels.filter((_, i) => i !== index),
      );
    },
    [form, formValues.telegramChannels],
  );

  const onValid: SubmitHandler<GetFineTuneSourceReply> = useCallback(
    formValues => updateSources(formValues),
    [updateSources],
  );

  useEffect(
    () => {
      if (data?.telegramChannels) form.setValue('telegramChannels', data.telegramChannels);
      if (data?.twittersProfiles) form.setValue('twittersProfiles', data.twittersProfiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onValid)}>
        <Box sx={{ background: theme => theme.colors.gradients.sheet, p: 5, mb: '164px' }}>
          <Container maxWidth="xl">
            <Typography sx={{ my: 5 }} variant="h3">
              Manage finetune sources
            </Typography>
            <Grid container spacing={5}>
              <Grid item md={6} sm={12}>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between" width="100%">
                    <Typography variant="h5">Twitter Profiles</Typography>
                    <Button disabled={isLoadingSources} onClick={addTwitterProfile} size="small">
                      Add
                    </Button>
                  </Stack>
                  <Divider />
                  <Stack spacing={1}>
                    {formValues.twittersProfiles?.map((twitterProfile, index) => (
                      <Stack
                        alignItems="center"
                        direction="row"
                        key={['twittersProfiles', index].join('-')}
                        spacing={1}
                      >
                        <FormTextFieldBase
                          fieldConfig={{
                            name: `twittersProfiles[${index}]`,
                            placeholder: `twittersProfiles[${index}]`,
                          }}
                          helperTextShrink
                          value={twitterProfile}
                        />
                        <IconButton onClick={() => removeTwitterProfile(index)} size="small">
                          <IconCircleMinus />
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={6} sm={12}>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between" width="100%">
                    <Typography variant="h5">Telegram Channels</Typography>
                    <Button disabled={isLoadingSources} onClick={addTelegramChannel} size="small">
                      Add
                    </Button>
                  </Stack>
                  <Divider />
                  <Stack spacing={1}>
                    {formValues.telegramChannels?.map((telegramChannel, index) => (
                      <Stack
                        alignItems="center"
                        direction="row"
                        key={['telegramChannel', index].join('-')}
                        spacing={1}
                      >
                        <FormTextFieldBase
                          fieldConfig={{
                            name: `telegramChannels[${index}].channelId`,
                            placeholder: `telegramChannels[${index}].channelId`,
                          }}
                          helperTextShrink
                          value={telegramChannel?.channelId}
                        />
                        <FormTextFieldBase
                          fieldConfig={{
                            name: `telegramChannels[${index}].channelName`,
                            placeholder: `telegramChannels[${index}].channelName`,
                          }}
                          helperTextShrink
                          value={telegramChannel?.channelName}
                        />
                        <IconButton onClick={() => removeTelegramChannel(index)} size="small">
                          <IconCircleMinus />
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="end" width="100%">
                  <LoadingButton
                    loading={form.formState.isSubmitting || isUpdateSourcesPending}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ManageFinetuneSources;
