import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { raas } from 'shared/api';
import { ListRollupReply, Rollup } from 'shared/types/protoc-gen/raas-rollup';
import { QUERY_KEYS } from 'shared/types/react-query';

export const useRaasDeployments = (
  options?: Omit<UseQueryOptions<Rollup[]>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery<Rollup[]>({
    queryKey: [QUERY_KEYS.GET_DEPLOYMENTS],
    queryFn: async () => {
      const reply = await raas.get<ListRollupReply>('/bff/rollups');

      return reply.data.rollups;
    },
    ...options,
  });
};
