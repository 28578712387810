import { useCallback, useMemo, useState } from 'react';
import { isPositiveNumber, parseUnitsSafe } from 'shared/utils/strings';

export function useAmount(decimals = 18) {
  const [amountRaw, setAmountRaw] = useState<string>('');

  const setSanitizedAmountStr = useCallback(
    (input: string) => {
      if (input) {
        const [whole, fractional] = input.split('.', 2);
        const truncatedFractional = fractional?.substring(0, decimals);
        const result = truncatedFractional ? [whole, truncatedFractional].join('.') : whole;

        if (input?.match(/^\d*\.$/)) {
          setAmountRaw(result + '.');
        } else if (!input?.match(/^\d*\.{0,1}\d*$/)) {
          setAmountRaw(result?.match(/^\d*\.{0,1}\d*$/) ? result : result?.slice(0, -1));
        } else {
          setAmountRaw(result);
        }
      } else {
        setAmountRaw(input);
      }
    },
    [decimals],
  );

  return useMemo(() => {
    if (!isPositiveNumber(amountRaw)) {
      return { amountBN: 0n, amountRaw, setAmountRaw: setSanitizedAmountStr };
    }

    return {
      amountBN: parseUnitsSafe(amountRaw, decimals),
      amountRaw,
      setAmountRaw: setSanitizedAmountStr,
    };
  }, [amountRaw, decimals, setSanitizedAmountStr]);
}
