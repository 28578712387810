import { AVS_QUORUMS } from 'shared/types/avs';
import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    strategies: yup
      .array()
      .of(yup.number().oneOf(Object.values(AVS_QUORUMS)).required())
      .required(),
  })
  .required();
