import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  StackProps,
  Switch,
  Typography,
} from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { AgentPersonaButton } from 'pages/aaas/components/ConfigSimpleMode/AgentPersonaButton';
import { FormGroupContainer } from 'pages/aaas/components/ConfigSimpleMode/FormGroupContainer';
import {
  GeneratedCharacter,
  useListAppTypes,
  useListGeneratedCharacters,
  usePollGeneratedCharacter,
} from 'pages/aaas/hooks';
import { getTemplateById } from 'pages/aaas/NewAgentDeployment/helpers';
import { CharacterFormValues } from 'pages/aaas/NewAgentDeployment/types';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FieldPath, FormProvider, useFormContext } from 'react-hook-form';
import { FormTextFieldBase, Label } from 'shared/components/form';
import { IconCircleMinus } from 'shared/components/icons/IconCircleMinus';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { tryParseJSON } from 'shared/utils/json';

import { AgentPersona, Character, MessageExample } from './types';

interface AgentPersonaItem {
  id: string;
  type: AgentPersona;
  title: string;
  description: string | string[];
  onClick: () => void;
  loading?: boolean;
  hidden?: boolean;
}

interface IConfigSimpleMode extends StackProps {
  enableAgentPersona?: boolean;
  agentPersonaSectionLabelEndAdornment?: ReactNode;
  currentCharacterMetadata?: Character;
}

export const ConfigSimpleMode: FC<IConfigSimpleMode> = ({
  agentPersonaSectionLabelEndAdornment,
  currentCharacterMetadata,
  enableAgentPersona,
  ...props
}) => {
  const form = useFormContext<CharacterFormValues>();
  const formValues = form.watch();

  const { md, sm } = useBreakpoints();
  const { addAlert } = useAlerts();
  const { data: templates } = useListAppTypes();

  const { data: generatedCharacters } = useListGeneratedCharacters();
  const { data: recentlyGeneratedCharacterResult } = usePollGeneratedCharacter({
    params: { jobId: formValues.persona?.recentJobId ?? '' },
  });

  const recentlyGeneratedCharacter = useMemo(
    () => ({
      ...(tryParseJSON(recentlyGeneratedCharacterResult?.characterConfig) as GeneratedCharacter),
      ...(showExperimentalFeatures
        ? { bio: [formValues.persona?.bio], lore: [formValues.persona?.lore] }
        : {}),
    }),
    [
      formValues.persona?.bio,
      formValues.persona?.lore,
      recentlyGeneratedCharacterResult?.characterConfig,
    ],
  );

  const agentPersonaCharacter = useMemo(
    () =>
      currentCharacterMetadata ??
      (tryParseJSON(
        getTemplateById(formValues.templateId ?? '', templates)?.configExample,
      ) as Character), // Supports either creation & edit mode
    [currentCharacterMetadata, formValues.templateId, templates],
  );

  useEffect(
    () => {
      const formValues = form.getValues();

      if (formValues.config?.messageExamples && formValues.config?.messageExamples?.length === 0) {
        addMessageExample();
        addPeople();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const selectAgentPersona = useCallback(
    (id: string, persona: AgentPersona, _generatedCharacter?: GeneratedCharacter) => {
      const config = _generatedCharacter ?? recentlyGeneratedCharacter;

      if (!agentPersonaCharacter) {
        addAlert({
          severity: ALERT_SEVERITY.ERROR,
          title: 'Agent persona not found',
        });

        return console.error('Agent persona not found');
      }

      form.setValue('persona.selectedPersonaId', id, {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      });

      switch (persona) {
        case AgentPersona.Template:
          form.setValue(
            'config',
            {
              ...agentPersonaCharacter,
              clients: formValues.config?.clients ?? ['twitter'],
              modelProvider: 'openrouter',
            },
            { shouldValidate: true },
          );
          break;
        case AgentPersona.Custom:
          form.setValue(
            'config',
            {
              name: '',
              clients: ['twitter'],
              modelProvider: 'openrouter',
              settings: {
                secrets: {},
                voice: {
                  model: 'en_US-male-medium',
                },
              },
              plugins: [],
              bio: [''],
              lore: [''],
              knowledge: [],
              people: [],
              messageExamples: [],
              postExamples: [''],
              topics: [''],
              style: {
                all: [''],
                chat: [''],
                post: [''],
              },
              adjectives: [''],
            },
            { shouldValidate: true },
          );
          break;
        case AgentPersona.Generated:
          form.setValue(
            'config',
            {
              plugins: [],
              settings: {
                secrets: {},
                voice: {
                  model: 'en_US-male-medium',
                },
              },
              ...config,
              clients: formValues.config?.clients ?? ['twitter'],
              modelProvider: 'openrouter',
            },
            { shouldValidate: true },
          );
          break;
        default:
          break;
      }

      form.setError('config', {});
    },
    [addAlert, agentPersonaCharacter, form, formValues.config?.clients, recentlyGeneratedCharacter],
  );

  const addMessageExample = useCallback(() => {
    const messageExample: MessageExample[] = [
      {
        user: '{{user1}}',
        content: {
          text: '',
        },
      },
      {
        user: formValues.config?.name ?? '',
        content: {
          text: '',
        },
      },
    ];

    form.setValue('config.messageExamples', [
      ...(formValues.config?.messageExamples ?? []),
      messageExample,
    ]);
  }, [form, formValues.config]);

  const removeMessageExample = useCallback(
    (parentIndex: number) => {
      const messageExamples = [...(formValues.config?.messageExamples ?? [])];

      messageExamples.splice(parentIndex, 1);

      form.setValue('config.messageExamples', messageExamples);
    },
    [form, formValues.config?.messageExamples],
  );

  const addAdjective = useCallback(() => {
    form.setValue('config.adjectives', [...(formValues.config?.adjectives ?? []), '']);
  }, [form, formValues.config?.adjectives]);

  const removeAdjective = useCallback(
    (index: number) => {
      const adjectives = [...(formValues.config?.adjectives ?? [])];

      adjectives.splice(index, 1);

      form.setValue('config.adjectives', adjectives);
    },
    [form, formValues.config?.adjectives],
  );

  const addPeople = useCallback(() => {
    form.setValue('config.people', [...(formValues.config?.people ?? []), '']);
  }, [form, formValues.config?.people]);

  const removePeople = useCallback(
    (index: number) => {
      const people = [...(formValues.config?.people ?? [])];

      people.splice(index, 1);

      form.setValue('config.people', people);
    },
    [form, formValues.config?.people],
  );

  const getFormFieldValue = (fieldName: FieldPath<CharacterFormValues>) =>
    typeof form.getValues(fieldName) === 'string'
      ? form.getValues(fieldName)
      : Array.isArray(form.getValues(fieldName))
      ? (form.getValues(fieldName) as string[]).join('\n\n')
      : undefined;

  const onFormFieldChange: (
    fieldName: FieldPath<CharacterFormValues>,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void = (fieldName, event) => {
    const value = event.target.value;

    const formValue = value.split('\n\n');

    form.setValue(fieldName, formValue);
  };

  const agentPersonaItems = useMemo<AgentPersonaItem[]>(() => {
    const recent: AgentPersonaItem = {
      id: recentlyGeneratedCharacter?.name
        ? recentlyGeneratedCharacter?.name?.trim().toLowerCase()
        : ['generated', new Date().getTime()].join('-'),
      title: recentlyGeneratedCharacter?.name
        ? `${recentlyGeneratedCharacter?.name} (recently generated)`
        : 'Generating...',
      type: AgentPersona.Generated,
      description: recentlyGeneratedCharacterResult?.isFinished
        ? recentlyGeneratedCharacter?.bio
        : 'Generate a character persona based on the provided template.',
      onClick: () => selectAgentPersona(recentlyGeneratedCharacter?.name, AgentPersona.Generated),
      loading: !recentlyGeneratedCharacterResult?.isFinished,
      hidden: !recentlyGeneratedCharacterResult,
    };
    const custom: AgentPersonaItem = {
      id: 'custom',
      type: AgentPersona.Custom,
      title: 'Custom',
      description:
        'Define your own character persona by providing detailed information about their background, personality, and communication style.',
      onClick: () => selectAgentPersona('custom', AgentPersona.Custom),
    };

    const items = [
      {
        id: agentPersonaCharacter?.name?.trim().toLowerCase(),
        type: AgentPersona.Template,
        title: agentPersonaCharacter?.name,
        description: agentPersonaCharacter?.bio,
        onClick: () =>
          selectAgentPersona(
            agentPersonaCharacter?.name?.trim().toLowerCase(),
            AgentPersona.Template,
          ),
      },
    ];

    const _generatedCharacters = generatedCharacters?.map<AgentPersonaItem>(cur => ({
      id: cur.name?.trim().toLowerCase(),
      type: AgentPersona.Generated,
      title: cur.name,
      description: cur.bio,
      onClick: () =>
        selectAgentPersona(cur.name?.trim().toLowerCase(), AgentPersona.Generated, cur),
    }));

    let uniqueItems = [...items, ...(_generatedCharacters ?? []), custom, recent].reduce<
      AgentPersonaItem[]
    >((acc, cur) => {
      if (!acc.find(item => item.id === cur.id)) acc.push(cur);

      return acc;
    }, []);

    uniqueItems = uniqueItems.filter(cur => !cur.hidden);

    return uniqueItems;
  }, [
    agentPersonaCharacter?.bio,
    agentPersonaCharacter?.name,
    recentlyGeneratedCharacterResult,
    recentlyGeneratedCharacter?.bio,
    recentlyGeneratedCharacter?.name,
    generatedCharacters,
    selectAgentPersona,
  ]);

  return (
    <Stack spacing={2} sx={{ '& .MuiTextField-root': { background: '#fafafa' } }} {...props}>
      <FormProvider {...form}>
        <Stack spacing={5}>
          {enableAgentPersona && agentPersonaCharacter && (
            <FormGroupContainer
              endAdornment={agentPersonaSectionLabelEndAdornment}
              title="Agent Persona"
            >
              <Grid container>
                {agentPersonaItems.map((cur, index) => (
                  <Grid key={[cur.title, index].join('-')} md={4} sm={12} xs={12}>
                    <AgentPersonaButton
                      description={cur.description}
                      loading={cur.loading}
                      onClick={cur.onClick}
                      selected={cur.id === formValues.persona?.selectedPersonaId}
                      title={cur.title}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroupContainer>
          )}
          <FormGroupContainer title="Basic information">
            <Label label="Name">
              <FormTextFieldBase
                fieldConfig={{ name: 'config.name' }}
                value={getFormFieldValue('config.name')}
              />
            </Label>
            <Label label="Client">
              <Stack direction="row" spacing={1}>
                {/* 
                {availableClients
                  .filter(availableClient => supportedClients.includes(availableClient))
                  .map(availableClient => (
                    <Chip key={availableClient} label={availableClient} />
                  ))} 
                */}

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.config?.clients?.includes('twitter')}
                      onChange={() => {
                        if (formValues.config?.clients?.includes('twitter')) {
                          return form.setValue('config.clients', [], {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }

                        return form.setValue('config.clients', ['twitter'], {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                      }}
                      ref={undefined}
                      sx={{
                        '& .MuiSwitch-thumb, .MuiSwitch-track': {
                          borderRadius: 0,
                          boxShadow: 'none',
                        },
                        // Thumb styles for unchecked state
                        '& .MuiSwitch-switchBase': {
                          color: theme => theme.colors.functional.subject.first,
                        },
                        // Thumb styles for checked state
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: theme => theme.colors.functional.text.primary,
                        },
                        // Track styles for unchecked state
                        '& .MuiSwitch-track': {
                          backgroundColor: '#FFFFFF',
                          border: '1.5px solid',
                          borderColor: theme => theme.colors.functional.subject.first,
                          opacity: 100,
                        },
                        // Track styles for checked state
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: '#FFFFFF',
                          borderColor: theme => theme.colors.functional.text.primary,
                          opacity: 100,
                        },

                        '&&': {
                          p: 1,
                          ml: -1,
                          borderRadius: 0,
                        },
                      }}
                    />
                  }
                  label={<Typography variant="body1">Enable Twitter</Typography>}
                  labelPlacement="end"
                />
              </Stack>
            </Label>
          </FormGroupContainer>
          <FormGroupContainer title="Character details">
            <Label label="Bio">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'config.bio',
                  placeholder: `Provide a detailed biography of the character, including their background, life experiences, and key personality traits. Write one complete sentence per line.`,
                }}
                minRows={4}
                multiline
                onChange={event => onFormFieldChange('config.bio', event)}
                value={getFormFieldValue('config.bio')}
              />
            </Label>
            <Label label="Lore">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'config.lore',
                  placeholder: `Detail the character's universe, background, and significant events that influenced them. Write one complete sentence per line.`,
                }}
                minRows={4}
                multiline
                onChange={event => onFormFieldChange('config.lore', event)}
                value={getFormFieldValue('config.lore')}
              />
            </Label>
            <Label label="Topics">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'config.topics',
                  placeholder: `Enumerate the subjects the character is well-versed in or passionate about. Write one complete sentence per line.`,
                }}
                minRows={4}
                multiline
                onChange={event => onFormFieldChange('config.topics', event)}
                value={getFormFieldValue('config.topics')}
              />
            </Label>
          </FormGroupContainer>
          <FormGroupContainer title="Style">
            <Label label="General style">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'config.style.all',
                  placeholder: `Outline the character's overall communication style. Mention speech patterns, mannerisms, and common expressions. Write one complete sentence per line.`,
                }}
                minRows={4}
                multiline
                onChange={event => onFormFieldChange('config.style.all', event)}
                value={getFormFieldValue('config.style.all')}
              />
            </Label>
            <Label label="Chat style">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'config.style.chat',
                  placeholder: `Explain the character's conversational behavior. Include typical responses and chat-specific quirks. Write one complete sentence per line.`,
                }}
                minRows={4}
                multiline
                onChange={event => onFormFieldChange('config.style.chat', event)}
                value={getFormFieldValue('config.style.chat')}
              />
            </Label>
            <Label label="Post style">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'config.style.post',
                  placeholder: `Detail the character's approach to writing posts or longer content. Include their formatting preferences and overall writing style. Write one complete sentence per line.`,
                }}
                minRows={4}
                multiline
                onChange={event => onFormFieldChange('config.style.post', event)}
                value={getFormFieldValue('config.style.post')}
              />
            </Label>
          </FormGroupContainer>
          <FormGroupContainer title="Examples">
            <Label label="Post examples">
              <FormTextFieldBase
                fieldConfig={{
                  name: 'config.postExamples',
                  placeholder: `Provide sample posts showcasing the character's writing style. Include various types of content they might produce. Write each post on a new line.`,
                }}
                minRows={4}
                multiline
                onChange={event => onFormFieldChange('config.postExamples', event)}
                value={getFormFieldValue('config.postExamples')}
              />
            </Label>
            <Label
              label="Message examples"
              labelEndAdornment={
                md && (
                  <Button onClick={addMessageExample} size="small">
                    Add
                  </Button>
                )
              }
            >
              <Stack spacing={5} width="100%">
                <Stack spacing={3} width="100%">
                  {formValues.config?.messageExamples?.map(
                    (messageExample, parentIndex: number) => (
                      <Grid
                        container
                        direction="row"
                        item
                        key={['messageExample', parentIndex].join('-')}
                        spacing={2}
                        width="100%"
                        xs={12}
                      >
                        <Stack alignItems="start" direction="row" width="100%">
                          <Grid container spacing={1}>
                            {messageExample.map(({ content, user }, index: number) => (
                              <Grid item key={[user, index].join('-')} md={6} sm={6} xs={12}>
                                <FormTextFieldBase
                                  fieldConfig={{
                                    name: `config.messageExamples[${parentIndex}][${index}].content.text`,
                                    placeholder:
                                      user === formValues.config?.name
                                        ? `Write the character's response...`
                                        : 'Write an example user message...',
                                  }}
                                  minRows={4}
                                  multiline
                                  value={content?.text}
                                />
                              </Grid>
                            ))}
                          </Grid>
                          <IconButton
                            onClick={() => removeMessageExample(parentIndex)}
                            size="small"
                          >
                            <IconCircleMinus />
                          </IconButton>
                        </Stack>
                      </Grid>
                    ),
                  )}
                </Stack>
                {sm && (
                  <Button fullWidth onClick={addMessageExample}>
                    Add
                  </Button>
                )}
              </Stack>
            </Label>
          </FormGroupContainer>
          <FormGroupContainer title="Adjectives">
            <Label
              label="Character adjectives"
              labelEndAdornment={
                md && (
                  <Button onClick={addAdjective} size="small">
                    Add
                  </Button>
                )
              }
            >
              <Stack spacing={5} width="100%">
                <Stack spacing={1} width="100%">
                  {formValues.config?.adjectives?.map((adjective: string, index: number) => (
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      key={['adjective', index].join('-')}
                      width="100%"
                    >
                      <FormTextFieldBase
                        fieldConfig={{
                          name: `config.adjectives[${index}]`,
                          placeholder: 'Enter an adjective',
                        }}
                        value={adjective}
                      />
                      <IconButton onClick={() => removeAdjective(index)} size="small">
                        <IconCircleMinus />
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
                {sm && (
                  <Button fullWidth onClick={addAdjective}>
                    Add
                  </Button>
                )}
              </Stack>
            </Label>
          </FormGroupContainer>
          <FormGroupContainer title="People">
            <Label
              label="Known people"
              labelEndAdornment={
                md && (
                  <Button onClick={addPeople} size="small">
                    Add
                  </Button>
                )
              }
            >
              <Stack spacing={5} width="100%">
                <Stack spacing={1} width="100%">
                  {formValues.config?.people?.map((people: string, index: number) => (
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      key={['people', index].join('-')}
                      width="100%"
                    >
                      <FormTextFieldBase
                        fieldConfig={{
                          name: `config.people[${index}]`,
                          placeholder: `Enter person's name`,
                        }}
                        value={people}
                      />
                      <IconButton onClick={() => removePeople(index)} size="small">
                        <IconCircleMinus />
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
                {sm && (
                  <Button fullWidth onClick={addPeople}>
                    Add
                  </Button>
                )}
              </Stack>
            </Label>
          </FormGroupContainer>
        </Stack>
      </FormProvider>
    </Stack>
  );
};
