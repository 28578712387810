import { AxiosResponse } from 'axios';
import { raas } from 'shared/api';
import { ValidatePromoCodeReply, ValidatePromoCodeRequest } from 'shared/types/protoc-gen/raas-rollup';

export const verifyPromoCode = async (code?: string): Promise<boolean> => {
  if (!code) {
    throw Error('No code provided');
  }

  const res = await raas.post<ValidatePromoCodeRequest, AxiosResponse<ValidatePromoCodeReply>>(
    `/bff/promo`,
    {
      code,
    },
  );

  if (!res?.data?.valid) {
    throw Error('Promo Code is Invalid');
  }

  return res?.data?.valid;
};
