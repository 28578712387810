import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Section } from 'shared/components';

import WithdrawalSubSectionHeader from './WithdrawalSubSectionHeader';

// TODO: Just skeleton UI -> Update to BE data later

const CollectionHistorySection: React.FC = () => {
  return (
    <Section title="Collection history">
      <WithdrawalSubSectionHeader title="Monthly Bill" />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {['DATE', 'TOTAL FEE', 'ALTLAYER FEE', 'GAS FEE', 'BILL'].map(header => (
                <TableCell key={header}>
                  <Typography variant="captionC">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 4 }, (_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="bodySmall">2024/12</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodySmallM">10.3423 ETH</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodySmallM">0.517115 ETH</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodySmallM">0.0012 ETH</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodySmallM">9.823985 ETH</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <WithdrawalSubSectionHeader title="Collection Details" />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {['FEE VAULT', 'TX HASH', 'AMOUNT (ETH)', 'STATUS', 'TIME'].map(header => (
                <TableCell key={header}>
                  <Typography variant="captionC">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 4 }, (_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="bodySmall">L1FeeVault</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      color: theme => theme.colors.functional.text.link,
                    }}
                    variant="bodySmall"
                  >
                    0xd06b….9631
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodySmallM">10.002312</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodySmall">Finalized</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodySmall">2024/08/01 12:00:00</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
};

export default CollectionHistorySection;
