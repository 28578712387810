import { useQuery } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import useSubmitTemplateRequest from 'pages/aaas/AaasDeployments/components/AgentFrameworksCarousel/useSubmitTemplateRequest';
import { useMemo } from 'react';
import { raas } from 'shared/api';
import { ListAAATemplateReply, ListAAATemplateRequest } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

export default function useManageAgentRequests({
  onReviewSuccess,
}: {
  onReviewSuccess?: () => void;
}) {
  const { addAlert } = useAlerts();

  const { data: pendingRequests, isPending: isLoadingRequests } = useQuery({
    queryKey: [QUERY_KEYS.AAAS_PENDING_AGENT_REQUESTS],
    queryFn: async () => {
      const res = await raas.get<ListAAATemplateReply>('/bff/aaa/templates', {
        params: { isPending: true } satisfies ListAAATemplateRequest,
      });

      return res?.data?.appStore;
    },
  });

  const { isSubmittingRequest: isSubmittingReview, submitRequest: reviewRequest } =
    useSubmitTemplateRequest({
      onSuccess: () => {
        addAlert({
          severity: ALERT_SEVERITY.SUCCESS,
          title: 'Request has been updated.',
        });
        onReviewSuccess?.();
      },
    });

  return useMemo(
    () => ({
      pendingRequests,
      isLoadingRequests,
      reviewRequest,
      isSubmittingReview,
    }),
    [pendingRequests, isLoadingRequests, reviewRequest, isSubmittingReview],
  );
}
