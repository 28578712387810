import React from 'react';

import {
  CollectionHistorySection,
  FeeCollectionSection,
  FeeWithdrawalWorkflowSection,
} from './components';

const FeeWithdrawalView: React.FC = () => {
  return (
    <>
      <FeeWithdrawalWorkflowSection />

      <FeeCollectionSection />

      <CollectionHistorySection />
    </>
  );
};

export default FeeWithdrawalView;
