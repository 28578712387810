import { UseQueryOptions } from '@tanstack/react-query';
import { useRaasDeployments } from 'pages/raas/hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Rollup } from 'shared/types/protoc-gen/raas-rollup';

export interface IUseRaasDeployment
  extends Omit<UseQueryOptions<Rollup | undefined>, 'queryKey' | 'queryFn'> {
  rollupId?: string | undefined;
}

export const useRaasDeployment = (options?: IUseRaasDeployment) => {
  const params = useParams();
  const _rollupId = useMemo(
    () => options?.rollupId ?? params?.rollupId,
    [params?.rollupId, options?.rollupId],
  );
  const { data: deployments, ...queryResult } = useRaasDeployments();

  return { data: deployments?.find(rollup => rollup?.id === _rollupId), ...queryResult };
};
