import { Box, Button, Typography } from '@mui/material';
import { useListAppTypes } from 'pages/aaas/hooks';
import { templateNamesToIconsMap } from 'pages/aaas/mappings';
import { useMemo } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import TabbedSection from 'shared/components/TabbedSection';
import { AAATemplateStatus, aAATemplateStatusFromJSON } from 'shared/types/protoc-gen/bffaaa';

import { AgentCarouselCardProps } from './AgentFrameworksCarousel/AgentCarouselCard';
import { AgentFrameworksCarouselSkeleton } from './AgentFrameworksCarousel/AgentFrameworksCarousel';
import { AgentCarouselTags } from './AgentFrameworksCarousel/CarouselCardTag';
import AgentFrameworksCarousel from './AgentFrameworksCarousel';

const statusToTagMap: Partial<Record<AAATemplateStatus, AgentCarouselTags>> = {
  [AAATemplateStatus.AAA_TEMPLATE_STATUS_PUBLISHED]: AgentCarouselTags.PUBLISHED,
  [AAATemplateStatus.AAA_TEMPLATE_STATUS_SUBMITTED]: AgentCarouselTags.UNPUBLISHED,
  [AAATemplateStatus.AAA_TEMPLATE_STATUS_REJECTED]: AgentCarouselTags.REJECTED,
  [AAATemplateStatus.AAA_TEMPLATE_STATUS_PENDING]: AgentCarouselTags.PENDING_APPROVAL,
};

export const AgentFrameworksSection: React.FC = () => {
  const navigate = useNavigate();
  const { data, isPending } = useListAppTypes();

  const builtInTemplates: AgentCarouselCardProps[] = useMemo(() => {
    const featuredCards =
      data?.builtin?.map(cur => ({
        ...cur,
        tagNames: cur.id.toLowerCase().trim() !== 'base agent' ? [AgentCarouselTags.FEATURED] : [],
        iconLink: templateNamesToIconsMap?.[cur?.name],
      })) || [];

    // TODO: Temporary solution for now might remove later
    const hyperbolic = data?.appStore.find(cur => cur.name?.toLowerCase().includes('hyperbolic'));

    if (hyperbolic && hyperbolic.id && hyperbolic.name) {
      featuredCards.push({
        ...hyperbolic,
        tagNames: [AgentCarouselTags.FEATURED],
      });
    }

    return featuredCards;
  }, [data?.builtin, data?.appStore]);

  const appStoreTemplates: AgentCarouselCardProps[] = useMemo(
    () =>
      data?.appStore
        ?.filter(
          cur =>
            AAATemplateStatus.AAA_TEMPLATE_STATUS_DELETED !== cur?.status &&
            // TODO: Temporary solution for now might remove later
            !cur.name?.toLowerCase().includes('hyperbolic'),
        )
        ?.map(cur => ({
          ...cur,
          tagNames: [AgentCarouselTags.COMMUNITY],
        })) || [],
    [data?.appStore],
  );
  const selfOwnedTemplates: AgentCarouselCardProps[] = useMemo(
    () =>
      data?.selfOwned
        ?.filter(
          cur =>
            ![
              AAATemplateStatus.AAA_TEMPLATE_STATUS_DELETED,
              AAATemplateStatus.AAA_TEMPLATE_STATUS_PUBLISHED,
            ]?.includes(aAATemplateStatusFromJSON(cur?.status)),
        )
        ?.map(cur => ({
          ...cur,
          tagNames: [
            (cur?.status && statusToTagMap[aAATemplateStatusFromJSON(cur?.status)]) ||
              AgentCarouselTags.UNRECOGNIZED,
          ],
        })) || [],
    [data?.selfOwned],
  );

  const tabs = useMemo(() => {
    return [
      {
        label: 'Store',
        content: isPending ? (
          <AgentFrameworksCarouselSkeleton />
        ) : (
          <AgentFrameworksCarousel
            cards={[...builtInTemplates, ...appStoreTemplates]}
            key="store-carousel" // key is required for carousel to re-mount on tab change
          />
        ),
      },
      {
        label: 'My Uploads',
        content: isPending ? (
          <AgentFrameworksCarouselSkeleton />
        ) : (
          <AgentFrameworksCarousel cards={selfOwnedTemplates} key="uploads-carousel" /> // key is required for carousel to re-mount on tab change
        ),
      },
    ];
  }, [isPending, builtInTemplates, appStoreTemplates, selfOwnedTemplates]);

  return (
    <Box pt={5} px={{ lg: 5, xs: 2, sm: 3, background: '#fafafa' }}>
      <Typography mb={3} mt={2} variant="h5">
        Agent frameworks
      </Typography>
      <TabbedSection
        endAdornment={
          <Button
            onClick={() => navigate(PATHS.AAAS_PUBLISH_AGENT)}
            sx={{ '&,&:hover': { borderColor: '#202723' }, width: { xs: '100%', sm: 'auto' } }}
            variant="outlined"
          >
            <Typography
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: 2 }}
              variant="bodySmall"
            >
              <IoAddCircleSharp size={30} />
              Upload new framework
            </Typography>
          </Button>
        }
        sx={{
          '&': {
            flexWrap: { xs: 'wrap', lg: 'nowrap' },
            rowGap: 2,
            columnGap: 2,
          },
        }}
        tabs={tabs}
      />
    </Box>
  );
};
