import { PromptReply } from '../hooks';

export function findLongestStringOfLastArrayElement(input: string | any[] | object): string {
  if (typeof input === 'string') {
    return input;
  }

  if (Array.isArray(input)) {
    if (input.length === 0) return '';
    const lastElement = input[input.length - 1];

    return findLongestStringOfLastArrayElement(lastElement);
  }

  if (typeof input === 'object' && input !== null) {
    let longestString = '';

    for (const [, value] of Object.entries(input)) {
      const candidate = findLongestStringOfLastArrayElement(value);

      if (candidate.length > longestString.length) {
        longestString = candidate;
      }
    }

    return longestString;
  }

  return ''; // Default return for unsupported types
}

export function flexiParseAgentResponse(response: PromptReply) {
  return findLongestStringOfLastArrayElement(response);
}
